var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { style: _vm.tableDimension }, [
    _c(
      "table",
      [
        _c(
          "thead",
          _vm._l(_vm.headers, function (header, index) {
            return _c(
              "th",
              {
                key: `dark_table_${header.key}_${index}`,
                staticClass: "user-select-none",
                class: { clickable: _vm.sortByCol !== null && header.sortable },
                style: header.thStyle,
                attrs: { scope: "col" },
                on: {
                  click: function ($event) {
                    return _vm.handleClickForSort(header)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                  },
                  [
                    _vm._t(
                      `header_${header.key}`,
                      function () {
                        return [
                          _vm._v(
                            "\r\n                        " +
                              _vm._s(
                                header.showHeader &&
                                  !!header.label &&
                                  !header.label.includes("null")
                                  ? header.label
                                  : null
                              ) +
                              "\r\n                    "
                          ),
                        ]
                      },
                      { data: _vm.getDataItem(header, null, index) }
                    ),
                    _vm._v(" "),
                    header.showHeader && header.sortable
                      ? _c("div", {
                          staticClass: "ml-2",
                          domProps: {
                            innerHTML: _vm._s(_vm.createChevrons(header)),
                          },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        !_vm.isRowDraggable
          ? _c(
              "tbody",
              { staticClass: "position-relative" },
              [
                _vm._t("body", function () {
                  return [
                    !_vm._isNullOrEmpty(_vm.pageinatedItems)
                      ? [
                          _vm._l(_vm.pageinatedItems, function (item, index) {
                            return [
                              _vm._t(
                                `row_${item.id}`,
                                function () {
                                  return [
                                    _c(
                                      "tr",
                                      { key: index },
                                      _vm._l(_vm.headers, function (column) {
                                        return _c(
                                          "td",
                                          {
                                            key: `td_${column.key}`,
                                            style: column.colStyle,
                                          },
                                          [
                                            _vm._t(
                                              `cell_${column.key}`,
                                              function () {
                                                return [
                                                  _vm._v(
                                                    "\r\n                                        " +
                                                      _vm._s(
                                                        _vm.getValue(
                                                          item,
                                                          column
                                                        )
                                                      ) +
                                                      "\r\n                                    "
                                                  ),
                                                ]
                                              },
                                              {
                                                data: _vm.getDataItem(
                                                  column,
                                                  item,
                                                  index
                                                ),
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                },
                                { data: _vm.getDataItem(null, item, index) }
                              ),
                            ]
                          }),
                        ]
                      : [
                          _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: _vm.headers.length } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _vm._t("empty-items", function () {
                                      return [
                                        !!_vm.filter &&
                                        typeof _vm.filter == "string"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "no-results-container",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-search",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "title" },
                                                  [_vm._v("No results found")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "subtitle" },
                                                  [
                                                    _vm._v(
                                                      "Your filters did not match any records. Please try again."
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : [
                                              _vm._v(
                                                "\r\n                                        No items to be displayed \r\n                                    "
                                              ),
                                            ],
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ],
                  ]
                }),
                _vm._v(" "),
                _vm.maxPageSize !== undefined &&
                !_vm._isNullOrEmpty(_vm.filteredItems) &&
                _vm.filteredItems.length > _vm.maxPageSize
                  ? _c("tr", { attrs: { id: "pagination-control" } }, [
                      _c(
                        "td",
                        { attrs: { colspan: _vm.headers.length } },
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.filteredItems.length,
                              "per-page": _vm.maxPageSize,
                              "first-number": "",
                              "last-number": "",
                              align: _vm.paginationPosition,
                            },
                            on: { change: _vm.changePage },
                            model: {
                              value: _vm.currentPage,
                              callback: function ($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isRowDraggable
          ? _vm._t("body", function () {
              return [
                !_vm._isNullOrEmpty(_vm.pageinatedItems)
                  ? _vm._l(_vm.pageinatedItems, function (item, index) {
                      return _c(
                        "tr",
                        { key: index },
                        [
                          _vm._t(
                            `row_${item.id}`,
                            function () {
                              return _vm._l(_vm.headers, function (column) {
                                return _c(
                                  "td",
                                  { key: `td_${column.key}` },
                                  [
                                    _vm._t(
                                      `cell_${column.key}`,
                                      function () {
                                        return [
                                          _vm._v(
                                            "\r\n                                " +
                                              _vm._s(
                                                _vm.getValue(item, column)
                                              ) +
                                              "\r\n                            "
                                          ),
                                        ]
                                      },
                                      {
                                        data: _vm.getDataItem(
                                          column,
                                          item,
                                          index
                                        ),
                                      }
                                    ),
                                  ],
                                  2
                                )
                              })
                            },
                            { data: _vm.getDataItem(null, item, index) }
                          ),
                        ],
                        2
                      )
                    })
                  : [
                      _c("tr", [
                        _c("td", { attrs: { colspan: _vm.headers.length } }, [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _vm._t("empty-items", function () {
                                return [
                                  !!_vm.filter && typeof _vm.filter == "string"
                                    ? _c(
                                        "div",
                                        { staticClass: "no-results-container" },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-search",
                                          }),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "title" }, [
                                            _vm._v("No results found"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "subtitle" },
                                            [
                                              _vm._v(
                                                "Your filters did not match any records. Please try again."
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : [
                                        _vm._v(
                                          "\r\n                                    No items to be displayed \r\n                                "
                                        ),
                                      ],
                                ]
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ],
              ]
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.maxPageSize !== undefined &&
        !_vm._isNullOrEmpty(_vm.filteredItems) &&
        _vm.filteredItems.length > _vm.maxPageSize &&
        _vm.isRowDraggable
          ? _c("tr", { attrs: { id: "pagination-control" } }, [
              _c(
                "td",
                { attrs: { colspan: _vm.headers.length } },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.filteredItems.length,
                      "per-page": _vm.maxPageSize,
                      "first-number": "",
                      "last-number": "",
                      align: "center",
                    },
                    on: { change: _vm.changePage },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("tfoot", [_vm._t("footer")], 2),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }