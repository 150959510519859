<template>
    <b-container fluid class="h-100" :style="{'overflow-y': 'hidden'}">

        <!-- User Interface controls -->

        <b-navbar toggleable="lg" variant="secondary">
            <b-navbar-brand  class="font-weight-bold" size="xl" href="#" b>Process Logs</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-text><b>Sort:</b> {{ sortBy }}-
                        {{ sortDesc ? 'Descending' : 'Ascending' }}</b-nav-text>
                </b-navbar-nav>
                <b-container fluid style="margin-left:2rem;max-width:40%" >
                    <b-row >
                        <b-col class="dropdown"   md="4" >
                            <b-form-select v-model="filters['process']"  class="mb-3">
                                <template #first>
                                    <b-form-select-option value="" >Process</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="(proc, index) in allProcesses" :value="proc.process" :key="index">{{proc.process}}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                        <b-col class="dropdown" md="4" >
                            <b-form-select v-model="filters['name']"  class="mb-3">
                                <template #first>
                                    <b-form-select-option value="" >Well</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="(well, index) in wells" :value="well.name" :key="index">{{well.name}}</b-form-select-option>

                            </b-form-select>
                        </b-col>
                        <b-col class="dropdown"  md="4" >
                            <b-form-select v-model="filters['shift']"  class="mb-3">
                                <template #first>
                                    <b-form-select-option value="" >Shift</b-form-select-option>
                                </template>
                                <option v-for="(shift,index) in allShifts" :value="shift.start" :key="index">{{shortenYear(shift.start)}}</option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                </b-container>
                <b-navbar-nav class="ml-auto">
                    <b-form-group
                        label="Search"
                        label-for="search-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                    >
                        <b-input-group size="lg">
                            <b-form-input
                                id="search-input"
                                v-model="search"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-navbar-nav>

            </b-collapse>
        </b-navbar>
        <b-table id="scrollable-table"
                 hover
                 noCollapse
                 sticky-header="75vh"
                 :items="filtered"
                 :fields="fields"
                 :filter="search"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :per-page="perPage"
                 :current-page="currentPage"
                 table-variant="dark"
                 sort-icon-left
                 responsive="lg"
        >
            <template #cell(name)="data">
                <div class="nameplate" :style="{background:data.item.color, 'color':nameColor(data.item)}">
                    {{data.value}}
                </div>
            </template>
            <template #cell(stageNumber)="data">
                <div class="justify-content-center">
                    {{data.value}}
                </div>
            </template>
            <template #cell(show_details)="data">
                <b-button size="lg" @click="data.toggleDetails" class="mr-2">
                    {{ data.detailsShowing ? '▲' : '▼'}}
                </b-button>
            </template>
            <template #cell(processStatus)="data">
                <div v-show="['In Progress', 'request', 'bypassActuate'].includes(data.item.processStatus) || (data.item.processStatus == 'resolved' && data.item.events.length > 1)">
                    <i class="fa fa-spinner fa-spin fa-3x ml-1"></i>
                </div>
                <div v-show="['completed'].includes(data.item.processStatus) || (data.item.processStatus == 'resolved' && data.item.events.length === 1)">
                    <i class="fa fa-check fa-3x ml-1"></i>
                </div>
                <div v-show="!['In Progress', 'completed', 'resolved', 'request', 'bypassActuate'].includes(data.item.processStatus)">
                    <i class="fa fa-times fa-3x ml-1"></i>
                </div>
            </template>

            <template #row-details="row" class="rowDetails">
                <div class="stepContainer">
                    <ProcessLogStepComponent
                        v-for="(event,index) in row.item.events"
                        :event="event"
                        :key="index"
                        :offset="job.hourOffset"
                        :wells="wells"
                        :processName=" row.item.process"></ProcessLogStepComponent>
                </div>
            </template>
        </b-table>

        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            class="justify-content-center"
        ></b-pagination>
    </b-container>
</template>

<script>
import moment from 'moment';
import ProcessLogStepComponent from './ProcessLogStepComponent.vue'
import GlobalFunctions from '../../GlobalFunctions';

export default {
    data() {
        return {
            sortBy: 'processStartTime',
            sortDesc: true,
            search: null,
            webServerLocalTimestamp:'',
            allShifts:[],
            allProcesses:[],
            allRequesters:[],
            numberOfShifts:10,
            wellColor:'red',
            filters:{
                name:'',
                statusMessage:'',
                requesterUser:'',
                shift:'',
                process:''
            },
            fields: [
                { key: 'process', thClass: 'd-none', tdClass: 'd-none', formatter:'formatProcesses', sortable: true},
                { key: 'statusMessage', label:'Process', sortable: true},
                { key: 'name', label:'Well', thStyle: { width: '120px'}, tdStyle: { width: '120px'}, sortable: true },
                { key: 'stageNumber', label:'Stage', sortable: true },
                { key: 'date', label:'Date', formatter:'formatDate', sortable: false },
                { key: 'processStartTime', label:'Start Time', formatter:'formatStartTime', sortable: true },
                { key: 'finalProcessTime', label:'End Time', formatter:'formatEndTime', sortable: false },
                { key: 'completedBy', label:'Completed By', sortable: true },
                { key: 'shift',label: 'Shift',formatter:'formatShifts',sortable: true},
                { key: 'processStatus', label:"Status", sortable: false},
                { key: 'handshake_duration', label:'Handshake Duration', sortable: false},
                { key: 'process_duration', label:'Process Duration',  sortable: false},
                'show_details'
            ],
            items: this.events.data,
            allEvents: this.events.data,
            processes: {},
            perPage: 10,
            currentPage: 1,
            rows: 1,
        }
    },
    mounted() {
        this.rows = this.items.length;
        let self = this;
        this.$root.$on('mqttConnected',function(){
            self.mqttInit();
        });
        this.updateOngoingTimes();
        setInterval(this.updateOngoingTimes, 1000);
    },
    computed: {
        filtered () {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key])
                )
            })
            this.rows = filtered.length;
            return filtered.length > 0 ? filtered : []
        }
    },
    methods:{
        get_Shifts(stime){
            let shift = {
                start:stime,
            }
            var i = this.allShifts.findIndex(x => x.start === shift.start)
            if(i==-1){
                this.allShifts.push(shift)
            }
        },
        get_Processes(value){
            let proc = {
                process:value,
            }
            var i = this.allProcesses.findIndex(x => x.process === proc.process)
            if(i==-1){
                this.allProcesses.push(proc)
            }
        },
        formatShifts(value, key, item){
            this.get_Shifts(item.shift);
            if ( this.filters['shift'] != ""){
                // Specific request that when filtering by shifts, we want to display all processes in one page
                this.perPage = this.filtered.length;
            }
            else{
                this.perPage = 10;
            }
            return item.shift;
        },
        formatProcesses(value, key, item){
            this.get_Processes(item.process);
            return item.process;
        },
        formatStartTime(value, key, item) {
            if(typeof item.processStartTime !== 'undefined'){
                return moment(item.processStartTime).utcOffset(this.job.hourOffset*60).format('HH:mm:ssA');
            }else{
                return null;
            }
        },
        formatEndTime(value, key, item) {
            if(typeof item.finalProcessTime !== 'undefined' && item.finalProcessTime !== null){
                return moment(item.finalProcessTime).utcOffset(this.job.hourOffset*60).format('HH:mm:ssA');
            }else{
                return null;
            }
        },
        formatDate(value, key, item) {
            if(item !== null){
                return moment(item.processStartTime).utcOffset(this.job.hourOffset*60).format('YYYY-MM-DD');
                ;
            }else{
                return null;
            }
        },
        determineShift(timestamp){
            let amShift = moment(timestamp).hour(this.job.shiftStart).minute(0);
            let pmShift = moment(amShift).add(12, 'hours');
            let dayPriorPMShift = moment(pmShift).subtract(1, 'days');
            if (pmShift.isBefore(timestamp) ){
                return pmShift.format("YYYY-M-D A")
            }
            else if (amShift.isBefore(timestamp)){
                return amShift.format("YYYY-M-D A")
            }
            return dayPriorPMShift.format("YYYY-M-D A")
        },
        insertNewProcess(event,timestamp){
            let processInstanceId = event.data.process_instance_id;
            let relevantWell = this.wells.find(well => well.index === event.wellNumber)
            let shift = this.determineShift(timestamp);
            let completedBy = event.data.users?.map(user => user.user).join(", ")
            if (event.category === 'emergencyBypass' && event['subCategory']==="request")
                completedBy = event.data.requesterUser
            let process = {
                processInstanceId:processInstanceId,
                events:[],
                color:relevantWell.color,
                name:relevantWell.name,
                stageNumber:event.stageNumber,
                process:event.data.requestReason,
                statusMessage:event.data.statusMessage,
                processStartTime:timestamp.toISOString(),
                finalProcessTime:null,
                handshake_duration:null,
                process_duration:null,
                handshakeStatus:"In Progress",
                processStatus:"In Progress",
                completedBy: completedBy === undefined ? ""  : completedBy,
                shift:shift
            };
            event.timestamp = {date:timestamp.toISOString(), timezone:null};
            if(event.category==='inspectValves') {
                switch(event.subCategory) {
                    case 'mismatched':
                        process.handshakeStatus = event.subCategory
                        break;
                    case 'resolved':
                        process.processStatus = process.handshakeStatus = event.subCategory
                        process.process_duration = '00:00:00'
                        process.finalProcessTime = process.processStartTime
                        break;
                    default:
                        break;
                }
            }
            process.events.push(event);
            this.items.push(process);
        },
        insertNewEvent(event, timestamp){
            let processInstanceId = event.data.process_instance_id;
            let process = this.items.find(item => item.processInstanceId === processInstanceId);
            if (process){
                let prevEvent = process.events[process.events.length-1]
                let stepDuration = this.durationDiff(prevEvent.timestamp.date, timestamp)
                event.duration = stepDuration;
                event.timestamp = {date:timestamp.toISOString(), timezone:null};
                process.events.push(event);
                // See if there is any changes to process & handshake status from this event
                if (['processEvent', 'emergencyBypass'].includes(event.category) || event['subCategory']=="rejected"){
                    process.processStatus = event.subCategory;
                    process.finalProcessTime = timestamp.toISOString();
                    process.process_duration = this.durationDiff(process.processStartTime, timestamp)
                    if ( process.handshakeStatus=="In Progress"){
                        process.handshake_duration = process.process_duration;
                        process.handshakeStatus = "rejected";
                        process.completedBy = event.data.users?.map(user => user.user).join(", ")
                    }
                    if (event.category === 'emergencyBypass' && event['subCategory']==="completed" && event.data.hasOwnProperty('cancelledBy')) {
                        process.completedBy = event.data.requesterUser + ', ' + event.data.cancelledBy
                    }
                }
                else if ( event['category']=='handshake' && process.handshakeStatus=="In Progress"){
                    process.completedBy = event.data.users?.map(user => user.user).join(", ")
                    if(['rejected','completed'].includes(event.subCategory)){
                        process.handshakeStatus = event.subCategory;
                        process.handshake_duration = this.durationDiff(process.processStartTime, timestamp);
                    }
                }
            }
            else{
                this.insertNewProcess(event,timestamp);
            }

        },
        mqttInit(){
            var self = this;
            this.$root.$mqtt.on("connect", function() {
                self.$root.$mqtt.subscribe('hub/+/events');
                console.log('mqtt connected');
            });
            this.$root.$mqtt.on("message", function(topic, message) {
                var topicArray = topic.split("/");
                if (topicArray[0]=="hub" && topicArray[2]=="events"){
                    let decoded = new TextDecoder("utf-8").decode(message);
                    let event = JSON.parse(decoded);
                    if (event.data.hasOwnProperty('process_instance_id')){
                        let timestamp = moment();
                        self.insertNewEvent(event, timestamp);
                    }
                }
            });
        },
        durationDiff(oldTimestamp, newTimestamp=null){
            let oldMoment = moment(oldTimestamp);
            let newMoment = newTimestamp ? moment(newTimestamp) : moment()
            let duration = moment.duration(newMoment.diff(oldMoment));
            // Looks somewhat hack-y but this is a simple way to display the HH:mm:ss duration, HS should never exceed 1 day
            let string = moment('2000-01-01 00:00:00').add(duration).format('HH:mm:ss');
            return string
        },
        updateOngoingTimes() {
            Object.values(this.items).forEach(item => {
                if(item.processStatus == "In Progress"){
                    let ongoingDuration = this.durationDiff(item.processStartTime);
                    item.process_duration = ongoingDuration;
                    if(item.handshakeStatus == "In Progress" && item.category !== 'inspectValves'){
                        item.handshake_duration = ongoingDuration;
                    }
                }
            });
        },
        shortenYear(shift){
            return "'" + shift.substring(2)
        },
        nameColor(well){
            return GlobalFunctions.getTitleColorForBG(well.color);
        },
    },
    watch: {
        search(newVal) {
            this.currentPage = 1;
        }
    },
    props : {
        wells: {
            type: Array,
            default: []
        },
        job: {
            type: Object,
            default: {}
        },
        events: {
            type: Object,
            default: {}
        },
    },
    components:{
        ProcessLogStepComponent
    }
}
</script>

<style>
#scrollable-table{
    max-height: 70vh;
    overflow-y: scroll;
}

.stepContainer{
    align-items: center;
    display:flex;
    justify-content: center;
    flex-direction: column;
}
.nameplate{
    font-weight: bold;
    text-overflow: clip;
    border-radius: 25px;
    padding: 20px;
    white-space: nowrap;
}
</style>
