import { render, staticRenderFns } from "./iwsBadge.vue?vue&type=template&id=95e08eb2&scoped=true&"
import script from "./iwsBadge.vue?vue&type=script&lang=js&"
export * from "./iwsBadge.vue?vue&type=script&lang=js&"
import style0 from "./iwsBadge.vue?vue&type=style&index=0&id=95e08eb2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95e08eb2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/9878/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('95e08eb2')) {
      api.createRecord('95e08eb2', component.options)
    } else {
      api.reload('95e08eb2', component.options)
    }
    module.hot.accept("./iwsBadge.vue?vue&type=template&id=95e08eb2&scoped=true&", function () {
      api.rerender('95e08eb2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/Common/iwsBadge.vue"
export default component.exports