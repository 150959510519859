var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showModal
    ? _c(
        "div",
        [
          _c(
            "portal",
            { attrs: { to: "modals" } },
            [
              _c("transition", { attrs: { name: "modal" } }, [
                _c(
                  "div",
                  {
                    staticClass: "modal-mask positioned",
                    class: {
                      backdrop: _vm.showBackdrop,
                      ..._vm.classObj,
                    },
                    attrs: {
                      id: _vm.id,
                      onscroll:
                        "enableClickOutside ? event => event.preventDefault() : null",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "on-clickaway",
                            rawName: "v-on-clickaway",
                            value: _vm.handleClickAway,
                            expression: "handleClickAway",
                          },
                        ],
                        staticClass: "modal-container d-flex flex-column",
                        class: _vm.position,
                        style: {
                          "max-width": _vm.maxWidth,
                          "max-height": _vm.maxHeight,
                          top: _vm.top ? _vm.top + "px" : "",
                          left: _vm.left ? _vm.left + "px" : "",
                        },
                        attrs: { role: "dialog" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "header p-3" },
                          [
                            _vm._t("title", function () {
                              return [_c("div", [_vm._v(_vm._s(_vm.title))])]
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "close-button clickable",
                              attrs: { src: "/images/icons/close.svg" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeModal()
                                },
                              },
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "content",
                            on: {
                              "&scroll": function ($event) {
                                return _vm.onScroll.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._t("content")],
                          2
                        ),
                        _vm._v(" "),
                        (_vm.secondaryButtonText &&
                          _vm.secondaryButtonVisible) ||
                        _vm.primaryButtonVisible
                          ? _c(
                              "div",
                              { staticClass: "footer p-3" },
                              [
                                _vm._t("footer", function () {
                                  return [
                                    _vm.secondaryButtonText &&
                                    _vm.secondaryButtonVisible
                                      ? _c("iws-button", {
                                          attrs: {
                                            text: _vm.secondaryButtonText,
                                            type: "outline-light",
                                            disabled:
                                              _vm.secondaryButtonDisabled,
                                          },
                                          on: { click: _vm.secondaryClicked },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.primaryButtonVisible
                                      ? _c("iws-button", {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            text: _vm.primaryButtonText,
                                            type: "primary",
                                            disabled: _vm.primaryButtonDisabled,
                                            click: _vm.primaryButtonClick,
                                          },
                                          on: { click: _vm.primaryClicked },
                                        })
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }