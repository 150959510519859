var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "CameraSettings" } },
    [
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isBusy.busy,
            variant: "light",
            blur: "5px",
            opacity: "0.90",
          },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("b-spinner", {
                        staticClass: "align-middle",
                        attrs: { large: "" },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "mt-2" }, [
                        _vm._v(
                          _vm._s(_vm.isBusy.state) +
                            ", this may take a while..."
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "position-relative" },
            [
              _c("iws-breadcrumbs", {
                attrs: {
                  path: [
                    {
                      title: "Devices",
                      link: `/cameras/settings`,
                    },
                    {
                      title: _vm.device?.deviceID,
                      link: `/cameras/settings/${_vm.workOrderId}/${_vm.device?.deviceID}`,
                    },
                    {
                      title: _vm.camera?.displayName,
                      link: `/cameras/settings/${_vm.workOrderId}/${_vm.device?.deviceID}/${_vm.camera?.id}`,
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "page-title" }, [
                _vm._v("\n                Camera Configuration \n            "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "positioned-actions" },
                [
                  _vm.camera?.primaryStream?.id
                    ? _c(
                        "a",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            href: `/cameras/viewer/${_vm.workOrderId}/${_vm.camera?.id}/${_vm.camera?.primaryStream?.id}`,
                          },
                        },
                        [
                          _c("iws-button", {
                            attrs: {
                              type: "outline-primary",
                              icon: "far fa-eye",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("iws-button", {
                    attrs: {
                      type: "outline-light",
                      text: "Keychain",
                      click: _vm.openAccountsModal,
                    },
                  }),
                  _vm._v(" "),
                  _c("iws-button", {
                    staticClass: "mx-2",
                    attrs: {
                      type: "outline-light",
                      text: "Sync from ONVIF",
                      disabled: _vm.disableSyncWithOnvif,
                      click: _vm.onClickSyncWithOnvif,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "status-border",
                      class: {
                        "green-gradient": _vm.camera?.online,
                        "red-gradient": !_vm.camera?.online,
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.camera?.online ? "Online" : "Offline") +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "full-width" }, [
                _c(
                  "span",
                  { attrs: { id: "search" } },
                  [
                    _c("iws-search", {
                      attrs: { value: _vm.searchKey },
                      on: {
                        "update:value": function ($event) {
                          _vm.searchKey = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { attrs: { id: "add-new" } },
                  [
                    _c("iws-button", {
                      attrs: {
                        text: "New Stream",
                        "append-icon": "fas fa-plus",
                        click: _vm.addStream,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              !_vm._isNullOrEmpty(_vm.streamConfigurations)
                ? _c(
                    "div",
                    [
                      _c("iws-table", {
                        attrs: {
                          columns: _vm.columns,
                          items: _vm.streamConfigurations,
                          filter: _vm.searchKey,
                          sortByCol: _vm.sortByCol,
                          maxPageSize: 12,
                        },
                        on: {
                          "update:sortByCol": function ($event) {
                            _vm.sortByCol = $event
                          },
                          "update:sort-by-col": function ($event) {
                            _vm.sortByCol = $event
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell_broadcast",
                              fn: function ({ data }) {
                                return [
                                  _c("iws-checkbox", {
                                    attrs: { value: data.item.broadcast },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          data.item,
                                          "broadcast",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "cell_isAIEnabled",
                              fn: function ({ data }) {
                                return [
                                  _c("iws-checkbox", {
                                    attrs: { value: data.item.isAIEnabled },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          data.item,
                                          "isAIEnabled",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "header_resolutionData",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-100 d-flex flex-column justify-content-between",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "align-self-center" },
                                        [_vm._v("Resolution")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-around",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "\n                                    Field\n                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                                    Cloud\n                                "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "header_bitrateData",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-100 d-flex flex-column justify-content-between",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "align-self-center" },
                                        [_vm._v("Bitrate (kbps)")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-around",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "\n                                    Field\n                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                                    Cloud\n                                "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "header_fpsData",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-100 d-flex flex-column justify-content-between",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "align-self-center" },
                                        [_vm._v("FPS")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-around",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "\n                                    Field\n                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                                    Cloud\n                                "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "cell_resolutionData",
                              fn: function ({ data }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-around",
                                    },
                                    [
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              data.item.resolutionData.field
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              data.item.resolutionData.cloud
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell_bitrateData",
                              fn: function ({ data }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-around",
                                    },
                                    [
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(data.item.bitrateData.field)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(data.item.bitrateData.cloud)
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell_fpsData",
                              fn: function ({ data }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-around",
                                    },
                                    [
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(data.item.fpsData.field)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(data.item.fpsData.cloud)
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell_actions",
                              fn: function ({ data }) {
                                return [
                                  _c("iws-button", {
                                    attrs: {
                                      type: "outline-light",
                                      icon: "fas fa-cog",
                                      click: () => _vm.editStream(data.item),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("iws-button", {
                                    staticClass: "ml-2",
                                    attrs: {
                                      type: "outline-info",
                                      icon: "fas fa-pencil-alt",
                                      click: () =>
                                        _vm.editStreamQuality(data.item),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("iws-button", {
                                    staticClass: "ml-2",
                                    attrs: {
                                      type: "outline-danger",
                                      icon: "fas fa-trash-alt",
                                      disabled: data.item.isSelected,
                                      click: () => _vm.deleteStream(data.item),
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3948978523
                        ),
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-container" }, [
                        _c("label", [
                          _vm._v(
                            "\n                        Local Configuration\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("iws-input", {
                                attrs: {
                                  value: _vm.camera.hostname,
                                  label: "Hostname",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.camera,
                                      "hostname",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("iws-input", {
                                attrs: {
                                  value: _vm.camera.onvifUsername,
                                  label: "Username",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.camera,
                                      "onvifUsername",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("iws-input", {
                                attrs: {
                                  value: _vm.camera.onvifPassword,
                                  label: "Password",
                                  type: "password",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.camera,
                                      "onvifPassword",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("iws-input", {
                                attrs: {
                                  value: _vm.camera.cameraName,
                                  label: "Camera Name",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.camera,
                                      "cameraName",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("iws-input", {
                                attrs: {
                                  value: _vm.camera.brand,
                                  label: "Brand",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(_vm.camera, "brand", $event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("iws-input", {
                                attrs: {
                                  value: _vm.camera.model,
                                  label: "Model",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(_vm.camera, "model", $event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("iws-input", {
                                attrs: {
                                  value: _vm.camera.displayName,
                                  label: "Display Name",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.camera,
                                      "displayName",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-container" }, [
                        _c("label", [
                          _vm._v(
                            "\n                        AI Configuration\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("iws-input", {
                                attrs: {
                                  value: _vm.camera.aiPersonConfidenceThreshold,
                                  label: "Person Confidence Threshold",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.camera,
                                      "aiPersonConfidenceThreshold",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-3 d-flex flex-column-reverse" },
                            [
                              _c("iws-checkbox", {
                                staticClass: "mb-2",
                                attrs: {
                                  value: _vm.camera.aiPersonDetectionEnabled,
                                  label: "Enable Person Detection",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.camera,
                                      "aiPersonDetectionEnabled",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-3 d-flex flex-column-reverse" },
                            [
                              _c("iws-checkbox", {
                                staticClass: "mb-2",
                                attrs: {
                                  value: _vm.camera.showAIDebugInfo,
                                  label: "Show AI Debug Info",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.camera,
                                      "showAIDebugInfo",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("iws-input", {
                                staticClass: "mb-2",
                                attrs: {
                                  value: _vm.camera.aiRedZoneViolationSeconds,
                                  label: "Redzone Violation Seconds",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.camera,
                                      "aiRedZoneViolationSeconds",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-3 d-flex flex-column-reverse" },
                            [
                              _c("iws-checkbox", {
                                staticClass: "mb-2",
                                attrs: {
                                  value: _vm.camera.aiRedZoneDetectionEnabled,
                                  label: "Enable Red Zone Detection",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.camera,
                                      "aiRedZoneDetectionEnabled",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "page-save-action full-width" },
                        [
                          _c("iws-button", {
                            attrs: { text: "Save", click: _vm.saveDevice },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "no-items clickable",
                      on: {
                        click: function ($event) {
                          return _vm.addStream()
                        },
                      },
                    },
                    [
                      _c("h2", { staticClass: "danger-text-color" }, [
                        _vm._v(
                          "\n                    No streams configured for this camera\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("h3", [
                        _vm._v(
                          "\n                    Click here to create one\n                "
                        ),
                      ]),
                    ]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c("add-stream-modal", { ref: "AddStreamModal" }),
          _vm._v(" "),
          _c("stream-quality-modal", { ref: "StreamQualityModal" }),
          _vm._v(" "),
          _c("keychain-modal", { ref: "KeychainModal" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }