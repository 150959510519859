
<template>
<div>
    <span class="dot">
        <b-button class="dot-clickable" ref="marker" :id="'create-msg-target'+_uid" />
    </span>
   
    <div id="fake-popover" :class="{ 'low-opacity': activeDrawing }" :style="popupPosition">
       
        <div class="text-white pt-2" style="display: flex; justify-content: space-between;">
            <div>
                <h5>Frac NPT Event</h5>
            </div>

        <div>
            <b-icon-arrows-move id="NPT-Event-Drag-icon" style="width: 20px; height: 20px; margin-right: 10px;" ></b-icon-arrows-move>
        </div>
    </div>
        <template v-if="loadingAutoSelect">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="text-white">Loading auto select data....</div>
        </template>
        <template v-else>
            <div class="input-group-sm mt-1 mb-3 white-text">
                <label for="startTimePicker" class="p-0 m-1">Start:</label>
                <input  :disabled="startNPT? true : false" 
                    type="datetime-local" :value="startTime" 
                    @focus="activeDateInput = 'startTimePicker'" @blur="dateBlurred('startTimePicker')"
                    step="1" class="form-control form-control-sm stopPropagation" :placeholder="startTime" id="startTimePicker" @input="(event) => onCalendarChange(event.target.value, endTime)"
                    :style="{backgroundColor: startNPT? 'lightgrey': ''}"
                    :max="new Date().toISOString().split('.')[0]"
                />
                <label v-if="mode != 'startEvent'" for="endTimePicker" class="p-0 m-1">End:</label>
                <input v-if="mode != 'startEvent'" type="datetime-local" :value="endTime"
                    @focus="activeDateInput = 'endTimePicker'" @blur="dateBlurred('endTimePicker')" 
                    step="1" class="form-control form-control-sm stopPropagation" :placeholder="endTime" id="endTimePicker" @input="(event) =>  onCalendarChange(startTime, event.target.value)"
                    :min="startTime" :max="new Date().toISOString().split('.')[0]"/>
                <div class="text-white">
                    <small>( The end date cannot overlap with the next frac stage. )</small>
                </div>
                <label v-if="mode != 'startEvent'" class="p-0 m-1">Duration:</label>
                <div v-if="mode != 'startEvent'" class="d-flex flex-row align-items-center stopPropagation">
                    <input type="number" v-model="duration.hours" placeholder="hh" min="0" max="59" class="form-control form-control-sm" style="width:60px"/> 
                    <div class="px-1">h</div>
                    <input type="number" v-model="duration.minutes" min="0" max="59" placeholder="mm" class="form-control form-control-sm" style="width:60px"/>
                    <div class="px-1">m</div>
                    <input type="number" v-model="duration.seconds" min="0" max="59" placeholder="ss" class="form-control form-control-sm" style="width:60px"/>
                    <span class="px-1">s</span>
                </div>
            </div>
            <div v-if="!activeDrawing">
                <loading
                    :active.sync="loadingAutoConfig"
                    :is-full-page="false"
                    :opacity="0.1"
                />
                <div class="input-group-sm mb-3 d-flex flex-column">
                    <label for="comment" class="p-0 m-1 white-text">Comment:</label>
                    <textarea id="comment" v-model="commentText" style="resize: none;" rows="5" class="form-control w-100 stopPropagation" aria-label="With textarea" placeholder="Type your comment here..."></textarea>
                </div>
                <div class="input-group mb-3 white-text">
                    <div class="col-12 m-0 p-0">
                        <div class="list-label">
                            Well:
                        </div>
                        <select class="form-control form-control-sm stopPropagation" v-model="selectedWell" @change="updateStageOptions" :disabled="startNPT? true : false" :class="{'cursor-disabled': startNPT}">
                            <option selected :value="null" disabled> --No Well--</option>
                            <option v-for="(item) in wells" :value="item" v-bind:key="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="input-group mb-3 white-text">
                    <div class="col-12 m-0 p-0">
                        <label for="startTimePicker" class="p-0 m-1">Stage Number:</label>
                        <div v-if="loadingStageNumber" class="spinner-border spinner-border-sm text-primary " role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <input v-else
                            :disabled="startNPT ? true : false" 
                            v-model="selectedStage" 
                            class="form-control form-control-sm stopPropagation"
                            placeholder="N/A ( Well number missing )"
                            id="stageNumberInput"
                            type="number"
                            min="1"
                            :max="maxStage"
                        />
                    </div>
                </div>
                <div class="input-group mb-3 white-text">
                    <div class="col-12 m-0 p-0">
                        <div class="list-label">
                            NPT Description:
                        </div>
                        <select class="form-control form-control-sm stopPropagation" v-model="selectedReasonId" :disabled="startNPT? true:false" :class="{'cursor-disabled': startNPT}">
                            <option selected :value="null" disabled> --Not Set--</option>
                            <option v-for="(item) in stepDescriptions" :value="item.id" v-bind:key="item.id">{{item.type}}</option>
                        </select>
                    </div>
                </div>
                <div class="input-group mb-3 white-text" v-if="nptOutcomes && nptOutcomes.length > 0">
                    <div class="col-12 m-0 p-0">
                        <div class="list-label">
                            NPT Outcome:
                        </div>
                        <select class="form-control form-control-sm stopPropagation" v-model="selectedOutcomeId" :class="{'cursor-disabled': startNPT}">
                            <option selected :value="null">--Not Set--</option>
                            <option v-for="(item) in nptOutcomesSorted" :value="item.id?.toLowerCase()" v-bind:key="item.id?.toLowerCase()">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="input-group mb-3 white-text" v-if="customerVendors && customerVendors.length > 0">
                    <div class="col-12 m-0 p-0">
                        <div class="list-label">
                            NPT Responsible Vendors:
                        </div>
                        <checkbox-list     
                            class="w-50 ml-1 stopPropagation"             
                            :enableSearch="true"
                            :valueKey="'friendly_name'"
                            :outputKey="'id'"
                            :height="120"
                            :maxHeight="120"
                            :options="sortedCustomerVendors"
                            fullWidth
                            v-bind:selectedOptions="selectedCustomerVendors"
                            v-on:update:selectedOptions="selectedCustomerVendors = $event"
                            >
                        </checkbox-list>
                    </div>
                </div>
                <div class="input-group mb-3 white-text stopPropagation">
                    <iws-checkbox
                        :value.sync="selectedNonFracNPT"
                        label="Non Frac NPT Event"
                    />
                </div>
                <div v-if="responseMessage"  class="alert" :class="{ 'alert-success': !responseError, 'alert-danger': responseError }">
                    {{ responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1) }}
                </div>

                <div id="sticky-actions" class="d-flex">
                    <div class="flex-grow-1"></div>
                    <button class="btn btn-secondary btn-sm mr-2" @click="onCancelClick" :disabled="loading">
                        Cancel
                    </button>
                    <button class="btn btn-primary btn-sm" v-on:click="handleCommentButtonClick" :disabled="durationError || !hasEditPermission">
                        <div class="spinner-border text-light spinner-border-sm" role="status" v-if="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-if="startNPT">End NPT</span>
                        <span v-else-if="editingNPT && editingNPT.length > 0">Update NPT</span>
                        <span v-else>Create NPT</span>
                    </button>
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script>
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import GlobalFunctions from '../../GlobalFunctions.js';
const { isNullOrEmpty, isFalsy, toast } = GlobalFunctions;

const DONE_TYPING_INTERVAL = 1000;

export default {
    props: {
        startTimestamp: Number,
        timestamp: Number,
        jobHourOffset: Number,
        jobNumber: String,
        onMarkerRemoved: Function,
        onCommentsUpdated: Function,
        onPopoverShown: Function,
        onPopoverHidden: Function,
        eventActivities: Array,
        eventReasons: Array,
        nptOutcomes: Array,
        customerVendors: Array,
        eventActivityEventReasonsLookup: Object,
        contractors: Array,
        wells: Array,
        loadingAutoSelect: Boolean,
        headerStyle: String,
        activeDrawing: Boolean,
        popupPosition: Object|String,
        startNPT: Object,
        editingNPT: Array,
        wellStageIntervals: Object,
        mode: { //can be 'fullEvent' or 'startEvent'
            type: String,
            default: 'fullEvent'
        },
        nonFracNPT: Boolean,
        hasEditPermission: Boolean,
        timeseriesHeight:Number,
        timeseriesWidth:Number
    },
    mounted()
    {
        this.dragElement(document.getElementById("fake-popover"));

        //load up editing values
        if (this.editingNPT.length > 0) {
            this.startTime = this.editingNPT[0].data.startTime;
            if (this.editingNPTPair)
                this.endTime = this.editingNPT[1].data.endTime;
            
            this.commentText = this.editingNPT[0].data.comment;
            this.selectedWell = this.wells.find((well) => well.id?.toLowerCase() == this.editingNPT[0].wellId?.toLowerCase());
            this.updateStageOptions();
            this.selectedReasonId  = this.editingNPT[0].data.customerCodeStandardTagId?.toLowerCase() ?? null;
            this.selectedOutcomeId = this.editingNPT[0].data.nptOutcomeId?.toLowerCase() ?? null;

            this.selectedCustomerVendors = this.getCustomerVendorsFromIds(this.editingNPT[0].data.customerVendorIds);
            this.selectedStage = this.editingNPT[0].data.stageNumber;
            this.selectedNonFracNPT = this.editingNPT[0].data.nonFracNPT || false;
        }
        
        this.updateDateTime();
        this.$nextTick(() => {
            this.show = true;
            this.initStateCompleted = true;
        });
        
        if (this.startNPT)
        {
            this.$nextTick(()=>{
                this.startTime = this.startNPT.data.startTime;
                this.commentText = this.startNPT.data.comment;
                this.selectedWell = this.wells.find((well)=>well.index == this.startNPT.wellNumber);;
                this.selectedReasonId  = this.startNPT.data.customerCodeStandardTagId?.toLowerCase();
                this.selectedOutcomeId = this.startNPT.data.nptOutcomeId?.toLowerCase();
                this.selectedCustomerVendors = this.getCustomerVendorsFromIds(this.startNPT.data.customerVendorIds);
            });
        }
    },
    beforeDestroy() {
        this.onMarkerRemoved();
    },
    data() {
        return {
            durationError: false,
            show: false,
            startTime: '1900-01-01T00:00',
            endTime: '1900-01-01T00:00',
            duration: {
                hours: 0,
                minutes: 0,
                seconds: 0
            },
            commentText: '',
            activeDateInput: null,
            selectedActivityId: 1,
            selectedReasonId: null,
            selectedOutcomeId: null,
            selectedCustomerVendors: [],
            selectedContractorId: null,
            selectedWell: null,
            selectedStage: null,
            selectedNonFracNPT: false,
            activityReasonLookup: {},
            responseMessage: '',
            responseError: false,
            stageOptions: [],
            loading: false,
            loadingAutoConfig: false,
            initStateCompleted: false,
            activeDrag: false,
            loadingStageNumber: false,
            modalPosition: []
        };
    },
    computed: {
        stepDescriptions: function()
        {
            const regex = /^.+?_npt_.+$/;;
            return this.eventReasons.filter((stepObject)=>{
                if(stepObject?.standard_tag_details?.name) {
                    return regex.test(stepObject.standard_tag_details.name);
                } else {
                    return false
                }
            }).map((stepObject) => {
                return {
                    ...stepObject,
                    id: stepObject.id?.toLowerCase(),
                    type: stepObject.name
                };
            }).sort((a,b) => a.type.localeCompare(b.type));
        },
        nptOutcomesSorted: function() {
            return this.nptOutcomes.sort((a,b) => a.name.localeCompare(b.name));
        },
        editingNPTPair: function()
        {
            return this.editingNPT.length > 1;
        },
        sortedCustomerVendors: function() {
            return this.customerVendors.sort((a, b) => a.friendly_name.localeCompare(b.friendly_name));
        },
        maxStage() {
            if (!isNullOrEmpty(this.stageOptions))
                return Math.max(...this.stageOptions);

            if (!isNullOrEmpty(this.wells))
                return Math.max(...this.wells.map(_well => _well.numberOfStages));

            return null;
        }
    },
    components: {
        Loading
    },
    watch: {
        'startTimestamp': function(value) {
            this.updateDateTime();
        },
        'timestamp': function(value) {
            this.updateDateTime();
        },
        'selectedWell': function(value) {
            if(this.initStateCompleted) {
                this.updateStageNumber();
                this.updateStageOptions();
            }
        },
        duration: {
            handler(newValue, oldValue)
            {
                let endTime = moment.utc(this.startTime)
                                .add(parseInt(newValue.hours), "hours")
                                .add(parseInt(newValue.minutes), "minutes")
                                .add(parseInt(newValue.seconds), "seconds");

                const endTimeClone = endTime.clone().subtract(this.jobHourOffset, 'hours').valueOf();

                const now = moment.utc().valueOf();

                if(endTimeClone >= now) {
                    this.endTime = moment.utc().format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
                    this.updateDuration();
                } else {
                    this.endTime = endTime.format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
                }
                this.onDateChange(this.startTime, this.endTime);
            },
            deep: true
        },
        startTime: function(value)
        {
            this.updateDuration();
            this.updateStageNumber();
        },
        endTime: function(value)
        {
            this.updateDuration();
        }
    },
    methods: {

        dragElement(elmnt) {
            this.modalPosition = [0,0,0,0]
            document.getElementById("fake-popover").onmousedown = (event) => this.dragMouseDown(event,elmnt);
        },
        dragMouseDown(e, elmnt) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            this.modalPosition[2] = e.clientX;
            this.modalPosition[3] = e.clientY;
            document.onmouseup = () => this.closeDragElement();
            // call a function whenever the cursor moves:
            document.onmousemove = (event) => this.elementDrag(event,elmnt);

            //stop propagation on interactable elements
            const stopPropagationElements = document.querySelectorAll('.stopPropagation');
            stopPropagationElements.forEach(selectElement => {
                selectElement.addEventListener('mousedown', (event) => {
                    event.stopPropagation();
                });
            });
        },
        elementDrag(e,elmnt) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            this.modalPosition[0] = this.modalPosition[2] - e.clientX;
            this.modalPosition[1] = this.modalPosition[3] - e.clientY;
            this.modalPosition[2] = e.clientX;
            this.modalPosition[3] = e.clientY;

            elmnt.style.top = Math.max(0, Math.min(this.timeseriesHeight-elmnt.offsetHeight, (elmnt.offsetTop -  this.modalPosition[1]) ))+ "px";
            elmnt.style.left =Math.max(0, Math.min(this.timeseriesWidth - elmnt.offsetWidth , (elmnt.offsetLeft -  this.modalPosition[0]))) + "px";
        },
        closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
            
        },
        onCancelClick() {
            this.$emit('onDismiss');
        },
        onWellSelect(newValue) {
            this.selectedWell = newValue;
        },
        dateBlurred(inputId) {
            if (this.activeDateInput === inputId) {
                this.activeDateInput = null;
            }
        },
        onCalendarChange: function(start, end) {
            if(this.$options.typingTimer) {
                clearTimeout(this.$options.typingTimer);
            }
            this.$options.typingTimer = setTimeout(() => this.onDateChange(start, end), DONE_TYPING_INTERVAL);
        },
        onDateChange: function(start, end) {
            this.responseMessage = '';
            this.responseError = false;
            this.durationError = false;
            if (this.editingNPT.length > 0) {
                if (!this.editingNPTPair) {
                    end = start
                }
            }

            if ((this.startNPT || this.editingNPTPair) && start == end) {
                this.responseMessage = 'NPT event duration cannot be 0.';
                this.responseError = true;
                this.durationError = true;
            }

            
            let startT = moment.utc(start).subtract(this.jobHourOffset, 'hours');
            let endT = moment.utc(end).subtract(this.jobHourOffset, 'hours');
            const now = moment.utc();

            if(startT.valueOf() >= now.valueOf()) {
                //don't let the user select future times
                startT = now;
                this.startTime = now.format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
            }

            if(endT.valueOf() >= now.valueOf()) {
                //don't let the user select future times
                endT = now;
                this.endTime = now.format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
            }

            if(this.selectedWell && this.selectedStage && this.wellStageIntervals && start && end) {
                const flattenedStageInfo = Object.values(this.wellStageIntervals) 
                .map(innerObject => Object.values(innerObject)) 
                .flat();

                const futureStages = flattenedStageInfo.filter((stageInfo)=>{
                    if(moment.utc(stageInfo.startTime).subtract(this.jobHourOffset, 'hours').valueOf()  > startT.valueOf()) {
                        return true;
                    } else {
                        return false;
                    }
                })
                
                const sortedFutureStages = _.sortBy(futureStages, [function(o) { return moment.utc(o.startTime, "YYYY-MM-DDTHH:mm:ss").valueOf(); }]);
                const stageStartEndInfo = sortedFutureStages[0] ?? null;
                if(stageStartEndInfo && stageStartEndInfo.startTime && moment.utc(stageStartEndInfo.startTime, "YYYY-MM-DDTHH:mm:ss").subtract(this.jobHourOffset, 'hours').valueOf() <  endT.valueOf()) {
                    endT = moment.utc(stageStartEndInfo.startTime, "YYYY-MM-DDTHH:mm:ss").subtract(this.jobHourOffset, 'hours');
                    this.endTime = endT.format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
                }
            }
            

            if(this.startNPT && start > end) {
                this.$emit('onDateChange', startT, startT);
                this.$nextTick(()=>{
                    this.responseMessage = 'Invalid time range. Please ensure that the event end time is after the start time. End time has been reset to default.';
                    this.responseError = true;
                })
            } else if(this.mode === 'startEvent') {
                this.$emit('onDateChange', startT, startT);
            } else {
                this.$emit('onDateChange', startT, endT);
            }
        },
        updateDateTime: function() { 
            this.startTime = moment.utc(this.startTimestamp).add(this.jobHourOffset, 'hours').format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
            this.endTime = moment.utc(this.timestamp).add(this.jobHourOffset, 'hours').format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
        },
        getCustomerVendorsFromIds: function(ids) {
            return this.customerVendors.filter((cv) => {
                if (ids.find(i => i?.toLowerCase() == cv.id?.toLowerCase()))
                    return cv;
            });
        },  
        async getEventByStartEndSelection() {
            this.initStateCompleted = false;

            const flattenedStageInfo = Object.values(this.wellStageIntervals) 
            .map(innerObject => Object.values(innerObject)) 
            .flat();

            const orderedStageArray = _.orderBy(flattenedStageInfo, (obj) => moment(obj.startTime) ,'desc');
            const selectedStageIndex = orderedStageArray.findIndex((stage) => {
                return moment.utc(stage.startTime, "YYYY-MM-DD HH:mm:ss").valueOf() <= moment.utc(this.startTime, "YYYY-MM-DDTHH:mm:ss").valueOf();
            });
            
            const selectedStage = orderedStageArray[selectedStageIndex] ?? null;

            if(selectedStage) {
                this.selectedWell = this.wells.find((well)=> (selectedStage.wellNumber == well.index));
                this.selectedStage = selectedStage.stageNumber;
                this.$nextTick(()=>{
                    this.onDateChange(this.startTime, this.endTime)
                    this.loadingAutoConfig = false;
                })
            } else {
                this.selectedWell = null;
                this.loadingAutoConfig = false;
            }
            this.initStateCompleted = true;
        },
        async updateStageNumber() {
            if(this.$options.onChangeTimer) {
                //stop spamming the backend if the user keeps on changing values;
                clearTimeout(this.$options.onChangeTimer);
            }

            const self = this;
            this.loadingStageNumber = true;
            this.$options.onChangeTimer = setTimeout(()=>{
                if(this.selectedWell == null) {
                    this.loadingStageNumber = false;
                } else {
                    const wellIndex = this.selectedWell?.index;
                    const unixStart = moment.utc(this.startTime, "YYYY-MM-DDTHH:mm:ss").subtract(this.jobHourOffset, 'hours').valueOf();
                    const url = `/npt-stage-number/${this.jobNumber}/${wellIndex}/${unixStart}`
                    $.get(url).then((res) => {
                        if(res?.stageNumber) {
                            self.selectedStage = res.stageNumber;
                        } else {
                            self.selectedStage = null
                        }
                        self.loadingStageNumber = false;
                    });
                }
            }, 2000);
        },
        updateStageOptions() {
            if (isFalsy(this.selectedWell)) {
                this.stageOptions = [];
            } else {
                const _id = this.selectedWell?.id?.toLowerCase();
                const numberOfStages = this.wells.find(well => well.id?.toLowerCase() === _id)?.numberOfStages;
                this.stageOptions = Array.from({ length: numberOfStages }, (_, i) => i + 1);
            }
        },

        handleCommentButtonClick: function(event)
        {
            if(this.loading == false) {
                this.loading = true;
                this.postComment();
            }
        },
        updateDuration: function()
        {
            this.updateDateTime();
            const time = GlobalFunctions.timeDiff(this.startTime, this.endTime).split(':');
            this.duration.hours = parseInt(time[0]);
            this.duration.minutes = parseInt(time[1]);
            this.duration.seconds = parseInt(time[2]);

            if (this.duration.hours < 0 || this.duration.minutes < 0 || this.duration.seconds < 0)
            {
                this.duration.hours = 0;
                this.duration.minutes = 0;
                this.duration.seconds = 0;
            }
        },
        postComment: function ()
        {
            if (isFalsy(this.selectedStage) || (!isFalsy(this.maxStage) && +this.selectedStage > this.maxStage)) {
                this.loading = false;
                return toast({
                    title: 'Please enter a valid stage number',
                    variant: 'danger'
                });
            }

            const startMoment = moment.utc(this.startTime, "YYYY-MM-DDTHH:mm:ss");
            startMoment.subtract(this.jobHourOffset, 'hours');
            
            const endMoment = moment.utc(this.endTime, "YYYY-MM-DDTHH:mm:ss");
            endMoment.subtract(this.jobHourOffset, 'hours');
            const startTimestamp = startMoment.toISOString();
            const endTimestamp = endMoment.toISOString();
            const url = '/time-keeper/' + this.jobNumber;
            const self = this;
            
            if(this.startNPT) { //case: create end npt

                const customerVendors = this.selectedCustomerVendors;
                const nptOutcomeId = this.selectedOutcomeId?.toLowerCase();

                const dataPacket = 
                {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    reference: this.startNPT.reference,
                    endTime: endTimestamp,
                    createEndNPT: 1,
                    customerVendors: customerVendors,
                    nptOutcomeId: nptOutcomeId,
                    comment: this.commentText,
                    stageNumber: this.selectedStage,
                    nonFracNPT: this.selectedNonFracNPT,
                };

                $.ajax({
                    method: "PUT",
                    url: url,
                    data: dataPacket,
                    dataType: 'json'
                })
                .done((res)=>{
                    self.loading = false;
                    self.responseMessage = res.message;
                    self.responseError = res.error;
                    if (!self.responseError)
                    {
                        self.resetInput();
                        self.$emit('onDismiss');
                    }
                })
                .fail(function( jqXHR, textStatus, errorThrown ) {
                    console.log("Error creating comment", errorThrown);
                    self.loading = false;
                });
            } 
            else if (this.editingNPT.length > 0) { //case: edit single npt or pair
                const dataPacket = {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    reference: this.editingNPT[0].reference,
                    startTime: startTimestamp,
                    endTime: endTimestamp == startTimestamp? null : endTimestamp,
                    wellId: this.selectedWell?.id?.toLowerCase(),
                    comment: this.commentText,
                    stageNumber: this.selectedStage,
                    customerCodeStandardTagId: this.selectedReasonId?.toLowerCase(),
                    customerVendors: this.selectedCustomerVendors,
                    nptOutcomeId: this.selectedOutcomeId?.toLowerCase(),
                    createEndNPT: 0, //false didn't work here, unsure why,
                    nonFracNPT:this.selectedNonFracNPT
                };
                
                $.ajax({
                    method: "PUT",
                    url: url,
                    data: dataPacket,
                    dataType: 'json'
                })
                .done((res)=>{
                    self.loading = false;
                    self.responseMessage = res.message;
                    self.responseError = res.error;
                    if (!self.responseError)
                    {
                        self.resetInput();
                        self.$emit('onDismiss');
                    }
                    self.handleResponse(res);
                })
                .fail(function( jqXHR, textStatus, errorThrown ) {
                    self.loading = false;
                });
            }
            else { //case: create new npt pair or single
                const eventReason_id = this.selectedReasonId?.toLowerCase();
                const stepDescription = this.stepDescriptions.find((sd)=>sd.id?.toLowerCase() === eventReason_id?.toLowerCase());
    
                const cmd = 'createEvent';
                const wellId = this.selectedWell?.id?.toLowerCase();
                const startTime = startTimestamp;
                const comment = this.commentText;
                const customerCodeStandardTagId = stepDescription?.id?.toLowerCase();
                const customerVendors = this.selectedCustomerVendors;
                const nptOutcomeId = this.selectedOutcomeId?.toLowerCase();
    
                const dataPacket = 
                {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    cmd: cmd,
                    wellId: wellId,
                    startTime: startTime,
                    comment: comment,
                    stageNumber: this.selectedStage,
                    customerCodeStandardTagId: customerCodeStandardTagId,
                    customerVendors: customerVendors,
                    nptOutcomeId: nptOutcomeId,
                    nonFracNPT:this.selectedNonFracNPT
                };
                //don't send end time all the time
                if (this.mode != 'startEvent')
                    dataPacket.endTime = endTimestamp;
    
                $.post(
                    url,
                    dataPacket,
                    function(data) {
                        self.loading = false;
                        self.responseMessage = data.message;
                        self.responseError = data?.error ?? false;

                        if (!self.responseError)
                        {
                            self.resetInput();
                            self.$emit('onDismiss');
                        }
                        self.handleResponse(data);
                    },
                    'json'
                ).fail(function( jqXHR, textStatus, errorThrown ) {
                    self.loading = false;
                });
            }
        },  
        handleResponse(res) {
            if (res['error'] && res['fracSummaryPermission'] == true && confirm(`In order to update this NPT event, the Frac Stage Summary Report needs to be unapproved.  Do you wish to proceed with unapproving stage ${res.fracInfo.stageNumber}, well ${res.fracInfo.wellName}, on the Frac Stage Summary Report?`)) {
                const jobId = res.fracInfo.jobId;
                const stageNumber = res.fracInfo.stageNumber;
                const wellNumber = res.fracInfo.wellNumber;
                const url = `/stage-summary/approval/${jobId}/${wellNumber}`;
                const self = this;
                
                $.ajax({
                    url: url,
                    method: 'POST',
                    data: {
                        stageNumbers: [stageNumber],
                        type: 'frac',
                        approvalStatus: false,
                        _token: GlobalFunctions.getCSRFToken()
                    },
                    dataType: 'json'
                }).done((data) => {	
                    alert('Report has been successfully unapproved. You may now save the NPT event.');
                    self.responseMessage = 'Report has been successfully unapproved. You may now save the NPT event.';
                    self.responseError = true;
                })
                
            } else if(res['error'] && res['fracSummaryPermission'] == false) {
                alert(`Please contact your company admin or an authorized Frac Stage Summary Report user to unapprove stage ${res.fracInfo.stageNumber}, well ${res.fracInfo.wellName}`);
            }
        },
        onHidden(evt)
        {
            evt.preventDefault();
            this.loading = false;
            this.responseMessage = '';
            this.responseError = false;
            if(this.onPopoverHidden)
                this.onPopoverHidden();
        },
        resetInput()
        {
            this.startTime = '1900-01-01T00:00';
            this.endTime = '1900-01-01T00:00';
            this.duration = {
                hours:0, 
                minutes:0, 
                seconds:0
            };
            this.commentText = '';
            this.selectedActivityId = 1;
            this.selectedReasonId =  null;
            this.selectedContractorId = null;
            this.selectedWell = null;
            this.responseMessage = '';
            this.responseError =  false;
        }
    }
}
</script>

<style scoped>
    .dot {
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
    }

    .white-text {
        color: #FFFFFF;
    }

    .dot-clickable {
        height: 1rem;
        width: 1rem;
        padding: 0;
        margin: 0;
        border-radius: 50%;
        border: none;
        display: inline-block;
        position: absolute;
        top: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
        border-color: transparent;
        border-style: solid;
        border-width: thin;
        background-color: transparent;
    }

    #fake-popover {
        background-color: #373A3C;
        padding: 10px;
        border-style: solid;
        border-radius: 3.5px;
        border-color: #95989A;
        border-width: thin;
        
        text-align: left;
        
        width: 500px;
        max-width: calc(100% - 10px);
        max-height: calc(100% - 10px);
        overflow-y: auto;

        z-index: 99;

        position: fixed;
        top: 5px;
        left: 5px;
    }
    #fake-popover.low-opacity {
        opacity: 0.4;
    }

    #sticky-actions {
        position: sticky;
        bottom: -10px;
        background-color: #373A3C;
        padding: 10px;
        width: calc(100% + 10px);
    }
    
    .cursor-disabled {
        cursor: default;
    }
</style>