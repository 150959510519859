<template>
    <div class="d-flex ht-100 pb-0 flex-column justify-content-start align-items-center loginPageGrid">
        <div class="mt-3 mb-2 login-header">
            <h1 class="mb-2" style="text-align: center">Safer completions. Better completions</h1>
            <div class="d-flex flex-column align-items-center">
                <span>Optimizing completions with software, sensors,</span>
                <span>controls and best practices.</span>
            </div>
        </div>

        <div class="d-flex flex-column align-items-start loginPageInnerGrid mt-3">
            <img class="align-self-center" id="imgLogo-login" src="/images/inVision_rebrand_logo_2021.png" height="30" alt="IWS" >
            <div class="w-100">
                <form id="login-form" method="POST" action="/login" onsubmit="updateCookies()">
                    <input type="hidden" name="_token" v-model="csrfToken">
                    <input type="hidden" name="redirectUrl" v-model="redirectUrl">
                    <div class="w-100">
                        <label for="username" class="">Email</label>
                        <div class="d-flex user-pass-container">
                            <img src="/images/person_icon.png" height="25">
                            <input id="username" type="text" :class="{'form-control':true,'is-invalid': Object.keys(errors).indexOf('username') > -1}" name="username" v-model="username" required autocomplete="username" autofocus
                                    @click="input($event)" class="rounded loginPageInputField w-100" placeholder="Enter your email">
                        </div>
                        <div class="invalid-creds" v-if="Object.keys(errors).indexOf('username') > -1" >{{ errors['username'] ? errors['username'][0] : undefined }}</div>
                    </div>
                    <div class="w-100 mt-3">
                        <label for="password" class="">Password</label>
                        <div class="">
                            <div id="password" class="input-group user-pass-container">
                                <img src="/images/lock-password_icon.png" height="25">
                                <input id="password" :type="showPassword?'text':'password'" placeholder="Enter your password"
                                 :class="{'form-control':true, 'is-invalid': Object.keys(errors).indexOf('password') > -1}" 
                                 name="password" required autocomplete="current-password" v-model="password" @click="input($event)" class="rounded loginPageInputField">
                                <div class="input-group-append loginPageShowPasswordButton">
                                    <span class="input-group-text">
                                        <i @click="showPasswordToggle('password')" class="fa fa-eye-slash" aria-hidden="true" style="cursor: pointer;"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 mt-2">
                        <div class="d-flex flex-row justify-content-between">
                            <iws-checkbox
                                ref="rememberMeCheck"
                                class="mt-1"
                                label="Remember Me"
                                :value.sync="shouldRemember"
                                :enableClickLabel="true"
                            />
                        </div>
                    </div>
                    <div class=" w-100 mt-4">
                        <div class="w-100">
                        <button type="submit" class="btn btn-primary w-100 gap-8 height44px" @click="addUsernameCookie()">
                            Login
                        </button>
                        <div class="mt-1 forgot-password">
                            <a class="btn btn-link" href="/password/reset" style="padding: 0px !important">
                                Forgot Your Password?
                            </a>
                        </div>
                        <div class="w-100 text-center mt-3">
                            Or
                        </div>

                        </div>
                        <div class=" text-center w-100 mt-3">
                            <a href="/auth/azure" class="text-decoration-none text-white">
                                <div class="signInWithMicrosoftButton text-deocration-none">
                                    <MicrosoftLogoSVG></MicrosoftLogoSVG> Sign in with Microsoft
                                </div>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import MicrosoftLogoSVG from './MicrosoftLogoSVG.vue';
export default {
    components: { MicrosoftLogoSVG },
    data() {
        return {
            showKeyboard: false,
            activeInput: '',
            username: window.getCookie('iws_username'),
            password: '',
            showPassword: false,
            shouldRemember: false
        };
    },
    computed: {
    },
    props: ['csrfToken','errors', 'redirectUrl'],
    methods: {
      showPasswordToggle(id) {
            let idString = '#' + id;
            if($(idString + ' input').attr("type") == "text"){
                $(idString + ' input').attr('type', 'password');
                $(idString + ' i').addClass( "fa-eye-slash" );
                $(idString + ' i').removeClass( "fa-eye" );
            }else if($(idString + ' input').attr("type") == "password"){
                $(idString + ' input').attr('type', 'text');
                $(idString + ' i').removeClass( "fa-eye-slash" );
                $(idString + ' i').addClass( "fa-eye" );
            }
        },
        keyPress(val) {
            const self = this;
            switch(val) {
            case 'Back':
                self[self.activeInput] = self[self.activeInput].substring(0,self[self.activeInput].length-1);
                break;
            case 'Enter':
                this.showKeyboard = false;
                const event = new Event('change');
                document.getElementById(self.activeInput).dispatchEvent(event);
                break;
            default:
                self[self.activeInput] = self[self.activeInput]+val;
                break;
            }
        },
        input(event) {
            this.activeInput = event.currentTarget.id;
            this.showKeyboard = true;
        },
        addUsernameCookie() {
            if(this.shouldRemember) {
                const d = new Date();
                d.setTime(d.getTime() + (14 * 24 * 60 * 60 * 1000));
                const expires = 'expires=' + d.toUTCString();
                document.cookie = 'iws_username=' + this.username + ';' + expires + ';path=/';
            }
        }
    }
};
</script>
<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill{
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
}

.invalid-creds{
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.loginPageInnerGrid{
	width: 35%;
	min-width: 320px;
    padding: 40px;
    gap: 20px;
    border-radius: 4px;
    border: 1px solid #5B5F65;
	background-color: rgb(36, 42, 48, 0.8);
}
.loginPageInnerGrid > span {
	font: "Inter";
	font-size: x-large;
	font-weight: 600;
	margin-top: -30px;
}

.loginPageInputField{
    border: none !important;
    background: #343A40;
    display: flex;
    padding: 10px 5px !important;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: white;
    height: 44px;
}

.loginPageShowPasswordButton > span {
    background: #343A40 !important;
    border: none !important;
}

.loginPageShowPasswordButton > span > i {
    color: #676E78;
}

.user-pass-container {
	border: 1px solid #676E78;
	border-radius: 4px;
	background: #343A40;
}

.user-pass-container > img {
	filter: invert(44%) sepia(12%) saturate(321%) hue-rotate(176deg) brightness(95%) contrast(96%);
	margin: auto 0px auto 5px;
}

.forgot-password a {
	padding-left: 0px;
}

.login-header span {
    font-size: x-large;
}

.signInWithMicrosoftButton{
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    background: #343A40;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 4px;
    height: 44px;
    border: 1px solid #6B7380;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.height44px{
    height: 44px;
}

@media (min-width:320px) {
    .login-header {
        display: none ;
    }

    .loginPageInnerGrid{
        width: 90%;
        padding: 20px
    }
    .loginPageGrid {
        justify-content: center ;
    }
}

@media (min-width:600px) {
    .login-header {
        display: flex ;
        flex-direction: column;
        align-items: center;
    }
    .login-header span {
        font-size: large;
    }
    .loginPageInnerGrid{
        width: 50%;
        padding: 30px
    }
    .login-header {
        justify-content: flex-start ;
    }    
}

@media (min-width:1025px) {
    .loginPageInnerGrid{
        width: 35%;
    }

    .login-header span {
        font-size: x-large;
    }
    
}

@media (max-height: 770px) {
    .login-header {
        display: none;
    }
}
</style>
