<!--
    * Component Description
        A simple, re-usable input wrapper that applies IWS styling and other small features
        See props for an explanation of all supported functions

    * Side Effects
    Will emit 7 signals
        update:value    <- syncs with the 'value' prop to comunicate upwards what the last selection made
        input           <- triggers upon input within the input field
        focus           <- triggers upon focus within the input field
        blur            <- triggers upon losing focus within the input field
        keyup           <- triggers upon key entry input within the input field
        prepependClick  <- triggers upon click of the prepend icon area
        appendClick     <- triggers upon click of the append icon area

    * Example Usage
        <iws-input
            label="Name"
            placeholder="Provide a name"
            hint="Should be unique!"
            :value.sync="name"
            required
            error-message="Please provide a name"
        />
        
        <iws-input
            type="number"
            label="Stage Number"
            placeholder="Provide a stage number"
            :value.sync="stageNumber"
            min="0"
            max="99"
        />

        <iws-input
            type="datetime-local"
            label="Date"
            placeholder="yyyy-mm-dd hh:mm:ss"
            :value.sync="startDate"
            :min="minDate"
            :max="maxDate"
            step="1"
        />
-->
<template>
<div :class="{ 'form-input-spacing': formSpacing !== false }" :id="id">
    <label v-if="label" class="primary-text-color">
        {{ typeof label == 'function' ? label() : label }}
    </label>

    <div class="input-group" :class="{ 'focused': isFocused }">
        <div v-if="!!prependIcon" class="input-group-prepend" @click="$emit('prepependClick', $event)">
            <span class="input-group-text" id="addon-wrapping">
                <span :class="`fas ${prependIcon}`"></span>
            </span>
        </div>

        <input v-if="type !== 'textarea'"
            ref="inputField"
            :type="type !== 'password' || !showPassword ? type : 'text'"
            :placeholder="!!placeholder && typeof placeholder == 'function' ? placeholder() : placeholder"
            :value="value"
            :disabled="disabled"
            :required="required !== false ? 'required' : null"
            class="form-control"
            :class="{ 'is-invalid': inErrorState }"

            @input="handleEvent('input', $event)"
            @change="handleEvent('change', $event)"
            @focus="handleEvent('focus', $event)"
            @blur="handleEvent('blur', $event)"
            @keyup="handleEvent('keyup', $event)"

            :min="min"
            :max="max"
            :step="step"
            :autocomplete="autocomplete"
            :readonly="readonly"
        >
        <textarea v-else
            :placeholder="placeholder"
            :value="value"
            :disabled="disabled"
            :required="required !== false ? 'required' : null"
            class="form-control"
            :class="{ 'is-invalid': inErrorState }"

            @input="handleEvent('input', $event)"
            @change="handleEvent('change', $event)"
            @focus="handleEvent('focus', $event)"
            @blur="handleEvent('blur', $event)"
            @keyup="handleEvent('keyup', $event)"

            :rows="rows"
            :min="min"
            :max="max"
            :autocomplete="autocomplete"
            :readonly="readonly"
        />

        <div v-if="!!appendIcon" class="input-group-append" @click="$emit('appendClick', $event)">
            <span class="input-group-text" id="addon-wrapping">
                <slot name="appendIcon">
                    <span :class="`fas ${appendIcon}`"></span>
                </slot>
            </span>
        </div>
        <div v-else-if="type == 'password'" class="input-group-append" @click="showPassword = !showPassword" style="width: 45px !important">
            <span class="input-group-text clickable" id="addon-wrapping">
                <slot name="appendIcon">
                    <span :class="`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`" style="width: 20px !important"></span>
                </slot>
            </span>
        </div>
    </div>

    <label v-if="hint" class="secondary-text-color">
        {{ typeof hint == 'function' ? hint() : hint }}
    </label>

    <label v-if="inErrorState" class="danger-text-color" style="display: block;">
        <template v-if="required !== false && _isFalsy(value)">
            Required
        </template>
        <template v-else-if="_isTruthy(errorMessage)">
            {{ typeof errorMessage == 'function' ? errorMessage() : errorMessage }}
        </template>
        <template v-else-if="type == 'email'">
            Must be a valid e-mail address
        </template>
        <template v-else-if="type == 'tel'">
            Must be a valid telephone
        </template>
        <template v-else-if="_isTruthy(min) && value < min">
            Must be at least {{ min }}
        </template>
        <template v-else-if="_isTruthy(max) && value > max">
            Must be at less than {{ max+1 }}
        </template>
    </label>
</div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isTruthy } = GlobalFunctions;

export default {
    props: {
        id: {
			type: String
		},

        // Input type
        // Example: text (default/optional), number, datetime-local
        // See component definition (at top of file) for examples of using types
        type: {
            type: String,
            default: 'text'
        },

        // Label for input, appears at the top-left
        label: {
			type: String | Number | Function
		},
        // Placeholder/hint that appears INSIDE input (only visible when value is null)
		placeholder: {
			type: String | Number | Function
		},
        // Placeholder/hint that appears BELOW input (is ALWAYS visible when provided)
        hint: {
			type: String | Number | Function
		},

        // Prepend appears at the LEFT most side of the input content
        prependIcon: {
            type: String
        },
        // Append appears at the RIGHT most side of the input content
        appendIcon: {
            type: String
        },

        // Synced value of input field
        value: {
			type: String | Number
		},

        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        // Allows the input to be focused but not allow input (used to make iwsSelect behave as a normal, non searchable select)
        searchable: {
            type: Boolean | String,
            default: true
        },
        required: {
            type: Boolean | String,
            default: false
        },
        // Provides a consistent margin between fields across forms
        formSpacing: {
            type: Boolean | String,
            default: false
        },

        // Validate function provides more validation than is required or not (can be used with required == true)
        // Validation is run on blur, if entered error state we run validation every input
        // Users can only enter errorState once they click away but can resolve the error as soon as corrected
        validator: {
            type: Function,
            required: false
        },

        // When in errorState, explain the error / how to resolve it
        // Is optional, but field will take danger border colour when in errorState
        errorMessage: {
            type: String | Function
        },
        // Can be used to direct or disable autocomplete in forms
        // Example: autocomplete="username", autocomplete="new-password"
        autocomplete: {
            type: String,
            default: 'off'
        },

        prependIcon: {
            type: String
        },
        appendIcon: {
            type: String
        },

        rows: {
            type: Number | String,
            default: 1
        },

        // When type="number" or type="datetime-local" min/maxs can be provided
        // Both are INCLUSIVE (value >= min && value <= max)
        min: Number | String,
        max: Number | String,
        step: Number | String
    },

    data: () => ({
        isFocused: false,
        inErrorState: false,
        showPassword: false
    }),

    methods: {	
        _isFalsy(value) {
            return isFalsy(value);
        },
        _isTruthy(value) {
            return isTruthy(value);
        },
        						
        handleEvent(type, $event) {
            this.$emit(`pre-${type}`, $event);

            if (type == 'input') {
                if (!this.searchable) {
                    this.$refs.inputField.value = null;
                } else {
                    // If the type was specified to be a number input, emit the value as a number not a string
                    this.$emit('update:value', this.type === 'number' ? (+$event.target.value) : $event.target.value);
                }
            } else if (type == 'focus') {
                this.isFocused = true;
            } else if (type == 'blur') {
                this.isFocused = false;
                this.validateInput();
            } else if (type == 'keyup' && $event.key == 'Enter') {
                this.$emit('enter', $event);
            }

            this.$emit(type, $event);
        },

        validateInput() {
            // To support just the required keyword in the parent, anything other than just false is required
            this.inErrorState = this.required !== false && isFalsy(this.value);
            
            // validator is an optional prop to validate the input value in a custom way
            // When a validator function is provided, the value must pass it to be in a valid state
            if (isTruthy(this.validator) && isFalsy(this.validator(this.value)))
                this.inErrorState = true;

            if (isTruthy(this.value)) {
                // For special input types, if set must match the types regex
                if (this.type == 'email' && !/^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/.test(String(this.value).toLowerCase()))
                    this.inErrorState = true;
                if (this.type == 'tel' && !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/.test(String(this.value).toLowerCase()))
                    this.inErrorState = true;

                // When min and max values are provided, ensure they are valid
                if (isTruthy(this.min) && this.value < this.min)
                    this.inErrorState = true;
                if (isTruthy(this.max) && this.value > this.max)
                    this.inErrorState = true;
            }

            return !this.inErrorState;
        },

        focus() {
            this.$refs.inputField.focus();
        },
        blur() {
            this.$refs.inputField.blur();
        }
    },

    watch: {
        value() {
            // When in errorstate, validate on input to reset as soon as error is resolved
            // When not, give them time to enter a value before saying it's invalid
            if (this.inErrorState)
                this.validateInput();
        }
    }
};
</script>

<style scoped>
    label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    .input-group.focused input,
    .input-group.focused textarea,
    .input-group.focused .input-group-text {
        transition: border-color 0.25s ease-in-out;
    }
    .input-group.focused input,
    .input-group.focused textarea,
    .input-group.focused .input-group-text {
        background: #343A40;
        color: var(--primary-text-color);
        border-color: var(--primary-color) !important;
    }
    .input-group-text {
        background-color: #676E78;
        color: #FFFFFF;
    }

    .form-control {
        background: #343A40;

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 4px;

        color: var(--primary-text-color);

        padding-left: 7.5px;
        padding-right: 7.5px;
    }

    .form-control.is-invalid {
        background-image: none !important;
    }

    .form-control:disabled {
        color: grey;
        cursor: not-allowed;
        border-color: grey;
    }

    /* Show the datetime calendar icon show in light text */
    input[type=datetime-local] {
        color-scheme: dark;
    }
</style>