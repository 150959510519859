import { render, staticRenderFns } from "./DashboardNavbar.vue?vue&type=template&id=164a3e29&scoped=true&"
import script from "./DashboardNavbar.vue?vue&type=script&lang=js&"
export * from "./DashboardNavbar.vue?vue&type=script&lang=js&"
import style0 from "./DashboardNavbar.vue?vue&type=style&index=0&id=164a3e29&lang=css&"
import style1 from "./DashboardNavbar.vue?vue&type=style&index=1&id=164a3e29&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "164a3e29",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/9878/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('164a3e29')) {
      api.createRecord('164a3e29', component.options)
    } else {
      api.reload('164a3e29', component.options)
    }
    module.hot.accept("./DashboardNavbar.vue?vue&type=template&id=164a3e29&scoped=true&", function () {
      api.rerender('164a3e29', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/DashboardNavbar.vue"
export default component.exports