<!--
    * Component Description
        A modal component that unifies modal styling.
        The modal will be automatically teleported (physically not logically) to be inside the portal-target (name="modals")
        component in the app.blade so that transforms etc. won't affect full screen modals.
        See props for an explanation of all supported functions

    * Side Effects
        Will emit 4 signals
            close               <- triggers upon clicking outside the modal or the X button (Typically a CANCEL action)
            primary-action      <- triggers open clicking the primary button in the modal footer (typically a CONFIRM action).
                                    - see primaryButtonClick below for a secondary approach passing a function reference.
            secondary-action    <- triggers open clicking the secondary button in the modal footer (typically a REJECT/CANCEL action)
            modal-scroll        <- triggers upon scrolling of the modal body content, currently needed to reposition active iws-selects within the modal

    * Usage
        Control the visibility of the modal using the 'showModal' boolean
        Set primary and (optional) secondary button text and attach functionality to their events.
        Wire up the close event.
        Fill in the content slot as if it were part of the parent component.

    * Dependencies
        - portal-vue library

    * Available slots to fill:
        - title
        - content
        - footer

    * Props


    * Example Usage
        <iws-modal
            :showModal="showCustomFilterModal"
            title="Custom Filter"
            @close="showCustomFilterModal = false"
            @secondary-action="clearCustomFilter()"
            @primary-action="saveCustomFilter()"
        >
            <template #content>
                InsertModalContentHere
            </template>
        </iws-modal>
-->

<template>
    <div v-if="showModal">
        <portal to="modals">
            <transition name="modal">
                <div
                    class="modal-mask positioned"
                    :class="{
                        'backdrop': showBackdrop,
                        ...classObj
                    }"
                    :id="id"
                    onscroll="enableClickOutside ? event => event.preventDefault() : null"
                >
                    <div class="modal-container d-flex flex-column"
                        :class="position"
                        role="dialog"
                        v-on-clickaway="handleClickAway"
                        :style="{ 'max-width': maxWidth, 'max-height': maxHeight, top: top ? top + 'px' : '', left: left ? left + 'px' : '' }"
                    >
                        <div class="header p-3">
                            <slot name="title">
                                <div>{{title}}</div>
                            </slot>
                            <img src="/images/icons/close.svg" class="close-button clickable" @click="closeModal()"/>
                        </div>

                        <div class="content" @scroll.passive="onScroll">
                            <slot name="content"></slot>
                        </div>

                        <div v-if="(secondaryButtonText && secondaryButtonVisible) || primaryButtonVisible" class="footer p-3">
                            <slot name="footer">
                                <iws-button v-if="secondaryButtonText && secondaryButtonVisible"
                                    :text="secondaryButtonText"
                                    type="outline-light"
                                    :disabled="secondaryButtonDisabled"
                                    @click="secondaryClicked"
                                />

                                <iws-button v-if="primaryButtonVisible"
                                    style="margin-left: 10px"
                                    :text="primaryButtonText"
                                    type="primary"
                                    :disabled="primaryButtonDisabled"
                                    @click="primaryClicked"
                                    :click="primaryButtonClick"
                                />
                            </slot>
                        </div>
                    </div>
                </div>
            </transition>
        </portal>
    </div>
</template>

<script>
import app from '../../app.js';
import { directive as onClickaway } from 'vue-clickaway2';

import eventBus from '../../eventBus';

export default {
    directives: {
        onClickaway: onClickaway
    },
    props: {
        id: String,

        // A list (in object form) of conditional classes to apply to the modal mask (one level above the actual modal)
        classObj: Object,

        // Boolean controlling whether to show the modal or not
        showModal: {
            type: Boolean,
            default: false,
            required: true
        },

        // Modal title/header
        title: {
            type: String,
            required: false
        },

        primaryButtonText: {
            type: String,
            required: false,
            default: 'Save'
        },
        // Will show the button, but disabled
        primaryButtonDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        // Hides the button entirely
        primaryButtonVisible: {
            type: Boolean,
            default: true
        },
        // Pass function reference to handle onClick
        // When the function returns a promise (i.e. api call) the button will enter the loading state until resolved (this shows a spinner and does not allow other clicks)
        // Should not be used in conjunction with @primary-action
        primaryButtonClick: {
            type: Function,
            required: false
        },

        secondaryButtonText: {
            type: String,
            default: 'Cancel',
            required: false
        },
        // Will show the button, but disabled
        secondaryButtonDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        // Hides the button entirely
        secondaryButtonVisible: {
            type: Boolean,
            default: true
        },

        // Controls the showing or hiding of the mostly transparent black background
        showBackdrop: {
            type: Boolean,
            default: true
        },
        // Allows the modal to be closed (or not) when clicking outside of the modal body
        enableClickOutside: {
            type: Boolean,
            default: true
        },
        closeOnClickaway: {
            type: Boolean,
            default: true
        },

        // Position of the modal in the viewport
        // Examples: top-left, top-middle, top-right, center-left, center-middle, center-right, bottom-left, bottom-middle, bottom-right
        position: {
            type: String,
            required: false,
            default: 'center-middle'
        },
        maxWidth: {
            type: String,
            required: false,
            default: '40em'
        },
        maxHeight: {
            type: String,
            required: false,
            default: '40em'
        },
        top: {
            type: Number,
            required: false,
        },
        left: {
            type: Number,
            required: false,
        }
    },

    data: () => ({
        data: null
    }),

    methods: {
        closeModal() {
            this.$emit('close');
            app.$emit('modal-close');
        },
        handleClickAway($event) {
            // Only close if parent allows and the user clicked the modal-mask (not a confirm modal or another target)
            if (this.closeOnClickaway && $event?.target?.classList?.contains('modal-mask'))
                this.closeModal();
        },
        primaryClicked() {
            if (!this.primaryButtonDisabled) {
                this.$emit('primary-action');
                app.$emit('modal-primary-action');
            }
        },
        secondaryClicked() {
            if (!this.secondaryButtonDisabled) {
                this.$emit('secondary-action');
                app.$emit('modal-secondary-action');
            }
        },
        onScroll($event) {
            eventBus.$emit('modal-scroll', $event);
        }
    },
    watch: {
        // Disables accidently scrolling the document (outside the modal) while modals are open
        showModal() {
            // If we allow interacting outside of the modal while it's open, don't mess with the body scrolling
            if (this.enableClickOutside)
                document.body.style.overflow = this.showModal ? 'hidden' : 'auto'
        }
    }
};

</script>

<style scoped>
    .modal-mask {
        width: 100%;
        height: 100%;
        transition: opacity 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
        z-index: 101;

        /* position: fixed;
        top: 0;
        left: 0; */
    }
    .modal-mask.backdrop {
        background-color: rgba(52, 64, 84, 0.8);
    }
    .modal-mask.positioned {
        position: fixed;
        top: 0;
        left: 0;
    }

    .modal-container {
        position: fixed;
        z-index: 101 !important;

        width: 100%;
        background-color: #242A30;
        border-radius: 12px;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
        transition: all 0.3s ease;
    }
    .modal-mask,
    .dark-modal.modal,
    .modal-dialog,
    .modal-content {
        z-index: 101;
    }

    @media only screen and (max-width: 600px) {
    .modal-container {
        min-width: 100%;
        width: 100%;
        overflow: auto;
    }
    }

    .modal-container.top-left {
        top: 10px !important;
        left: 10px !important;
    }
    .modal-container.top-middle {
        top: 10px !important;
    }
    .modal-container.top-right {
        top: 10px !important;
        right: 10px !important;
    }

    .modal-container.center-left {
        left: 10px !important;
    }
    .modal-container.center-right {
        right: 10px !important;
    }

    .modal-container.bottom-left {
        left: 10px !important;
        bottom: 10px !important;
    }
    .modal-container.bottom-middle {
        bottom: 10px !important;
    }
    .modal-container.bottom-right {
        right: 10px !important;
        bottom: 10px !important;
    }


    .header {
        font-size: 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #7B8A98;
    }

    .content {
        padding: 1.5em;
        overflow: auto;
        font-size: 0.8em;
        max-height: inherit;
    }

    .content::-webkit-scrollbar {
        width: 6px;
    }

    .content::-webkit-scrollbar-track {
        background: inherit;
    }

    .content::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #E2E2E2;
    }

    .footer {
        padding: 0.5em 1em;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #7B8A98;
    }

    .primary-button{
        color:white;
        background-color: #004DBF;
        min-width:6em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .primary-button[disabled="disabled"] {
        color:#748CB0;
        background-color: #42546D33;
    }

    .secondary-button {
        color:white;
        border:1px solid white;
        min-width:6em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .close-button {
        height:0.9em;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
    opacity: 0;
    }

    .modal-leave-active {
    opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    }



    .secondary-button[disabled="disabled"]
    {
        color: #787E87;
        border:1px solid #787E87;
    }
</style>
