var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    {
      staticClass: "h-100",
      style: { "overflow-y": "hidden" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", variant: "secondary" } },
        [
          _c(
            "b-navbar-brand",
            {
              staticClass: "font-weight-bold",
              attrs: { size: "xl", href: "#", b: "" },
            },
            [_vm._v("Process Logs")]
          ),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c("b-nav-text", [
                    _c("b", [_vm._v("Sort:")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.sortBy) +
                        "-\n                    " +
                        _vm._s(_vm.sortDesc ? "Descending" : "Ascending")
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-container",
                {
                  staticStyle: { "margin-left": "2rem", "max-width": "40%" },
                  attrs: { fluid: "" },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "dropdown", attrs: { md: "4" } },
                        [
                          _c(
                            "b-form-select",
                            {
                              staticClass: "mb-3",
                              scopedSlots: _vm._u([
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: "" } },
                                        [_vm._v("Process")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.filters["process"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "process", $$v)
                                },
                                expression: "filters['process']",
                              },
                            },
                            [
                              _vm._v(" "),
                              _vm._l(_vm.allProcesses, function (proc, index) {
                                return _c(
                                  "b-form-select-option",
                                  {
                                    key: index,
                                    attrs: { value: proc.process },
                                  },
                                  [_vm._v(_vm._s(proc.process))]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { staticClass: "dropdown", attrs: { md: "4" } },
                        [
                          _c(
                            "b-form-select",
                            {
                              staticClass: "mb-3",
                              scopedSlots: _vm._u([
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: "" } },
                                        [_vm._v("Well")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.filters["name"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "name", $$v)
                                },
                                expression: "filters['name']",
                              },
                            },
                            [
                              _vm._v(" "),
                              _vm._l(_vm.wells, function (well, index) {
                                return _c(
                                  "b-form-select-option",
                                  { key: index, attrs: { value: well.name } },
                                  [_vm._v(_vm._s(well.name))]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { staticClass: "dropdown", attrs: { md: "4" } },
                        [
                          _c(
                            "b-form-select",
                            {
                              staticClass: "mb-3",
                              scopedSlots: _vm._u([
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: "" } },
                                        [_vm._v("Shift")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.filters["shift"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "shift", $$v)
                                },
                                expression: "filters['shift']",
                              },
                            },
                            [
                              _vm._v(" "),
                              _vm._l(_vm.allShifts, function (shift, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: shift.start },
                                  },
                                  [_vm._v(_vm._s(_vm.shortenYear(shift.start)))]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-0",
                      attrs: {
                        label: "Search",
                        "label-for": "search-input",
                        "label-cols-sm": "3",
                        "label-align-sm": "right",
                        "label-size": "lg",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { size: "lg" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "search-input",
                              type: "search",
                              placeholder: "Type to Search",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          id: "scrollable-table",
          hover: "",
          noCollapse: "",
          "sticky-header": "75vh",
          items: _vm.filtered,
          fields: _vm.fields,
          filter: _vm.search,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          "per-page": _vm.perPage,
          "current-page": _vm.currentPage,
          "table-variant": "dark",
          "sort-icon-left": "",
          responsive: "lg",
        },
        on: {
          "update:sortBy": function ($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function ($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function ($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function ($event) {
            _vm.sortDesc = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(name)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "nameplate",
                    style: {
                      background: data.item.color,
                      color: _vm.nameColor(data.item),
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(data.value) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(stageNumber)",
            fn: function (data) {
              return [
                _c("div", { staticClass: "justify-content-center" }, [
                  _vm._v(
                    "\n                " + _vm._s(data.value) + "\n            "
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(show_details)",
            fn: function (data) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: { size: "lg" },
                    on: { click: data.toggleDetails },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(data.detailsShowing ? "▲" : "▼") +
                        "\n            "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(processStatus)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          ["In Progress", "request", "bypassActuate"].includes(
                            data.item.processStatus
                          ) ||
                          (data.item.processStatus == "resolved" &&
                            data.item.events.length > 1),
                        expression:
                          "['In Progress', 'request', 'bypassActuate'].includes(data.item.processStatus) || (data.item.processStatus == 'resolved' && data.item.events.length > 1)",
                      },
                    ],
                  },
                  [_c("i", { staticClass: "fa fa-spinner fa-spin fa-3x ml-1" })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          ["completed"].includes(data.item.processStatus) ||
                          (data.item.processStatus == "resolved" &&
                            data.item.events.length === 1),
                        expression:
                          "['completed'].includes(data.item.processStatus) || (data.item.processStatus == 'resolved' && data.item.events.length === 1)",
                      },
                    ],
                  },
                  [_c("i", { staticClass: "fa fa-check fa-3x ml-1" })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: ![
                          "In Progress",
                          "completed",
                          "resolved",
                          "request",
                          "bypassActuate",
                        ].includes(data.item.processStatus),
                        expression:
                          "!['In Progress', 'completed', 'resolved', 'request', 'bypassActuate'].includes(data.item.processStatus)",
                      },
                    ],
                  },
                  [_c("i", { staticClass: "fa fa-times fa-3x ml-1" })]
                ),
              ]
            },
          },
          {
            key: "row-details",
            fn: function (row) {
              return [
                _c(
                  "div",
                  { staticClass: "stepContainer" },
                  _vm._l(row.item.events, function (event, index) {
                    return _c("ProcessLogStepComponent", {
                      key: index,
                      attrs: {
                        event: event,
                        offset: _vm.job.hourOffset,
                        wells: _vm.wells,
                        processName: row.item.process,
                      },
                    })
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-pagination", {
        staticClass: "justify-content-center",
        attrs: {
          "total-rows": _vm.rows,
          "per-page": _vm.perPage,
          "aria-controls": "my-table",
        },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }