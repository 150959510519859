<template>
    <iws-modal
        id="comms-control"
        :classObj="{ 'pushToTalkInProgress': pushToTalkInProgress, 'minimized': minimized, 'callIsMuted': callIsMuted }"
        :show-modal="modalIsOpen"
        :primary-button-visible="false"
        :secondary-button-visible="false"

        :show-backdrop="false"
        :enable-click-outside="false"

        :max-width="!minimized ? '420px' : '188px'"
        :max-height="getModalHeight"

        @close="$emit('close', $event)"
        :top="commsModalPositionTop"
        :left="commsModalPositionLeft"
    >
        <template #title>
            <div v-if="!minimized" class="modal-title" @mousedown="dragStart" style="font-size: 19px; display:flex;">
                BETA - {{ job?.location || job?.jobNumber || 'Call' }}
                <div class="equaliser-container" v-if="connected && activeSpeakerUserId.length > 0">
                    <ol class="equaliser-column">
                        <li class="colour-bar"></li>
                    </ol>
                    <ol class="equaliser-column">
                        <li class="colour-bar"></li>
                    </ol>
                    <ol class="equaliser-column">
                        <li class="colour-bar"></li>
                    </ol>
                    <ol class="equaliser-column">
                        <li class="colour-bar"></li>
                    </ol>
                    <ol class="equaliser-column">
                        <li class="colour-bar"></li>
                    </ol>
                </div>
            </div>
            <iws-button v-else-if="!connected" type="primary btn-sm" :click="joinCall" :disabled="activeChannels.length === 0" >
                <template #text>
                    <i class="fas fa-phone"></i> Join
                </template>
            </iws-button>
            <iws-button v-else
                :type="pushToTalkConnecting ? 'warning btn-sm':'success btn-sm'"
                @press="$emit('pushToTalk', 'start')"
                @release="$emit('pushToTalk', 'end')"
                data-toggle="tooltip"
                :disabled="commsRole !== 'talk'"
                data-placement="top"
                :title="commsRole !== 'talk' ? 'You lack talk permissions':'Hold Push to Talk or Ctrl + Space to talk'"
            >
                <template #text>
                   <span v-if="pushToTalkInProgress"> <i class="fas fa-microphone"></i> Talking</span>
                   <span v-else>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 15V15C10.343 15 9 13.657 9 12V6C9 4.343 10.343 3 12 3V3C13.657 3 15 4.343 15 6V12C15 13.657 13.657 15 12 15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18 10V12C18 15.314 15.314 18 12 18V18C8.686 18 6 15.314 6 12V10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 18V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.82031 21H16.1803" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> Talk

                   </span>
                </template>
            </iws-button>

            <i :class="`fas expand fa-${minimized ? 'expand' : 'compress'}-alt clickable`" @click="toggleSize"></i>

            <i class="fas fa-sliders-h clickable" @click="togglePosition" v-if="!minimized"></i>
            <i class="fas fa-arrows-alt modal-title" :class="minimized ? 'minimized-class': 'maximized-class'" @mousedown="dragStart"></i>

        </template>

        <template v-if="!minimized" #content>
            <template v-if="connected">
                <div class="row">
                    <div class="col-12">
                        <!-- check if active channel is set else get the first element from the activeChannels -->
                     <span style="font-size:15px;">Active Channel:   {{ getActiveChannelText }} </span>
                     <br/>
                     <span style="font-size:13px;">
                        <i class="fas fa-info-circle"></i> If you wish to change channels, please disconnect the call first
                    </span>
                    </div>

                </div>
                <div class="row mt-2" id="comms-control-container">
                    <div class="col">
                        <iws-button type="outline-danger" @click="$emit('endCallFromButton')">
                            <template #text>
                                <i class="fas fa-phone-slash"></i>
                            </template>
                        </iws-button>

                        <iws-button type="outline-light" :disabled="_isNullOrEmpty(logs)" data-toggle="collapse" data-target="#collapseLog" aria-expanded="true" aria-controls="collapseLog" @click="$emit('fetchLogs')">
                            <template #text>
                                <i class="fas fa-list-ul"></i>
                            </template>
                        </iws-button>
                    </div>

                    <div class="col-4" data-toggle="tooltip" data-placement="top" :title="commsRole !== 'talk' ? 'You lack talk permissions':'Hold Push to Talk or Ctrl + Space to talk'">
                        <iws-button :type="getPTTButtonType" @press="$emit('pushToTalk', 'start')" @release="$emit('pushToTalk', 'end')" style="width: 100%; padding:5px;" :disabled="commsRole !== 'talk'">
                            <template #text>

                                <span v-if="pushToTalkInProgress"><i class="fas fa-microphone"></i> Talking </span>
                                <span v-else>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 15V15C10.343 15 9 13.657 9 12V6C9 4.343 10.343 3 12 3V3C13.657 3 15 4.343 15 6V12C15 13.657 13.657 15 12 15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M18 10V12C18 15.314 15.314 18 12 18V18C8.686 18 6 15.314 6 12V10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 18V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.82031 21H16.1803" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> Push to Talk
                                </span>

                            </template>
                        </iws-button>
                    </div>

                    <div class="col">
                        <iws-button type="outline-light" :disabled="_isNullOrEmpty(users)" data-toggle="collapse" data-target="#collapseUsers" @click="showUserAvatarList = !showUserAvatarList" aria-expanded="true" aria-controls="collapseUsers">
                            <template #text>
                                <i class="fas fa-users"></i>
                            </template>
                        </iws-button>

                        <iws-button :type="callIsMuted ? 'outline-warning' : 'outline-light'" @click="toggleMute">
                            <template #text>
                                <i :class="`fas fa-volume-${callIsMuted ? 'mute' : 'up'}`"></i>
                            </template>
                        </iws-button>
                    </div>
                </div>

                <div v-if="!_isFalsy(callMutedStampfromNow)" class="timestamp-details warning">
                    Muted call {{ callMutedStampfromNow }} ago
                </div>
                <div class="mt-2"  v-if="!_isNullOrEmpty(users) && showUserAvatarList">
                    <ul class="avatar-list">
                        <!-- Iterate over users for the first nine -->

                        <li v-for="(user, index) in activeUserList" :key="index" class="avatar" :class="activeSpeakerUserId.includes(user.id) ? 'active-speaker':''" :title="`${user.first_name} ${user.last_name}`" >
                            <span v-if="user.role === 'Presenter'"><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path opacity="1" fill="#D0D5DD" d="M112 24c0-13.3-10.7-24-24-24S64 10.7 64 24V96H48C21.5 96 0 117.5 0 144V300.1c0 12.7 5.1 24.9 14.1 33.9l3.9 3.9c9 9 14.1 21.2 14.1 33.9V464c0 26.5 21.5 48 48 48H304c26.5 0 48-21.5 48-48V371.9c0-12.7 5.1-24.9 14.1-33.9l3.9-3.9c9-9 14.1-21.2 14.1-33.9V144c0-26.5-21.5-48-48-48H320c0-17.7-14.3-32-32-32s-32 14.3-32 32H224c0-17.7-14.3-32-32-32s-32 14.3-32 32H112V24zm0 136H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg></span>
                            <span v-else>{{ user.first_name[0] }}{{ user.last_name[0] }}</span>

                        </li>
                        <!-- ... More... -->
                        <li class="more-avatars" v-if="users.length > 9">+{{ users.length - 9 }}</li>
                    </ul>

                </div>
            </template>
            <div v-else>
                <div class="row ">
                    <div v-if="activeChannels.length === 0" class="text-center w-100 justify-content-center">
                        <span class="text-danger">Please contact RoC to map a channel to the job</span>
                    </div>
                    <div v-else class="w-100">
                        <div class="col-12">
                                <select class="form-control" @change="channelChanged" id="activeChannelList"  style="color: #35495e">

                                        <option :value="channel.roip_name" v-for="(channel) in activeChannels"  :key="channel.roip_name" :selected="storedActiveChannel === channel.roip_name" >{{ channel.alias ? channel.alias : 'No Service Mapped' }} - {{ channel.roip_name }}</option>
                                </select>
                                </div>

                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-2">
                        <iws-button type="outline-light" :disabled="_isNullOrEmpty(logs)" data-toggle="collapse" data-target="#collapseLog" aria-expanded="true" aria-controls="collapseLog" @click="$emit('fetchLogs')">
                            <template #text>
                                <i class="fas fa-list-ul"></i>
                            </template>
                        </iws-button>
                    </div>

                    <div class="col-8">
                        <iws-button type="primary" :click="activeChannels.length === 0? null:joinCall" style="width: 100%;" :disabled="activeChannels.length === 0">
                            <template #text>
                                <span v-if="loading && !hasUserEndedTheCall">
                                    Connecting ...
                                </span>
                                <span v-else>
                                    <i class="fas fa-phone"></i> Join Call
                                </span>
                            </template>
                        </iws-button>
                    </div>

                    <div class="col-2">
                        <iws-button type="outline-light" :disabled="_isNullOrEmpty(users)" data-toggle="collapse" data-target="#collapseUsers" aria-expanded="true" aria-controls="collapseUsers" style="float: right">
                            <template #text>
                                <i class="fas fa-users"></i>
                            </template>
                        </iws-button>
                    </div>
                </div>
            </div>


            <div v-if="!_isNullOrEmpty(logs)" id="accordionLog">
                <div id="collapseLog" class="collapse" data-parent="#accordionLog">
                    <h3 style="margin-top: 10px; margin-bottom: 0px ">
                        Logs
                    </h3>
                    <div>
                        <div v-for="log in logs" class="log">
                            <span v-if="log.subCategory === 'join'">
                                <span style="font-size:small">{{ _timeDisplay(log.timestamp) }}: {{ log.data.userName }} joined</span>
                            </span>
                            <span v-if="log.subCategory === 'disconnect'">
                                <span style="font-size:small">{{ _timeDisplay(log.timestamp) }}: {{ log.data.userName }} left</span>
                            </span>
                            <span v-if="log.subCategory === 'talkEnd'">
                                <span style="font-size:small">{{ _timeDisplay(log.timestamp) }}: {{ log.data.userName }} spoke ({{ Math.round(log.data.timeSpoken) }} s)
                                    <span v-if="log.data.isROIPOnline === false"> unable to actuate radio</span>

                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!_isNullOrEmpty(users)" id="accordionUsers">
                <div id="collapseUsers" class="collapse" data-parent="#accordionUsers">
                    <h3 style="margin-top: 10px; margin-bottom: 0px">
                        Users
                    </h3>
                    <ol style="margin-bottom: 0px; padding-left: 15px;">
                        <li v-for="(user,index) in users" :key="index" class="log">
                            <span class="row">
                                <span class="col-6">
                                    <span v-if="activeSpeakerUserId.includes(user.id)" class="user-speaking">
                                        {{ user.first_name }} {{ user.last_name }}
                                    </span>
                                    <span v-else>
                                        {{ user.first_name }} {{ user.last_name }}
                                    </span>
                                </span>
                                <span class="col-6 text-right" v-if="activeSpeakerUserId.includes(user.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M4.89648 7.18746L8.21232 4.3783C8.75398 3.91913 9.58398 4.30413 9.58398 5.01413V14.985C9.58398 15.695 8.75315 16.08 8.21232 15.6208L4.89648 12.8116" stroke="#32D583" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M4.89648 12.8125H2.91732C2.45732 12.8125 2.08398 12.4392 2.08398 11.9792V8.02083C2.08398 7.56083 2.45732 7.1875 2.91732 7.1875H4.89648" stroke="#32D583" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M15.0007 6.66675V13.3334" stroke="#32D583" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M12.5007 8.33325V11.6666" stroke="#32D583" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.5007 4.16675V15.8334" stroke="#32D583" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                </span>

                            </span>
                        </li>
                    </ol>
                </div>
            </div>
        </template>
    </iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty } = GlobalFunctions;

import DateFunctions from '../../DateFunctions.js';
const { fromNow, logDate } = DateFunctions;
import eventBus from '../../eventBus';

export default {
    props: {
        modalIsOpen: {
            type: Boolean,
            required: true
        },
        job: {
            type: Object,
            default: {}
        },
        logs: {
            type: Array,
            required: true,

        },
        users: {
            type: Array,
            required: true,
            default: []
        },
        pushToTalkInProgress: {
            type: Boolean,
            required: true
        },
        pushToTalkConnecting: {
            type: Boolean,
            required: false
        },
        callIsMuted: {
            type: Boolean,
            required: true
        },
        connected: {
            type: Boolean,
            required: true
        },
        joinCall: {
            type: Function,
            required: true
        },
        commsRole: {
            type: String,
            required: true
        },
        isMinimized: {
            type: Boolean,
            required: true
        },
        activeSpeakerUserId: {
            type: Array,
            default: [],
            required: true
        },
        activeChannels: {
            type: Array,
            default: [],
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        storedActiveChannel: {
            validator: function(value) {
                // The value is considered valid if it's either a string or explicitly null
                return typeof value === 'string' || value === null;
            },
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        hasUserEndedTheCall: {
            type: Boolean,
            required: true
        },
        commsModalPositionTop: {
            type: Number,
            required: true
        },
        commsModalPositionLeft: {
            type: Number,
            required: true
        }
    },

    data: () => ({
        minimized: false,
        callMutedStampfromNow: null,
        callMutedInterval: null,
        showUserAvatarList: true,
        activeChannel: null,
        dragging: false,
        startX: 0,
        startY: 0,
        startLeft: 0,
        startTop: 0

    }),
    beforeMount() {
        this.minimized = this.isMinimized;

    },

    methods: {
        _isFalsy(val) {
            return isFalsy(val);
        },
        _isNullOrEmpty(val) {
            return isNullOrEmpty(val);
        },
        _timeDisplay(val) {
            return logDate(val);
        },

        toggleSize() {
            this.minimized = !this.minimized;
            this.$emit('toggleSize', this.minimized);
        },
        togglePosition() {
            this.$emit('openPosition');
        },

        toggleMute() {
            // This runs before the flip, so if currently off we're setting it to muted
            if (!this.callIsMuted) {
                const callMutedStamp = new Date();

                this.callMutedStampfromNow = fromNow(callMutedStamp);
                this.callMutedInterval = setInterval(() => {
                    this.callMutedStampfromNow = fromNow(callMutedStamp);
                }, 1000)
            } else {
                this.callMutedStampfromNow = null;
                clearInterval(this.callMutedInterval);
            }
            this.$emit('toggleMute');
        },
        channelChanged() {
            this.activeChannel = document.getElementById('activeChannelList').value;
        },
        dragStart(e) {
            this.dragging = true;

            // Correctly set the starting point for the drag
            const modal = document.querySelector('#comms-control .modal-container');
            const modalRect = modal.getBoundingClientRect();
            this.startX = e.clientX;
            this.startY = e.clientY;
            this.startLeft = modalRect.left;
            this.startTop = modalRect.top;

            // Ensure movement is relative to the viewport
            document.addEventListener('mousemove', this.dragMove);
            document.addEventListener('mouseup', this.dragEnd);
        },

        dragMove(e) {
            if (!this.dragging) return;

            const dx = e.clientX - this.startX;
            const dy = e.clientY - this.startY;
            let newLeft = this.startLeft + dx;
            let newTop = this.startTop + dy;
            const modal = document.querySelector('#comms-control .modal-container');
            if (!modal) return;

            const modalRect = modal.getBoundingClientRect();
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            if (newLeft < 0){
                newLeft = 0;
            }else if (newLeft + modalRect.width > viewportWidth){
                newLeft = viewportWidth - modalRect.width;
            }
            if (newTop < 0){
                newTop = 0;
            }else if (newTop + modalRect.height > viewportHeight){
                newTop = viewportHeight - modalRect.height;
            }

            modal.style.left = `${newLeft}px`;
            modal.style.top = `${newTop}px`;
            eventBus.$emit('commsModalPosition', { left: newLeft, top: newTop });

        },
        dragEnd() {
            this.dragging = false;
            document.removeEventListener('mousemove', this.dragMove);
            document.removeEventListener('mouseup', this.dragEnd);

        }
    },
    watch: {
        connected() {
            // The title row wasn't updating once connecting
            // Hack to force the title slot to update and switch to push to talk button
            if (this.minimized) {
                this.minimized = false;
                this.$nextTick(() => {
                    this.minimized = true;
                })
            }
        },
        activeChannel() {
            eventBus.$emit('channelChange', this.activeChannel);
        },
        activeChannels() {
            if(this.activeChannels.length > 0){
                let channel = this.storedActiveChannel ? this.storedActiveChannel : this.activeChannels[0].roip_name;
                this.activeChannel = channel;
            }
        }
    },
    computed: {
        getPTTButtonType() {
            return this.pushToTalkConnecting ? 'outline-warning':(this.pushToTalkInProgress ? 'success' : 'ptt-idle-state');
        },
        activeUserList() {
            return this.users.sort((a, b) => {
                if(this.activeSpeakerUserId.includes(a.id) && !this.activeSpeakerUserId.includes(b.id)) {
                    return -1;
                }
                if(!this.activeSpeakerUserId.includes(a.id) && this.activeSpeakerUserId.includes(b.id)) {
                    return 1;
                }
                 return a.first_name.localeCompare(b.first_name);
                }).slice(0,9);
        },
        getActiveChannelText(){
            if(this.activeChannel){
                // Get the channel alias
                const channel = this.activeChannels.find(channel => channel.roip_name === this.activeChannel);
                if(channel.alias){
                    return channel.alias;
                }else{
                    return 'No Service Mapped - ' + this.activeChannel;
                }
            }
            return this.activeChannels.length > 0 ? this.activeChannels[0].alias : 'No Service Mapped - ' + this.activeChannels[0].roip_name;
        },
        getModalHeight(){
                if(this.minimized){
                    return 'auto';
                }else{
                    if(this.connected){
                        return '24em';
                    }else{
                        return '22em';
                    }
                }
        }
    }
};
</script>

<style>
    #comms-control .modal-container  {
        border: 1px solid var(--primary);
        border-color: var(--primary);
        opacity: 0.85;
    }
    #comms-control .row .col .btn {
        width: 46px !important;
    }

    #comms-control.modal-mask.minimized .header {
        border-bottom: none;
    }
    #comms-control.modal-mask.minimized .content {
        display: none;
        padding: 0px !important;
    }

    #comms-control .alert {
        padding: .375rem .75rem;
    }
    #comms-control .alert .fas.fa-info-circle {
        position: relative;
        top: -5px;
    }

    #logs-control-container {
        width: 100%;
        font-size: 22px;
        padding: 0px 0px;
        margin-top: 20px
    }
    #logs-control-container .fas {
        position: absolute;
        top: 10px;
        right: 5px;

        transition: all 1s;
    }
    #comms-control div[aria-expanded="true"] .fas {
        rotate: -180deg;
    }

    #comms-control-container .col {
        padding: 5px 15px;
    }
    #comms-control-container .col button:first-of-type {
        margin-right: 5px;
    }
    #comms-control-container .col-4 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    #comms-control-container .col-4 button {
        height: 100%;
    }

    .timestamp-details {
        position: absolute;
        bottom: 4px;

        width: 361px;

        text-align: center;
        font-size: 12px;
    }
    .timestamp-details.warning {
        color: var(--warning-color);
    }

    #collapseLog div,
    #collapseUsers div {
        max-height: 140px;
        overflow-y: auto;
    }
    #collapseLog .log,
    #collapseUsers .log {
        font-size: 16px;
        padding: 2px 0px;
    }
    #collapseLog .top-spacing {
        margin-top: 20px;
    }

    #comms-control .header {
        border-radius: 10px 10px 0px 0px;
    }
    #comms-control.minimized .header {
        border-radius: 10px !important;
    }
    #comms-control .content {
        border: 3px solid #242A30;
        border-top: none !important;
        border-radius: 0px 0px 10px 10px;
    }
    #comms-control.pushToTalkInProgress .header {
        background-color: var(--success);
    }
    #comms-control.pushToTalkInProgress .content {
        border-color: var(--success);
    }

    #comms-control.callIsMuted .header {
        background-color: var(--warning-color);
    }
    #comms-control.callIsMuted .content {
        border-color: var(--warning-color);
    }
    .btn-ptt-idle-state{
        color: #fff;
        background-color: #28a74582;
        border-color: #28a74582;
    }
    .btn-ptt-idle-state:hover{
        color: #fff;
        background-color: #28a74582;
        border-color: #28a74582;
    }
    .fas.expand {
        position: absolute;
        top: 22px;
        right: 75px;
        font-size: 16px;
    }
    .fas.fa-sliders-h {
        position: absolute;
        top: 22px;
        right: 45px;
        font-size: 16px;
    }
    .fas.fa-arrows-alt.maximized-class{
        position: absolute;
        top: 19px;
        right: 105px;
        font-size: 16px;
    }
    .fas.fa-arrows-alt.minimized-class{
        position: absolute;
        top: 19px;
        right: 45px;
        font-size: 16px;
    }
    .avatar-list {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
    }
    .avatar-list .avatar {
        width: 32px; /* Adjust size as needed */
        height: 32px; /* Adjust size as needed */
        border-radius: 50%;
        background-color: #475467; /* Bootstrap primary color, or use any other color */
        color: #D0D5DD;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px; /* Spacing between avatars */
    }
    .avatar-list .avatar.active-speaker{
        background-color: #039855 !important;
        border: 2px solid  #32D583 !important;
    }
    .avatar-list .more-avatars {
        background-color: transparent; /* Bootstrap secondary color */
        color: #D0D5DD;
        border: 1px solid #6B7380;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px; /* Adjust size as needed */
        height: 32px; /* Adjust size as needed */
        border-radius: 50%;
    }
    .user-speaking{
        color: #32D583 !important;
    }


    .equaliser-container {
        height: 20px;
        width: 30px;
        margin: 5px;
        padding: 3px;
        position: relative;
      }

      .colour-bar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 10px;
        background: #f0f0f0;
      }

      .equaliser-column {
        width: 3px;
        float: left;
        margin: 0 1px 0 0;
        padding: 0;
        height: 10px;
        position: relative;
        list-style-type: none;
      }

      .equaliser-column:nth-child(1) .colour-bar {
        animation: color-bar 2s 1s ease-out alternate infinite;
      }

      .equaliser-column:nth-child(2) .colour-bar {
        animation: color-bar 2s 0.5s ease-out alternate infinite;
      }

      .equaliser-column:nth-child(3) .colour-bar {
        animation: color-bar 2s 1.5s ease-out alternate infinite;
      }

      .equaliser-column:nth-child(4) .colour-bar {
        animation: color-bar 2s 0.25s ease-out alternate infinite;
      }

      .equaliser-column:nth-child(5) .colour-bar {
        animation: color-bar 2s 2s ease-out alternate infinite;
      }

      .equaliser-column:last-child {
        margin-right: 0;
      }

      @keyframes color-bar {
        0% {
          height: 1px;
          background: #f0f0f0;
        }
        10% {
          height: 3px;
          background: #f0f0f0;
        }
        20% {
          height: 5px;
          background: #f0f0f0;
        }
        30% {
          height: 2px;
          background: #f0f0f0;
        }
        40% {
          height: 7px;
          background: #f0f0f0;
        }
        50% {
          height: 9px;
          background: #f0f0f0;
        }
        60% {
          height: 3px;
          background: #f0f0f0;
        }
        70% {
          height: 8px;
          background: #f0f0f0;
        }
        80% {
          height: 5px;
          background: #f0f0f0;
        }
        90% {
          height: 3px;
          background: #f0f0f0;
        }
        100% {
          height: 1px;
          background: #f0f0f0;
        }
      }
    .modal-title{
        cursor:move;
        user-select: none; /* Prevent text selection */
    }
</style>
