import { render, staticRenderFns } from "./DashboardAndAPIPermission.vue?vue&type=template&id=3afc9ecb&"
import script from "./DashboardAndAPIPermission.vue?vue&type=script&lang=js&"
export * from "./DashboardAndAPIPermission.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/9878/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3afc9ecb')) {
      api.createRecord('3afc9ecb', component.options)
    } else {
      api.reload('3afc9ecb', component.options)
    }
    module.hot.accept("./DashboardAndAPIPermission.vue?vue&type=template&id=3afc9ecb&", function () {
      api.rerender('3afc9ecb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/DashboardAndAPIPermission.vue"
export default component.exports