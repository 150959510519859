var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "CameraSettings" } },
    [
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _c("iws-breadcrumbs", {
            attrs: {
              path: [
                {
                  title: "Devices",
                  link: `/cameras/settings`,
                },
                {
                  title: _vm.device?.deviceID,
                  link: `/cameras/settings/${_vm.workOrderId}/${_vm.device?.deviceID}`,
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "page-title" }, [
            _vm._v("\n            Device Configuration\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "positioned-actions" },
            [
              _vm.canProvision
                ? [
                    _c("iws-button", {
                      attrs: {
                        type: "primary",
                        text: "Provision",
                        disabled: !_vm.canProvision,
                        click: _vm.provision,
                      },
                    }),
                  ]
                : [
                    _c(
                      "a",
                      {
                        staticClass: "mr-3",
                        attrs: { href: `/cameras/viewer/${_vm.workOrderId}` },
                      },
                      [
                        _c("iws-button", {
                          attrs: {
                            type: "outline-primary",
                            icon: "far fa-eye",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("iws-button", {
                      staticClass: "mr-3",
                      attrs: {
                        type: "outline-light",
                        text: "Keychain",
                        click: _vm.openAccountsModal,
                      },
                    }),
                    _vm._v(" "),
                    _c("iws-button", {
                      attrs: {
                        type: "outline-danger",
                        text: "Deprovision",
                        disabled: !_vm.canDeprovision,
                        click: _vm.deprovision,
                      },
                    }),
                    _vm._v(" "),
                    _c("iws-button", {
                      staticClass: "mx-2",
                      attrs: {
                        type: "outline-danger",
                        text: "Restart",
                        disabled: !_vm.canRestart,
                        click: _vm.restart,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "status-border",
                        class: {
                          "green-gradient": _vm.canDeprovision,
                          "red-gradient": !_vm.canDeprovision,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.vmData?.status || "Deprovisioned") +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "full-width" }, [
            _c(
              "span",
              { attrs: { id: "search" } },
              [
                !_vm._isNullOrEmpty(_vm.device?.cameraConfigurations)
                  ? _c("iws-search", {
                      attrs: { value: _vm.searchKey },
                      on: {
                        "update:value": function ($event) {
                          _vm.searchKey = $event
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { attrs: { id: "add-new" } },
              [
                _c("iws-button", {
                  staticClass: "mr-2",
                  attrs: {
                    text: "Discover Cameras",
                    type: "outline-light",
                    "append-icon": "fab fa-searchengin",
                    click: _vm.onDiscoverCameras,
                  },
                }),
                _vm._v(" "),
                _c("iws-button", {
                  attrs: {
                    text: "New Camera",
                    "append-icon": "fas fa-plus",
                    click: _vm.addCamera,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          !_vm._isNullOrEmpty(_vm.device?.cameraConfigurations)
            ? [
                _c("iws-table", {
                  attrs: {
                    columns: _vm.columns,
                    items: _vm.device?.cameraConfigurations,
                    filter: _vm.searchKey,
                    sortByCol: _vm.sortByCol,
                    maxPageSize: 12,
                  },
                  on: {
                    "update:sortByCol": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sort-by-col": function ($event) {
                      _vm.sortByCol = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell_online",
                        fn: function ({ data }) {
                          return [
                            _c("iws-checkbox", {
                              attrs: { value: data.item.online },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(data.item, "online", $event)
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell_publish",
                        fn: function ({ data }) {
                          return [
                            _c("iws-checkbox", {
                              attrs: { value: data.item.publish },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(data.item, "publish", $event)
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell_status",
                        fn: function ({ data }) {
                          return [
                            _c("span", {
                              staticClass: "circle",
                              class: {
                                "green-gradient": data?.item?.online,
                                "red-gradient": !data?.item?.online,
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell_actions",
                        fn: function ({ data }) {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: `/cameras/viewer/${_vm.workOrderId}/${data?.item?.id}/${data?.item?.primaryStream?.id}`,
                                },
                              },
                              [
                                _c("iws-button", {
                                  attrs: {
                                    type: "outline-primary",
                                    icon: "far fa-eye",
                                    disabled: _vm._isFalsy(
                                      data?.item?.primaryStream?.id
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "ml-3 mr-2",
                                attrs: {
                                  href: `/cameras/settings/${_vm.workOrderId}/${_vm.device?.deviceID}/${data?.item?.id}`,
                                },
                              },
                              [
                                _c("iws-button", {
                                  attrs: {
                                    type: "outline-light",
                                    icon: "fas fa-cog",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("iws-button", {
                              attrs: {
                                type: "outline-danger",
                                icon: "fas fa-trash-alt",
                                click: () => _vm.deleteCamera(data.item),
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3859479412
                  ),
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-container" }, [
                  _c("label", [
                    _vm._v("\n                    VM Data\n                "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-input", {
                          attrs: {
                            value: _vm.device.workOrderId,
                            label: "Work Order",
                            required: "",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.device, "workOrderId", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3", attrs: { id: "last-heartbeat" } },
                      [
                        _c("iws-input", {
                          attrs: {
                            value: _vm.formatHeartbeatTime(
                              _vm.vmData?.heartbeatTime
                            ),
                            label: "Last Heartbeat",
                            disabled: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                   Local Stream Quality\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "Resolution",
                            value: _vm.device.localResolution,
                            options: _vm.streamHeightOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "localResolution",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "FPS",
                            value: _vm.device.localFPS,
                            options: _vm.fpsOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.device, "localFPS", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "Birrate",
                            value: _vm.device.localBitrate,
                            options: _vm.bitrateOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "localBitrate",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "Key Frame Interval Factor",
                            value: _vm.device.localKeyFrameIntervalFactor,
                            options: _vm.keyFrameIntervalFactorOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "localKeyFrameIntervalFactor",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "Encoder Preset",
                            value: _vm.device.localPreset,
                            options: _vm.presetOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.device, "localPreset", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "Quality Preset",
                            value: _vm.device.localQualityPreset,
                            options: _vm.qualityPresetOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "localQualityPreset",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                   Cloud Stream Quality\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "Resolution",
                            value: _vm.device.cloudResolution,
                            options: _vm.streamHeightOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "cloudResolution",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "FPS",
                            value: _vm.device.cloudFPS,
                            options: _vm.fpsOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.device, "cloudFPS", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "Birrate",
                            value: _vm.device.cloudBitrate,
                            options: _vm.bitrateOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "cloudBitrate",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "Key Frame Interval Factor",
                            value: _vm.device.cloudKeyFrameIntervalFactor,
                            options: _vm.keyFrameIntervalFactorOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "cloudKeyFrameIntervalFactor",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "Encoder Preset",
                            value: _vm.device.cloudPreset,
                            options: _vm.presetOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.device, "cloudPreset", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: "Quality Preset",
                            value: _vm.device.cloudQualityPreset,
                            options: _vm.qualityPresetOptions,
                            "display-name": "text",
                            "value-name": "value",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "cloudQualityPreset",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                    Pressure Threshold Settings\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-input", {
                          attrs: {
                            value: _vm.device.pressureUpperThreshold,
                            label: "Pressure Upper Threshold",
                            type: "number",
                            min: "0",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "pressureUpperThreshold",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-input", {
                          attrs: {
                            value: _vm.device.pressureLowerThreshold,
                            label: "Pressure Lower Threshold",
                            type: "number",
                            min: "0",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "pressureLowerThreshold",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                    Local Archive Settings\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-input", {
                          attrs: {
                            value: _vm.device.localVodArchivalDays,
                            label: "VOD archival time (days)",
                            type: "number",
                            min: "0",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "localVodArchivalDays",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-input", {
                          attrs: {
                            value: _vm.device.localVodChunkSizeHours,
                            label: "VOD chunk size (hours)",
                            type: "number",
                            min: "0",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "localVodChunkSizeHours",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-input", {
                          attrs: {
                            value: _vm.device.minStorageThreshold,
                            label: "Min. Storage Threshold (GB)",
                            type: "number",
                            min: "0",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "minStorageThreshold",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                    Cloud Archive Settings\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-input", {
                          attrs: {
                            value: _vm.device.cloudVodArchivalDays,
                            label: "VOD archival time (days)",
                            type: "number",
                            min: "0",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "cloudVodArchivalDays",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("iws-input", {
                          attrs: {
                            value: _vm.device.cloudVodChunkSizeHours,
                            label: "VOD chunk size (hours)",
                            type: "number",
                            min: "0",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.device,
                                "cloudVodChunkSizeHours",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-save-action full-width" },
                  [
                    _c("iws-button", {
                      attrs: {
                        text: "Save",
                        click: _vm.saveDevice,
                        disabled: _vm.vmData?.status == "Provisioning",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _c(
                "div",
                {
                  staticClass: "no-items clickable",
                  on: {
                    click: function ($event) {
                      return _vm.addCamera()
                    },
                  },
                },
                [
                  _c("h2", { staticClass: "danger-text-color" }, [
                    _vm._v(
                      "\n                No cameras configured for this device\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h3", [
                    _vm._v(
                      "\n                Click here to create one\n            "
                    ),
                  ]),
                ]
              ),
        ],
        2
      ),
      _vm._v(" "),
      _c("add-camera-modal", { ref: "AddCameraModal" }),
      _vm._v(" "),
      _c("discover-cameras-modal", { ref: "discoverCamsModal" }),
      _vm._v(" "),
      _c("keychain-modal", { ref: "KeychainModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }