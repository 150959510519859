<!--
    * Component Description
        A simple, re-usable table wrapper that applies IWS styling and other small features
        See props for an explanation of all supported functions

    * Side Effects
        Will emit 3 signals
            update:sortByCol    <- triggers on header click to select new column for sorting
            update:sortAsc      <- triggers when the current sorting header is click (or reset) to flip the direction
            pageChange          <- triggers when pagination is enabled, $event represents the new page index

    * Slots
        #header_<column.key>    <- Slot in for custom header body per cell
        #body                   <- Slot in for entire table body (not recommended as it overwrites a lot of built in styling, more for extreme customization)
        #row_<item.id>          <- Slot in for entire rows by item id (also not recommended for above reasons)
        #cell_<column.key>      <- Slot in targeting specific cells (Most recommended way to customize cells as it is the least intrusive)
        #empty-items            <- Slot in for no items warning (this is typically the result of parent filters returning no results). Useful when generic wording isn't descriptive enough
        
    * Example Usage    
        1. Simple, no cell redesign
            <iws-table
                :columns="[ 
                    {
                        key: 'name',
                        label: 'Name'
                    }, {
                        key: 'description',
                        label: 'Description',
                        sortable: false
                    }
                ]"
                
                :items="items"

                :filter="searchKey"             // OPTIONAL: enables filtering (will match to any key given in columns)
                :sortByCol.sync="sortByCol"     // OPTIONAL: controller for sorting, provides a default and .sync will change with new column clicks
                :maxPageSize="12"               // OPTIONAL: enables pagination, indicates how many rows to show per page
            />     

        2. Targeting specific cell redesign
            <iws-table
                :columns="[ 
                    {
                        key: 'name',
                        label: 'Name'
                    }, {
                        key: 'description',
                        label: 'Description'
                    }, {
                        label: null,
                        key: 'actions',
                        showHeader: false,
                        sortable: false
                    }
                ]"
                
                :items="items"

                :filter="searchKey"             // OPTIONAL: enables filtering (will match to any key given in columns)
                :sortByCol.sync="sortByCol"     // OPTIONAL: controller for sorting, provides a default and .sync will change with new column clicks
                :maxPageSize="12"               // OPTIONAL: enables pagination, indicates how many rows to show per page
            >      
                Allows us to stylize the actions cell for all rows (one at a time, using the given data object for that row). Can be used for all columns needed
                <template #cell_actions="{ data }">
                    <iws-button type="outline-primary" icon="fas fa-cog" />

                    <iws-button type="outline-danger" icon="fas fa-trash-alt" class="ml-2" />
                </template>
            </iws-table>

        3. Header targeting
            <iws-table
                :columns="[ 
                    {
                        key: 'name',
                        label: 'Name'
                    }, {
                        key: 'description',
                        label: 'Description'
                    }, {
                        label: null,
                        key: 'actions',
                        showHeader: false,
                        sortable: false
                    }
                ]"
                
                :items="items"

                :filter="searchKey"             // OPTIONAL: enables filtering (will match to any key given in columns)
                :sortByCol.sync="sortByCol"     // OPTIONAL: controller for sorting, provides a default and .sync will change with new column clicks
                :maxPageSize="12"               // OPTIONAL: enables pagination, indicates how many rows to show per page
            >
                <template #header_name>
                    Name
                </template>
                <template #header_description>
                    Description
                </template>
                <template #header_actions>
                    Actions
                </template>
            </iws-table>
-->
<template>
<div :style="tableDimension">
    <table>
        <thead>
            <th v-for="(header, index) in headers"
                :key="`dark_table_${header.key}_${index}`"
                class="user-select-none"
                :class="{ 'clickable': sortByCol !== null && header.sortable }"
                :style="header.thStyle"
                @click="handleClickForSort(header)"
                scope="col"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <slot :name="`header_${header.key}`" :data="getDataItem(header, null, index)">
                        {{ header.showHeader && !!header.label && !header.label.includes('null') ? header.label : null }}
                    </slot>
                    <div v-if="header.showHeader && header.sortable" class="ml-2" v-html="createChevrons(header)"></div>
                </div>
            </th>
        </thead>

        <!-- If row type is not draggable then render the table body as usual -->
        <tbody class="position-relative" v-if="!isRowDraggable">
            <slot name="body" class="position-relative">
                <template v-if="!_isNullOrEmpty(pageinatedItems)">
                    <template v-for="(item, index) in pageinatedItems">
                        <slot :name="`row_${item.id}`" :data="getDataItem(null, item, index)">
                            <tr :key="index">
                                <td v-for="column in headers" :key="`td_${column.key}`" :style="column.colStyle">
                                    <slot :name="`cell_${column.key}`" :data="getDataItem(column, item, index)">
                                        {{ getValue(item, column) }}
                                    </slot>
                                </td>
                            </tr>
                        </slot>
                    </template>
                </template>
                <template v-else>
                    <tr>
                        <td :colspan="headers.length">
                            <div class="text-center">
                                <slot name="empty-items">
                                    <div v-if="!!filter && typeof filter == 'string'" class="no-results-container">
                                        <i class="fas fa-search"></i>
    
                                        <div class="title">No results found</div>
                                        <div class="subtitle">Your filters did not match any records. Please try again.</div>
                                    </div>
                                    <template v-else>
                                        No items to be displayed 
                                    </template>
                                </slot>
                            </div>
                        </td>
                    </tr>
                </template>
            </slot>
            <tr v-if="maxPageSize !== undefined && !_isNullOrEmpty(filteredItems) && filteredItems.length > maxPageSize" id="pagination-control">
                <td :colspan="headers.length">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="filteredItems.length"
                        :per-page="maxPageSize"
                        @change="changePage"
                        first-number
                        last-number
                        :align="paginationPosition"
                    />
                </td>
            </tr>
        </tbody>
        <!-- If table body is draggable then render the table body as a slot since the <draggable> component is 
            of type (tag) tbody and it will already generate a table body tag that will wrap the child elements-->
        <slot v-if="isRowDraggable" name="body" class="position-relative">
            <template v-if="!_isNullOrEmpty(pageinatedItems)">
                <tr v-for="(item, index) in pageinatedItems" :key="index">
                    <slot :name="`row_${item.id}`" :data="getDataItem(null, item, index)">
                        <td v-for="column in headers" :key="`td_${column.key}`">
                            <slot :name="`cell_${column.key}`" :data="getDataItem(column, item, index)">
                                {{ getValue(item, column) }}
                            </slot>
                        </td>
                    </slot>
                </tr>
            </template>
            <template v-else>
                <tr>
                    <td :colspan="headers.length">
                        <div class="text-center">
                            <slot name="empty-items">
                                <div v-if="!!filter && typeof filter == 'string'" class="no-results-container">
                                    <i class="fas fa-search"></i>

                                    <div class="title">No results found</div>
                                    <div class="subtitle">Your filters did not match any records. Please try again.</div>
                                </div>
                                <template v-else>
                                    No items to be displayed 
                                </template>
                            </slot>
                        </div>
                    </td>
                </tr>
            </template>
        </slot>
        <tr v-if="maxPageSize !== undefined && !_isNullOrEmpty(filteredItems) && filteredItems.length > maxPageSize && isRowDraggable" 
            id="pagination-control">
            <td :colspan="headers.length">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="filteredItems.length"
                    :per-page="maxPageSize"
                    @change="changePage"
                    first-number
                    last-number
                    align="center"
                />
            </td>
        </tr>

        <tfoot>
            <slot name="footer"></slot>
        </tfoot>
    </table>
</div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isNullOrEmpty, isFalsy } = GlobalFunctions;

export default {
    props: {
        // Array of objects detailing the structure of the table
        // Data Structure: {
        //     thStyle: Object,
        //     colStyle: Object,
        //     sortable: Boolean,
        //     formatter: Function(value, key, item),
        //     filter: Function(filter, item),
        //     key: String,
        //     label: String,
        //     showHeader: Boolean,
        //     hasDetails: Boolean
        // }

        // Simple, "normal example": [{
        //     key: 'name',
        //     label: 'Name',
        // }, {
        //     label: null,
        //     key: 'actions',
        //     showHeader: false,
        //     sortable: false
        // }]

        // Full Example: {
        //     thStyle: { top: '1px' },
        //     colStyle: { position: 'sticky', top: '0px' },
        //     sortable: true,
        //     formatter: (value, key, item) => isTruthy(value) ? value : '-'
        //     filter: (filter, item) => item?.name?.toLocaleLowerCase().includes(filter),
        //     key: 'name',
        //     label: 'Name',
        //     showHeader: true,
        //     hasDetails: false
        // }
        columns: {
            type: Array,
            required: true,
            default: () => {
                return [];
            }
        },
        // List of items to be displayed in the table body (should have properties matching each of the header keys)
        items: {
            type: Array,
            required: true,
            default: () => {
                return [];
            }
        },

        // Search key, (when provided) only items with at least 1 cell value including the filter as a non case sensative substring will render
        filter: {
            type: String | Function
        },
        // Header key to sort table on
        sortByCol: {
            type: String
        },
        // Sort direction
        sortAsc: {
            type: Boolean,
            default: true
        },
        // When provided, will auto implement pagination with the given page size limit
        maxPageSize: {
            type: Number
        },

        // Style object for re-dimensioning table bounds
        tableDimension: {
            type: Object,
            required: false,
            default: () => {
                return  {
                    height: '100%',
                    width: '100%'
                };
            }
        },

        // Pagination page horizontal alignment
        // Examples: start, center, end
        paginationPosition: {
            type: String,
            required: false,
            default: "center"
        },

        // Controls the manual re-organizing of rows
        isRowDraggable: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data() {
        return {
            currentPage: 1,
            sortAscLocal: this.sortAsc,
            filterTrigger: 0
        }
    },

    computed: {
        headers() {
            if (isNullOrEmpty(this.columns))
                return [];

            return this.columns.map((col) => {
                if (typeof col === 'object') {
                    return {
                        thStyle: col?.thStyle ?? null,
                        colStyle: col?.colStyle ?? null,
                        sortable: col?.sortable !== false,
                        formatter: col?.formatter ?? null,
                        filter: col?.filter ?? null,
                        key: col?.key ?? col?.label ?? col,
                        label: col?.label ?? col?.key ?? col,
                        showHeader: col?.showHeader !== false,
                        hasDetails: col?.hasDetails ?? false
                    };
                }
                 else {
                    return  {
                        key: col,
                        label: col,
                        showHeader: true,
                        formatter: null,
                        hasDetails: false,
                        sortable: (col == null || col == '') ? false : true
                    };
                }
            });
        },

        pageCount() {
            // Need items to paginate
            if (isNullOrEmpty(this.filteredItems))
                return 0;
            // Pagination is optional, only implement when the parent has given page sizes
            if (!isFalsy(this.maxPageSize))
                return Math.ceil(this.filteredItems.length / this.maxPageSize);
            // Items set but pagination is not enabled, show all
            return this.filteredItems.length;
        },

        filteredItems() {
            if (isNullOrEmpty(this.items))
                return [];

            this.filterTrigger;
            let items = [ ...this.items ];

            // Filtering is an optional feature, only filter if some filter method is provided
            if (!isFalsy(this.filter)) {
                if (typeof this.filter == 'function') {
                    items = items.filter(item => this.filter(item));
                } else if (typeof this.filter == 'string') {
                    const _filter = this.filter.toLocaleLowerCase();

                    items = items.filter(item => !!this.headers.find(header => {
                        if (!isFalsy(header.filter))
                            return header.filter(this.filter, item);
                        return `${_.get(item, header.key)}`.toLocaleLowerCase().includes(_filter);
                    }));
                }
            }

            // Sorting is optional too, only enable sorting when a sort controller is provided
            if (!isFalsy(this.sortByCol)) {
                items = _.orderBy(items, [this.sortByCol], [this.sortAscLocal ? 'asc' : 'desc']);
            }

            this.$emit('update:items', items);
            return items;
        },
        pageinatedItems() {
            if (!isFalsy(this.maxPageSize)) {
                const start = (this.currentPage-1) * this.maxPageSize;
                return this.filteredItems.slice(start, start + this.maxPageSize);
            }

            return this.filteredItems;
        }
    },

    watch: {
        items() {
            this.currentPage = 1;
        }
    },

    methods: {		
        _isNullOrEmpty(val) {
            return isNullOrEmpty(val);
        },
        forceItemsUpdate() {
            this.filterTrigger++;
        },
        createChevrons(column) {
            if (!column.sortable)
                return;

            const chevronUp = '<i class="fas fa-chevron-up sort-icon"></i>';
            const chevronDown = '<i class="fas fa-chevron-down sort-icon"></i>';
            const selectedChevron = `<i class="fas fa-chevron-${this.sortAscLocal ? 'down' : 'up'} sort-icon"></i>`;
            const htmlString = `
            <div class="d-flex flex-column">
                ${this.sortByCol == column.key ? selectedChevron : chevronUp + chevronDown}
            </div>
            `;
            return htmlString;
        },
        handleClickForSort(column) {
            if (!column.sortable)
                return;

            if (this.sortByCol == column.key) {
                // Clicking the current sort column flips direction
                this.$emit('update:sortAsc', !this.sortAscLocal);
                this.sortAscLocal = !this.sortAscLocal;
                
            } else {
                this.$emit('update:sortByCol', column.key);
                this.$emit('update:sortAsc', true);
                this.sortAscLocal = true;
            }
        },

        changePage(newIndex) {
            this.$emit('pageChange', newIndex);
        },

        getValue(item, column) {
            let value = _.get(item, column.key);
            if (column.hasOwnProperty('formatter') && column.formatter)
                return column.formatter(value, column.key, item);
            return value;
        },
        getDataItem(column, item = null, index) {
            if (column == null && item)
                return { item, index };
            if (item)
                return {
                    item,
                    label: column.label,
                    value: this.getValue(item, column),
                    index: index
                };
            return {
                column,
                label: column.label,
                index: index
            };
        }
    }
};
</script>

<style>
    .page-item,
    .page-item button,
    .page-item span,
    .page-item.disabled,
    .page-item.disabled button,
    .page-item.disabled span {
        background-color: transparent !important;
        border-color: transparent !important;
        color: #7B8A98;
    }
    .page-item.active {
        padding: 5px 20px 0px;
    }
    .page-item:not(.active) {
        padding: 5px 0px 0px;
    }
    .page-item.active button {
        border-color: var(--primary-color) !important;
        border-radius: 5px;
        font-size: 18px;
        color: var(--primary-text-color);

        display: block;
        height: 30px;
        width: 30px;

        padding: 0px !important;
        margin: auto;
        flex-grow: unset !important;
    }
</style>
<style scoped>
    table {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0em;
        width: 100%;
        border-collapse: separate; /* Overriding collapsed border */
        border-spacing: 0;
        margin-top: 10px;
        margin-bottom: 50px;
    }
    thead {
        position: sticky;
        top: 0px;
    }
    th {
        font-size: 18px;
    }
    tfoot, thead {
        font-weight: 600;
        background: #2E353D !important;
        color: var(--primary-text-color) !important;
        z-index: 99;
    }
    table::v-deep tr {
        background: #242A30 !important;
        color: var(--primary-text-color) !important;
    }
    table::v-deep tr:hover {
        background: #2E353D !important;
    }
    thead th, table::v-deep td {
        border-top-style: solid;
        border-top-color: #7B8A98;
        border-top-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #7B8A98;
        border-bottom-width: 1px;
        padding: 12px 12px 12px 12px;
    }
    thead th:first-child, table::v-deep td:first-child { 
        border-left-style: solid;
        border-left-color: #7B8A98;
        border-left-width: 1px;
    }
    thead th:last-child, table::v-deep td:last-child { 
        border-right-style: solid;
        border-right-color: #7B8A98;
        border-right-width: 1px;
    }
    thead th:first-child { border-top-left-radius: 5px !important; }
    thead th:last-child { border-top-right-radius: 5px !important; }
    table::v-deep tr:last-child>td:first-child { border-bottom-left-radius: 5px !important; }
    table::v-deep tr:last-child>td:last-child { border-bottom-right-radius: 5px !important; }
    :deep(th .sort-icon) {
        font-size: 12px;
        color: #667085;
    }
    #pagination-control {
        position: sticky;
        bottom: 0px;
        z-index: 4;

    }
    #pagination-control>td {
        padding: 0px !important;
        border-top: solid 1px #7B8A98 !important;
        border-top-style: solid !important;
        border-top-color: #7B8A98 !important;
        border-top-width: 1px !important;
    }
    #pagination-control>td>.pagination {
        margin-bottom: 0px !important;
    }
    .no-results-container {
        text-align: center;
        width: 100%;
        padding: 20px 10px;
    }
    .no-results-container i {
        font-size: 36px;
        color: var(--primary-text-color);
        margin-bottom: 20px;
        background: rgba(217, 215, 215, 0.1);
        border: 6px solid rgba(240, 240, 240, 0.1);
        border-radius: 50%;
        padding: 20px;
    }
    .no-results-container .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-text-color);
    }
    .no-results-container .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
    }
    .darkmode-form {
        background-color: #25232B;
        color: #FFFFFF;
        border: 1px solid #000000;
        min-width: 100px;
    }
    
</style>