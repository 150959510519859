<template>
    <div id="CameraSettings">
        <b-overlay :show="isBusy.busy" variant="light" blur="5px" opacity="0.90">
            <template #overlay>
                <div class="text-center">
                    <b-spinner large class="align-middle"></b-spinner>
                    <p class="mt-2">{{ isBusy.state }}, this may take a while...</p>
                </div>
            </template>
            <div class="position-relative">
                <iws-breadcrumbs
                    :path="[{
                        title: 'Devices',
                        link: `/cameras/settings`
                    }, {
                        title: device?.deviceID,
                        link: `/cameras/settings/${workOrderId}/${device?.deviceID}`
                    }, {
                        title: camera?.displayName,
                        link: `/cameras/settings/${workOrderId}/${device?.deviceID}/${camera?.id}`
                    }]"
                />
                
                
                <div class="page-title">
                    Camera Configuration 
                </div>
    
                <div class="positioned-actions">
                    <a v-if="camera?.primaryStream?.id" :href="`/cameras/viewer/${workOrderId}/${camera?.id}/${camera?.primaryStream?.id}`" class="mr-3">
                        <iws-button type="outline-primary" icon="far fa-eye" />
                    </a>
                    
                    <iws-button type="outline-light" text="Keychain" :click="openAccountsModal" />

                    <iws-button type="outline-light" text="Sync from ONVIF" :disabled="disableSyncWithOnvif" :click="onClickSyncWithOnvif" class="mx-2" />
                    
                    <span class="status-border" :class="{ 'green-gradient': camera?.online, 'red-gradient': !camera?.online }">
                        {{ camera?.online ? 'Online' : 'Offline' }}
                    </span>
                </div>
    
                <div class="full-width">
                    <span id="search">
                        <iws-search :value.sync="searchKey" />
                    </span>
    
                    <span id="add-new">
                        <iws-button text="New Stream" append-icon="fas fa-plus" :click="addStream" />
                    </span>
                </div>
    
                <div v-if="!_isNullOrEmpty(streamConfigurations)">
                    <iws-table
                        :columns="columns"
                        
                        :items="streamConfigurations"
    
                        :filter="searchKey"
                        :sortByCol.sync="sortByCol"
                        :maxPageSize="12"
                    >      
                        <template #cell_broadcast="{ data }">
                            <iws-checkbox :value.sync="data.item.broadcast" />
                        </template>
                        <template #cell_isAIEnabled="{ data }">
                            <iws-checkbox :value.sync="data.item.isAIEnabled" />
                        </template>
                        <template #header_resolutionData>
                            <div class="w-100 d-flex flex-column justify-content-between">
                                <div class="align-self-center">Resolution</div>
                                <div class="d-flex justify-content-around">
                                    <div>
                                        Field
                                    </div>
                                    <div>
                                        Cloud
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #header_bitrateData>
                            <div class="w-100 d-flex flex-column justify-content-between">
                                <div class="align-self-center">Bitrate (kbps)</div>
                                <div class="d-flex justify-content-around">
                                    <div>
                                        Field
                                    </div>
                                    <div>
                                        Cloud
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #header_fpsData>
                            <div class="w-100 d-flex flex-column justify-content-between">
                                <div class="align-self-center">FPS</div>
                                <div class="d-flex justify-content-around">
                                    <div>
                                        Field
                                    </div>
                                    <div>
                                        Cloud
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #cell_resolutionData="{ data }">
                            <div class="d-flex justify-content-around">
                                <div><span>{{ data.item.resolutionData.field }}</span></div> 
                                <div><span>{{ data.item.resolutionData.cloud }}</span></div> 
                            </div>
                        </template>
                        <template #cell_bitrateData="{ data }">
                            <div class="d-flex justify-content-around">
                                <div><span>{{ data.item.bitrateData.field }}</span></div> 
                                <div><span>{{ data.item.bitrateData.cloud }}</span></div> 
                            </div>
                        </template>
                        <template #cell_fpsData="{ data }">
                            <div class="d-flex justify-content-around">
                                <div><span>{{ data.item.fpsData.field }}</span></div> 
                                <div><span>{{ data.item.fpsData.cloud }}</span></div> 
                            </div>
                        </template>
                        <template #cell_actions="{ data }">
                            <iws-button type="outline-light" icon="fas fa-cog" :click="() => editStream(data.item)" />
    
                            <iws-button type="outline-info" icon="fas fa-pencil-alt" :click="() => editStreamQuality(data.item)" class="ml-2" />
                                    
                            <iws-button type="outline-danger" icon="fas fa-trash-alt" :disabled="data.item.isSelected" :click="() => deleteStream(data.item)" class="ml-2" />
                        </template>
                    </iws-table>
    
                    <div class="form-container">
                        <label>
                            Local Configuration
                        </label>
    
                        <div class="row">
                            <div class="col-3">
                                <iws-input :value.sync="camera.hostname" label="Hostname" />
                            </div>
    
                            <div class="col-3">
                                <iws-input :value.sync="camera.onvifUsername" label="Username" />
                            </div>
    
                            <div class="col-3">
                                <iws-input :value.sync="camera.onvifPassword" label="Password" type="password" />
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-3">
                                <iws-input :value.sync="camera.cameraName" label="Camera Name" />
                            </div>
    
                            <div class="col-3">
                                <iws-input :value.sync="camera.brand" label="Brand" />
                            </div>
    
                            <div class="col-3">
                                <iws-input :value.sync="camera.model" label="Model" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <iws-input :value.sync="camera.displayName" label="Display Name" />
                            </div>
                        </div>
                    </div>
    
                    <div class="form-container">
                        <label>
                            AI Configuration
                        </label>
    
                        <div class="row">
                            <div class="col-3">
                                <iws-input :value.sync="camera.aiPersonConfidenceThreshold" label="Person Confidence Threshold" />
                            </div>
                            <div class="col-3 d-flex flex-column-reverse">
                                <iws-checkbox class="mb-2" :value.sync="camera.aiPersonDetectionEnabled" label="Enable Person Detection"/>
                            </div>
                            <div class="col-3 d-flex flex-column-reverse">
                                <iws-checkbox class="mb-2" :value.sync="camera.showAIDebugInfo" label="Show AI Debug Info"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <iws-input class="mb-2" :value.sync="camera.aiRedZoneViolationSeconds" label="Redzone Violation Seconds" />
                            </div>
                            <div class="col-3 d-flex flex-column-reverse">
                                <iws-checkbox class="mb-2" :value.sync="camera.aiRedZoneDetectionEnabled" label="Enable Red Zone Detection"/>
                            </div>
                        </div>
                    </div>
    
                    <div class="page-save-action full-width">
                        <iws-button text="Save" :click="saveDevice" />
                    </div>
                </div>
                <div v-else class="no-items clickable" @click="addStream()">
                    <h2 class="danger-text-color">
                        No streams configured for this camera
                    </h2>
                    <h3>
                        Click here to create one
                    </h3>
                </div>
            </div>
    
            <add-stream-modal ref="AddStreamModal" />
            <stream-quality-modal ref="StreamQualityModal" />
            <keychain-modal ref="KeychainModal" />
        </b-overlay>
    </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, toast } = GlobalFunctions;

import AddStreamModal from './AddStreamModal.vue';
import StreamQualityModal from './StreamQualityModal.vue';
import KeychainModal from './KeychainModal.vue';

export default {
    props: [ 'user', 'workOrderId', 'device', 'camera' ],

    components: {
        AddStreamModal,
        StreamQualityModal,
        KeychainModal
    },
    data: () => ({
        columns: [ 
            {
                key: 'broadcast',
                label: 'Broadcast'
            }, {
                key: 'name',
                label: 'ID'
            },{
                key: 'isAIEnabled',
                label: 'AI Enabled'
            }, {
                key: 'resolutionData',
                label: 'Resolution',
                sortable: false
            }, {
                key: 'bitrateData',
                label: 'Bitrate',
                sortable: false
            }, {
                key: 'fpsData',
                label: 'FPS',
                sortable: false
            }, {
                label: null,
                key: 'actions',
                showHeader: false,
                sortable: false
            }
        ],
        searchKey: null,
        sortByCol: 'isSelected',
        isBusy: {
                busy: false,
                state: null
        },
    }),
    mounted() {
        window.addEventListener('beforeunload', (event) => {
            if (this.isBusy.busy) {
                event.preventDefault();
                event.returnValue = '';
            }
        });
    },
    computed: {
        disableSyncWithOnvif() {
            return this.camera.ipAddress == null || this.camera.ipAddress === '' 
                    || this.camera.onvifUsername == null || this.camera.onvifUsername === '' 
                    || this.camera.onvifPassword == null || this.camera.onvifPassword === '' ;
        },
        streamConfigurations() {
            const streamConfigs = []
            if (this.camera?.streamConfigurations?.length) {
                this.camera.streamConfigurations.forEach(stream => {
                    streamConfigs.push({
                        ...stream,
                        resolutionData: {
                            field: stream.localResolution ?? `Source (${stream.streamHeight})`,
                            cloud: stream.cloudResolution
                        },
                        bitrateData: {
                            field: stream.localBitrate ? stream.localBitrate/1000 : 'Not Available',
                            cloud: stream.cloudBitrate ? stream.cloudBitrate/1000 : 'Not Available'
                        },
                        fpsData: {
                            field: stream.localFPS,
                            cloud: stream.cloudFPS
                        }
                    })
                })
            }
            return streamConfigs;
        }
    },
    methods: {
        _isNullOrEmpty: (value) => isNullOrEmpty(value),

        onClickSyncWithOnvif() {
            return axios.post(`/cameras/${this.device.deviceID}/cameras/${this.camera.id}/streams/syncWithOnvif`).then(_ => {
                location.reload();
            }).catch(_error => {
                return toast({
                    title: 'Failed to sync device',
                    body: _error.message,
                    variant: 'error'
                });
            });
        },
        editStreamQuality(stream) {
            return this.$refs.StreamQualityModal.open(stream).then(updatedStream => {
                if (!isFalsy(updatedStream)) {
                    this.isBusy = {
                        busy:true,
                        state: 'Editing stream quality'
                    };
                    return axios.put(`/cameras/${this.device.deviceID}/stream/${this.camera.id}/${stream.id}/quality`, updatedStream).then(_ => {
                        this.isBusy = false;
                        location.reload();
                    }).catch(_error => {
                        this.isBusy = false;
                        return toast({
                            title: 'Failed to update stream',
                            body: _error.message,
                            variant: 'error'
                        });
                    });
                }
            });
        },
        addStream() {
            return this.$refs.AddStreamModal.open().then(stream => {
                if (!isFalsy(stream))
                    this.isBusy = {
                        busy:true,
                        state: 'Adding stream'
                    };
                    return axios.post(`/cameras/${this.device.deviceID}/stream/${this.camera.id}`, stream).then(_ => {
                        this.isBusy = false;
                        location.reload();
                    }).catch(_error => {
                        this.isBusy = false;
                        return toast({
                            title: 'Failed to create stream',
                            body: _error.message,
                            variant: 'error'
                        });
                    });
            });
        },
        editStream(stream) {
            return this.$refs.AddStreamModal.open(stream).then(updatedStream => {
                if (!isFalsy(updatedStream))
                    this.isBusy = {
                        busy:true,
                        state: 'Editing stream'
                    };
                    return axios.put(`/cameras/${this.device.deviceID}/stream/${this.camera.id}/${stream.id}`, updatedStream).then(_ => {
                        location.reload();
                    }).catch(_error => {
                        this.isBusy = false;
                        return toast({
                            title: 'Failed to create stream',
                            body: _error.message,
                            variant: 'error'
                        });
                    });
            });
        },
        deleteStream(stream) {
            return GlobalFunctions.iwsConfirm({
                title: 'Delete Stream',
                body: `Are you sure you want to delete "${stream.name || 'this stream'}"? This action cannot be undone!`,
                confirmColour: 'danger',
                width: '400px'
            }).then(_answer => {
                if (_answer)
                    this.isBusy = {
                        busy:true,
                        state: 'Deleting stream'
                    };
                    return axios.delete(`/cameras/${this.device.deviceID}/stream/${this.camera.id}/${stream.id}`).then(_ => {
                        location.reload();
                    }).catch(_error => {
                        this.isBusy = false;
                        return toast({
                            title: 'Failed to delete stream',
                            body: _error.message,
                            variant: 'error'
                        });
                    });
            })
        },

        saveDevice() {
            const index = this.device.cameraConfigurations.findIndex(_camera => _camera.id == this.camera.id)

            if (!isFalsy(index) && index >= 0) {
                this.device.cameraConfigurations[index] = this.camera;
                
                return axios.post(`/cameras/devices/${this.device.deviceID}`, this.device).catch(_error => {
                    return toast({
                        title: 'Failed to save changes',
                        body: _error.message,
                        variant: 'error'
                    });
                });
            }
        },

        openAccountsModal() {
            return this.$refs.KeychainModal.open(this.device);
        }
    }
};
</script>