<template>
    <div id="VideoPlayer" class="position-relative">
        <div class="position-relative" id="main-container" :class="{ 'show-controls': showControls }">
            <VideoScrubberV2 v-if="showControls && !(currentVod == null && vodMode == 'vod')" v-show="!disableScrub"
                style="margin-bottom: 10px;"
                :user="user"
                :workOrderId="workOrderId"
                :cameraList="device.cameras"
                :vodList="vods"
                :alertList="alerts"
                :clipList="clips"
                :hourOffset="job?.hourOffset"
                :permissions="permissions"
            /> 

            <div class="video-player-wrapper" @click="$emit('clicked')" :style="!videoOnly ? 'height: calc(100% - 176px);' : null">
                <div v-if="currentVod == null && vodMode == 'vod'" class="error-message">
                    No video from this time
                </div>
                <div v-else-if="currentState == 'loading'" class="player-spinner">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-else-if="playerError" class="error-message">
                    Video playback error. Retrying...
                </div>
                <div v-else-if="errorMessage" class="error-message">
                    {{ errorMessage }}
                </div>

                <div v-show="showVideoPlayer" id="video-player-viewer" :style="'aspect-ratio: ' + aspectRatio" ref="videoPlayerDisplay">
                    <canvas v-if="showControls && drawingMode"
                        id="redzone-canvas"
                        @click="onCanvasClicked"
                        :height="canvasHeight"
                        :width="canvasWidth" 
                        :style="'height: ' + canvasHeight + 'px; width: ' + canvasWidth + 'px;'"
                    ></canvas>
                    <div :id="playerId" class="video-player"></div>
                </div>
            </div>
        </div>

        <camera-control v-if="showControls"
            ref="CameraControl"
            :work-order-id="workOrderId"
            :device="device"
            :permissions="permissions"
            :camera="selectedCamera"
            :cameraDetails.sync="cameraDetails"
            :displayedRedZone="displayedRedZone"
            :drawingMode.sync="drawingMode"
            :control="control"

            :vodSelected.sync="vodSelected"
            :vodDropdownItems="vodDropdownItems"

            @saveRedzone="saveRedzone()"
            @deleteRedzone="deleteRedzone()"
            @clearCanvas="clearCanvas()"
            @drawCanvas="setDisplayedRedzone()"
        />
    </div>
</template>

<script>
import OvenPlayer from "ovenplayer";
import { debounce } from "debounce";
import { v4 as uuidv4 } from 'uuid';
import eventBus from "../../eventBus";
import moment from 'moment';

import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, toast, iwsConfirm } = GlobalFunctions;

import CameraControl from './CameraControl.vue';
import VideoScrubberV2 from './VideoScrubberV2.vue';

const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

export default{
    props: [ 'user', 'workOrderId', 'device', 'permissions', 'camera', 'job', 'vods', 'alerts', 'clips', 'index', 'control', 'videoOnly', 'disableScrub' ],

    components: { CameraControl, VideoScrubberV2 },

    data: () => ({
        vodMode: "live",
        vodSelected: 'Live',
        
        vodDropdownItems: [{
            label: 'Live',
            startSecond: 0
        }],

        cameraDetails: {
            id: -1,
            panEnabled: false,
            tiltEnabled: false,
            zoomEnabled: false
        },

        muted: false,
		paused: false,

        scrubberTicks: null,
        dvrDuration: 0,
        currentUnixTimestamp: 0,

        player: undefined,
        cameraList: [],
        cameraId: null,
        selectedCamera: null,
        videoConfig: null,
        currentVod: null,

        secondsBehindLive: 0,
        shouldSeek: false,
        playerError: false,
        errorMessage: null,
        forceRetryTimeout: null,
        badPlayerStates: [ 'error', 'stalled', 'idle' ],
        currentState: 'loading',

        // Redzone
        canvasHeight: 0,
        canvasWidth: 0,
        aspectRatio: null,
        displayedRedZone: null,

        canvas: null,
        context: null,
        drawingMode: false,
        drawnPoints: []
    }),

    computed: {
        playerId() {
            return `video_player_${uuidv4()}`;
        },
        cameraDropdownItems() {
            return [
                {
                    label: "None",
                    camera: null,
                },
                ...this.cameras.map(c => {
                    return {
                        label: `Camera ${c.displayName || c.name}`,
                        camera: c,
                    };
                })
            ];
        },
        liveMode() {
			return this.vodSelected === 'Live';
		},

        showVideoPlayer() {
            return this.currentState !== 'loading' && !this.playerError;;
        },
        showControls() {
            return this.permissions?.edit && !this.videoOnly && !isFalsy(this.selectedCamera);
        }
    },

    methods: {
        _isFalsy(value) {
            return isFalsy(value);
        },

        async onWorkOrderChange() {
            let resp = await axios.get(`/cameras/viewing/workOrders/${this.workOrderId}/dvr`);

            this.dvrDuration = SECONDS_IN_HOUR * (resp?.data || 0);
            resp = await axios.get(`/cameras/viewing/segments/${this.workOrderId}/dates`);

            this.vodDropdownItems = [{
                label: 'Live',
                startSecond: 0
            }];

            this.scrubberTicks = {};
            for (const startTime of resp.data) {
                const dt = new Date(startTime);
                const dateString = `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`;
                const secondsForDate = (dt.getHours() * SECONDS_IN_HOUR) + (dt.getMinutes() * SECONDS_IN_MINUTE) + dt.getSeconds(); 
                const existingItem = this.vodDropdownItems.find(i => i.label == dateString);

                if (this.scrubberTicks[`${dateString}`] == null) {
                    this.scrubberTicks[`${dateString}`] = {};
                }
                this.scrubberTicks[`${dateString}`][`${secondsForDate}`] = '|';

                if (existingItem == null) {
                    this.vodDropdownItems.push({
                        label: dateString,
                        startSecond: secondsForDate
                    });
                } else if (existingItem.startSecond > secondsForDate) {
                    existingItem.startSecond = secondsForDate;
                }
            }
        },
        async onCameraSelected(c) {
            if (!isFalsy(c?.primaryStream)) {
                await this.getCameraDetails(c);
                this.selectedCamera = { ...c, url: c.primaryStream.webRTC };

                this.videoConfig = {
                    autoStart: true,
                    autoFallback: true,
                    mute: true,
                    controls: false,
                    sources: [{
                        type: "hls",
                        file: this.selectedCamera.url
                    }]
                };

                if (this.vodMode === "vod" && !isFalsy(this.currentVod?.url)) {
                    this.setCurrentVod();
                    this.setTimestampInVod();
                    this.videoConfig.sources[0].file = this.currentVod?.url;
                }

                this.createPlayer();
            } else if (this.player != null) {
                this.selectedCamera = null;
                this.currentVod = null;
                this.removePlayer();
            } else {
                this.currentState = 'error';
                this.errorMessage = 'No Stream Available';
            }
        },
        setDisplayedRedzone() {
            // TODO: Set null if patrolling
            if (!isFalsy(this.cameraDetails?.activePreset))
                this.displayedRedZone = this.cameraDetails.cameraRedZones?.find(zone => zone.presetToken === this.cameraDetails.activePreset);
        },
        getCameraDetails(camera=this.selectedCamera) {
            try {
                return axios.get(`/cameras/devices/${this.device.deviceId}/cameras/${camera.cameraConfigId}`).then(resp => {
                    this.cameraDetails = resp?.data;
                    this.setDisplayedRedzone();
                });
            } catch (err) {
                console.error(`getCameraDetails error: ${err.toString()}`);
            }
        },
        async refreshPlayer() {
            this.removePlayer();
            this.createPlayer();
        },
        removePlayer() {
            try {
                if (!isFalsy(this.forceRetryTimeout))
                    clearTimeout(this.forceRetryTimeout);

                // Can fail if play back is in error state
                // Can move forward if this fails but better to always try to clear before starting a new player 
                this.player?.remove();
                this.player?.off('stateChanged', event => this.handleStateChanged(event));
                this.player = null;
            } catch(e) {
                console.error('Failed to remove player', e);
            }
        },
        createPlayer() {
            this.player = OvenPlayer.create(this.playerId, this.videoConfig);   
            
            this.player.on('stateChanged', event => this.handleStateChanged(event));
        },
        async handleStateChanged(event) {
            // badPlayerStates: [ 'error', 'stalled', 'idle' ]
            if (this.badPlayerStates.includes(event.newstate)) {
                // only start trying to remake the player if we're not already trying to remake the player (ie in error state)
                // Scrubbing can hit the 'stalled' state just before actually working, for a better experience just avoid this
                if (!this.playerError && (this.secondsBehindLive == 0 && !(event.newstate == 'stalled' || event.newstate == 'idle'))) {
                    this.playerError = true;
                    this.forcePlayerRetry();
                }
            } else {          
                this.playerError = false;

                if (event.newstate == 'playing') {
                    const videos = document.getElementById(this.playerId).getElementsByTagName("video");
                    if (!isNullOrEmpty(videos))
                        this.aspectRatio = `${videos[0].videoWidth} / ${videos[0].videoHeight}`;
                }
            }

            // When scrubbing time is changed, it makes shouldSeek
            // Once the event state is playing, wait for the next tick for it to be fully ready, then scrub
            if (this.shouldSeek && event.newstate == 'playing') {
                this.$nextTick(() => {
                    if (this.vodMode === 'vod') {
                        this.setTimestampInVod();
                    } else if (!isNaN(this.player.getDuration())) {
                        this.player.seek(this.player.getDuration() - this.secondsBehindLive);
                    }
                })

                // Kinda hacky but seek was retriggering this. Only allow scrubbing to happen once per action
                this.shouldSeek = false;
            }

            this.currentState = event.newstate;
        },
        async forcePlayerRetry() {
            // recreate the player
            this.refreshPlayer();

            // seek to current position
            if (this.vodMode == "live" && this.secondsBehindLive > 0) {
                this.player.seek(this.player.getDuration() - this.secondsBehindLive);
            } else if (this.vodMode != 'live') {
                this.setCurrentVod();
                this.setTimestampInVod();
            }

            // give the player a chance to start (and don't spam it with fast retries)
            this.forceRetryTimeout = setTimeout(() => {
                // if we're still in a bad state, force retry again!
                if (!this.player.getState() || this.badPlayerStates.includes(this.player.getState())) {
                    return this.forcePlayerRetry();
                } else {
                    this.playerError = false;
                }
            }, 5000)
        },
        mutePlayer() {
            this.muted = true;
            this.player.setMute(true);
        },
        unMutePlayer() {
            this.muted = false;
            this.player.setMute(false);
        },
        pausePlayer() {
            this.player.pause();
            this.paused = true;
        },
        playPlayer() {
            this.player.play();
            this.paused = false;
        },
        enterFullScreen() {
            this.player.toggleFullScreen();
        },
        handleLiveSelected() {
            this.removePlayer();
            this.currentVod = null;
            this.vodMode = 'live';

            if (this.selectedCamera) {
                // when switching from VOD to live, we need to set the selected camera to have the camera url
                this.videoConfig.sources[0].file = this.selectedCamera.primaryStream.webRTC;
                this.createPlayer();
            } else {
                // Clear selected camera
                this.selectedCamera = null;
            }
        },
        handleScrubberTimeJump() {
            if (this.player != null) {
                this.currentState = 'loading';
                this.playerError = false;

                if (this.secondsBehindLive > 0) {
                    var correctVod = this.findVodByTimestamp();
                    this.selectedCamera.url = correctVod?.url || this.selectedCamera?.llhls || this.selectedCamera?.url;
                    
                    this.vodMode = 'vod';
                    this.currentVod = correctVod;

                    // in live mode, we set video time stamp to be now - scrubberValue
                    this.shouldSeek = true;
                } else {
                    this.selectedCamera.url = this.selectedCamera.primaryStream.webRTC;
                    this.shouldSeek = false;
                }

                this.videoConfig = {
                    autoStart: true,
                    autoFallback: true,
                    mute: true,
                    controls: false,
                    sources: [{
                        type: "hls",
                        file: this.selectedCamera.url
                    }],
                    hlsConfig: {
                        xhrSetup: function(xhr, url) {
                            xhr.setRequestHeader('X-Signature', `?sig=${correctVod?.signature}`); //This will be changed when the player is created
                        } 
                    }
                };

                this.refreshPlayer();
            }
        },
        setScrubberTimeJump(time) {
            this.currentUnixTimestamp = time;
            this.secondsBehindLive = Math.max(Math.floor((Date.now() - time) / 1000), 0);
            this.handleScrubberTimeJump();
        },
        findVodByTimestamp(timestamp=this.currentUnixTimestamp) {
            return this.vods?.find(_vod => _vod.cameraConfigurationId == this.selectedCamera?.cameraConfigId 
                && (isFalsy(this.selectedCamera?.primaryStream?.globalId) || this.selectedCamera?.primaryStream?.globalId == _vod.streamGlobalId)
                && moment(_vod.startTime).valueOf() <= timestamp 
                && (isFalsy(_vod?.endTime) || moment(_vod.endTime).valueOf() > timestamp)
            );
        },
        setCurrentVod() {
            // find which VOD to play
            const foundVod = this.findVodByTimestamp();

            if (foundVod != null) {
                if (this.currentVod == null || foundVod.url != this.currentVod.url) {
                    // play correct VOD
                    this.currentVod = foundVod;
                    this.videoConfig.sources[0].file = this.currentVod.url;
                    this.refreshPlayer();
                } else {
                    // Same vod, just scrubbing
                    this.setTimestampInVod();
                }
            } else if (foundVod == null && this.currentVod != null) {
                this.currentVod = null;
                this.removePlayer();
            }
        },
        setTimestampInVod() {
            if (this.currentVod == null)
                return;

            const timeDiffInMs = this.currentUnixTimestamp - Date.parse(this.currentVod.startTime);
            this.player.seek(timeDiffInMs / 1000);
        },
        onPageFocus() {
            // when you return to the page, either jump to live (if in live mode) or make sure the players sync up with the slider (if in vod mode)
            // the players should be able to handle this automatically if we send the slider time
            if (this.playerError) {
                this.secondsBehindLive = 0;
                this.handleScrubberTimeJump();
            }
        },

        // Redzone
        scaleRedZonePoints(normalizedPoints) {
            // Convert a point from [0.0,1.0] to [0,canvasWidth], and same for height
            return normalizedPoints.map(point => ({
                x: point.x * this.canvas.width,
                y: point.y * this.canvas.height,
            }));
        },
        normalizeRedZonePoints(scaledPoints) {
            // Convert a point from [0,canvasWidth] to [0.0,1.0], and same for height
            return scaledPoints.map(point => ({
                x: point.x / this.canvas.width,
                y: point.y / this.canvas.height,
            }));
        },

        drawDot(x, y) {
            // Draw dot from onclick
            this.context.fillStyle = '#FF0000';
            this.context.beginPath();
            this.context.arc(x, y, 4, 0, 2*Math.PI);
            this.context.fill();
        },
        clearCanvas() {
            if (this.context != null)
                this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        },
        drawRedZone(points, showDashed=false) {
            if (isNullOrEmpty(points))
                return
                
            this.context.lineWidth = 2;
            this.context.strokeStyle = '#FF0000';

            this.context.beginPath();
            this.context.setLineDash([]);
            this.context.moveTo(points[0].x, points[0].y);
            for (const point of points)
                this.context.lineTo(point.x, point.y);
            this.context.stroke();
            this.context.closePath();

            // Draw last "imaginary" dashed line to close the shape
            this.context.beginPath();
            this.context.setLineDash(showDashed ? [5, 10] : []);
            this.context.moveTo(points[0].x, points[0].y);
            this.context.lineTo(points[points?.length-1].x, points[points?.length-1].y);
            this.context.stroke();
            this.context.closePath();
        },
        drawDrawnPoints() {
            this.drawRedZone(this.drawnPoints, true)

            this.drawnPoints.forEach(point => this.drawDot(point.x, point.y));
        },
        onCanvasClicked(event){
            if (this.drawingMode) {
                this.drawnPoints.push({ x: event.offsetX, y: event.offsetY });

                // Redraw existing red zone + theoretical box with orange theoretical closing line
                this.clearCanvas();
                this.drawDrawnPoints();
            }
        },

        async saveRedzone() {
            if (isNullOrEmpty(this.drawnPoints) || this.drawnPoints.length < 3)
                return toast({
                    title: 'At least 3 points are required for a redzone polygon',
                    variant: 'danger'
                });

            try {
                const deleteRedzone = (redzone) => axios.delete(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/red-zones/${redzone.id}`);

                // If not on a preset, we create one for the redzone named "redzone"
                if (isFalsy(this.cameraDetails?.activePreset)) {
                    // Create with name redzone
                    await axios.post(`/cameras/${this.device.deviceId}/presets/${this.camera.cameraConfigId}?name=Redzone`).then(_res => {
                        // Fetch all presets to grab the newest presets details
                        return this.$refs.CameraControl.fetchPresets().then(_presets => {
                            // Set the new preset as the active one in the backend / camera details
                            return axios.post(`/cameras/${this.device.deviceId}/presets/${this.camera.cameraConfigId}/gotopreset`, { token: _presets[_presets?.length-1]?.token }).then(device => {
                                this.cameraDetails = device.data?.cameraConfiguration;
                            });
                        });
                    });
                }

                // Make sure to wipe any exisiting redzones (technically the backend supports 1 per preset but we want only 1 per camera)
                if (!isNullOrEmpty(this.cameraDetails?.cameraRedZones))
                    await Promise.all(this.cameraDetails?.cameraRedZones.map(deleteRedzone))

                return axios.post(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/red-zones`, {
                    cameraId: this.camera.cameraConfigId, 
                    presetToken: this.cameraDetails.activePreset, 
                    redZonePoints: this.normalizeRedZonePoints(this.drawnPoints)
                }).then(resp => {
                    this.cameraDetails = resp?.data;
                    this.setDisplayedRedzone();
                    this.drawingMode = false;
                    this.drawnPoints = [];
                });
            } catch(ex) {
                console.error(ex);
                return toast({
                    title: ex?.response?.data || 'Error saving redzone',
                    variant: 'danger'
                });
            }
        },
        deleteRedzone() {
            if (isNullOrEmpty(this.cameraDetails?.cameraRedZones))
                return toast({
                    title: 'No Redzone config found',
                    variant: 'danger'
                });
                
            return iwsConfirm({
                title: 'Are you sure?',
                body: 'Are you sure you want to delete the set red zone? This can\'t be undone!',
                confirmColour: 'danger',
                width: '400px'
            }).then(_answer => {
                const deleteRedzone = (redzone) => axios.delete(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/red-zones/${redzone.id}`);

                if (_answer === true)
                    return Promise.all(this.cameraDetails?.cameraRedZones.map(deleteRedzone)).then(resp => {
                        this.cameraDetails = resp[resp?.length-1]?.data;
                        this.setDisplayedRedzone();
                        this.drawingMode = false;
                        this.drawnPoints = [];
                    });
            })
        }
    },

    mounted() {
        // documentation here: https://airensoft.gitbook.io/ovenplayer/initialization#sources
        OvenPlayer.debug(true);

        document.defaultView.window.addEventListener('focus', this.onPageFocus);

        this.onWorkOrderChange();

        if (isFalsy(this.selectedCamera) && !isFalsy(this.device?.cameras) && !isFalsy(this.index) && this.index in this.device.cameras) {
            this.onCameraSelected(this.device.cameras[this.index]);
        } else {
            this.player = null;
        }

        eventBus.$on('scrubber-time-jump', debounce(this.setScrubberTimeJump, 100));
        eventBus.$on('scrubber-pause', this.pausePlayer);
        eventBus.$on('scrubber-play', this.playPlayer);
    },

    beforeDestroyed() {
        document.defaultView.window.removeEventListener('focus', this.onPageFocus);

        eventBus.$off('scrubber-time-jump', debounce(this.setScrubberTimeJump, 100));
        eventBus.$off('scrubber-pause', this.pausePlayer);
        eventBus.$off('scrubber-play', this.playPlayer);

        this.removePlayer();
    },

    watch: {
        drawingMode() {
            if (this.drawingMode) {
                // Since the dimensions are dynamic, once the video player is showing grab its dimensions and set them to the canvas to click over it
                this.canvasHeight = this.$refs.videoPlayerDisplay.clientHeight;
                this.canvasWidth = this.$refs.videoPlayerDisplay.clientWidth;
                this.$nextTick(() => {
                    this.canvas = document.getElementById('redzone-canvas');
                    this.context = this.canvas.getContext('2d');
                })
            } else {
                this.canvas = null;
                this.context = null;
            }
        }
    }
};
</script>

<style>
    #VideoPlayer .op-player .op-media-element-container video {
        width: auto !important;
        margin: auto;
    }
</style>
<style scoped>
    #VideoPlayer {
        height: calc(100% - 60px);
        width: 100%;
    }
    
    #main-container {
        display: inline-block;
        height: 100%;
    }
    #main-container.show-controls {
        width: calc(100% - 350px);
    } 
    #main-container:not(.show-controls){
        width: 100%;
    }

    .video-player-wrapper {
        background: rgba(16, 24, 40, 0.25);
        height: 100%;
    }

    #video-player-viewer {
        position: relative;
        max-height: 100%;
        max-width: 100%;
        margin: auto;
        
        border-radius: 10px;
    }

    .error-message {
        font-size: 24px;
        color: var(--danger);
    }

    .error-message,
    .player-spinner {
        padding-top: 50px;
        padding-bottom: 50px;

        text-align: center;
    }

    #redzone-canvas {
        position: absolute;
        z-index: 50;
    }
</style>
