<template>
    <div class="flex-column text-center w-100" :ref="'info-container'+ componentId">
        <div class="awesome awesome-standard ash-bg btn-text d-flex align-items-center justify-content-center">
            <label>Selected Truck:
                <select class="w-100 h-100 form-control align-items-center justify-content-center" v-model="selectedTruck" @change="getCurrentWirelineInfo(true)"
                style="text-align-last: center">
                    <option v-for="(wirelineSystem, index) in wirelineSystems" :key="index" :value="wirelineSystem">{{wirelineSystem.name}}</option>
                </select>
            </label>
        </div>
        <div class="awesome awesome-standard ash-bg btn-text d-flex align-items-center justify-content-center" @click="onViewReportPress"
            style="line-height:0.7;width:100%;height:58px;">
            <span>View Report</span>
        </div>
        <div v-if="displayLogEntries.length>0" class="d-flex p-0 pt-2 overflow-auto" :style="{ 'height' : logsHeight + 'px' }">
            <log-panel :logEntries="displayLogEntries"/>
        </div>
    </div>
</template>

<style scoped>
    .my-style {
        border-radius: 4px
    }
</style>

<script>
import moment from 'moment';
import JSONLocalStorageMixin from '../mixins/JSONLocalStorageMixin';
export default {
    inject: ['dashboardData'],
    props: [
        'wirelineWellIndex',
        'jobNumber',
        'wells',
        'hourOffset',
        'logEntries',
        'height',
        'heightOffset',
        'item',
        'dashboardId'
    ],
    mixins: [JSONLocalStorageMixin],
    data() {
        return {
            dynamicHeight: null,
            selectedTruck: null,
            sortedLogs: [],
            previousLogEntryLength: 0,
            savedTruckObject: null,
            componentId: this.item.i,
            firstRender: true
        };
    },
    mounted() {
        if (this.item?.selectedTruckId && this.wirelineSystems) {
            this.selectedTruck = this.wirelineSystems.find(truck => truck.number == this.item?.selectedTruckId);
        } 
        //load the saved selected truck from local storage
        this.firstRender = true;
    },
    computed: {
        displayLogEntries() {
            // return only the logs that are serviced by the selected truck
            // update info component if new logs are added or on first load
            if ((this.logEntries.length !== this.previousLogEntryLength) || this.firstRender) {
                this.getCurrentWirelineInfo();
            }
            return this.sortedLogs;
        },
        wirelineSystems() {
            return this.dashboardData.wirelineSystems || [];
        },
        logsHeight() {
            const sectionElement = this.$refs['info-container' + this.componentId];
            const sectionHeight = sectionElement ? sectionElement.clientHeight : 0;
            return this.height - sectionHeight;
        },
        currentWell() {
            return this.wells[this.wirelineWellIndex] ?? null; //if no valid well, default to first well
        },
    },
    methods: {
        sortLogs(logEntries) {
            //sorts in reverse time order of the latest stage
            if (this.currentWell && this.currentWell?.currentStage) {
                const currentStageNumber = this.currentWell.currentStage;
                const latestStageLogs = logEntries.filter((logEntry) => logEntry.stageNumber === currentStageNumber);
                return latestStageLogs.sort((a, b) => {
                    return b.time.localeCompare(a.time); // reverse chronological order
                });
            }
            return [];
        },
        convertDateTimeWithOffset(datetimeStr) {
            const date = moment(datetimeStr);
            date.add({hours: this.hourOffset });
            return date;
        },		
        onViewReportPress() {
            if(this.wellIndex !== 999) {
                window.location.href = '/wireline-op/' + this.jobNumber + '/history/' + this.wirelineWellIndex + '/1/5' ;
            } else {
                window.location.href = '/wireline-op/' + this.jobNumber + '/history/' + this.wirelineWellIndex;
            }
        },
        getCurrentWirelineInfo(saveDashboardItems = false) {
            // sort log entries based on selected truck
            const wirelineWellsIndex = [];
            const sortedLogEntries = [];
            this.previousLogEntryLength = this.logEntries.length;
            let wellUpdated = true;

            if (this.selectedTruck && this.wells && this.logEntries && this.logEntries.length > 0) {
                this.wells.forEach((well) => {
                    // get all the wells that are serviced by the selected truck
                    if (well.status === 'Not updated') {
                        // if any of the wells are not updated, then don't update the logs
                        wellUpdated = false;
                    } else if (this.selectedTruck && well?.activityData?.service_id === this.selectedTruck.number && well?.activity === 'wireline') {
                        wirelineWellsIndex.push(well.index);
                    } 
                });
                
                if (wellUpdated) {
                    this.logEntries.forEach((logEntry) => {
                        if (wirelineWellsIndex.includes(logEntry.wellNumber)) {
                            sortedLogEntries.push(logEntry);
                        }
                    });
                    this.sortedLogs = this.sortLogs(sortedLogEntries);
                    this.firstRender = false;
                }
            }

            if (saveDashboardItems && (this.dashboardData.isIwsUser || this.dashboardData.isAdmin || this.dashboardData.isCompanyAdmin) && this.item) {
                if (this.item.selectedTruckId && this.selectedTruck?.number) {
                    this.item.selectedTruckId = this.selectedTruck.number;
                    this.dashboardData.selectedDashboard.items.find(item => item.i == this.item.i).selectedTruckId = this.selectedTruck.number;
                    this.dashboardData.saveDashboardItems(this.dashboardData.selectedDashboard);
                }
            }
        }
    }
};
</script>