var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex ht-100 pb-0 flex-column justify-content-start align-items-center loginPageGrid",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column align-items-start loginPageInnerGrid mt-3",
        },
        [
          _c("img", {
            staticClass: "align-self-center",
            attrs: {
              id: "imgLogo-login",
              src: "/images/inVision_rebrand_logo_2021.png",
              height: "30",
              alt: "IWS",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "w-100" }, [
            _c(
              "form",
              {
                attrs: {
                  id: "login-form",
                  method: "POST",
                  action: "/login",
                  onsubmit: "updateCookies()",
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.csrfToken,
                      expression: "csrfToken",
                    },
                  ],
                  attrs: { type: "hidden", name: "_token" },
                  domProps: { value: _vm.csrfToken },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.csrfToken = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.redirectUrl,
                      expression: "redirectUrl",
                    },
                  ],
                  attrs: { type: "hidden", name: "redirectUrl" },
                  domProps: { value: _vm.redirectUrl },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.redirectUrl = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "w-100" }, [
                  _c("label", { attrs: { for: "username" } }, [
                    _vm._v("Email"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex user-pass-container" }, [
                    _c("img", {
                      attrs: { src: "/images/person_icon.png", height: "25" },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.username,
                          expression: "username",
                        },
                      ],
                      staticClass: "rounded loginPageInputField w-100",
                      class: {
                        "form-control": true,
                        "is-invalid":
                          Object.keys(_vm.errors).indexOf("username") > -1,
                      },
                      attrs: {
                        id: "username",
                        type: "text",
                        name: "username",
                        required: "",
                        autocomplete: "username",
                        autofocus: "",
                        placeholder: "Enter your email",
                      },
                      domProps: { value: _vm.username },
                      on: {
                        click: function ($event) {
                          return _vm.input($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.username = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  Object.keys(_vm.errors).indexOf("username") > -1
                    ? _c("div", { staticClass: "invalid-creds" }, [
                        _vm._v(
                          _vm._s(
                            _vm.errors["username"]
                              ? _vm.errors["username"][0]
                              : undefined
                          )
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-100 mt-3" }, [
                  _c("label", { attrs: { for: "password" } }, [
                    _vm._v("Password"),
                  ]),
                  _vm._v(" "),
                  _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticClass: "input-group user-pass-container",
                        attrs: { id: "password" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/images/lock-password_icon.png",
                            height: "25",
                          },
                        }),
                        _vm._v(" "),
                        (_vm.showPassword ? "text" : "password") === "checkbox"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.password,
                                  expression: "password",
                                },
                              ],
                              staticClass: "rounded loginPageInputField",
                              class: {
                                "form-control": true,
                                "is-invalid":
                                  Object.keys(_vm.errors).indexOf("password") >
                                  -1,
                              },
                              attrs: {
                                id: "password",
                                placeholder: "Enter your password",
                                name: "password",
                                required: "",
                                autocomplete: "current-password",
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.password)
                                  ? _vm._i(_vm.password, null) > -1
                                  : _vm.password,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.input($event)
                                },
                                change: function ($event) {
                                  var $$a = _vm.password,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.password = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.password = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.password = $$c
                                  }
                                },
                              },
                            })
                          : (_vm.showPassword ? "text" : "password") === "radio"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.password,
                                  expression: "password",
                                },
                              ],
                              staticClass: "rounded loginPageInputField",
                              class: {
                                "form-control": true,
                                "is-invalid":
                                  Object.keys(_vm.errors).indexOf("password") >
                                  -1,
                              },
                              attrs: {
                                id: "password",
                                placeholder: "Enter your password",
                                name: "password",
                                required: "",
                                autocomplete: "current-password",
                                type: "radio",
                              },
                              domProps: { checked: _vm._q(_vm.password, null) },
                              on: {
                                click: function ($event) {
                                  return _vm.input($event)
                                },
                                change: function ($event) {
                                  _vm.password = null
                                },
                              },
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.password,
                                  expression: "password",
                                },
                              ],
                              staticClass: "rounded loginPageInputField",
                              class: {
                                "form-control": true,
                                "is-invalid":
                                  Object.keys(_vm.errors).indexOf("password") >
                                  -1,
                              },
                              attrs: {
                                id: "password",
                                placeholder: "Enter your password",
                                name: "password",
                                required: "",
                                autocomplete: "current-password",
                                type: _vm.showPassword ? "text" : "password",
                              },
                              domProps: { value: _vm.password },
                              on: {
                                click: function ($event) {
                                  return _vm.input($event)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.password = $event.target.value
                                },
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-group-append loginPageShowPasswordButton",
                          },
                          [
                            _c("span", { staticClass: "input-group-text" }, [
                              _c("i", {
                                staticClass: "fa fa-eye-slash",
                                staticStyle: { cursor: "pointer" },
                                attrs: { "aria-hidden": "true" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showPasswordToggle("password")
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-100 mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row justify-content-between" },
                    [
                      _c("iws-checkbox", {
                        ref: "rememberMeCheck",
                        staticClass: "mt-1",
                        attrs: {
                          label: "Remember Me",
                          value: _vm.shouldRemember,
                          enableClickLabel: true,
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.shouldRemember = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-100 mt-4" }, [
                  _c("div", { staticClass: "w-100" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary w-100 gap-8 height44px",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            return _vm.addUsernameCookie()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Login\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-100 text-center mt-3" }, [
                      _vm._v(
                        "\n                        Or\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center w-100 mt-3" }, [
                    _c(
                      "a",
                      {
                        staticClass: "text-decoration-none text-white",
                        attrs: { href: "/auth/azure" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "signInWithMicrosoftButton text-deocration-none",
                          },
                          [
                            _c("MicrosoftLogoSVG"),
                            _vm._v(
                              " Sign in with Microsoft\n                            "
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-3 mb-2 login-header" }, [
      _c(
        "h1",
        { staticClass: "mb-2", staticStyle: { "text-align": "center" } },
        [_vm._v("Safer completions. Better completions")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column align-items-center" }, [
        _c("span", [_vm._v("Optimizing completions with software, sensors,")]),
        _vm._v(" "),
        _c("span", [_vm._v("controls and best practices.")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-1 forgot-password" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-link",
          staticStyle: { padding: "0px !important" },
          attrs: { href: "/password/reset" },
        },
        [
          _vm._v(
            "\n                            Forgot Your Password?\n                        "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }