/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import Vue from 'vue'; // es6 syntax
import * as VueGoogleMaps from 'vue2-google-maps';
import moment from 'moment';
import Notifications from 'vue-notification';
import PortalVue from 'portal-vue';
import well from '@iws/well-component';
import VDatePicker from 'v-calendar';
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(PortalVue);
Vue.use(well);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDPIQlcr4eV0O2MdCk9XLGe33KGLGaNg-U'
    },
    installComponents: true
});

//Custom directive
Vue.directive('tooltip', function(el, binding) {
    $(el).tooltip({
        title: binding.value,
        placement: binding.arg,
        trigger: 'hover'
    });

    $(el).on('click', function() {
        $(this).tooltip('hide');
    });
});

Vue.filter('camelToEnglish', el => {
    return el
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function(str){ return str.toUpperCase(); })
})

window.Vue = Vue;

Vue.use(Notifications);

Vue.use(VDatePicker);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

/// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotations from 'chartjs-plugin-annotation';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
Chart.plugins.unregister(ChartDataLabels);
Chart.plugins.unregister(ChartAnnotations);

Vue.component('main-navbar', require('./components/MainNavbar.vue').default);

//Metadata management
Vue.component('metadata-component', require('./components/Metadata/MetadataComponent.vue').default);
Vue.component('metadata-top-level', require('./components/Metadata/MetadataTopLevel.vue').default);
Vue.component('metadata-operator-component', require('./components/Metadata/MetadataOperator.vue').default);
Vue.component('structure-node-component', require('./components/StructureNodeComponent.vue').default);
Vue.component('child-table-component', require('./components/ChildTableComponent.vue').default);
Vue.component('manage-systems-component', require('./components/ManageSystems.vue').default);

Vue.component('gmap-cluster', GmapCluster);
Vue.component('password-strength-component', require('./components/PasswordStrengthComponent.vue').default);
Vue.component('change-password-component', require('./components/ChangePasswordComponent.vue').default);
Vue.component('chart-export-component', require('./components/ChartExportComponent.vue').default);
Vue.component('latency-component', require('./components/LatencyComponent.vue').default);
Vue.component('latency-component-new', require('./components/LatencyComponentNew.vue').default);
Vue.component('wireline-info-component', require('./components/WirelineInfoComponent.vue').default);
Vue.component('single-well-display-component', require('./components/SingleWellDisplayComponent.vue').default);
Vue.component('analytics-dashboard-list', require('./components/Analytics/AnalyticsDashboardList.vue').default);
Vue.component('analytics-dashboard', require('./components/Analytics/AnalyticsDashboard.vue').default);
Vue.component('analytics-create-modal', require('./components/Analytics/AnalyticsCreateModal.vue').default);
Vue.component('wireline-comment-modal', require('./components/WirelineCommentModal.vue').default);
Vue.component('resolve-shot-modal', require('./components/ResolveShotModal.vue').default);
Vue.component('add-well-component', require('./components/AddWellComponent.vue').default);
Vue.component('activity-alert-component', require('./components/ActivityAlertComponent.vue').default);
Vue.component('flex-table-layout', require('./components/FlexTableLayout.vue').default);
Vue.component('stage-dial-component', require('./components/StageDialComponent.vue').default);
Vue.component('add-remove-buttons', require('./components/AddRemoveController.vue').default);
Vue.component('how-to-component', require('./components/HowToComponent.vue').default);
Vue.component('description-component', require('./components/DescriptionComponent.vue').default);
Vue.component('perforation-report-component', require('./components/PerforationReportComponent.vue').default);
Vue.component('tool-string-details-component', require('./components/ToolStringDetailsComponent.vue').default);
Vue.component('add-well-long-component', require('./components/AddWellLongComponent.vue').default);
Vue.component('comment-component', require('./components/CommentComponent.vue').default);
Vue.component('comment-card', require('./components/CommentCard.vue').default);
Vue.component('handshake-component', require('./components/handshakeComponent.vue').default);
Vue.component('well-component', require('./components/WellComponent.vue').default);
Vue.component('toolstring-config-component', require('./components/ToolstringConfigComponent.vue').default);
Vue.component('toolstring-construction-modal', require('./components/ToolstringConstructionModal.vue').default);
Vue.component('well-duplicate-modal', require('./components/WellDuplicateModal.vue').default);
Vue.component('toolstring-duplicate-modal', require('./components/ToolstringDuplicateModal.vue').default);
Vue.component('toolstring-stage-modal', require('./components/ToolstringStageModal.vue').default);

Vue.component('cluster-spacing-config', require('./components/ClusterSpacingConfig.vue').default);
Vue.component('linechart-component', require('./components/linechartComponent.vue').default);
Vue.component('dashboard-well-component', require('./components/DashboardWellComponent.vue').default);
Vue.component('job-component', require('./components/JobComponent.vue').default);
Vue.component('setting-component', require('./components/SettingComponent.vue').default);
Vue.component('setting-individual-component', require('./components/SettingIndividualComponent.vue').default);
Vue.component('enter-pin-component', require('./components/EnterPINComponent.vue').default);
Vue.component('alarm-component', require('./components/AlarmComponent.vue').default);
Vue.component('messages-toolbar-component', require('./components/MessagesToolbarComponent.vue').default);
Vue.component('messages-component', require('./components/MessagesComponent.vue').default);
Vue.component('warnings-component', require('./components/WarningsComponent.vue').default);
Vue.component('profile-history-component', require('./components/ProfileHistoryComponent.vue').default);
Vue.component('job-list-component', require('./components/JobListComponent.vue').default);
Vue.component('gun-list-component', require('./components/GunLibraryComponent.vue').default);
Vue.component('plug-list-component', require('./components/PlugLibraryComponent.vue').default);
Vue.component('ticket-status-component', require('./components/TicketStatus/TicketStatusComponent.vue').default);
Vue.component('customizable-dashboard-component', require('./components/CustomizableDashboardComponent.vue').default);
Vue.component('pad-info-component', require('./components/PadInfoComponent.vue').default);
Vue.component('display-item-component', require('./components/DisplayItemComponent.vue').default);
Vue.component('streaming-dashboard-component', require('./components/StreamingDashboardComponent.vue').default);
Vue.component('well-group-component', require('./components/WellGroupComponent.vue').default);
Vue.component('well-small-component', require('./components/WellSmallComponent.vue').default);
Vue.component('site-status-component', require('./components/SiteStatusComponent.vue').default);
Vue.component('dashboard-wireline-component', require('./components/DashboardWirelineComponent.vue').default);
Vue.component('handshake-table-component', require('./components/HandshakeTableComponent.vue').default);
Vue.component('event-table-component', require('./components/EventTableComponent.vue').default);
Vue.component('summary-bar-component', require('./components/SummaryBarComponent.vue').default);
Vue.component('summary-bar-component-old', require('./components/SummaryBarComponentOld.vue').default);
Vue.component('whiteboard-message-component', require('./components/WhiteboardMessageComponent.vue').default);
Vue.component('radial-gauge-component', require('./components/RadialGaugeComponent.vue').default);
Vue.component('current-value-component', require('./components/CurrentValueComponent.vue').default);
Vue.component('user-settings-component', require('./components/UserSettingsComponent.vue').default);
Vue.component('edit-user-profile', require('./components/EditUserProfile.vue').default);
Vue.component('dashboard-and-api-permission', require('./components/DashboardAndAPIPermission.vue').default);
Vue.component('graph-with-buffer-component', require('./components/GraphWithBufferComponent.vue').default);
Vue.component('graph-no-buffer-component', require('./components/GraphNoBufferComponent.vue').default);
Vue.component('job-info-header', require('./components/JobInfoHeader.vue').default);
Vue.component('select-component', require('./components/SelectComponent.vue').default);
Vue.component('plug-gun-status-component', require('./components/PlugGunStatusComponent.vue').default);
Vue.component('shot-plug-status-row', require('./components/ShotPlugStatusRow.vue').default);
Vue.component('log-panel', require('./components/LogPanel.vue').default);
Vue.component('auth-fail-component', require('./components/AuthFailComponent.vue').default);
Vue.component('key-component', require('./components/KeyComponent.vue').default);
Vue.component('keyboard-component', require('./components/KeyboardComponent.vue').default);
Vue.component('login-component', require('./components/LoginComponent.vue').default);
Vue.component('well-setup-cloud-component', require('./components/WellSetupCloudComponent.vue').default);
Vue.component('kpi-display-item', require('./components/KPIDisplayItem.vue').default);
Vue.component('activity-breakdown-pie-charts', require('./components/ActivityBreakdownPieCharts.vue').default);
Vue.component('time-interval-select', require('./components/TimeIntervalSelect.vue').default);
Vue.component('user-profile-component', require('./components/UserProfileComponent.vue').default);
Vue.component('valve-component', require('./components/ValveComponent.vue').default);
Vue.component('api-token-enabler', require('./components/UserWidgets/ApiTokenEnabler.vue').default);
Vue.component('lock-button', require('./components/LockButton.vue').default);
Vue.component('wireline-diameter-component', require('./components/WirelineDiameterComponent').default);
Vue.component('wireline-controls-component', require('./components/WirelineControlsComponent').default);
Vue.component('process-log-page', require('./components/ProcessLogs/ProcessLogPage.vue').default)

// Comms
Vue.component('comms-widget', require('./components/Comms/CommsWidget.vue').default);

//Utilities
Vue.component('modified-input', require('./components/ModifiedInput.vue').default);
Vue.component('calendar', require('./components/CalendarComponent.vue').default);
Vue.component('dropdown', require('./components/DropdownComponent.vue').default);
Vue.component('custom-date-time', require('./components/CustomDateTime.vue').default);

//Lighting Chart
Vue.component('lightning-chart', require('./components/LightningChart.vue').default);
Vue.component('well-comparison-lightning-chart', require('./components/WellComparisonLightningChart').default);
Vue.component('heat-map-lightning-chart', require('./components/HeatMapsLightningChart').default);
Vue.component('stage-comparison-settings-modal', require('./components/StageComparisonSettingsModalComponent.vue').default);
Vue.component('analytic-options-modal', require('./components/Analytics/AnalyticOptionsModal.vue').default);
Vue.component('user-analysis-defaults-modal', require('./components/UserAnalysisDefaultsModal.vue').default);

// Library common components
Vue.component('iws-modal', require('./components/Common/iwsModal.vue').default);
Vue.component('iws-breadcrumbs', require('./components/Common/iwsBreadcrumbs.vue').default);
Vue.component('iws-accordian', require('./components/Common/iwsAccordian.vue').default);
Vue.component('iws-tabs', require('./components/Common/iwsTabs.vue').default);
Vue.component('iws-table', require('./components/Common/iwsTable.vue').default);
Vue.component('iws-confirm', require('./components/Common/iwsConfirm.vue').default);

// Library form components
Vue.component('iws-input', require('./components/Forms/iwsInput.vue').default);
Vue.component('iws-button', require('./components/Forms/iwsButton.vue').default);
Vue.component('iws-select', require('./components/Forms/iwsSelect.vue').default);
Vue.component('iws-checkbox', require('./components/Forms/iwsCheckbox.vue').default);
Vue.component('iws-search', require('./components/Forms/iwsSearch.vue').default);
Vue.component('iws-slider', require('./components/Forms/iwsSlider.vue').default);
Vue.component('iws-switch', require('./components/Forms/iwsSwitch.vue').default);
Vue.component('iws-colour-picker', require('./components/Forms/iwsColourPicker.vue').default);
Vue.component('iws-form', require('./components/Forms/iwsForm.vue').default);

Vue.component('base-table-view', require('./components/BaseTableViewComponent.vue').default);
Vue.component('accordion', require('./components/AccordionComponent.vue').default);

Vue.component('iws-alert', require('./components/Common/iwsAlert.vue').default);
Vue.component('iws-badge', require('./components/Common/iwsBadge.vue').default);

//Errors Modal
Vue.component('errors-modal', require('./components/ErrorsModal.vue').default);

// Generic
Vue.component('checkbox-list', require('./components/CheckboxList.vue').default);
Vue.component('checkbox-list-sectioned', require('./components/CheckboxListSectioned.vue').default);

//Chart Config Modals
Vue.component('chart-config-modal', require('./components/ChartConfigModal.vue').default);

//Support Modal
Vue.component('support-modal', require('./components/SupportModal.vue').default);

// Chat Widget
Vue.component('omnichannel-chat-widget', require('./components/OmnichannelChatWidget').default);

//Signal-R Disconnect Modal
Vue.component('signalr-error-modal', require('./components/SignalRErrorModal.vue').default);

// Custom Dashboard Components
Vue.component('dashboard-time-series-chart', require('./components/DashboardComponents/DashboardTimeSeriesChart.vue').default);
Vue.component('wellbore-diagram-component', require('./components/WellboreDiagramComponent.vue').default);

Vue.component('dashboard-management-component', require('./components/DashboardManagementComponent.vue').default);
Vue.component('dashboard-navbar', require('./components/DashboardNavbar.vue').default);

Vue.component('dashboard-comparison-chart', require('./components/DashboardComponents/DashboardComparisonChart.vue').default);
Vue.component('well-comparison-chart', require('./components/MultiWellTimeLineChart.vue').default);
Vue.component('stage-summary-heat-map', require('./components/StageSummaryHeatMap.vue').default);

Vue.component('contractor-library-component', require('./components/ContractorLibraryComponent.vue').default);
Vue.component('users-list-component', require('./components/UsersListComponent.vue').default);
Vue.component('chart-comments-timeline', require('./components/ChartComments/NewCommentTimeline.vue').default);
Vue.component('chart-comments-marker', require('./components/ChartComments/ChartCommentMarker.vue').default);
Vue.component('chart-comments-create', require('./components/ChartComments/ChartCommentCreate.vue').default);
Vue.component('chart-comments-oob-list', require('./components/ChartComments/ChartCommentOOBList.vue').default);
Vue.component('chart-scrubber', require('./components/ChartComments/ChartScrubber.vue').default);

Vue.component('wireline-activity', require('./components/ActivitySVGs/WirelineActivity.vue').default);
Vue.component('frac-activity', require('./components/ActivitySVGs/FracActivity.vue').default);
Vue.component('maintenance-activity', require('./components/ActivitySVGs/MaintenanceActivity.vue').default);
Vue.component('continuous-frac-to-activity', require('./components/ActivitySVGs/ContinuousFracToActivity.vue').default);

// Tooltip components
Vue.component('tooltip-component', require('./components/TooltipComponent.vue').default);
Vue.component('tooltips-list-component', require('./components/TooltipsListComponent.vue').default);

// Alert Banner Components
Vue.component('alert-banner-management', require('./components/AlertBannerManagement.vue').default);
Vue.component('alert-banner-component', require('./components/AlertBannerComponent.vue').default);

//Vue-Multi-Select-Template
Vue.component('multi-select', require('./components/MultiSelectComponent.vue').default);
Vue.component('multi-select-checkbox', require('./components/MultiSelectCheckboxComponent.vue').default);
//Alert Modal Components
Vue.component('critical-alert-modal-component', require('./components/CriticalAlertModalComponent.vue').default);
//Tag Summary Components
Vue.component('tag-summary-component',require('./components/TagSummary/TagSummaryComponent.vue').default);
Vue.component('tag-summary-table-slot',require('./components/TagSummary/TagSummaryTableSlot.vue').default);

Vue.component('remote-control-component',require('./components/RemoteControlComponent.vue').default);

// Tag Summary Table Config
Vue.component('tag-summary-config', require('./components/TagSummaryTableConfigModal.vue').default);

//Time Keeper Components
Vue.component('time-keeper-component',require('./components/TimeKeeper/TimeKeeperComponent.vue').default);

//B-Table Components
Vue.component('b-table-export-component', require('./components/BTableExportComponent.vue').default);

// Company Jobs Select component
Vue.component('select-job-component', require('./components/SelectJobComponent.vue').default);

//Stage Summary Component
Vue.component('stage-summary-component', require('./components/StageSummaryComponent.vue').default);

//Form Components
Vue.component('generic-form', require('./components/GenericForm.vue').default);
Vue.component('edit-user-form', require('./components/EditUserForm.vue').default);
Vue.component('checkbox-list', require('./components/CheckboxList.vue').default);

// Remote Data Components
Vue.component('sync-remote-job', require('./components/SyncRemoteJob.vue').default);
Vue.component('copy-remote-data', require('./components/CopyRemoteDataComponent.vue').default);

// Camera
Vue.component('camera-viewer-multi-component', require('./components/Cameras/CameraViewerMultiComponent.vue').default);
Vue.component('camera-single-viewer', require('./components/Cameras/CameraSingleViewer.vue').default);
Vue.component('camera-clips-viewer', require('./components/Cameras/CameraClipsViewer.vue').default);
Vue.component('settings-device-list', require('./components/Cameras/SettingsDeviceList.vue').default);
Vue.component('settings-device', require('./components/Cameras/SettingsDevice.vue').default);
Vue.component('settings-camera', require('./components/Cameras/SettingsCamera.vue').default);
Vue.component('video-scrubber', require('./components/Cameras/VideoScrubber.vue').default);
Vue.component('video-player', require('./components/Cameras/VideoPlayer.vue').default);
Vue.component('camera-button', require('./components/Cameras/CameraButton.vue').default);
Vue.component('camera-navigation', require('./components/Cameras/CameraNavigation.vue').default);

// comms
Vue.component('push-to-talk', require('./components/PushToTalk.vue').default);


// Icons Components
Vue.component('import-icon', require('./components/Icons/ImportIcon.vue').default);
Vue.component('export-icon', require('./components/Icons/ExportIcon.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import VueMqtt from 'vue-mqtt';
window.systemMode = process.env.MIX_APP_TYPE;
window.setCookie = function(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

window.getCookie = function(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

window.deleteCookie = function(cname) {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
};

const app = new Vue({
    el: '#app',
    created() {
        setDashboardType();
        if (process.env.MIX_APP_TYPE == 'local') {
            if (getCookie('darkMode') == '')
            {setCookie('darkMode', 'false', 14);}
            Vue.use(VueMqtt, 'mqtt://' + process.env.MIX_MQTT_BROKER + ':9001', { clientId: 'WebClient-' + parseInt(Math.random() * 100000) });

            this.local = true;
        } else {
            if (window.getCookie('darkMode') == '')
            {window.setCookie('darkMode', 'true', 14);}
        }
        if (window.getCookie('darkMode') == 'true')
        {this.darkMode = true;}
        else
        {this.darkMode = false;}
        updateDarkMode();

        Vue.config.productionTip = false;
    },
    data: {
        local: false,
        darkMode: '',
        version: process.env.MIX_VERSION_NUM
    }

});

function setDashboardType() {
    if (getCookie('activeCustomDashboard') == '') {
        const currentUnixTimeStamp = moment().unix();
        setCookie('activeCustomDashboard', 'false', currentUnixTimeStamp + (10 * 365 * 24 * 60 * 60)); //setting expire date 10 years from now
    }
}

function updateDarkMode() {
    let fileCss = '/css/bootstrapDark.min.css';
    let fileLogo = '/images/inVision_rebrand_logo_2021.png';
    if (getCookie('darkMode') != 'true') {
        fileCss = '/css/bootstrap.min.css';
        fileLogo = '/images/inVision-Logo-RGB_light_theme.png';
    }
    $('#imgLogo').attr('src', fileLogo);
    $('#bootstrapCss').attr('href', fileCss);
}

export default app;

