<!--
    * Component Description
        A searchable dropdown menu / select box
        See props for an explanation of all supported functions

    * Side Effects
        Will emit 2 signals
            update:value    <- syncs with the 'value' prop to comunicate upwards what the last selection made
            change          <- triggers upon selection of an option
            select-all      <- triggers upon click of the "select all" option for multi select selects (after changes take affect)

    * Slots
        #options="{ data, option, filteredOptions, selectOption, filterOption }"    <- target each option (with essential functions)

    * Example Usage
        Most basic use case where options are just strings
            <iws-select label="Select Option" :value.sync="selectedOption" :options="options" />

        Most basic use case where options is a list of objects
            <iws-select
                label="Select Option"
                :value.sync="selectedOptionId"
                :options="options"
                display-name="name"         // tells the component to search on and display the 'name' property
                value-name="id"             // tells the component to emit upwards the 'id' propert upon selection
            />

        Enables multi select of options
            <iws-select
                :value.sync="selectedOptions" <- Should be a list
                :options="options"
                display-name="name"
                value-name="id"

                :multiselect="true"         // Enables multi select
                :maintain-on-select="true"  // Will keep the options box open upon selection (by default it closes when no longer focused on input, is not needed but recommended when using multiselect)
            />

        In order to allow for more control on the appearance of the options the option slot can be used
            <iws-select
                :value.sync="selectedOptionId"
                :options="[ 'option_a', 'option_b' ]"
            >
                <template #option="{ data, selectOption }">
                    <option @click="selectOption(value, display)">
                        {{ data.index+1 }}. {{ data.option }}
                    </option>
                </template>
            </iws-select>
-->
<template>
<div class="custom-search-select" :class="{ 'required': required !== false, 'form-input-spacing': formSpacing !== false }" ref="customSearchSelect" :id="_genUuid()">
    <label v-if="label" class="primary-text-color">
        {{ label }}
    </label>

    <div style="position: relative" :class="{ 'full-placeholder': fullPlaceholder }">
        <iws-input
            :placeholder="placeholder"
            :value.sync="searchKey"

            @focus="$event.target.select(); showOptions()"
            @blur="hideOptions"
            @keyup="handleKey"

            :disabled="disabled"
            :searchable="searchable"
            ref="searchBarInput"
        />

        <span class="select-chevron clickable" @click="optionsAreVisible ? $refs.searchBarInput.blur() : $refs.searchBarInput.focus()">
            <i class="fas passthrough fa-chevron-down" :class="{ 'spin-icon': optionsAreVisible }"></i>
        </span>
    </div>

    <div
        class="container"
        :style="{
            'top': `${dimensions.top}px`,
            'left': `${dimensions.left}px`,
            'width': `${dimensions.width}px`
        }"
        v-show="optionsAreVisible"
        ref="optionsContainer"
    >
        <div class="options-container" :style="{ 'max-height': optionsHeight }" ref="resultScroll">
            <template v-if="_isNullOrEmpty(options)">
                <slot name="option"
                    :filteredOptions="options"
                    :selectOption="selectOption"
                    :filterOption="filterOption"
                >
                    <option>
                        No Options
                    </option>
                </slot>
            </template>
            <template v-else-if="_isNullOrEmpty(filteredOptions)">
                <slot name="option"
                    :filteredOptions="filteredOptions"
                    :selectOption="selectOption"
                    :filterOption="filterOption"
                >
                    <option>
                        No Results
                    </option>
                </slot>
            </template>
            <template v-else>
                <div v-if="optionsAreVisible && multiselect && filteredOptions.length > 1" class="option-container">
                    <slot name="select_all">
                        <option
                            class="multi-select"
                            :class="{ 'selected': allAreSelected() }"
                            @click="selectAll()"
                        >
                            {{ allAreSelected() ? 'Unselect' : 'Select' }} All
                        </option>

                        <input @click="selectAll()" type="checkbox" id="select_all" :checked="allAreSelected()">
                    </slot>
                </div>
                <div v-for="(option, index) in filteredOptions" :key="index" class="option-container">
                    <slot
                        name="option"
                        :data="getDataItem(option, index)"
                        v-bind:option="option"
                        :filteredOptions="filteredOptions"
                        :selectOption="selectOption"
                        :filterOption="filterOption"
                    >
                        <option
                            :class="{
                                'selected': isSelected(option),
                                'focus-class': focus === index,
                                'multi-select': multiselect
                            }"
                            :key="index+''+getValue(option)"
                            @click="selectOption(getValue(option), getDisplay(option))"
                        >
                            <slot name="option_text" :data="getDataItem(option, index)">
                                {{ getDisplay(option) }}
                            </slot>
                        </option>
                        
                        <!-- We support multi select when the provided value is of type array -->
                        <template v-if="optionsAreVisible && multiselect">
                            <input type="checkbox" :id="`checkbox_${getValue(option)}`" :checked="isSelected(option)"
                                    @click="selectOption(getValue(option), getDisplay(option))">
                        </template>
                    </slot>
                </div>
            </template>
        </div>
    <slot name="dropdown-footer" :maintainOpenOptions="maintainOpenOptions"></slot>
    </div>

    <label v-if="hint" class="secondary-text-color">
        {{ typeof hint == 'function' ? hint() : hint }}
    </label>
</div>
</template>
    
    
<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isNullOrEmpty, isFalsy } = GlobalFunctions;
import { v4 as uuidv4 } from "uuid";

import eventBus from '../../eventBus';

export default {
    props: {
        // Label for input, appears at the top-left
        label: {
            type: String | Number | Function
        },
        // Placeholder/hint that appears INSIDE input (only visible when value is null)
        placeholder: {
            type: String | Number | Function,
            default: 'Search'
        },
        // Placeholder/hint that appears BELOW input (is ALWAYS visible when provided)
        hint: {
            type: String | Number | Function
        },

        // Synced value of input field
        // Can be list of synced items when multiselect == true
        value: String | Number | Array,

        // List of options to disaply in dropdown
        options: {
            type: Array,
            required: true
        },

        // The key/property that should be displayed, used to identify the object (i.e. name)
        displayName: String | Number | Function,
        // The unique key/property that identifies in the system (i.e. id)
        valueName: String | Number | Function,

        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean | String,
            default: false
        },
        formSpacing: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean | String,
            default: true
        },
        // Enables the multi selecting of options (idealy used with maintainOnSelect to keep dropdown open after selecting but necessary)
        multiselect: {
            type: Boolean,
            default: false
        },
        // Clears the search key / filter upon selection of an option
        clearOnSelect: {
            type: Boolean,
            default: false
        },
        // Keeps the drowndown menu open after selection of an option
        maintainOnSelect: {
            type: Boolean,
            default: false
        },
        // Shows placeholder in primary text colour (not normal faded colour for placeholders)
        // Used to make the component look more like a regular dropdown
        fullPlaceholder: {
            type: Boolean,
            default: false
        },

        // Controls how many options are within view in the dropdown menu
        visibleOptions: {
            type: Number,
            default: 5
        },
        dropdownFooterHeight: {
            type: Number,
            required: false
        }
    },

    data() {
        return {
            focus: -1,
            searchKey: this.matchDisplay(),
            selectedLocal: this.matchDisplay(),
            optionsAreVisible: false,

            inErrorState: false,

            dimensions: {
                top: 0,
                left: 0,
                width: 0
            }
        };
    },

    computed: {
        filteredOptions() {
            if (!isNullOrEmpty(this.options))
                return this.options.filter(option => this.filterOption(this.getDisplay(option)));
            return [];
        },
        dropdownHeight() {
            return this.visibleOptions * 35;
        },
        optionsHeight() {
            if (this.dropdownFooterHeight)
                return `${this.dropdownHeight - this.dropdownFooterHeight}px`;
            return `${this.dropdownHeight}px`;
        }
    },

    methods: {		
        _isNullOrEmpty(val) {
            return isNullOrEmpty(val);
        },
        _genUuid() {
            return uuidv4();
        },
        showOptions() {
            this.optionsAreVisible = true;

            // Apply position on next tick once it has rendered the bounding boxes
            this.setDimensions();
            this.$nextTick(() => {
                this.setDimensions();
            });

            // We need to adjust positions when any scroll happens
            addEventListener("scroll", this.setDimensions, true);
            eventBus.$on('modal-scroll', this.setDimensions);

            addEventListener("resize", this.setDimensions);
        },
        hideOptions() {
            setTimeout(() => {
                // When maintainOnSelect is true, selectOption will set optionsAreVisible to maintain
                // In this scenario we keep the options open until the user clicks outside of the component (maintains active when selecting options)
                if (this.optionsAreVisible == 'maintain') {
                    this.optionsAreVisible = true;
                } else {
                    this.optionsAreVisible = false;

                    removeEventListener('scroll', this.setDimensions);
                    eventBus.$off('modal-scroll', this.setDimensions);

                    removeEventListener('resize', this.setDimensions);
                }
            }, 150)
        },
        filterOption(option) {
            if (isFalsy(option))
                return false;
            if (this.searchKey === this.selectedLocal)
                return true;
            return !this.searchKey || option?.toLowerCase()?.includes(this.searchKey?.toLowerCase());
        },

        isSelected(option) {
            const _value = this.getValue(option);
            return this.multiselect ? this.value?.includes(_value) : this.value == _value;
        },
        getValue(option) {
            if (this.valueName == 'index')
                return this.options.findIndex(fo => this.getDisplay(fo) == this.getDisplay(option));
            return typeof option == 'object' && this.valueName && `${this.valueName}` in option ? option[this.valueName] : option;
        },
        getDisplay(option) {
            if (typeof this.displayName == 'function')
                return this.displayName(option);
            return typeof option == 'object' && this.displayName && `${this.displayName}` in option ? option[this.displayName] : option;
        },
        getDataItem(option, index) {
            return {
                option,
                index
            };
        },
        selectOption(value, display) {
            if (!this.disabled) {
                this.$emit('update:value', value);
                this.$emit('change', value);

                if (this.clearOnSelect) {
                    this.searchKey = null;
                } else if (this.maintainOnSelect) {
                    this.$refs.searchBarInput.focus();
                    this.optionsAreVisible = 'maintain';
                } else {
                    this.searchKey = display;
                }

                this.selectedLocal = display;

                this.$nextTick(_ => {
                    if (this.$refs.searchBarInput)
                        this.validateInput();
                });
            }
        },
        maintainOpenOptions() {
            this.optionsAreVisible = 'maintain';
        },
        allAreSelected() {
            if (this.multiselect) {
                // Select all only applies to the currently visible options
                // If a user searches for a subset of the options, they can select all of those
                if (!isNullOrEmpty(this.filteredOptions))
                    return !this.filteredOptions.find(_option => !this.isSelected(_option));
                return false;
            }
        },
        selectAll() {
            // Select all only applies to the currently visible options
            // If a user searches for a subset of the options, they can select all of those
            if (this.multiselect && !isNullOrEmpty(this.filteredOptions)) {
                let options = this.filteredOptions;
                if (this.value.length > 0 && !this.allAreSelected()) {
                    options = this.filteredOptions.filter(op => !this.value.includes(this.getValue(op)));
                }
                options.forEach((option) => {
                    this.selectOption(this.getValue(option), this.getDisplay(option));
                });

                this.$emit('select-all', this.value)
            }
        },
        clearOption(emitEvent=true) {
            this.searchKey = null;
            this.selectedLocal = null;

            // Allow the parent to avoid emitting in a loop
            if (emitEvent) {
                this.$emit('update:value', null);
                this.$emit('change', null);
            }
        },

        handleKey($event) {
            if ($event.key == "ArrowUp") {
                // If the up arrow was entered check the lower bound
                if (this.focus >= 0)
                    this.focus--;
                // scroll with the key movement
                if (this.focus > 1)
                    this.$refs.resultScroll.scroll(0, (this.focus-2) * 40);
            } else if ($event.key == "ArrowDown") {
                // If the down arrow was entered check the higher bound
                if (this.focus < this.filteredOptions.length-1)
                    this.focus++;
                // scroll with the key movement
                if (this.focus > 2)
                    this.$refs.resultScroll.scroll(0, (this.focus-2) * 40);
            } else if ($event.key == "Enter") {
                let option;

                if (this.focus != -1) {
                    // If the enter key was entered check if we are focusing a result
                    option = this.filteredOptions[this.focus];
                } else if (this.filteredOptions.length == 1) {
                    // If there is no focus but only one result allow to easily select that
                    option = this.filteredOptions[0];
                }

                if (!isFalsy(option)) {
                    this.selectOption(this.getValue(option), this.getDisplay(option));
                    this.$refs.searchBarInput.blur();
                }
            }
        },

        matchDisplay() {
            if (this.multiselect === true)
                return;
                
            if (!isFalsy(this.value)) {
                let _matchedOption;
                if (typeof this.value === 'object' && this.valueName) {
                    _matchedOption = this.options.find(_option => this.getValue(_option) == this.value[this.valueName]);
                } else {
                    _matchedOption = this.options.find(_option => this.getValue(_option) == this.value);
                }

                if (!isFalsy(_matchedOption)) {
                    const _display = this.getDisplay(_matchedOption);

                    if (!isFalsy(_display)) {
                        this.searchKey = _display;
                        this.selectedLocal = _display;
                    }
                }
            } else {
                this.searchKey = null;
                this.selectedLocal = null;
            }

            return this.searchKey;
        },

        validateInput() {
            this.$refs.searchBarInput.inErrorState = this.required !== false && isFalsy(this.selectedLocal);
            return !this.$refs.searchBarInput.inErrorState;
        },

        setDimensions() {
            // Apply a fixed position to the options container so it can ignore the bounds of sub scroll containers or avoid overflowing the screen
            // Minor offsets have to do with the 1px border width and avoiding any overlap
            const clientRect = this.$refs.customSearchSelect.getBoundingClientRect();
            const requiredHeight = this.$refs.optionsContainer.getBoundingClientRect().height;
            const distanceToBottom = window.innerHeight-clientRect.bottom; 
            
            this.dimensions.left = clientRect.left;
            this.dimensions.width = clientRect.width;
            
            // If the expected height + some space exceeds the screen bounds, flip directions
            if (distanceToBottom < requiredHeight+10) {
                this.dimensions.top = clientRect.top-requiredHeight-2;
            } else {
                this.dimensions.top = clientRect.bottom+1;
            }
        }
    },

    watch: {
        value() {
            this.matchDisplay();
        },
        options() {
            this.matchDisplay();
        }
    }
};
</script>

<style>
    .custom-search-select input {
        padding-right: 38px !important;
    }

    .custom-search-select .full-placeholder input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--primary-text-color);
        opacity: 1; /* Firefox, set opacity to match other browsers*/
    }
    .custom-search-select .full-placeholder input::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--primary-text-color);
    }
</style>
<style scoped>
    .custom-search-select {
        position: relative;
    }

    label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }

    .container {
        top: 42px;
        left: 1px;
        
        width: 100%;
        padding: 0;
        position: fixed;
        border-radius: 4px;
        max-width: calc(100% - 2px);
        color: var(--form-color) !important;
        border: 1px solid var(--form-color);
        background-color: #343A40 !important;
    }
    .container, .container option {
        z-index: 9999;
    }

    .options-container {
        width: calc(100% - 2px);
        overflow-y: auto;
    }

    .option-container {
        position: relative;
        padding: 0px !important;
    }
    .options-container>div>option {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: var(--primary-text-color);

        overflow: hidden; 
        white-space: nowrap; 
        text-overflow: ellipsis;
        
        position: relative;
        z-index: 9999;
        cursor: pointer;
        background: #343A40;

        border-top: 1px solid #7B8A98;

        padding: 5px 5px;
    }
    .options-container>div>option:first {
        border-top: none !important;
    }
    .options-container>div>option:hover:not(.focus-class),
    .options-container>div>option.selected:not(.focus-class) {
        background: #242A30 !important;
    }
    .options-container>div>option.focus-class {
        background: #1f2227 !important;
    }
    .multi-select {
        padding-left: 25px !important;
    }

    .passthrough {
        pointer-events: none;
    }
    .options-container input[type='checkbox'] {
        position: absolute;
        top: 8px;
        left: 6px;

        height: 20px;
        z-index: 9999;

        background: #242A30 !important;

        border: 1.5px solid #D0D5DD !important;
        border-radius: 6px !important;
    }
    .options-container input[type='checkbox']:checked {
        background-color: var(--primary-color) !important;
        color: var(--primary-text-color) !important;
    }

    hr {
        background: #7B8A98;

        margin: 0px 0px !important;
        padding: 0px !important;
    }

    .select-chevron {
        display: block;
        position: absolute;
        top: 0px;
        right: 0px;

        font-size: 16px;

        width: 38px;
        height: 100%;

        z-index: 99;
    }
    .fas.passthrough.fa-chevron-down {
        transition: all 0.5s;

        position: absolute;
        top: calc(50% - 7px);
        right: calc(50% - 7px);
    }
    .spin-icon {
        rotate: 180deg;
    }
</style>