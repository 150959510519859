<template>
    <div>
        <div>
            <div class="d-flex flex-column align-items-center">
                <div v-if="requestedByUser" class="font-large">Change password</div>
                <div v-if="!requestedByUser && !passwordResetEmail" class="font-large mt-4 mb-3">Please Change your password to meet the new requirements</div>
                <div v-if="passwordResetEmail" class="font-large mt-4 mb-3">Reset Password</div>
                <div v-if="isSuccess" class="text-success">
                    Password successfully changed
                </div>
                <div v-if="isSuccess && !requestedByUser && !passwordResetEmail" class="text-success">
                    Redirecting to home page
                </div>
                <div v-if="errorOcurred" class="text-danger">
                    Failed to update password
                </div>
            </div>
        </div>
        <div>
            <div class="form-group px-5">
                <div v-if="requestedByUser || passwordResetEmail" class="row">
                    <label class="form-label col-3">Email</label>
                    <div class="input-group has-validation col-9">
                        <input type="text" style="width:100%" class="form-control" aria-describedby="inputGroupPrepend" :value="userData.email" readonly>
                    </div>
                </div>
                <div v-if="!passwordResetEmail" class="row pt-4 align-items-end">
                    <label class="form-label col-3">Current password</label>
                    <div class="input-group has-validation col-9">
                        <input v-model="currentPassword" type="password" style="width:100%" :class="'form-control ' + errors.currentPassword? 'is-invalid' : ''" aria-describedby="inputGroupPrepend" required>
                        <div class="invalid-feedback">
                            {{ errors.currentPassword? errors.currentPassword[0] : ''  }}
                        </div>
                    </div>
                </div>
                <div class="row pt-4">
                    <label class="form-label col-3">Password</label>
                    <div class="input-group has-validation col-9">
                        <input v-model="newPassword" type="password" style="width:100%" :class="'form-control ' + + errors.password? 'is-invalid' : ''" aria-describedby="inputGroupPrepend" required>
                        <div class="invalid-feedback">
                            {{ errors.password? errors.password[0] : '' }}
                        </div>
                    </div>
                </div>
                <div class="row pt-4">
                    <label class="form-label col-3">Confirm password</label>
                    <div class="input-group has-validation col-9">
                        <input v-model="confirmNewPassword" style="width:100%" type="password" :class="'form-control ' + + errors.confirmPassword? 'is-invalid' : ''" aria-describedby="inputGroupPrepend" required >
                        <div class="invalid-feedback">
                            {{ errors.confirmPassword? errors.confirmPassword[0] : ''}}
                        </div>
                    </div>
                </div>
                <div v-if="passwordStrengthRequired" class="row">
                    <div class="col-3"></div>
                    <div class="col-9">
                        <password-strength-component
                            :password="newPassword"
                            :firstName="userData.first_name"
                            :lastName="userData.last_name"
                            :email="userData.email?? email"
                            :currentPassword="currentPassword?? ''"
                            :passwordResetEmail="passwordResetEmail"
                            @requirementsMet="(value) => passRequirementsMet = value"
                        >
                        </password-strength-component>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div v-if="!passwordResetEmail" class="d-flex w-100 flex-row-reverse">
                <iws-button type="success" :disabled="passwordStrengthRequired && !passRequirementsMet" text="Save" @click.prevent="onSavePasswordClicked"></iws-button>
            </div>
            <div v-else class="d-flex w-100 flex-row-reverse">
                <iws-button type="success" :disabled="passwordStrengthRequired && !passRequirementsMet" text="Reset Password" @click.prevent="onSavePasswordClicked"></iws-button>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions';
export default {
    props: {
        user:{
            type: Object,
            required: false
        },
        requestedByUser: {
            type: Boolean,
            required: false
        },
        passwordStrengthRequired: {
            type: [Number],
            required: true
        },
        email: {
            type: String,
            required: false
        },
        token: {
            type: String,
            required: false
        },
        passwordResetEmail: {
            type: [Number],
            required: false
        }
    },
    data() {
        return {
            errors: {},
            data: null,
            currentPassword: null,
            newPassword: null,
            confirmNewPassword: null,
            isSuccess: false,
            errorOcurred: false,
            passRequirementsMet: false,
            userData: {}
        };
    },
    created() {
        if (this.user) {
            this.userData = this.user
        }
        else {
            this.userData = {
                email: this.email,
                first_name: '',
                last_name: ''
            }
        }
    },
    methods: {
        onSavePasswordClicked() {
            this.errors = {};
            let url = this.requestedByUser ? '/userProfile/change-password' : '/update-password/change-password';
            if (this.passwordResetEmail) url = '/password/reset';
            $.ajax({
                url: url,
                method: this.passwordResetEmail ? 'POST' : 'PUT',
                data: {
                    _token: GlobalFunctions.getCSRFToken(),
                    token: this.token,
                    currentPassword: this.currentPassword,
                    password: this.newPassword,
                    confirmPassword: this.confirmNewPassword,
                    first_name: this.userData.first_name,
                    last_name: this.userData.last_name,
                    email: this.userData.email
                },
                dataType: 'json'
            }).done((data) => {
                this.isSuccess = true;
                setTimeout(()=>{
                    this.currentPassword = null;
                    this.newPassword = null;
                    this.confirmNewPassword = null;
                    this.isSuccess = false;
                    if (this.requestedByUser) this.$root.$emit('bv::hide::modal', 'password-change-modal')
                }, 2000);
                if (!this.requestedByUser && !this.passwordResetEmail) window.location.href = "/home";
            }).fail((msg) => {
                console.log(msg)
                if(msg.responseJSON.errors) {
                    this.errors = {
                        ...msg.responseJSON.errors
                    };
                } else if (msg.responseJSON.message) {
                    this.errorOcurred = true;
                    setTimeout(() => {
                        this.errorOcurred = false;
                    }, 3000);
                }
            });
        }
    }
}
</script>