var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `alert alert-${_vm.type} ${!_vm.icon ? "hide-icon" : ""}`,
      attrs: { role: "alert" },
    },
    [
      !!_vm.icon
        ? _c(
            "div",
            [
              _vm._t("icon", function () {
                return [_c("i", { class: _vm.icon })]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", [_vm._t("body")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }