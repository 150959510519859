var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.chartConfiguration != null
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-column",
          on: {
            mousemove: function ($event) {
              return _vm.setIsActiveChart(true)
            },
          },
        },
        [
          _c(
            "b-popover",
            {
              ref: "truckPopover" + _vm._uid,
              attrs: {
                if: _vm.isMultiWireline && !_vm.isNotDashboard,
                show: _vm.showTruck,
                target:
                  "popover-target-truck-" +
                  _vm.dashboardItem.options.configId +
                  _vm._uid,
                triggers: !_vm.showSuperSmallHeader() ? "hover" : "click",
                placement: "bottom",
                delay: { show: 0, hide: 500 },
              },
              on: {
                "update:show": function ($event) {
                  _vm.showTruck = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row mt-2 align-items-center justify-content-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "align-self-start mx-2 algin-self-center pt-2",
                      staticStyle: { color: "white" },
                    },
                    [_vm._v("\n                Truck:\n            ")]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedTruck,
                            expression: "selectedTruck",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedTruck = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.onSelectedTruckChange(
                                $event.target.value
                              )
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.truckOptions, function (truck, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: truck.value } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(truck.text) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("TimeSeriesConfigModal", {
            ref: "TimeSeriesConfigModal",
            attrs: {
              userId: _vm.userID,
              iwsUser: !!_vm.iwsUser,
              isAdmin: !!_vm.isAdmin,
              isCompanyAdmin: !!_vm.isCompanyAdmin,
              "job-number": _vm.jobNumber,
              jobHourOffset: _vm.jobHourOffset,
              chartType: "frac",
              wells: _vm.wells,
              dashboardData: _vm.dashboardData,
              dashboardItem: _vm.dashboardItem,
              defaultChartColumnCount: _vm.defaultChartColumnCount,
            },
          }),
          _vm._v(" "),
          _c("TimeSeriesHistoryModal", {
            ref: "TimeSeriesHistoryModal",
            attrs: {
              "job-number": _vm.jobNumber,
              dashboardItem: _vm.dashboardItem,
            },
          }),
          _vm._v(" "),
          _c(
            "b-popover",
            {
              attrs: {
                target: "popover-header-" + _vm._uid,
                triggers: "hover",
                placement: "bottom",
                delay: { show: 0, hide: 500 },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "align-items-center justify-content-center",
                  staticStyle: { color: "white" },
                },
                [
                  _vm.isEditable
                    ? _c(
                        "div",
                        {
                          staticClass: "clickable row align-items-center",
                          on: { click: _vm.onEditChartConfiguration },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-cog col-1 header-icon",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col submenu-link px-2",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v("Edit chart configuration")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "clickable row align-items-center",
                      on: { click: _vm.onChartHeaderPressed },
                    },
                    [
                      _vm.showChartHeaderConfig
                        ? _c("i", {
                            staticClass: "fas fa-eye col-1 header-icon",
                          })
                        : _c("i", {
                            staticClass: "fas fa-eye-slash col-1 header-icon",
                          }),
                      _vm._v(" "),
                      _vm.showChartHeaderConfig
                        ? _c("div", { staticClass: "col submenu-link px-2" }, [
                            _vm._v("Show chart header"),
                          ])
                        : _c("div", { staticClass: "col submenu-link px-2" }, [
                            _vm._v("Hide chart header"),
                          ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.shouldDrawGraph
                    ? _c(
                        "div",
                        {
                          staticClass: "clickable row align-items-center",
                          on: { click: _vm.onAlertIconPressed },
                        },
                        [
                          _vm.enableThresholdAlerts
                            ? _c("i", {
                                staticClass: "fas fa-bell col-1 header-icon",
                              })
                            : _c("i", {
                                staticClass:
                                  "fas fa-bell-slash col-1 header-icon",
                              }),
                          _vm._v(" "),
                          _vm.enableThresholdAlerts
                            ? _c(
                                "div",
                                { staticClass: "col submenu-link px-2" },
                                [_vm._v("Show alerts in comment line")]
                              )
                            : _c(
                                "div",
                                { staticClass: "col submenu-link px-2" },
                                [_vm._v("Hide alerts in comment line")]
                              ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.useCustomScaleOnDateAxis && _vm.shouldDrawGraph
                    ? _c(
                        "div",
                        {
                          staticClass: "clickable row align-items-center",
                          on: { click: _vm.onExportIconPress },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-download col-1 header-icon",
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "col submenu-link px-2" }, [
                            _vm._v("Export Chart Data"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shouldDrawGraph && !_vm.chartConfigEditMode
                    ? _c(
                        "div",
                        {
                          staticClass: "clickable row align-items-center",
                          attrs: {
                            id:
                              "popover-filter-" +
                              _vm.dashboardItem.options.configId +
                              _vm._uid,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleInnerPopover(
                                "filterPopover" + _vm._uid
                              )
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-filter col-1 header-icon",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col submenu-link px-2",
                              class: {
                                "font-weight-bold":
                                  _vm.innerPopoversOpened.includes(
                                    "filterPopover" + _vm._uid
                                  ),
                              },
                            },
                            [_vm._v("Filtering")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showSuperSmallHeader() &&
                  _vm.shouldDrawGraph &&
                  ((_vm.isMultiWireline && _vm.headerStyle == "wireline") ||
                    (_vm.isMultiFrac && _vm.headerStyle == "frac")) &&
                  !_vm.isNotDashboard
                    ? _c(
                        "div",
                        {
                          staticClass: "clickable row align-items-center",
                          attrs: {
                            id:
                              "popover-target-truck-" +
                              _vm.dashboardItem.options.configId +
                              _vm._uid,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleInnerPopover(
                                "truckPopover" + _vm._uid
                              )
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-truck col-1 header-icon",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col submenu-link px-2",
                              class: {
                                "font-weight-bold":
                                  _vm.innerPopoversOpened.includes(
                                    "truckPopover" + _vm._uid
                                  ),
                              },
                            },
                            [_vm._v("Change Truck")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showSuperSmallHeader() &&
                  !_vm.useCustomScaleOnDateAxis &&
                  _vm.shouldDrawGraph &&
                  !_vm.chartConfigEditMode
                    ? _c(
                        "div",
                        {
                          staticClass: "clickable row align-items-center",
                          attrs: {
                            id:
                              "popover-target-" +
                              _vm.dashboardItem.options.configId +
                              _vm._uid,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleInnerPopover(
                                "viewSettingsPopover" + _vm._uid
                              )
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-ruler-combined col-1 header-icon",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col submenu-link px-2",
                              class: {
                                "font-weight-bold":
                                  _vm.innerPopoversOpened.includes(
                                    "viewSettingsPopover" + _vm._uid
                                  ),
                              },
                            },
                            [_vm._v("View settings")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.useCustomScaleOnDateAxis
                    ? _c(
                        "div",
                        {
                          staticClass: "clickable row align-items-center",
                          on: {
                            click: function ($event) {
                              _vm.inStatsMode = !_vm.inStatsMode
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-chart-bar col-1 header-icon",
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "col submenu-link px-2" }, [
                            _vm._v(
                              "Turn " +
                                _vm._s(_vm.inStatsMode ? "off" : "on") +
                                " statistics mode"
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-popover",
            {
              ref: "viewSettingsPopover" + _vm._uid,
              attrs: {
                target:
                  "popover-target-" +
                  _vm.dashboardItem.options.configId +
                  _vm._uid,
                triggers: !_vm.showSuperSmallHeader() ? "hover" : "click",
                placement: "bottom",
                delay: { show: 0, hide: 500 },
              },
              on: {
                shown: function ($event) {
                  return _vm.isZoomPopoverOpen($event)
                },
                hidden: function ($event) {
                  return _vm.isZoomPopoverOpen($event)
                },
              },
            },
            [
              !_vm.useCustomScaleOnDateAxis
                ? _c("div", { staticClass: "d-flex flex-row-reverse" }, [
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm mx-1",
                          class: _vm.dragToZoom ? "btn-primary" : "btn-light",
                          on: {
                            click: function ($event) {
                              return _vm.selectionZoom(true)
                            },
                          },
                        },
                        [_vm._v("Drag to Zoom")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm mx-1",
                          class: !_vm.dragToZoom ? "btn-primary" : "btn-light",
                          on: {
                            click: function ($event) {
                              return _vm.selectionZoom(false)
                            },
                          },
                        },
                        [_vm._v("Drag to Pan, Scroll to Zoom")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light btn-sm mx-1",
                          on: {
                            click: function ($event) {
                              return _vm.resetChartView()
                            },
                          },
                        },
                        [_vm._v("Reset View")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.defaultZoomWindowHrs,
                          expression: "defaultZoomWindowHrs",
                        },
                      ],
                      staticClass: "form-control form-control-sm",
                      staticStyle: { width: "4em", "text-align": "right" },
                      attrs: { type: "number" },
                      domProps: { value: _vm.defaultZoomWindowHrs },
                      on: {
                        change: function ($event) {
                          return _vm.resetZoom(
                            _vm.$refs["lineChart"].$data._chart
                          )
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.defaultZoomWindowHrs = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mx-2 align-self-start small white-text" },
                      [_vm._v("Default Window (hours):")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.useCustomScaleOnDateAxis
                ? _c(
                    "div",
                    { staticClass: "d-flex flex-row-reverse my-2 full-width" },
                    [
                      _vm.dragToZoom
                        ? [
                            _c(
                              "div",
                              {
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: _vm.syncedChartsTooltip,
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm mx-1",
                                    class:
                                      _vm.zoomMode === "xy"
                                        ? "btn-primary"
                                        : "btn-light",
                                    attrs: { disabled: _vm.areChartsSynced },
                                    on: {
                                      click: function ($event) {
                                        return _vm.zoomLockRange("xy")
                                      },
                                    },
                                  },
                                  [_vm._v("XY")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: !_vm.chartConfiguration.isVertical
                                    ? _vm.syncedChartsTooltip
                                    : null,
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm mx-1",
                                    class:
                                      _vm.zoomMode === "y"
                                        ? "btn-primary"
                                        : "btn-light",
                                    attrs: {
                                      disabled:
                                        _vm.areChartsSynced &&
                                        !_vm.chartConfiguration.isVertical,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.zoomLockRange("y")
                                      },
                                    },
                                  },
                                  [_vm._v("Y")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: _vm.chartConfiguration.isVertical
                                    ? _vm.syncedChartsTooltip
                                    : null,
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm mx-1",
                                    class:
                                      _vm.zoomMode === "x"
                                        ? "btn-primary"
                                        : "btn-light",
                                    attrs: {
                                      disabled:
                                        _vm.areChartsSynced &&
                                        _vm.chartConfiguration.isVertical,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.zoomLockRange("x")
                                      },
                                    },
                                  },
                                  [_vm._v("X")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mx-2 align-self-start small white-text",
                              },
                              [_vm._v("Enable zoom on axis:")]
                            ),
                          ]
                        : [
                            _c("div", {
                              staticStyle: { "padding-right": "112px" },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: _vm.syncedChartsTooltip,
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm mx-1",
                                    class:
                                      _vm.panMode === "xy"
                                        ? "btn-primary"
                                        : "btn-light",
                                    attrs: { disabled: _vm.areChartsSynced },
                                    on: {
                                      click: function ($event) {
                                        return _vm.panLockRange("xy")
                                      },
                                    },
                                  },
                                  [_vm._v("XY")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: !_vm.chartConfiguration.isVertical
                                    ? _vm.syncedChartsTooltip
                                    : null,
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm mx-1",
                                    class:
                                      _vm.panMode === "y"
                                        ? "btn-primary"
                                        : "btn-light",
                                    attrs: {
                                      disabled:
                                        _vm.areChartsSynced &&
                                        !_vm.chartConfiguration.isVertical,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.panLockRange("y")
                                      },
                                    },
                                  },
                                  [_vm._v("Y")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: _vm.chartConfiguration.isVertical
                                    ? _vm.syncedChartsTooltip
                                    : null,
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm mx-1",
                                    class:
                                      _vm.panMode === "x"
                                        ? "btn-primary"
                                        : "btn-light",
                                    attrs: {
                                      disabled:
                                        _vm.areChartsSynced &&
                                        _vm.chartConfiguration.isVertical,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.panLockRange("x")
                                      },
                                    },
                                  },
                                  [_vm._v("X")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mx-2 align-self-start small white-text",
                              },
                              [_vm._v("Enable pan on axis:")]
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-popover",
            {
              ref: "filterPopover" + _vm._uid,
              attrs: {
                target:
                  "popover-filter-" +
                  _vm.dashboardItem.options.configId +
                  _vm._uid,
                triggers: "click",
                placement: "bottom",
                boundary: "viewport",
              },
              on: {
                shown: function ($event) {
                  return _vm.isFilteringPopoverOpen($event)
                },
                hidden: function ($event) {
                  return _vm.isFilteringPopoverOpen($event)
                },
              },
            },
            [
              _c("div", { staticClass: "d-flex flex-row mt-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "align-self-start mx-2",
                    staticStyle: { color: "white" },
                  },
                  [_vm._v("Activity:")]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.selectedWellStageIntervalsActivityTypeOption,
                          expression:
                            "selectedWellStageIntervalsActivityTypeOption",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedWellStageIntervalsActivityTypeOption =
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.filterByActivity($event.target.value)
                          },
                        ],
                      },
                    },
                    _vm._l(
                      _vm.wellStageIntervalsActivityTypeOptions,
                      function (activity) {
                        return _c(
                          "option",
                          { key: activity, domProps: { value: activity } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(activity) +
                                "\n                    "
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "align-self-start mx-2",
                    staticStyle: { color: "white" },
                  },
                  [_vm._v("Well:")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "white",
                      border: "6px solid white",
                      "border-radius": "4px",
                      "min-height": "38px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onWellSelectClicked()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "dropdown" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mr-2 ml-2",
                              staticStyle: { "font-size": "16px" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.selectedWellFilterDisplayString)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.selectedWellFilterDisplayColor != null
                            ? _c("div", {
                                staticClass: "mr-2 align-self-center",
                                style: {
                                  width: "25px",
                                  height: "25px",
                                  border: "solid #000000",
                                  marginLeft: "auto",
                                  marginRight: "0px",
                                  backgroundColor:
                                    _vm.selectedWellFilterDisplayColor,
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          ref: "well_dropdown_test",
                          staticClass: "filter-dropdown-content",
                          staticStyle: { "font-size": "16px" },
                        },
                        _vm._l(_vm.wells, function (well, index) {
                          return _c(
                            "a",
                            {
                              key: index,
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: { target: well.index },
                              on: {
                                click: function ($event) {
                                  return _vm.filterByWell($event)
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "mr-2",
                                  staticStyle: {
                                    display: "inline-block",
                                    overflow: "hidden",
                                    "white-space": "nowrap",
                                  },
                                  attrs: { target: well.index },
                                },
                                [_vm._v(_vm._s(well.name))]
                              ),
                              _vm._v(" "),
                              _c("a", {
                                style: {
                                  width: "25px",
                                  height: "25px",
                                  border: "solid #000000",
                                  display: "inline-block",
                                  marginLeft: "auto",
                                  marginRight: "0px",
                                  backgroundColor: well.color,
                                },
                                attrs: { target: well.index },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-row mt-3 mb-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "align-self-start ml-2",
                    staticStyle: { color: "white", "margin-right": "18px" },
                  },
                  [_vm._v("Stage:")]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedWellStageInterval,
                          expression: "selectedWellStageInterval",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedWellStageInterval = $event.target
                              .multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.filterByStage($event)
                          },
                        ],
                      },
                    },
                    [
                      _vm.selectedWellStageIntervalGroup == null
                        ? _c("option", { attrs: { value: "-1" } }, [
                            _vm._v("-- No Stage Set --"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(
                        _vm.selectedWellStageIntervalGroup,
                        function (interval, key, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              attrs: {
                                disabled: _vm.disableFilterOption(
                                  interval,
                                  index
                                ),
                              },
                              domProps: { value: interval },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    "Stage #" +
                                      key +
                                      " : " +
                                      (interval.startTime != null
                                        ? interval.startTime
                                        : "Unavailable") +
                                      " - " +
                                      (interval.endTime != null
                                        ? interval.endTime
                                        : _vm.disableFilterOption(
                                            interval,
                                            index
                                          )
                                        ? "Unavailable"
                                        : "Currently Ongoing")
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-between mt-3 mb-2" },
                [
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary grey-button",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$root.$emit("bv::hide::popover")
                          },
                        },
                      },
                      [_vm._v("\n                    Close\n                ")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-row-reverse" }, [
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-success green-button float-right ml-2",
                          attrs: {
                            type: "button",
                            disabled: _vm.isDownloadingData,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onSetWellStageFilter($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Set Filter\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-secondary grey-button float-right ml-2",
                          attrs: {
                            type: "button",
                            disabled: _vm.isDownloadingData,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onClearWellStageFilter($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Clear Filter\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-popover",
            {
              attrs: {
                "custom-class": _vm.isElementTransparent,
                target:
                  "popover-ghost-" +
                  _vm.dashboardItem.options.configId +
                  _vm._uid,
                triggers: "click blur",
                placement: "bottom",
                delay: { show: 0, hide: 0 },
                boundary: "viewport",
              },
              on: {
                shown: function ($event) {
                  return _vm.isGhostPopoverOpen($event)
                },
                hidden: function ($event) {
                  return _vm.isGhostPopoverOpen($event)
                },
              },
            },
            [
              _c("div", { staticClass: "d-flex flex-row mt-2" }, [
                _c("div", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ghostLineWell,
                          expression: "ghostLineWell",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.ghostLineWell = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.updateGhostPlotLineWell($event)
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: { value: "null", selected: "", disabled: "" },
                        },
                        [_vm._v("Ghost Well Select")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.wells, function (well) {
                        return _c(
                          "option",
                          { key: well.index, domProps: { value: well } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(well.name) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-row mt-2" }, [
                _c("div", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ghostLineStage,
                          expression: "ghostLineStage",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.ghostLineStage = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.updateGhostPlotLineStage($event)
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: { value: "null", selected: "", disabled: "" },
                        },
                        [_vm._v("Ghost Stage Select")]
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.selectedGhostWellStageIntervalGroupSanitized,
                        function (interval, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: interval } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    "Stage #" +
                                      index +
                                      " : " +
                                      interval.startTime +
                                      " - " +
                                      interval.endTime
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-row mt-2" }, [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("checkbox-list", {
                      attrs: {
                        label: "Ghost Channel(s) Select",
                        maxHeight: 250,
                        options: _vm.usedTagsDisplay,
                        valueKey: "priorityName",
                        outputKey: "name",
                        selectedOptions: _vm.ghostLineChannels,
                      },
                      on: {
                        "update:selectedOptions": function ($event) {
                          return _vm.updateGhostPlotChannels($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.ghostLineStage && _vm.ghostLineStage.startTime
                ? _c("div", { staticClass: "d-flex flex-row mt-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "align-self-center mr-2",
                        staticStyle: { color: "white" },
                      },
                      [_vm._v("Index Ghost Plot To:")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c("div", [
                        _c("i", {
                          staticClass: "fas fa-arrow-circle-left clickable",
                          staticStyle: {
                            "background-color": "white",
                            border: "2px solid white",
                            "border-radius": "5px",
                          },
                          on: {
                            mousedown: function ($event) {
                              return _vm.stepGhostPlotIndexTime("dec", true)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.getGhostPlotIndexStart,
                              expression: "getGhostPlotIndexStart",
                            },
                          ],
                          staticClass: "mx-1",
                          attrs: { type: "datetime-local" },
                          domProps: { value: _vm.getGhostPlotIndexStart },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.getGhostPlotIndexStart = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "fas fa-arrow-circle-right clickable",
                          staticStyle: {
                            "background-color": "white",
                            border: "2px solid white",
                            "border-radius": "5px",
                          },
                          on: {
                            mousedown: function ($event) {
                              return _vm.stepGhostPlotIndexTime("inc", true)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-row mt-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "align-self-start mr-2",
                    staticStyle: { color: "white" },
                  },
                  [_vm._v("Line Size :")]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ghostLineSize,
                          expression: "ghostLineSize",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.ghostLineSize = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            _vm.ghostPlotEnabled = false
                          },
                        ],
                      },
                    },
                    _vm._l([1, 2, 4, 8, 16], function (lineSize) {
                      return _c(
                        "option",
                        { key: lineSize, domProps: { value: lineSize } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(lineSize) +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("div", { staticStyle: { color: "white" } }, [
                      _vm._v("Opacity :"),
                    ]),
                    _vm._v(" "),
                    _c("vue-slider", {
                      staticClass: "mt-5",
                      attrs: {
                        options: _vm.ghostSliderOptions,
                        order: false,
                        tooltip: "always",
                      },
                      on: {
                        change: function ($event) {
                          _vm.ghostPlotEnabled = false
                        },
                      },
                      model: {
                        value: _vm.ghostLineOpacity,
                        callback: function ($$v) {
                          _vm.ghostLineOpacity = $$v
                        },
                        expression: "ghostLineOpacity",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-row-reverse mt-3 mb-2" }, [
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-success green-button float-right ml-2",
                      attrs: {
                        type: "button",
                        disabled:
                          _vm.isDownloadingData ||
                          _vm.ghostPlotSettingsIncomplete,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onSaveGhostPlot($event)
                        },
                      },
                    },
                    [_vm._v("\n                    Save\n                ")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-secondary grey-button float-right ml-2",
                      attrs: {
                        type: "button",
                        disabled: _vm.isDownloadingData,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onClearGhostPlot($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Reset Selections\n                "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.usedSections, function (chartItems, index) {
            return _c(
              "div",
              {
                key:
                  _vm.sectionIndexes[index].index +
                  "-" +
                  _vm.dashboardItem.options.configId,
              },
              _vm._l(chartItems, function (fracItem, ind) {
                return _c(
                  "div",
                  {
                    key:
                      fracItem.name + "-" + _vm.dashboardItem.options.configId,
                    style: _vm.chartItemColor(fracItem.name),
                  },
                  [
                    _vm.shouldDrawGraph && _vm.chartConfigEditMode
                      ? _c(
                          "b-popover",
                          {
                            attrs: {
                              target:
                                "popover-target-" +
                                _vm._uid +
                                "-" +
                                index +
                                "-" +
                                ind,
                              triggers: "click blur",
                              placement: "top",
                              boundary: "window",
                            },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "mx-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-row",
                                    staticStyle: { color: "white" },
                                  },
                                  [_vm._v("Label Font Size:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-row mt-2" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.getLabelTextOptions(
                                              index,
                                              ind
                                            ).fontSize,
                                            expression:
                                              "getLabelTextOptions(index, ind).fontSize",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.getLabelTextOptions(
                                                  index,
                                                  ind
                                                ),
                                                "fontSize",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              _vm.newTextSizeSelected = true
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { attrs: { value: "auto" } },
                                          [
                                            _vm._v(
                                              "\n                                    Auto\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "0.5em" } },
                                          [
                                            _vm._v(
                                              "\n                                    Small\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "0.7em" } },
                                          [
                                            _vm._v(
                                              "\n                                    Medium\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "1.0em" } },
                                          [
                                            _vm._v(
                                              "\n                                    Large\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "1.2em" } },
                                          [
                                            _vm._v(
                                              "\n                                    X-Large\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "1.4em" } },
                                          [
                                            _vm._v(
                                              "\n                                    XX-Large\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-row mt-2",
                                    staticStyle: { color: "white" },
                                  },
                                  [_vm._v("Label Font Style:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-row mt-2",
                                    staticStyle: { color: "white" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "align-self-start",
                                        staticStyle: { "margin-right": "7px" },
                                      },
                                      [
                                        _c("i", { staticClass: "fas fa-bold" }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.getLabelTextOptions(
                                                index,
                                                ind
                                              ).bold,
                                              expression:
                                                "getLabelTextOptions(index, ind).bold",
                                            },
                                          ],
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.getLabelTextOptions(
                                                index,
                                                ind
                                              ).bold
                                            )
                                              ? _vm._i(
                                                  _vm.getLabelTextOptions(
                                                    index,
                                                    ind
                                                  ).bold,
                                                  null
                                                ) > -1
                                              : _vm.getLabelTextOptions(
                                                  index,
                                                  ind
                                                ).bold,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.getLabelTextOptions(
                                                  index,
                                                  ind
                                                ).bold,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.getLabelTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "bold",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.getLabelTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "bold",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.getLabelTextOptions(
                                                    index,
                                                    ind
                                                  ),
                                                  "bold",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "align-self-start",
                                        staticStyle: { "margin-right": "7px" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-italic",
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.getLabelTextOptions(
                                                index,
                                                ind
                                              ).italic,
                                              expression:
                                                "getLabelTextOptions(index, ind).italic",
                                            },
                                          ],
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.getLabelTextOptions(
                                                index,
                                                ind
                                              ).italic
                                            )
                                              ? _vm._i(
                                                  _vm.getLabelTextOptions(
                                                    index,
                                                    ind
                                                  ).italic,
                                                  null
                                                ) > -1
                                              : _vm.getLabelTextOptions(
                                                  index,
                                                  ind
                                                ).italic,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.getLabelTextOptions(
                                                  index,
                                                  ind
                                                ).italic,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.getLabelTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "italic",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.getLabelTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "italic",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.getLabelTextOptions(
                                                    index,
                                                    ind
                                                  ),
                                                  "italic",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "align-self-start",
                                        staticStyle: { "margin-right": "7px" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-underline",
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.getLabelTextOptions(
                                                index,
                                                ind
                                              ).underline,
                                              expression:
                                                "getLabelTextOptions(index, ind).underline",
                                            },
                                          ],
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.getLabelTextOptions(
                                                index,
                                                ind
                                              ).underline
                                            )
                                              ? _vm._i(
                                                  _vm.getLabelTextOptions(
                                                    index,
                                                    ind
                                                  ).underline,
                                                  null
                                                ) > -1
                                              : _vm.getLabelTextOptions(
                                                  index,
                                                  ind
                                                ).underline,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.getLabelTextOptions(
                                                  index,
                                                  ind
                                                ).underline,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.getLabelTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "underline",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.getLabelTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "underline",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.getLabelTextOptions(
                                                    index,
                                                    ind
                                                  ),
                                                  "underline",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-row mt-2",
                                    staticStyle: { color: "white" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "align-self-start" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.getLabelTextOptions(
                                                index,
                                                ind
                                              ).color,
                                              expression:
                                                "getLabelTextOptions(index, ind).color",
                                            },
                                          ],
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.getLabelTextOptions(
                                                index,
                                                ind
                                              ).color
                                            )
                                              ? _vm._i(
                                                  _vm.getLabelTextOptions(
                                                    index,
                                                    ind
                                                  ).color,
                                                  null
                                                ) > -1
                                              : _vm.getLabelTextOptions(
                                                  index,
                                                  ind
                                                ).color,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.getLabelTextOptions(
                                                  index,
                                                  ind
                                                ).color,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.getLabelTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "color",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.getLabelTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "color",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.getLabelTextOptions(
                                                    index,
                                                    ind
                                                  ),
                                                  "color",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          "Use Graph Color\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-row mt-2" },
                                  [
                                    _vm.chartConfigEditMode
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-success green-button",
                                            staticStyle: {
                                              "font-size": "small",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onEditChartConfiguration(
                                                  ind,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Edit Data Source")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "mx-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-row",
                                    staticStyle: { color: "white" },
                                  },
                                  [_vm._v("Data Font Size:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-row mt-2" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.getDataTextOptions(
                                              index,
                                              ind
                                            ).fontSize,
                                            expression:
                                              "getDataTextOptions(index, ind).fontSize",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.getDataTextOptions(
                                                  index,
                                                  ind
                                                ),
                                                "fontSize",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              _vm.newTextSizeSelected = true
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { attrs: { value: "auto" } },
                                          [
                                            _vm._v(
                                              "\n                                    Auto\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "0.5em" } },
                                          [
                                            _vm._v(
                                              "\n                                    Small\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "0.7em" } },
                                          [
                                            _vm._v(
                                              "\n                                    Medium\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "1.0em" } },
                                          [
                                            _vm._v(
                                              "\n                                    Large\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "1.2em" } },
                                          [
                                            _vm._v(
                                              "\n                                    X-Large\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "1.4em" } },
                                          [
                                            _vm._v(
                                              "\n                                    XX-Large\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-row mt-2",
                                    staticStyle: { color: "white" },
                                  },
                                  [_vm._v("Data Font Style:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-row mt-2",
                                    staticStyle: { color: "white" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "align-self-start",
                                        staticStyle: { "margin-right": "7px" },
                                      },
                                      [
                                        _c("i", { staticClass: "fas fa-bold" }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.getDataTextOptions(
                                                index,
                                                ind
                                              ).bold,
                                              expression:
                                                "getDataTextOptions(index, ind).bold",
                                            },
                                          ],
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.getDataTextOptions(index, ind)
                                                .bold
                                            )
                                              ? _vm._i(
                                                  _vm.getDataTextOptions(
                                                    index,
                                                    ind
                                                  ).bold,
                                                  null
                                                ) > -1
                                              : _vm.getDataTextOptions(
                                                  index,
                                                  ind
                                                ).bold,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.getDataTextOptions(
                                                  index,
                                                  ind
                                                ).bold,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.getDataTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "bold",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.getDataTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "bold",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.getDataTextOptions(
                                                    index,
                                                    ind
                                                  ),
                                                  "bold",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "align-self-start",
                                        staticStyle: { "margin-right": "7px" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-italic",
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.getDataTextOptions(
                                                index,
                                                ind
                                              ).italic,
                                              expression:
                                                "getDataTextOptions(index, ind).italic",
                                            },
                                          ],
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.getDataTextOptions(index, ind)
                                                .italic
                                            )
                                              ? _vm._i(
                                                  _vm.getDataTextOptions(
                                                    index,
                                                    ind
                                                  ).italic,
                                                  null
                                                ) > -1
                                              : _vm.getDataTextOptions(
                                                  index,
                                                  ind
                                                ).italic,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.getDataTextOptions(
                                                  index,
                                                  ind
                                                ).italic,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.getDataTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "italic",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.getDataTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "italic",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.getDataTextOptions(
                                                    index,
                                                    ind
                                                  ),
                                                  "italic",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "align-self-start",
                                        staticStyle: { "margin-right": "7px" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-underline",
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.getDataTextOptions(
                                                index,
                                                ind
                                              ).underline,
                                              expression:
                                                "getDataTextOptions(index, ind).underline",
                                            },
                                          ],
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.getDataTextOptions(index, ind)
                                                .underline
                                            )
                                              ? _vm._i(
                                                  _vm.getDataTextOptions(
                                                    index,
                                                    ind
                                                  ).underline,
                                                  null
                                                ) > -1
                                              : _vm.getDataTextOptions(
                                                  index,
                                                  ind
                                                ).underline,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.getDataTextOptions(
                                                  index,
                                                  ind
                                                ).underline,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.getDataTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "underline",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.getDataTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "underline",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.getDataTextOptions(
                                                    index,
                                                    ind
                                                  ),
                                                  "underline",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-row mt-2",
                                    staticStyle: { color: "white" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "align-self-start" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.getDataTextOptions(
                                                index,
                                                ind
                                              ).color,
                                              expression:
                                                "getDataTextOptions(index, ind).color",
                                            },
                                          ],
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.getDataTextOptions(index, ind)
                                                .color
                                            )
                                              ? _vm._i(
                                                  _vm.getDataTextOptions(
                                                    index,
                                                    ind
                                                  ).color,
                                                  null
                                                ) > -1
                                              : _vm.getDataTextOptions(
                                                  index,
                                                  ind
                                                ).color,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.getDataTextOptions(
                                                  index,
                                                  ind
                                                ).color,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.getDataTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "color",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.getDataTextOptions(
                                                        index,
                                                        ind
                                                      ),
                                                      "color",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.getDataTextOptions(
                                                    index,
                                                    ind
                                                  ),
                                                  "color",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          "Use Graph Color\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-row mt-2" },
                                  [
                                    _vm.chartConfigEditMode
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-danger red-button",
                                            staticStyle: {
                                              "font-size": "small",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onDeleteChartItem(
                                                  ind,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Delete Data Source")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target:
                            "popover-chart-axis-options-target-" +
                            _vm.dashboardItem.i,
                          triggers: "click blur",
                          placement: "left",
                          boundary: "window",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { color: "white" },
                          },
                          [
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row justify-content-center mb-2",
                                },
                                [
                                  _vm._v(
                                    "\n                            Independent Axis\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.getLineChart().scales["date-axis"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mb-1 d-flex flex-row justify-content-between",
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "mr-2 pt-1" },
                                        [_vm._v("Label Font Size: ")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.localChartAxisOptions
                                                .independentAxis.labelFontSize,
                                            expression:
                                              "localChartAxisOptions.independentAxis.labelFontSize",
                                          },
                                        ],
                                        staticClass:
                                          "w-50 form-control form-control-sm",
                                        staticStyle: { "max-width": "70px" },
                                        attrs: {
                                          type: "number",
                                          min: "2",
                                          max: "50",
                                        },
                                        domProps: {
                                          value:
                                            _vm.localChartAxisOptions
                                              .independentAxis.labelFontSize,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.setChartFontSize(
                                              "independentLabel",
                                              _vm.chartConfiguration.isVertical
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.localChartAxisOptions
                                                .independentAxis,
                                              "labelFontSize",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mb-1 d-flex flex-row justify-content-between",
                                },
                                [
                                  _c("label", { staticClass: "mr-2 pt-1" }, [
                                    _vm._v("Tick Font Size: "),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.localChartAxisOptions
                                            .independentAxis.tickFontSize,
                                        expression:
                                          "localChartAxisOptions.independentAxis.tickFontSize",
                                      },
                                    ],
                                    staticClass:
                                      "w-50 form-control form-control-sm",
                                    staticStyle: { "max-width": "70px" },
                                    attrs: {
                                      type: "number",
                                      min: "2",
                                      max: "50",
                                    },
                                    domProps: {
                                      value:
                                        _vm.localChartAxisOptions
                                          .independentAxis.tickFontSize,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.setChartFontSize(
                                          "independentTick",
                                          _vm.chartConfiguration.isVertical
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.localChartAxisOptions
                                            .independentAxis,
                                          "tickFontSize",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row justify-content-center mb-2",
                                },
                                [
                                  _vm._v(
                                    "\n                            Dependant Axis\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mb-1 d-flex flex-row justify-content-between",
                                },
                                [
                                  _c("label", { staticClass: "mr-2 pt-1" }, [
                                    _vm._v("Label Font Size: "),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.localChartAxisOptions
                                            .dependantAxis.labelFontSize,
                                        expression:
                                          "localChartAxisOptions.dependantAxis.labelFontSize",
                                      },
                                    ],
                                    staticClass:
                                      "w-50 form-control form-control-sm",
                                    staticStyle: { "max-width": "70px" },
                                    attrs: {
                                      type: "number",
                                      min: "2",
                                      max: "50",
                                    },
                                    domProps: {
                                      value:
                                        _vm.localChartAxisOptions.dependantAxis
                                          .labelFontSize,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.setChartFontSize(
                                          "dependantLabel",
                                          _vm.chartConfiguration.isVertical
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.localChartAxisOptions
                                            .dependantAxis,
                                          "labelFontSize",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mb-1 d-flex flex-row justify-content-between",
                                },
                                [
                                  _c("label", { staticClass: "mr-2 pt-1" }, [
                                    _vm._v("Tick Font Size: "),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.localChartAxisOptions
                                            .dependantAxis.tickFontSize,
                                        expression:
                                          "localChartAxisOptions.dependantAxis.tickFontSize",
                                      },
                                    ],
                                    staticClass:
                                      "w-50 form-control form-control-sm",
                                    staticStyle: { "max-width": "70px" },
                                    attrs: {
                                      type: "number",
                                      min: "2",
                                      max: "50",
                                    },
                                    domProps: {
                                      value:
                                        _vm.localChartAxisOptions.dependantAxis
                                          .tickFontSize,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.setChartFontSize(
                                          "dependantTick",
                                          _vm.chartConfiguration.isVertical
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.localChartAxisOptions
                                            .dependantAxis,
                                          "tickFontSize",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            )
          }),
          _vm._v(" "),
          !_vm.isNotDashboard
            ? _c(
                "div",
                _vm._l(_vm.sectionIndexes, function (sectionData, index) {
                  return _c(
                    "div",
                    {
                      key:
                        "grid" +
                        index +
                        "-" +
                        _vm.dashboardItem.options.configId,
                    },
                    [
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target:
                              "manage-channels-" + _vm.dashboardItem.i + index,
                            triggers: "click blur",
                            boundary: "viewport",
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "container",
                              staticStyle: { color: "#FFF" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row justify-content-center" },
                                [_vm._v("Available Data Sources")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "checkbox-list",
                                    {
                                      attrs: {
                                        label: "Source Tag Name",
                                        height: "200",
                                        options: _vm.tagsSortedByName,
                                        valueKey: "priorityName",
                                        outputKey: "name",
                                        customToggleOnEvent:
                                          _vm.quickAddDataSource,
                                        customToggleOffEvent:
                                          _vm.quickRemoveDataSource,
                                        customData: {
                                          sectionData: sectionData,
                                          index: index,
                                        },
                                        selectedOptions:
                                          _vm.selectedTags[
                                            _vm.chartConfigurationData
                                              .orderedSections[index].key
                                          ],
                                        disabledOptions:
                                          _vm.getDisabledTagsForSection(
                                            _vm.chartConfigurationData
                                              .orderedSections[index].key
                                          ),
                                      },
                                      on: {
                                        "update:selectedOptions": function (
                                          $event
                                        ) {
                                          _vm.selectedTags[
                                            _vm.chartConfigurationData.orderedSections[
                                              index
                                            ].key
                                          ] = $event
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            >\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-modal", {
            attrs: {
              "no-close-on-backdrop": "",
              size: "xl",
              "header-class": "border-bottom-0 justify-content-center  p-0",
              "body-class": "pb-0",
              "footer-class": "border-top-0 pt-0",
              "content-class": "border-3 modal-bg",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "col w-100" }, [
                        _c("div", { staticClass: "modal-header" }, [
                          _c(
                            "div",
                            { staticClass: "font-large w-100 text-center" },
                            [_vm._v(" Max Interval reached! ")]
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row flex-wrap" },
                        [
                          _c("div", { staticClass: "pr-1" }, [
                            _vm._v("Change the interval to "),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.suggestedIntervals,
                            function (sInterval, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "pr-2" },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      staticClass: "link-txt-color",
                                      on: {
                                        click: () => {
                                          _vm.showAggregationAlert = false
                                          _vm.onAggregationChange(
                                            sInterval.value,
                                            sInterval.index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(sInterval.name))]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "modal-footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-right px-2 pb-2",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary text-white",
                              attrs: { type: "button" },
                              on: {
                                click: () => {
                                  _vm.showAggregationAlert = false
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              432887967
            ),
            model: {
              value: _vm.showAggregationAlert,
              callback: function ($$v) {
                _vm.showAggregationAlert = $$v
              },
              expression: "showAggregationAlert",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              key: "header-div",
              staticClass: "w-100 frac-header rounded my-2",
              class: {
                "border border-secondary":
                  (_vm.isWirelinePlaced && _vm.headerStyle == "wireline") ||
                  (_vm.isFracPlaced && _vm.headerStyle == "frac"),
                "bg-secondary": !(
                  (_vm.isWirelinePlaced && _vm.headerStyle == "wireline") ||
                  (_vm.isFracPlaced && _vm.headerStyle == "frac")
                ),
              },
              style: _vm.getHeaderStyle(_vm.well),
              on: {
                mouseover: () => _vm.onHeaderEnter(),
                mouseleave: () => _vm.onHeaderLeave(),
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between p-1",
                },
                [
                  _vm.showHeader
                    ? _c("div", { staticClass: "text-truncate" }, [
                        (_vm.isWirelinePlaced &&
                          _vm.headerStyle == "wireline") ||
                        (_vm.isFracPlaced && _vm.headerStyle == "frac")
                          ? _c("div", { staticStyle: { flex: "1 0 0px" } }, [
                              _vm.headerStyle == "frac" && _vm.isContinuousFrac
                                ? _c("div", [
                                    _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.displayCFStatus(_vm.well)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _vm.headerStyle == "frac"
                                ? _c("div", [
                                    _c("b", [
                                      _vm._v(
                                        "Frac: " +
                                          _vm._s(
                                            _vm.wellFriendlyName(_vm.well)
                                          ) +
                                          " " +
                                          _vm._s(_vm.well.currentStage) +
                                          "/" +
                                          _vm._s(_vm.well.numberOfStages)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.headerStyle == "wireline" &&
                              _vm.well &&
                              _vm.well.hasOwnProperty("index")
                                ? _c("div", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.well.index > -1 &&
                                            _vm.well.index < 999
                                            ? "Perforating Well " +
                                                _vm.wellFriendlyName(_vm.well) +
                                                " Stage " +
                                                _vm.well.currentStage +
                                                "/" +
                                                _vm.well.numberOfStages
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ])
                          : _c("div", { staticClass: "text-white" }, [
                              _vm.headerStyle == "frac"
                                ? _c("div", [
                                    _c("b", [
                                      _vm._v(
                                        "Frac not currently assigned to a well"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.headerStyle == "wireline"
                                ? _c("div", [
                                    _c("b", [
                                      _vm._v(
                                        "Wireline not currently assigned to a well"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.headerStyle == "default"
                                ? _c("div", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.chartConfiguration.templateName
                                        ) + " "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticStyle: { flex: "auto" } }, [
                    _c("div", { staticClass: "d-flex justify-content-end" }, [
                      _vm.currentWellStageFilterInterval != null
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                                "white-space": "nowrap",
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip:top",
                                      value:
                                        "[ Well : " +
                                        _vm.currentWellFilterDisplayString +
                                        ", Stage #" +
                                        _vm.currentSelectedStage +
                                        " ]",
                                      expression:
                                        "'[ Well : ' + currentWellFilterDisplayString + ', Stage #' + currentSelectedStage + ' ]'",
                                      arg: "top",
                                    },
                                  ],
                                  staticStyle: {
                                    display: "inline-block",
                                    "min-width": "0px",
                                    "white-space": "nowrap",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                  },
                                },
                                [
                                  _c(
                                    "b",
                                    {
                                      staticStyle: { display: "inline-block" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          "[ Well : " +
                                            _vm.currentWellFilterDisplayString
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.currentWellFilterDisplayColor != null
                                    ? _c("b", {
                                        staticClass: "ml-1 mr-1",
                                        style: {
                                          display: "inline-block",
                                          width: "16px",
                                          height: "16px",
                                          border: "3px solid #000000",
                                          marginLeft: "auto",
                                          marginRight: "0px",
                                          backgroundColor:
                                            _vm.currentWellFilterDisplayColor,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "b",
                                    {
                                      staticStyle: { display: "inline-block" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          ", Stage #" +
                                            _vm.currentSelectedStageValue +
                                            " ]"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.useCustomScaleOnDateAxis &&
                      _vm.shouldDrawGraph &&
                      !_vm.chartConfigEditMode &&
                      _vm.zoomHistory.length > 1
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "align-self-center clickable pr-1 d-flex",
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip:top",
                                      value: "Undo Zoom",
                                      expression: "'Undo Zoom'",
                                      arg: "top",
                                    },
                                  ],
                                  staticClass: "ml-2 fake-button",
                                  class: { "ml-2": _vm.showHeader },
                                  attrs: {
                                    disabled:
                                      _vm.isDownloadingData ||
                                      _vm.isDownloadingGhostData,
                                  },
                                  on: { click: _vm.onUndoZoomPressed },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-undo header-icon clickable",
                                    class: {
                                      "icon-disabled":
                                        _vm.isDownloadingData ||
                                        _vm.isDownloadingGhostData,
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showSuperSmallHeader() &&
                      _vm.headerStyle == "wireline" &&
                      _vm.shouldDrawGraph &&
                      _vm.isMultiWireline &&
                      !_vm.isNotDashboard
                        ? _c(
                            "span",
                            { staticClass: "align-self-center clickable pr-1" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip:top",
                                      value: "Select Wireline Truck",
                                      expression: "'Select Wireline Truck'",
                                      arg: "top",
                                    },
                                  ],
                                  staticClass: "d-flex flex-row",
                                },
                                [
                                  _c("select-component", {
                                    ref:
                                      "wirelineTruckSelect-" +
                                      _vm.dashboardItem.i,
                                    staticClass: "d-flex align-items-center",
                                    attrs: {
                                      title: "",
                                      initSelected: _vm.initSelectedTruck,
                                      options: _vm.wirelineSystems,
                                      propValue: "number",
                                      displayPropName: "name",
                                      onChange: _vm.onSelectedTruckChange,
                                      externalInputStyles: {
                                        fontSize: "0.9rem",
                                        width: "auto",
                                        color: "#000000",
                                        background: "white",
                                        height: "20px",
                                      },
                                      isCustomClassEnabled: true,
                                      customClass: _vm.headerSelectStyle,
                                      disabled: _vm.isDownloadingData,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showMultiFracSelect
                        ? _c(
                            "span",
                            { staticClass: "align-self-center clickable pr-1" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip:top",
                                      value: "Select Frac Truck",
                                      expression: "'Select Frac Truck'",
                                      arg: "top",
                                    },
                                  ],
                                  staticClass: "d-flex flex-row",
                                },
                                [
                                  _c("select-component", {
                                    ref:
                                      "fracTruckSelect-" + _vm.dashboardItem.i,
                                    staticClass: "d-flex align-items-center",
                                    attrs: {
                                      title: "",
                                      initSelected: _vm.initSelectedTruck,
                                      options: _vm.fracSystems,
                                      propValue: "number",
                                      displayPropName: "name",
                                      onChange: _vm.onSelectedTruckChange,
                                      externalInputStyles: {
                                        fontSize: "0.9rem",
                                        width: "auto",
                                        color: "#000000",
                                        background: "white",
                                        height: "20px",
                                      },
                                      isCustomClassEnabled: true,
                                      customClass: _vm.headerSelectStyle,
                                      disabled: _vm.isDownloadingData,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showSuperSmallHeader() &&
                      !_vm.useCustomScaleOnDateAxis
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.chartConfigEditMode &&
                                    _vm.currentAggregationIndex !== null &&
                                    _vm.aggregationOptions.length > 0,
                                  expression:
                                    "!chartConfigEditMode && currentAggregationIndex !== null && aggregationOptions.length > 0",
                                },
                              ],
                              staticClass: "d-flex align-self-center",
                              class: { "ml-1": _vm.showHeader },
                            },
                            [
                              _c("select-component", {
                                ref:
                                  "autoAggregateSelect-" + _vm.dashboardItem.i,
                                staticClass: "d-flex align-items-center",
                                attrs: {
                                  title: "",
                                  initIndex: _vm.currentAggregationIndex,
                                  options: _vm.aggregationOptions,
                                  displayPropName: "name",
                                  onChange: _vm.onAggregationChange,
                                  externalInputStyles: {
                                    fontSize: "0.9rem",
                                    width: "auto",
                                    color: "#000000",
                                    background: "white",
                                    height: "20px",
                                  },
                                  isCustomClassEnabled: true,
                                  customClass: _vm.headerSelectStyle,
                                  disabled: _vm.isDownloadingData,
                                  useIndex: true,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.showSmallHeader() &&
                                !_vm.chartConfigEditMode &&
                                !_vm.useCustomScaleOnDateAxis,
                              expression:
                                "!showSmallHeader() && !chartConfigEditMode && !useCustomScaleOnDateAxis",
                            },
                          ],
                          staticClass:
                            "badge badge-light align-self-center my-1 ml-2",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ghostPlotEnabled,
                                expression: "ghostPlotEnabled",
                              },
                            ],
                            staticStyle: { "vertical-align": "middle" },
                            attrs: {
                              type: "checkbox",
                              disabled:
                                _vm.ghostPlotSettingsIncomplete &&
                                !_vm.isGhostPlotDataLoaded(),
                            },
                            domProps: {
                              checked: Array.isArray(_vm.ghostPlotEnabled)
                                ? _vm._i(_vm.ghostPlotEnabled, null) > -1
                                : _vm.ghostPlotEnabled,
                            },
                            on: {
                              click: _vm.ghostPlotClicked,
                              change: function ($event) {
                                var $$a = _vm.ghostPlotEnabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.ghostPlotEnabled = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.ghostPlotEnabled = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.ghostPlotEnabled = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "#",
                                id:
                                  "popover-ghost-" +
                                  _vm.dashboardItem.options.configId +
                                  _vm._uid,
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    Ghost Plot\n                                    "
                              ),
                              _vm.showPopoverGhostPlot
                                ? _c("i", { staticClass: "fas fa-caret-up" })
                                : _c("i", { staticClass: "fas fa-caret-down" }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.shouldDrawGraph && _vm.chartConfigEditMode
                        ? _c(
                            "span",
                            { staticClass: "ml-2 align-self-center" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary btn-sm",
                                  staticStyle: {
                                    "font-size": "small",
                                    "padding-top": "1px",
                                    "padding-bottom": "1px",
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: _vm.onCancelEditChartOrderPressed,
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.shouldDrawGraph && _vm.isEditable
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "ml-2 align-self-center clickable pr-1",
                              on: { click: _vm.onEditChartOrderPressed },
                            },
                            [
                              _vm.chartConfigEditMode
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-success btn-sm",
                                      staticStyle: {
                                        "font-size": "small",
                                        "padding-top": "1px",
                                        "padding-bottom": "1px",
                                        "padding-left": "1px",
                                        "padding-right": "1px",
                                      },
                                      attrs: { type: "button" },
                                    },
                                    [_vm._v("Save")]
                                  )
                                : _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip:top",
                                        value: "Edit controls options",
                                        expression: "'Edit controls options'",
                                        arg: "top",
                                      },
                                    ],
                                    staticClass: "fas fa-edit header-icon",
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.showSmallHeader() &&
                                !_vm.chartConfigEditMode,
                              expression:
                                "!showSmallHeader() && !chartConfigEditMode",
                            },
                          ],
                          staticClass: "align-self-center clickable pr-1",
                          on: { click: _vm.onChartHeaderPressed },
                        },
                        [
                          this.showChartHeaderConfig
                            ? _c("i", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip:top",
                                    value: "Show/Hide chart header",
                                    expression: "'Show/Hide chart header'",
                                    arg: "top",
                                  },
                                ],
                                staticClass: "fas fa-eye header-icon",
                              })
                            : _c("i", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip:top",
                                    value: "Show/Hide chart header",
                                    expression: "'Show/Hide chart header'",
                                    arg: "top",
                                  },
                                ],
                                staticClass: "fas fa-eye-slash header-icon",
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.showSmallHeader() &&
                                !_vm.chartConfigEditMode &&
                                !_vm.useCustomScaleOnDateAxis,
                              expression:
                                "!showSmallHeader() && !chartConfigEditMode && !useCustomScaleOnDateAxis",
                            },
                          ],
                          staticClass: "align-self-center clickable pr-1",
                          on: {
                            click: function ($event) {
                              _vm.inStatsMode = !_vm.inStatsMode
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { class: { "in-stats": _vm.inStatsMode } },
                            [
                              _vm.inStatsMode
                                ? _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip:top",
                                        value: "Toggle Statistics Mode",
                                        expression: "'Toggle Statistics Mode'",
                                        arg: "top",
                                      },
                                    ],
                                    staticClass: "fas fa-chart-bar header-icon",
                                  })
                                : _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip:top",
                                        value: "Toggle Statistics Mode",
                                        expression: "'Toggle Statistics Mode'",
                                        arg: "top",
                                      },
                                    ],
                                    staticClass: "fas fa-chart-bar header-icon",
                                  }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.shouldDrawGraph
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.showSmallHeader() &&
                                    !_vm.chartConfigEditMode,
                                  expression:
                                    "!showSmallHeader() && !chartConfigEditMode",
                                },
                              ],
                              staticClass: "align-self-center clickable pr-1",
                              on: { click: _vm.onAlertIconPressed },
                            },
                            [
                              this.enableThresholdAlerts
                                ? _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip:top",
                                        value:
                                          "Show/Hide threshold alerts in chart comment line",
                                        expression:
                                          "'Show/Hide threshold alerts in chart comment line'",
                                        arg: "top",
                                      },
                                    ],
                                    staticClass: "fas fa-bell header-icon",
                                  })
                                : _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip:top",
                                        value:
                                          "Show/Hide threshold alerts in chart comment line",
                                        expression:
                                          "'Show/Hide threshold alerts in chart comment line'",
                                        arg: "top",
                                      },
                                    ],
                                    staticClass:
                                      "fas fa-bell-slash header-icon",
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.useCustomScaleOnDateAxis && _vm.shouldDrawGraph
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.showSmallHeader() &&
                                    !_vm.chartConfigEditMode,
                                  expression:
                                    "!showSmallHeader() && !chartConfigEditMode",
                                },
                              ],
                              staticClass: "align-self-center clickable pr-1",
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip:top",
                                      value: "Export Chart Data",
                                      expression: "'Export Chart Data'",
                                      arg: "top",
                                    },
                                  ],
                                  on: { click: _vm.onExportIconPress },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-download header-icon",
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showSmallHeader() &&
                      _vm.shouldDrawGraph &&
                      !_vm.chartConfigEditMode
                        ? _c(
                            "span",
                            {
                              staticClass: "align-self-center clickable pr-1",
                              attrs: {
                                id:
                                  "popover-filter-" +
                                  _vm.dashboardItem.options.configId +
                                  _vm._uid,
                              },
                            },
                            [
                              _c("span", [
                                _c("i", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip:top",
                                      value: "Filtering",
                                      expression: "'Filtering'",
                                      arg: "top",
                                    },
                                  ],
                                  staticClass: "fas fa-filter header-icon",
                                }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showSuperSmallHeader() &&
                      !_vm.useCustomScaleOnDateAxis &&
                      _vm.shouldDrawGraph &&
                      !_vm.chartConfigEditMode
                        ? _c(
                            "span",
                            {
                              staticClass: "align-self-center clickable pr-1",
                              attrs: {
                                id:
                                  "popover-target-" +
                                  _vm.dashboardItem.options.configId +
                                  _vm._uid,
                              },
                            },
                            [_vm._m(0)]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isEditable
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip:top",
                                  value: "Edit Chart Configuration",
                                  expression: "'Edit Chart Configuration'",
                                  arg: "top",
                                },
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.showSmallHeader() &&
                                    !_vm.chartConfigEditMode,
                                  expression:
                                    "!showSmallHeader() && !chartConfigEditMode",
                                },
                              ],
                              staticClass: "align-self-center clickable pr-1",
                              on: { click: _vm.onEditChartConfiguration },
                            },
                            [_c("i", { staticClass: "fas fa-cog header-icon" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !this.jobEnd && _vm.shouldDrawGraph
                        ? _c(
                            "span",
                            {
                              staticClass: "align-self-center",
                              staticStyle: {
                                "vertical-align": "middle",
                                "margin-top": "-2px",
                              },
                            },
                            [
                              _vm.shouldDrawGraph
                                ? _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.chartConfigEditMode,
                                          expression: "!chartConfigEditMode",
                                        },
                                      ],
                                      staticClass:
                                        "btn btn-secondary align-self-center btn-sm ml-1 p-0",
                                      staticStyle: {
                                        "font-size": "13px",
                                        "background-color": "white",
                                        "white-space": "nowrap",
                                      },
                                      attrs: {
                                        disabled: _vm.isDownloadingData,
                                        type: "button",
                                      },
                                      on: { click: _vm.followToLatestTime },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "px-1 font-weight-bold",
                                          staticStyle: {
                                            "font-family":
                                              "FontAwesome, sans-serif",
                                          },
                                        },
                                        [
                                          this.followingUpdates
                                            ? _c("i", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip:top",
                                                    value: "Live Data",
                                                    expression: "'Live Data'",
                                                    arg: "top",
                                                  },
                                                ],
                                                staticClass:
                                                  "fas fa-satellite-dish green-color",
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          this.followingUpdates
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "green",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.showSmallHeader()
                                                        ? ""
                                                        : " Latest Data"
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !this.followingUpdates
                                            ? _c("i", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip:top",
                                                    value:
                                                      "Not Following Live Data",
                                                    expression:
                                                      "'Not Following Live Data'",
                                                    arg: "top",
                                                  },
                                                ],
                                                staticClass:
                                                  "fas fa-satellite-dish not-live-tracking",
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !this.followingUpdates
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "not-live-tracking",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.showSmallHeader()
                                                        ? ""
                                                        : " Not Following Latest Data"
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                (_vm.showSuperSmallHeader() ||
                                  _vm.showSmallHeader()) &&
                                !_vm.chartConfigEditMode,
                              expression:
                                "(showSuperSmallHeader() || showSmallHeader()) && !chartConfigEditMode",
                            },
                            {
                              name: "tooltip",
                              rawName: "v-tooltip:top",
                              value: "More options",
                              expression: "'More options'",
                              arg: "top",
                            },
                          ],
                          staticClass: "ml-2",
                          attrs: { id: "popover-header-" + _vm._uid },
                        },
                        [_vm._m(1)]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "d-flex": true,
                "flex-row": _vm.controlsPosition == "left",
                "flex-row-reverse": _vm.controlsPosition == "right",
                "flex-column": _vm.controlsPosition == "top",
                "flex-column-reverse": _vm.controlsPosition == "bottom",
              },
              style: { height: _vm.bodyHeight + "px" },
              attrs: { id: "chart-space-" + _vm.dashboardItem.i },
            },
            [
              !_vm.hideControlsColumn
                ? _c(
                    "div",
                    {
                      ref: "sectionsDiv",
                      class: {
                        "pr-1":
                          (_vm.controlsPosition == "left" ||
                            _vm.controlsPosition == "right") &&
                          _vm.shouldDrawGraph,
                      },
                      style: _vm.sectionWidth,
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "px-0",
                          class: {
                            "col-12":
                              _vm.controlsPosition == "left" ||
                              _vm.controlsPosition == "right" ||
                              !_vm.shouldDrawGraph,
                          },
                          style: _vm.readoutControlsSectionsStyle,
                        },
                        [
                          _c(
                            "div",
                            {
                              ref: "mainGridLayout" + _vm._uid,
                              class: _vm.mainGridClass,
                            },
                            [
                              _c(
                                "draggable",
                                {
                                  class: _vm.daggableClass,
                                  attrs: { handle: ".handle" },
                                  model: {
                                    value: _vm.sectionIndexes,
                                    callback: function ($$v) {
                                      _vm.sectionIndexes = $$v
                                    },
                                    expression: "sectionIndexes",
                                  },
                                },
                                _vm._l(
                                  _vm.sectionIndexes,
                                  function (sectionData, index) {
                                    return _c(
                                      "div",
                                      {
                                        key:
                                          "grid" +
                                          index +
                                          "-" +
                                          _vm.dashboardItem.options.configId,
                                        class: _vm.chartItemClass,
                                        style: _vm.sectionFill,
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row rounded bg-secondary border border-dark my-1",
                                            staticStyle: {
                                              "margin-left": "0px !important",
                                              "margin-right": "4px !important",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "my-1 ml-2",
                                                staticStyle: {
                                                  "flex-grow": "3",
                                                  "white-space": "nowrap",
                                                  overflow: "hidden",
                                                  "text-overflow": "ellipsis",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.sectionLabel[
                                                        sectionData.index
                                                      ]
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "my-1 mr-2 d-flex flex-row-reverse justify-content-start flex-wrap-reverse",
                                                staticStyle: {
                                                  "flex-grow": "1",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  ref:
                                                    "axisDataArea-" +
                                                    _vm.usedSectionsIDs[
                                                      sectionData.index
                                                    ] +
                                                    "-eye",
                                                  refInFor: true,
                                                  staticClass: "fas",
                                                  class: {
                                                    "fa-caret-up":
                                                      !_vm.isSectionHidden(
                                                        _vm.usedSectionsIDs[
                                                          sectionData.index
                                                        ]
                                                      ),
                                                    "fa-caret-down":
                                                      _vm.isSectionHidden(
                                                        _vm.usedSectionsIDs[
                                                          sectionData.index
                                                        ]
                                                      ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onExpandButtonPress(
                                                        sectionData.index
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _vm.shouldDrawGraph
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.sectionChecked[
                                                              sectionData.index
                                                            ],
                                                          expression:
                                                            "sectionChecked[sectionData.index]",
                                                        },
                                                      ],
                                                      staticClass: "m-1 px-1",
                                                      attrs: {
                                                        type: "checkbox",
                                                        disabled:
                                                          _vm.isDisabledCheckbox,
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          _vm.sectionChecked[
                                                            sectionData.index
                                                          ]
                                                        )
                                                          ? _vm._i(
                                                              _vm
                                                                .sectionChecked[
                                                                sectionData
                                                                  .index
                                                              ],
                                                              null
                                                            ) > -1
                                                          : _vm.sectionChecked[
                                                              sectionData.index
                                                            ],
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$a =
                                                                _vm
                                                                  .sectionChecked[
                                                                  sectionData
                                                                    .index
                                                                ],
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.sectionChecked,
                                                                    sectionData.index,
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.sectionChecked,
                                                                    sectionData.index,
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.sectionChecked,
                                                                sectionData.index,
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                          function ($event) {
                                                            return _vm.sectionCheckedChanged(
                                                              sectionData.index
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.shouldDrawGraph &&
                                                _vm.chartConfigEditMode
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fas fa-grip-lines handle show-move-cursor",
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.shouldDrawGraph &&
                                                          _vm.chartConfigEditMode &&
                                                          !_vm.isNotDashboard,
                                                        expression:
                                                          "shouldDrawGraph && chartConfigEditMode && !isNotDashboard",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge badge-light d-flex align-items-center mt-1 ml-1 mr-2 pr-1",
                                                        staticStyle: {
                                                          display:
                                                            "inline-block",
                                                          "font-size": "65%",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "fake-button",
                                                            attrs: {
                                                              id:
                                                                "manage-channels-" +
                                                                _vm
                                                                  .dashboardItem
                                                                  .i +
                                                                index,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Manage Channels"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.dataReceived
                                          ? _c(
                                              "div",
                                              {
                                                ref:
                                                  "axisDataArea-" +
                                                  _vm.usedSectionsIDs[
                                                    sectionData.index
                                                  ],
                                                refInFor: true,
                                                staticClass: "px-1 py-1",
                                                class: _vm.chartConfigEditMode
                                                  ? "bg-light"
                                                  : null,
                                                style: {
                                                  display: _vm.isSectionHidden(
                                                    _vm.usedSectionsIDs[
                                                      sectionData.index
                                                    ]
                                                  )
                                                    ? "none"
                                                    : "",
                                                  overflowY: "auto",
                                                  overflowX: "hidden",
                                                  borderRadius: "5px",
                                                },
                                              },
                                              [
                                                _vm.chartItemLayouts[
                                                  _vm.usedSectionsIDs[
                                                    sectionData.index
                                                  ]
                                                ]
                                                  ? _c(
                                                      "grid-layout",
                                                      {
                                                        attrs: {
                                                          layout:
                                                            _vm
                                                              .chartItemLayouts[
                                                              _vm
                                                                .usedSectionsIDs[
                                                                sectionData
                                                                  .index
                                                              ]
                                                            ],
                                                          "is-draggable":
                                                            _vm.chartConfigEditMode,
                                                          "is-resizable":
                                                            _vm.chartConfigEditMode,
                                                          "col-num":
                                                            _vm.defaultChartColumnCount,
                                                          "row-height": 30,
                                                          "auto-size": true,
                                                          "prevent-collision": false,
                                                          "vertical-compact": false,
                                                          margin: [3, 3],
                                                          "use-css-transforms": true,
                                                        },
                                                        on: {
                                                          "layout-mounted":
                                                            _vm.onGridMounted,
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.usedSections[
                                                          sectionData.index
                                                        ],
                                                        function (
                                                          fracItem,
                                                          ind
                                                        ) {
                                                          return _c(
                                                            "grid-item",
                                                            {
                                                              key:
                                                                fracItem.name +
                                                                _vm
                                                                  .dashboardItem
                                                                  .options
                                                                  .configId,
                                                              class:
                                                                _vm.setClassesForChartItems(
                                                                  _vm
                                                                    .columnCounts[
                                                                    sectionData
                                                                      .index
                                                                  ]
                                                                ),
                                                              staticStyle: {
                                                                "white-space":
                                                                  "nowrap",
                                                                overflow:
                                                                  "hidden",
                                                                "text-overflow":
                                                                  "ellipsis",
                                                              },
                                                              style:
                                                                _vm.chartItemColor(
                                                                  fracItem.name
                                                                ),
                                                              attrs: {
                                                                id:
                                                                  "chartitem-" +
                                                                  fracItem.name,
                                                                x: _vm
                                                                  .chartItemLayouts[
                                                                  _vm
                                                                    .usedSectionsIDs[
                                                                    sectionData
                                                                      .index
                                                                  ]
                                                                ][ind].x,
                                                                y: _vm
                                                                  .chartItemLayouts[
                                                                  _vm
                                                                    .usedSectionsIDs[
                                                                    sectionData
                                                                      .index
                                                                  ]
                                                                ][ind].y,
                                                                w: _vm
                                                                  .chartItemLayouts[
                                                                  _vm
                                                                    .usedSectionsIDs[
                                                                    sectionData
                                                                      .index
                                                                  ]
                                                                ][ind].w,
                                                                h: _vm
                                                                  .chartItemLayouts[
                                                                  _vm
                                                                    .usedSectionsIDs[
                                                                    sectionData
                                                                      .index
                                                                  ]
                                                                ][ind].h,
                                                                i: _vm
                                                                  .chartItemLayouts[
                                                                  _vm
                                                                    .usedSectionsIDs[
                                                                    sectionData
                                                                      .index
                                                                  ]
                                                                ][ind].i,
                                                                "drag-allow-from":
                                                                  ".vue-draggable-handle",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pt-1",
                                                                  style:
                                                                    _vm.getChartitemStyle(
                                                                      _vm.getLabelTextOptions(
                                                                        sectionData.index,
                                                                        ind
                                                                      ),
                                                                      fracItem,
                                                                      "label",
                                                                      _vm
                                                                        .paramChecked[
                                                                        fracItem
                                                                          .name
                                                                      ]
                                                                    ),
                                                                  attrs: {
                                                                    id:
                                                                      "labelFont-" +
                                                                      fracItem.name,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                " +
                                                                      _vm._s(
                                                                        _vm.getDisplayTagName(
                                                                          fracItem.name
                                                                        )
                                                                      ) +
                                                                      "\n                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pt-1",
                                                                  style:
                                                                    _vm.getChartitemStyle(
                                                                      _vm.getDataTextOptions(
                                                                        sectionData.index,
                                                                        ind
                                                                      ),
                                                                      fracItem,
                                                                      "data",
                                                                      _vm
                                                                        .paramChecked[
                                                                        fracItem
                                                                          .name
                                                                      ]
                                                                    ),
                                                                  attrs: {
                                                                    id:
                                                                      "dataFont-" +
                                                                      fracItem.name,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                " +
                                                                      _vm._s(
                                                                        _vm.getFormattedChartValue(
                                                                          fracItem
                                                                        )
                                                                      ) +
                                                                      "\n                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.shouldDrawGraph
                                                                ? _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .paramChecked[
                                                                              fracItem
                                                                                .name
                                                                            ],
                                                                          expression:
                                                                            "paramChecked[fracItem.name]",
                                                                        },
                                                                      ],
                                                                    staticStyle:
                                                                      {
                                                                        position:
                                                                          "absolute",
                                                                        right:
                                                                          "3px",
                                                                        bottom:
                                                                          "3px",
                                                                      },
                                                                    attrs: {
                                                                      type: "checkbox",
                                                                      disabled:
                                                                        _vm.isDownloadingData,
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm
                                                                            .paramChecked[
                                                                            fracItem
                                                                              .name
                                                                          ]
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm
                                                                                .paramChecked[
                                                                                fracItem
                                                                                  .name
                                                                              ],
                                                                              null
                                                                            ) >
                                                                            -1
                                                                          : _vm
                                                                              .paramChecked[
                                                                              fracItem
                                                                                .name
                                                                            ],
                                                                    },
                                                                    on: {
                                                                      change: [
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm
                                                                                .paramChecked[
                                                                                fracItem
                                                                                  .name
                                                                              ],
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                null,
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  _vm.paramChecked,
                                                                                  fracItem.name,
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  _vm.paramChecked,
                                                                                  fracItem.name,
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              _vm.paramChecked,
                                                                              fracItem.name,
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.setActiveData(
                                                                            fracItem
                                                                          )
                                                                        },
                                                                      ],
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.shouldDrawGraph &&
                                                              _vm.chartConfigEditMode
                                                                ? _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "fake-button",
                                                                      staticStyle:
                                                                        {
                                                                          position:
                                                                            "absolute",
                                                                          left: "3px",
                                                                          top: "1px",
                                                                          color:
                                                                            "#6c757d",
                                                                          padding:
                                                                            "1px !important",
                                                                        },
                                                                      attrs: {
                                                                        id:
                                                                          "popover-target-" +
                                                                          _vm._uid +
                                                                          "-" +
                                                                          sectionData.index +
                                                                          "-" +
                                                                          ind,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onEditChartItemPressed(
                                                                              fracItem.name,
                                                                              _vm
                                                                                .chartItemLayouts[
                                                                                _vm
                                                                                  .usedSectionsIDs[
                                                                                  sectionData
                                                                                    .index
                                                                                ]
                                                                              ],
                                                                              ind
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fas fa-cog",
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.shouldDrawGraph &&
                                                              _vm.chartConfigEditMode
                                                                ? _c("i", {
                                                                    staticClass:
                                                                      "fas fa-grip-lines vue-draggable-handle",
                                                                    staticStyle:
                                                                      {
                                                                        position:
                                                                          "absolute",
                                                                        right:
                                                                          "3px",
                                                                        top: "3px",
                                                                        color:
                                                                          "#6c757d",
                                                                      },
                                                                  })
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _c("div", [
                                                      _vm._v(
                                                        "\n                                    No chart items in this section\n                                "
                                                      ),
                                                    ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.shouldDrawGraph,
                      expression: "shouldDrawGraph",
                    },
                  ],
                  style: _vm.chartWidth,
                },
                [
                  _vm.chartConfigEditMode && !_vm.chartConfiguration.isVertical
                    ? _c(
                        "div",
                        {
                          staticClass: "w-100 d-flex justify-content-between",
                          attrs: { id: "editVerticalAxisContainer" },
                        },
                        [
                          _c(
                            "div",
                            _vm._l(
                              _vm.getYAxes("left").slice().reverse(),
                              function (axis) {
                                return _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip:top",
                                        value: `Edit Axis`,
                                        expression: "`Edit Axis`",
                                        arg: "top",
                                      },
                                    ],
                                    key: axis.options.key,
                                    staticClass: "fake-button",
                                    style: `width:${axis.width}px;color: #6c757d;`,
                                    attrs: {
                                      id:
                                        "edit-axis-target-" + axis.options.key,
                                    },
                                  },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            _vm._l(_vm.getYAxes("right"), function (axis) {
                              return _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip:top",
                                      value: `Edit Axis`,
                                      expression: "`Edit Axis`",
                                      arg: "top",
                                    },
                                  ],
                                  key: axis.options.key,
                                  staticClass: "fake-button",
                                  style: `width:${axis.width}px;color: #6c757d;`,
                                  attrs: {
                                    id: "edit-axis-target-" + axis.options.key,
                                  },
                                },
                                [_c("i", { staticClass: "fas fa-cog" })]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.configurationDataYAxesClone,
                            function (axis) {
                              return _c(
                                "b-popover",
                                {
                                  key: axis.key + _vm.forceRefreshStaticKey,
                                  attrs: {
                                    target: "edit-axis-target-" + axis.key,
                                    triggers: "click blur",
                                    placement: "left right",
                                    boundary: "window",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { color: "white" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 text-center mb-1 d-flex justify-content-center",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(axis.label)),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 mb-1 d-flex justify-content-between",
                                        },
                                        [
                                          _c("label", { staticClass: "pr-2" }, [
                                            _vm._v("Name"),
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: axis.label,
                                                expression: "axis.label",
                                              },
                                            ],
                                            attrs: { type: "text" },
                                            domProps: { value: axis.label },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  axis,
                                                  "label",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 mb-1 d-flex justify-content-between",
                                        },
                                        [
                                          _c("label", { staticClass: "pr-2" }, [
                                            _vm._v("Minimum"),
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: axis.min,
                                                expression: "axis.min",
                                                modifiers: { number: true },
                                              },
                                            ],
                                            attrs: { type: "number" },
                                            domProps: { value: axis.min },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  axis,
                                                  "min",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 mb-1 d-flex justify-content-between",
                                        },
                                        [
                                          _c("label", { staticClass: "pr-2" }, [
                                            _vm._v("Maximum"),
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: axis.max,
                                                expression: "axis.max",
                                                modifiers: { number: true },
                                              },
                                            ],
                                            attrs: { type: "number" },
                                            domProps: { value: axis.max },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  axis,
                                                  "max",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 mb-1 d-flex justify-content-between",
                                        },
                                        [
                                          _c("label", { staticClass: "pr-2" }, [
                                            _vm._v("Number of Ticks"),
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: axis.ticks,
                                                expression: "axis.ticks",
                                                modifiers: { number: true },
                                              },
                                            ],
                                            attrs: {
                                              type: "number",
                                              step: "1",
                                            },
                                            domProps: { value: axis.ticks },
                                            on: {
                                              blur: [
                                                function ($event) {
                                                  return _vm.validateAxisTickNumber(
                                                    axis
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              ],
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  axis,
                                                  "ticks",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 mb-1 d-flex justify-content-between",
                                        },
                                        [
                                          _c("label", { staticClass: "pr-2" }, [
                                            _vm._v("Position"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: axis.position,
                                                  expression: "axis.position",
                                                },
                                              ],
                                              staticClass: "w-100",
                                              staticStyle: {
                                                "max-width": "162px",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    axis,
                                                    "position",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "left" } },
                                                [_vm._v("Left")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "right" } },
                                                [_vm._v("Right")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 mb-1 d-flex justify-content-between",
                                        },
                                        [
                                          _c("label", { staticClass: "pr-2" }, [
                                            _vm._v("Display Gridlines"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      axis.displayGridlines,
                                                    expression:
                                                      "axis.displayGridlines",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    axis.displayGridlines
                                                  )
                                                    ? _vm._i(
                                                        axis.displayGridlines,
                                                        null
                                                      ) > -1
                                                    : axis.displayGridlines,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        axis.displayGridlines,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            axis,
                                                            "displayGridlines",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            axis,
                                                            "displayGridlines",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        axis,
                                                        "displayGridlines",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 mb-1 d-flex justify-content-between",
                                        },
                                        [
                                          _c("label", { staticClass: "pr-2" }, [
                                            _vm._v("Color"),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "w-100" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-end",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "w-100",
                                                    staticStyle: {
                                                      "max-width": "162px",
                                                    },
                                                    style: {
                                                      backgroundColor:
                                                        axis.color || "fff",
                                                      color:
                                                        _vm.getTitleColorForBG(
                                                          axis.color || "fff"
                                                        ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showAxisColorPicker =
                                                          !_vm.showAxisColorPicker
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        !_vm.showAxisColorPicker
                                                          ? "Select Color"
                                                          : "Click To Hide"
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.showAxisColorPicker
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-end",
                                                  },
                                                  [
                                                    _c("sketch-picker", {
                                                      attrs: {
                                                        value: axis.color
                                                          ? axis.color
                                                          : "#FFFFFF",
                                                        disableAlpha: true,
                                                        "preset-colors":
                                                          _vm.presetColors,
                                                      },
                                                      on: {
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          _vm.showAxisColorPicker = false
                                                        },
                                                        input: (color) => {
                                                          axis.color = color.hex
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 mb-1 d-flex justify-content-end mt-2",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.applyYAxisEdits(
                                                    axis
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Apply Changes")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.editYAxisValidator
                                                  .axisKey == axis.key,
                                              expression:
                                                "editYAxisValidator.axisKey == axis.key",
                                            },
                                          ],
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.editYAxisValidator
                                                      .noAxisLabelError,
                                                  expression:
                                                    "editYAxisValidator.noAxisLabelError",
                                                },
                                              ],
                                              staticClass: "text-danger",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Axis label must be provided\n                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.editYAxisValidator
                                                      .noMinError,
                                                  expression:
                                                    "editYAxisValidator.noMinError",
                                                },
                                              ],
                                              staticClass: "text-danger",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Axis minimum must be provided\n                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.editYAxisValidator
                                                      .noMaxError,
                                                  expression:
                                                    "editYAxisValidator.noMaxError",
                                                },
                                              ],
                                              staticClass: "text-danger",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Axis maximum must be provided\n                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.editYAxisValidator
                                                      .minMaxError,
                                                  expression:
                                                    "editYAxisValidator.minMaxError",
                                                },
                                              ],
                                              staticClass: "text-danger",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Maximum must be greater than minimum\n                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.editYAxisValidator
                                                      .noNumberOfTicksError,
                                                  expression:
                                                    "editYAxisValidator.noNumberOfTicksError",
                                                },
                                              ],
                                              staticClass: "text-danger",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Axis tick number must be provided and > 2\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.useCustomScaleOnDateAxis &&
                            _vm.disabledZoomMessage,
                          expression:
                            "!useCustomScaleOnDateAxis && disabledZoomMessage",
                        },
                      ],
                      style: {
                        zIndex: 10,
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        backgroundColor: "#3490dc",
                        fontSize: "0.8vw",
                        padding: "10px",
                      },
                    },
                    [_vm._v("Click the chart to enable scroll zoom")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: { "bg-light": _vm.chartConfigEditMode },
                      style: _vm.chartStyle,
                      attrs: { tabindex: "-1" },
                      on: {
                        mouseleave: function ($event) {
                          _vm.hideAllTooltips()
                          _vm.hoveringChart = false
                        },
                        mouseover: function ($event) {
                          _vm.hoveringChart = true
                        },
                        wheel: _vm.zoomDisabledMessage,
                        "!focus": function ($event) {
                          return _vm.enableZoomOnFocus()
                        },
                        blur: function ($event) {
                          return _vm.disableZoomOnBlur()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "w-100",
                          staticStyle: { position: "absolute" },
                        },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.shouldDrawGraph &&
                                    _vm.chartConfigEditMode,
                                  expression:
                                    "shouldDrawGraph && chartConfigEditMode",
                                },
                              ],
                              staticClass: "fake-button",
                              staticStyle: {
                                color: "#6c757d",
                                padding: "1px !important",
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                              },
                              attrs: {
                                id:
                                  "popover-chart-axis-options-target-" +
                                  _vm.dashboardItem.i,
                              },
                            },
                            [_c("i", { staticClass: "fas fa-cog" })]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("transition", { attrs: { name: "fade" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.wellStageLegendDisplay.show,
                                expression: "wellStageLegendDisplay.show",
                              },
                            ],
                          },
                          [
                            _c(
                              "div",
                              {
                                ref: "annotation-legend-box",
                                staticClass: "v-fade",
                                staticStyle: {
                                  border: "1px solid gray",
                                  "border-radius": "5px",
                                  "background-color": "#1f2227",
                                },
                                style: _vm.annotationHoverStyle,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex text-center justify-content-center",
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "mr-1 ml-2",
                                      style: `height:10px;width:10px;margin-top:4px;background-color:${_vm.wellStageLegendDisplay.color}`,
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          id:
                                            "annotation-legend-display-" +
                                            _vm.dashboardItem.i,
                                        },
                                        on: {
                                          setNewAnnotation: function ($event) {
                                            return _vm.setNewAnnotation($event)
                                          },
                                          clearAnnotationDisplay: function (
                                            $event
                                          ) {
                                            return _vm.clearAnnotation()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.wellStageLegendDisplay.label
                                                .fullContent
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isMinWidth &&
                      _vm.chartConfiguration.isVertical &&
                      _vm.chartId !== false
                        ? _c("div", {
                            staticClass: "tooltip-area-style",
                            attrs: { id: "tooltip-area-" + _vm.chartId },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "p-1",
                          staticStyle: {
                            position: "absolute",
                            "background-color": "#404040",
                            "z-index": "99",
                            visibility: "hidden",
                            overflow: "auto",
                          },
                          attrs: { id: "tsi-div" + _vm._uid },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c(
                                "h4",
                                { staticStyle: { "font-size": "1em" } },
                                [_vm._v("Statistics")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "pt-0.5 pr-2",
                                  on: { click: _vm.closeTsiAnalytics },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-times-circle",
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", {
                            staticStyle: { "font-size": "0.7em" },
                            attrs: { id: "tsi-time" + _vm._uid },
                          }),
                          _vm._v(" "),
                          _vm.tsiAnalyticsIsDownloading
                            ? _c(
                                "div",
                                {
                                  staticClass: "m-5",
                                  staticStyle: { "font-size": "0.7em" },
                                },
                                [
                                  _c("p", { staticClass: "text-center" }, [
                                    _vm._v("Loading statistics..."),
                                  ]),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  class: {
                                    "d-flex": _vm.chartConfiguration.isVertical,
                                    "flex-wrap":
                                      _vm.chartConfiguration.isVertical,
                                  },
                                  staticStyle: { "font-size": "0.7em" },
                                },
                                _vm._l(
                                  _vm.tsiAnalytics,
                                  function (analytic, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "d-flex pb-2",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "ml-1",
                                          style: {
                                            backgroundColor: analytic.color,
                                            width: "4px",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "ml-2" }, [
                                          _c("p", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  analytic.tagName +
                                                    " " +
                                                    analytic.ghostPlotLabel
                                                ) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c("p", [
                                              _vm._v("Min: "),
                                              _c("br"),
                                              _vm._v(
                                                "  " + _vm._s(analytic.min)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "ml-3" }, [
                                              _vm._v("Max: "),
                                              _c("br"),
                                              _vm._v(
                                                "  " + _vm._s(analytic.max)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "ml-3" }, [
                                              _vm._v("Avg: "),
                                              _c("br"),
                                              _vm._v(
                                                " " + _vm._s(analytic.avg)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "ml-3" }, [
                                              _vm._v("Delta: "),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (
                                                      analytic.last -
                                                      analytic.first
                                                    ).toFixed(2)
                                                  )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("canvas", {
                        staticStyle: {
                          position: "absolute",
                          "pointer-events": "none",
                        },
                        attrs: {
                          id: "overlay-tsi-analytics" + _vm._uid,
                          width: "600",
                          height: "400",
                        },
                      }),
                      _vm._v(" "),
                      _c("canvas", {
                        staticStyle: {
                          position: "absolute",
                          "pointer-events": "none",
                        },
                        attrs: {
                          id: "overlay-chart-comment" + _vm._uid,
                          width: "600",
                          height: "400",
                        },
                      }),
                      _vm._v(" "),
                      _c("scatter-chart", {
                        ref: "lineChart",
                        staticClass: "chart-style",
                        attrs: {
                          id: _vm.lineChartId,
                          "chart-data": _vm.datacollection,
                          options: _vm.options,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          ref: "dashboardTimeSeriesChart",
                          staticClass: "w-100",
                          staticStyle: {
                            "font-size": "0.8rem",
                            "background-color": "#fff",
                          },
                          attrs: { id: "dashboardTimeSeriesChart" },
                        },
                        [
                          _c("notifications", {
                            ref: "ghostplotNotification",
                            attrs: {
                              id: "ghostplotNotification",
                              group: "ghostData-" + _vm._uid,
                              position: "top right",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isDownloadingData
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "spinner-border spinner-border-sm",
                                style: _vm.getSpinnerStyle(),
                                attrs: { role: "status" },
                              },
                              [
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Loading..."),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.editMode
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-center",
                          attrs: { id: "hideCommentsTimelineContainer" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "badge d-flex justify-content-center bg-light",
                            },
                            [
                              _c(
                                "b-popover",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.chartConfiguration.isVertical ||
                                        _vm.useCustomScaleOnDateAxis,
                                      expression:
                                        "chartConfiguration.isVertical || useCustomScaleOnDateAxis",
                                    },
                                  ],
                                  attrs: {
                                    target: "hideCommentsTimelineContainer",
                                    triggers: "hover",
                                    placement: "top",
                                    "custom-class": "white-text",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "white-text m-0",
                                      staticStyle: { "font-size": "0.9em" },
                                    },
                                    [
                                      _vm._v(
                                        "Comments timeline is disabled for custom axes and vertical charts"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isCommentsTimelineHidden,
                          expression: "!isCommentsTimelineHidden",
                        },
                      ],
                    },
                    [
                      _vm.$refs.lineChart != null
                        ? _c("chart-comments-timeline", {
                            ref: "chartTimeline",
                            attrs: {
                              jobNumber: this.jobNumber,
                              jobID: this.jobID,
                              userID: this.userID,
                              componentSpecificPermissions:
                                _vm.componentSpecificPermissions,
                              chartcomments: {
                                comments: _vm.chartcomments,
                                alerts: _vm.thresholdAlertsHistory,
                                flags: [],
                              },
                              onHover: _vm.onCommentLineHover,
                              jobHourOffset: _vm.jobHourOffset,
                              chart: _vm.$refs.lineChart,
                              eventReasons: _vm.eventReasons,
                              stepDescriptions: _vm.stepDescriptions,
                              nptOutcomes: _vm.nptOutcomes,
                              customerVendors: _vm.customerVendors,
                              contractors: _vm.contractors,
                              eventActivityEventReasons:
                                _vm.eventActivityEventReasons,
                              wells: _vm.wells,
                              headerStyle: _vm.headerStyle,
                              signalRConnected: _vm.signalRConnected,
                              wellStageIntervals: _vm.wellStageIntervals,
                              config: _vm.chartConfiguration,
                              timeseriesHeight: _vm.height,
                              timeseriesWidth: _vm.width,
                            },
                            on: {
                              createNPTAnnotations: _vm.createNPTMarkers,
                              removeNPTAnnotations: _vm.removeNPTMarkers,
                              moveChart: _vm.onScrubberBrushMoved,
                              onChange: _vm.chartOverlayDraw,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isScrubberHidden
                    ? _c(
                        "div",
                        {
                          staticStyle: { "max-height": "160px!important" },
                          style: { height: `${_vm.scrubberHeight}!important` },
                        },
                        [
                          _vm.$refs.lineChart != null
                            ? _c("chart-scrubber", {
                                ref: "chartScrubber",
                                attrs: {
                                  minDate: _vm.currentChartMin
                                    ? _vm.currentChartMin.valueOf()
                                    : 0,
                                  maxDate: _vm.currentChartMax
                                    ? _vm.currentChartMax.valueOf()
                                    : 0,
                                  rightSideMsOffset: this.rightSideMsOffset,
                                  ghostPlotXMax: this.ghostPlotXMax,
                                  jobStart: _vm
                                    .formatDBDateToMoment(
                                      _vm.dashboardData.jobStart
                                    )
                                    .valueOf(),
                                  jobEnd: _vm.dashboardData.jobEnd
                                    ? _vm
                                        .formatDBDateToMoment(
                                          _vm.dashboardData.jobEnd
                                        )
                                        .valueOf()
                                    : null,
                                  onBrushMoved: _vm.onScrubberBrushMoved,
                                  onBrushMoving: _vm.onScrubberBrushMoving,
                                  onIsCompactChanged:
                                    _vm.onScrubberIsCompactChanged,
                                  propTSIOptions:
                                    _vm.jsonLocalStorage.chartTSIOptions,
                                  defaultResolutionHours:
                                    _vm.chartConfiguration.resolutionZoomHours,
                                  defaultZoomWindowHours:
                                    _vm.defaultZoomWindowHrs,
                                  dashboardItem: _vm.dashboardItem,
                                  enabled: !_vm.isDownloadingData,
                                  jobHourOffset: _vm.jobHourOffset,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.$refs.lineChart != null
            ? _c("chart-export-component", {
                ref: "chartExport",
                attrs: {
                  jobNumber: _vm.jobNumber,
                  jobHourOffset: _vm.jobHourOffset,
                  jobStart: _vm.formatMomentToJobLocalTime(
                    _vm.dashboardData.jobStart
                  ),
                  jobStartUTC: _vm.dashboardData.jobStart,
                  rightSideMsOffset: this.rightSideMsOffset,
                  tagsArray: _vm.usedTagsDisplay,
                  minDate: _vm.formatMomentToJobLocalTime(_vm.currentChartMin),
                  maxDate: _vm.formatMomentToJobLocalTime(_vm.currentChartMax),
                  stageTimeData: _vm.wellStageIntervalsByActivityType,
                  wells: _vm.wells,
                  isFetching: _vm.isExportModalFetchingData,
                },
                on: {
                  dataFetchStart: function ($event) {
                    _vm.isExportModalFetchingData = true
                  },
                  dataFetchComplete: function ($event) {
                    _vm.isExportModalFetchingData = false
                  },
                },
              })
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-ruler-combined header-icon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fas fa-bars" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }