var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative", attrs: { id: "VideoPlayer" } },
    [
      _c(
        "div",
        {
          staticClass: "position-relative",
          class: { "show-controls": _vm.showControls },
          attrs: { id: "main-container" },
        },
        [
          _vm.showControls && !(_vm.currentVod == null && _vm.vodMode == "vod")
            ? _c("VideoScrubberV2", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.disableScrub,
                    expression: "!disableScrub",
                  },
                ],
                staticStyle: { "margin-bottom": "10px" },
                attrs: {
                  user: _vm.user,
                  workOrderId: _vm.workOrderId,
                  cameraList: _vm.device.cameras,
                  vodList: _vm.vods,
                  alertList: _vm.alerts,
                  clipList: _vm.clips,
                  hourOffset: _vm.job?.hourOffset,
                  permissions: _vm.permissions,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "video-player-wrapper",
              style: !_vm.videoOnly ? "height: calc(100% - 176px);" : null,
              on: {
                click: function ($event) {
                  return _vm.$emit("clicked")
                },
              },
            },
            [
              _vm.currentVod == null && _vm.vodMode == "vod"
                ? _c("div", { staticClass: "error-message" }, [
                    _vm._v(
                      "\n                No video from this time\n            "
                    ),
                  ])
                : _vm.currentState == "loading"
                ? _c(
                    "div",
                    { staticClass: "player-spinner" },
                    [_c("b-spinner", { attrs: { label: "Loading..." } })],
                    1
                  )
                : _vm.playerError
                ? _c("div", { staticClass: "error-message" }, [
                    _vm._v(
                      "\n                Video playback error. Retrying...\n            "
                    ),
                  ])
                : _vm.errorMessage
                ? _c("div", { staticClass: "error-message" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.errorMessage) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showVideoPlayer,
                      expression: "showVideoPlayer",
                    },
                  ],
                  ref: "videoPlayerDisplay",
                  style: "aspect-ratio: " + _vm.aspectRatio,
                  attrs: { id: "video-player-viewer" },
                },
                [
                  _vm.showControls && _vm.drawingMode
                    ? _c("canvas", {
                        style:
                          "height: " +
                          _vm.canvasHeight +
                          "px; width: " +
                          _vm.canvasWidth +
                          "px;",
                        attrs: {
                          id: "redzone-canvas",
                          height: _vm.canvasHeight,
                          width: _vm.canvasWidth,
                        },
                        on: { click: _vm.onCanvasClicked },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "video-player",
                    attrs: { id: _vm.playerId },
                  }),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showControls
        ? _c("camera-control", {
            ref: "CameraControl",
            attrs: {
              "work-order-id": _vm.workOrderId,
              device: _vm.device,
              permissions: _vm.permissions,
              camera: _vm.selectedCamera,
              cameraDetails: _vm.cameraDetails,
              displayedRedZone: _vm.displayedRedZone,
              drawingMode: _vm.drawingMode,
              control: _vm.control,
              vodSelected: _vm.vodSelected,
              vodDropdownItems: _vm.vodDropdownItems,
            },
            on: {
              "update:cameraDetails": function ($event) {
                _vm.cameraDetails = $event
              },
              "update:camera-details": function ($event) {
                _vm.cameraDetails = $event
              },
              "update:drawingMode": function ($event) {
                _vm.drawingMode = $event
              },
              "update:drawing-mode": function ($event) {
                _vm.drawingMode = $event
              },
              "update:vodSelected": function ($event) {
                _vm.vodSelected = $event
              },
              "update:vod-selected": function ($event) {
                _vm.vodSelected = $event
              },
              saveRedzone: function ($event) {
                return _vm.saveRedzone()
              },
              deleteRedzone: function ($event) {
                return _vm.deleteRedzone()
              },
              clearCanvas: function ($event) {
                return _vm.clearCanvas()
              },
              drawCanvas: function ($event) {
                return _vm.setDisplayedRedzone()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }