var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c("modal", {
        staticStyle: {
          width: "40% !important",
          "max-width": "30em !important",
          "max-height": "400px",
        },
        attrs: { title: "Filter", showModal: _vm.deleteModal },
        on: {
          close: function ($event) {
            _vm.deleteModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", [
                  _vm._v(
                    "Select the date and time range you wish to filter for"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex my-2",
                    staticStyle: {
                      border: "1px solid #626770",
                      "border-radius": "5px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "mr-2",
                        staticStyle: {
                          "margin-left": "15px",
                          "margin-right": "30px",
                          padding: "10px",
                        },
                      },
                      [
                        _c("div", [_vm._v("Start Date")]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "date-select pr-1",
                          staticStyle: {
                            width: "110% !important",
                            padding: "10px",
                          },
                          attrs: {
                            type: "datetime-local",
                            placeholder: "Select start date and time",
                          },
                          domProps: { value: _vm.filterStartTime },
                          on: {
                            input: (event) =>
                              (_vm.filterStartTime = event.target.value),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "10px",
                          "margin-right": "30px",
                        },
                      },
                      [
                        _c("div", [_vm._v("End Date")]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "date-select pr-1",
                          staticStyle: {
                            width: "110% !important",
                            padding: "10px",
                          },
                          attrs: {
                            type: "datetime-local",
                            placeholder: "Select end date and time",
                          },
                          domProps: { value: _vm.filterEndTime },
                          on: {
                            input: (event) =>
                              (_vm.filterEndTime = event.target.value),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", [_vm._v("Select Cameras")]),
                _vm._v(" "),
                _c("multiselect", {
                  staticStyle: {
                    background: "#1d2025 !important",
                    "background-color": "#1d2025 !important",
                  },
                  attrs: {
                    options: _vm.cameras,
                    label: "name",
                    multiple: true,
                  },
                  model: {
                    value: _vm.filterCams,
                    callback: function ($$v) {
                      _vm.filterCams = $$v
                    },
                    expression: "filterCams",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.modalShow,
              expression: "modalShow",
            },
          ],
        },
        [
          _c("div", { staticClass: "modal-mask" }, [
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "modal-header d-flex justify-content-between align-items-center",
                  },
                  [
                    _c("p", { staticClass: "my-0" }, [
                      _vm._v(
                        "Preview  " +
                          _vm._s(_vm.currentClip.cameraName) +
                          " - " +
                          _vm._s(_vm.currentClip.description)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("b-icon-x", {
                      staticClass: "h4 my-0 clickable",
                      on: { click: _vm.hideModal },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "video",
                    {
                      key: _vm.currentClip.url,
                      attrs: { width: "550", controls: "", autoplay: "" },
                    },
                    [
                      _c("source", {
                        attrs: { src: _vm.currentClip.url, type: "video/mp4" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.currentClip.type == "Manual"
                    ? _c(
                        "div",
                        { staticClass: "badge badge-pill badge-clip my-2" },
                        [_vm._v("Clip ")]
                      )
                    : _c(
                        "div",
                        { staticClass: "badge badge-pill badge-alert my-2" },
                        [_vm._v(" Redzone")]
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center",
                    },
                    [
                      _c("h6", [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.currentClip.cameraName) +
                              " - " +
                              _vm._s(_vm.currentClip.description)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("iws-button", {
                        attrs: {
                          type: "danger",
                          prependIcon: "fas fa-trash-alt",
                          text: "Delete",
                          click: () => _vm.deleteClip(_vm.currentClip),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _c("b", [_vm._v("Camera:")]),
                    _vm._v(" " + _vm._s(_vm.currentClip.cameraName) + " "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Date:")]),
                    _vm._v(" " + _vm._s(_vm.getDate(_vm.currentClip)) + " "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Duration:")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.getDuration(_vm.currentClip)) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("camera-navigation", {
        staticStyle: { width: "calc(100vw - 20px)" },
        attrs: {
          user: _vm.user,
          device: _vm.device,
          "work-order-id": _vm.workOrderId,
          isClips: "",
          "is-home": "",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c("div", { staticClass: "justify-content-between d-flex" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.groupBy,
                    expression: "groupBy",
                  },
                ],
                staticClass: "form-control mr-sm-3",
                staticStyle: {
                  width: "270px",
                  "background-color": "#1d2025",
                  border: "solid 1px #626973",
                  color: "#f0f0f0",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.groupBy = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "midnight" } }, [
                  _vm._v(" Group by : Midnight to Midnight"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "shift" } }, [
                  _vm._v(" Group by : Shift to Shift"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchInput,
                  expression: "searchInput",
                },
              ],
              staticClass: "form-control clip-search",
              attrs: {
                type: "text",
                placeholder: "Search",
                "aria-label": "Search",
              },
              domProps: { value: _vm.searchInput },
              on: {
                keyup: function ($event) {
                  return _vm.searchFilterClips()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchInput = $event.target.value
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.sortedClips == null || _vm.isloading
        ? _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  height: "100%",
                  width: "auto",
                  "justify-content": "center",
                  "margin-top": "130px",
                },
              },
              [
                _c("b-spinner", {
                  staticClass: "align-middle",
                  attrs: { large: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._l(_vm.sortedClipKeysArray, function (shift) {
            return _c("div", { staticClass: "shift" }, [
              _c(
                "div",
                {
                  staticClass: "shift-title d-flex align-items-center",
                  staticStyle: { "flex-direction": "row" },
                },
                [
                  _vm._v(
                    "\n                 " +
                      _vm._s(
                        _vm.groupBy == "shift" ? `${shift} Shift` : shift
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "clipSet" },
                _vm._l(_vm.retreiveClips(shift), function (clip) {
                  return _c(
                    "div",
                    {
                      staticClass: "card clipCard clickable position-relative",
                      on: {
                        click: function ($event) {
                          return _vm.openModal(clip)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "justify-content-center align-items-center d-flex",
                        },
                        [
                          _c("img", {
                            staticClass: "clipImage",
                            attrs: {
                              src: clip.thumbnailURL,
                              alt: "Card image cap",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body" }, [
                        _c("h6", { staticClass: "card-title" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(clip.cameraName) +
                                " - " +
                                _vm._s(clip.description)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "clip-time" }, [
                          _vm._v(
                            "Taken at: " + _vm._s(_vm.getDate(clip, false))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "clip-duration" }, [
                          _vm._v(" Duration: " + _vm._s(_vm.getDuration(clip))),
                        ]),
                        _vm._v(" "),
                        clip.type == "Manual"
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-pill badge-clip position-absolute mt-0",
                                staticStyle: { bottom: "5px", left: "5px" },
                              },
                              [_vm._v("Clip ")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-pill badge-alert position-absolute bottom-0 left-0",
                                staticStyle: { bottom: "5px", left: "5px" },
                              },
                              [_vm._v("Redzone ")]
                            ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ])
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }