<!--
    * Component Description
        A simple, re-usable button wrapper that applies IWS styling and other small features
        See props for an explanation of all supported functions

    * Side Effects
    Will emit 4 signals
        click           <- triggers upon click of the button
        press           <- triggers when the user begins pressing the button (Useful for press and hold features)
        release         <- triggers when the user finishes pressing the button (Useful for press and hold features)
        disabled-click  <- triggers when the user clicks and the button is disabled (Useful to explain why the button is disabled)

    * Slots
        #text           <- target button body (more customizable option to props)
        #loading        <- target loading stage (Useful to give more detailed explanation of what is running)

    * Example Usage
        <iws-button
            text="Save"
            type="primary"
            :click="onSaveClick"    <- Can listen to the @click event or pass a function reference. (see click prop for more explanation)
        />

        For more complicated bodies, the #text slot can be used
        <iws-button type="primary" @click="openSettings">
            <template #text>
                <i class="fas fa-cog"></i> Settings
            </template>
        </iws-button>
-->
<template>
    <button
        :id="id"
        :key="id"
        :class="computedClass"
        @click="onClick"
        @pointerdown="onPress"
        @pointerup="onRelease"
        @pointerleave="onPointerLeave"
    >
        <slot name="text">
            <template v-if="!!prependIcon">
                <i :class="prependIcon" />
            </template>
            <template v-if="!!text">
                {{ typeof text == 'function' ? text() : text }}
            </template>
            <template v-if="!!icon || !!appendIcon">
                <i :class="icon || appendIcon" />
            </template>
        </slot>

        <div v-if="allowLoadingState && loading" :class="`spinner-border ${type}-text-color`" role="status">
            <slot name="loading">
                <span class="sr-only">Loading...</span>
            </slot>
        </div>
    </button>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
    props: {
        id: {
            type: String,
            default: `default_id_${uuidv4()}`
        },

        // The button text
        text: {
            type: String | Number | Function
        },
        // The fontAwesome icon (will appear to the right of text if used together)
        // Icons: https://fontawesome.com/v5/search?o=r&m=free
        // Example Use: icon="fas fa-cog"
        icon: {
            type: String
        },
        // Prepend appears at the LEFT most side of the button content (allows you to show icon before text when used together)
        prependIcon: {
            type: String
        },
        // Append appears at the RIGHT most side of the button content (allows you to show icon after text when used together)
        appendIcon: {
            type: String
        },

        // Pass function reference to handle onClick
        // When the function returns a promise (i.e. api call) the button will enter the loading state until resolved (this shows a spinner and does not allow other clicks)
        // Should not be used in conjunction with @click
        click: {
            type: Function
        },

        disabled: {
            type: Boolean,
            default: false
        },
        // Can be used to hide loading state (spinner)
        allowLoadingState: {
            type: Boolean,
            default: true
        },

        // Button type / styling
        // Examples: primary, secondary, success, danger, warning, info, light, dark, link
        // All types can be used as: 'outline-<type>' to show as outline button
        type: {
            type: String,
            default: 'primary'
        },
        // Button size
        // Example: sm, small, md, medium, lg, large
        // Supports either short form or full wording
        size: {
            type: String,
            default: 'medium'
        },
        // Will show button as circle
        // Example: rounded, :rounded="true"
        // Typically used for icon buttons
        rounded: {
            type: Boolean,
            default: false
        },
        // Will show button as pill (oval)
        // Example: pill, :pill="true"
        // Typically used for text buttons with very round edges
        pill: {
            type: Boolean,
            default: false
        }
    },
    
    data: () => ({
        loading: false,
        isButtonPressed: false,
    }),

    computed: {
        // Size is an optional prop
        // Bootstrap likes to use appreviations like 'sm' instead of 'small' but I always forget that, so we support both
        mappedSize() {
            if (this.size) {
                let mappedSize = this.size;

                if (this.size == 'small' || this.size == 'sm') {
                    mappedSize = 'sm';
                } else if (this.size == 'medium' || this.size == 'md') {
                    mappedSize = 'md';
                } else if (this.size == 'large' || this.size == 'lg') {
                    mappedSize = 'lg';
                }

                return `btn-${mappedSize}`
            }

            return '';
        },

        computedClass() {
            let str = `btn btn-${this.type} clickable ${this.mappedSize}`

            if (this.loading || this.disabled)
                str += ' disabled';

            if (!!this.rounded)
                str += ' rounded';

            if (!!this.pill)
                str += ' pill';

            if (this.loading)
                str += ' loading';

            return str;
        }
    },

    methods: {
        async onClick($event) {
            // When the button is disabled, we allow click so we can emit a disabled message but do not allow the function to go further
            // In many cases, we want the button to show as disabled and not allow the normal function, but perform another function (explaining why it's disabled)
            if (this.disabled || this.loading) {
                $event.preventDefault();
                this.$emit('disabledClick', $event);
                this.$emit('disabled-click', $event);
                return;
            }

            this.$emit('click', $event);

            if (!this.disabled && !!this.click) {
                try {
                    this.loading = true;
                    var res = await this.click($event);
                } finally {
                    this.loading = false;
                    return res;
                }
            }
        },

        onPress($event) {
            if (!this.disabled) {
                this.isButtonPressed = true;
                this.$emit('press', $event);
            }
        },
        onRelease($event) {
            if (!this.disabled) {
                this.isButtonPressed = false;
                this.$emit('release', $event);
            }
        },
        onPointerLeave($event) {
            if (this.isButtonPressed) {
                this.isButtonPressed = false;
                this.$emit('release', $event);
            }
        }
    }
};
</script>

<style scoped>
    .btn.rounded {
        border-radius: 50% !important;
    }
    .btn.pill {
        border-radius: 30px !important;
    }
    .btn.loading {
        cursor: wait;
    }
    .btn.disabled:focus {
        box-shadow: none !important;
    }

    .btn.btn-link {
        color: var(--primary-text-color);
    }
    .btn.btn-link:hover {
        color: var(--secondary-text-color);
    }

    .btn-text-primary,
    .btn-text-secondary,
    .btn-text-light,
    .btn-text-danger,
    .btn-text-warning {
        padding: 0px 5px;
    }
    .btn-text-primary:focus,
    .btn-text-secondary:focus,
    .btn-text-light:focus,
    .btn-text-danger:focus,
    .btn-text-warning:focus {
        box-shadow: none !important;
    }
    .btn-text-primary {
        color: var(--primary-color);
    }
    .btn-text-secondary {
        color: var(--secondary-color);
    }
    .btn-text-light {
        color: white;
    }
    .btn-text-danger {
        color: var(--danger-color);
    }
    .btn-text-warning {
        color: var(--warning-color);
    }

    .btn-text-primary:hover,
    .btn-text-secondary:hover,
    .btn-text-danger:hover {
        color: white;
    }
    .btn-text-light:hover {
        color: var(--secondary-text-color);
    }
    .btn-text-warning:hover {
        color: black;
    }

    /* Loading spinners should match button text colours but on some nonoutline button styles it becomes hard to read */
    .btn.btn-danger .spinner-border.danger-text-color {
        color: white !important;
    }
</style>
