import { render, staticRenderFns } from "./AddWellComponent.vue?vue&type=template&id=54afb419&"
import script from "./AddWellComponent.vue?vue&type=script&lang=js&"
export * from "./AddWellComponent.vue?vue&type=script&lang=js&"
import style0 from "./AddWellComponent.vue?vue&type=style&index=0&id=54afb419&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/9878/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54afb419')) {
      api.createRecord('54afb419', component.options)
    } else {
      api.reload('54afb419', component.options)
    }
    module.hot.accept("./AddWellComponent.vue?vue&type=template&id=54afb419&", function () {
      api.rerender('54afb419', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/AddWellComponent.vue"
export default component.exports