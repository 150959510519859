<template>
    <div style="padding: 20px;"> 
            <!-- Filter Modal -->
            <modal title="Filter"  :showModal="deleteModal"  @close="deleteModal = false" style="width: 40% !important; max-width: 30em !important; max-height: 400px;">
          
                <template #content style="overflow: visible !important;  max-height: 200px !important; width: 40% !important; max-width: 30em !important; max-height: 400px;">
                    <div>Select the date and time range you wish to filter for</div>
                    <div class="d-flex my-2" style="border: 1px solid #626770; border-radius: 5px;">
                        
                        <div class="mr-2" style="margin-left:15px; margin-right:30px;   padding: 10px;">
                            <div>Start Date</div>
                            <input type="datetime-local" class="date-select pr-1" placeholder="Select start date and time"
                                :value="filterStartTime"
                                @input="event => filterStartTime = event.target.value"
                                style="width: 110% !important;padding: 10px"

                            />
                        </div>
                        <div  style="padding: 10px; margin-right: 30px;">
                            <div>End Date</div>
                            <input type="datetime-local" class="date-select pr-1"  placeholder="Select end date and time"
                                :value="filterEndTime"
                                @input="event => filterEndTime = event.target.value"
                                style="width: 110% !important;padding: 10px"
                                />
                        </div>
                    </div>
                    <div>Select Cameras</div>
                

                    <multiselect  v-model="filterCams" :options="cameras" label="name" :multiple="true" style="background: #1d2025 !important; background-color: #1d2025 !important;"/>
                </template>
            </modal>
            
            <!-- Clip Modal-->
            <div v-show="modalShow">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container">
                            <div class="modal-header d-flex justify-content-between align-items-center">
                                <p class="my-0">Preview  {{ currentClip.cameraName}} - {{ currentClip.description }}</p>
                                <b-icon-x class="h4 my-0 clickable"  @click="hideModal"></b-icon-x>
                            </div>
                            <div class="modal-body">
                                    <video :key="currentClip.url" width="550" controls autoplay >
                                        <source :src="currentClip.url" type="video/mp4">
                                    </video>
                                <div v-if="currentClip.type == 'Manual'" class="badge badge-pill badge-clip  my-2">Clip </div>
                                <div v-else class="badge badge-pill badge-alert my-2"> Redzone</div>
                                <div class=" d-flex justify-content-between align-items-center">
                                    <h6 > <b>{{ currentClip.cameraName}} - {{ currentClip.description }}</b></h6>
                                    <iws-button type="danger" prependIcon="fas fa-trash-alt" text="Delete" :click="() => deleteClip(currentClip)" />
                                </div>
                                <p>
                                    <b>Camera:</b> {{ currentClip.cameraName }} <br>
                                    <b>Date:</b> {{ getDate(currentClip) }} <br>
                                    <b>Duration:</b> {{ getDuration(currentClip) }}
                                </p>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

        <camera-navigation :user="user" :device="device" :work-order-id="workOrderId" isClips style="width: calc(100vw - 20px)" is-home />

        <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 20px">
            <div class="justify-content-between d-flex ">
                <!--NOTE:  when implementing Filters uncomment -->
                <!-- <button @click="deleteModal=true" class="btn  btn-outline-light d-inline-flex align-items-center mr-3"  style="background-color: #1d2025; border: solid 1px #626973; color: #f0f0f0;">
                    <i class="fas fa-filter pr-2"></i> 
                    <span>Filters</span>
                </button>  -->
                <select  v-model="groupBy"  class="form-control mr-sm-3" style="width:270px;   background-color: #1d2025; border: solid 1px #626973; color: #f0f0f0;">
                    <option value ='midnight'> Group by : Midnight to Midnight</option>
                    <option value ='shift'> Group by : Shift to Shift</option>
                </select>
                <input class="form-control clip-search"  type="text" v-model="searchInput"  @keyup="searchFilterClips()" placeholder="Search" aria-label="Search">
        
            
            </div>
        </div>

         <div v-if="sortedClips == null || isloading " >
            <div style="display:flex; height: 100%; width: auto; justify-content:center; margin-top: 130px;">
                <b-spinner large class="align-middle"></b-spinner>
            </div>
        </div>

        <div v-else v-for=" shift in sortedClipKeysArray" class="shift " >
                <div class="shift-title d-flex align-items-center "style="flex-direction: row;">
                     {{groupBy == 'shift' ? `${shift} Shift`: shift }}
                </div>
            
            <div class="clipSet">    
                <div v-for="clip in retreiveClips(shift)" @click='openModal(clip)' class="card clipCard clickable  position-relative">
                        <div class="justify-content-center  align-items-center d-flex">
                            <img class=" clipImage " :src="clip.thumbnailURL" alt="Card image cap" >

                        </div>
                        <div class="card-body">
                            <h6 class="card-title"><b>{{ clip.cameraName }} - {{ clip.description}}</b></h6>
                            <p class="clip-time">Taken at: {{getDate(clip,false)}}</p>
                            <p class="clip-duration"> Duration: {{getDuration(clip)}}</p>                           
                            
                            <span v-if="clip.type == 'Manual' " class="badge badge-pill badge-clip position-absolute mt-0 " style="bottom: 5px; left: 5px;">Clip </span>
                            <span v-else class="badge badge-pill badge-alert position-absolute bottom-0 left-0" style="bottom: 5px; left: 5px;" >Redzone </span>
                        </div>
                </div>

            </div>

        </div>


    </div>
</template>


<script>
import Multiselect from 'vue-multiselect'
import DateFunctions from '../../DateFunctions';
import GlobalFunctions from '../../GlobalFunctions.js';
const {  toast } = GlobalFunctions;
const { applyTimeOffset } = DateFunctions;
const Moment = require('moment');
export default{
    components: { Multiselect },
    props: [ 'user', 'device', 'workOrderId', 'devices','hourOffset'],

    data(){
        return{
            modalShow: false,
            allClips: [],   // [ClipData,ClipData,...]
            filteredClips: new Map(), // ClipID : ClipData
            sortedClips: new Map(), // time period: [ClipID,CLipID,ClipID] e.g. "1/27/2024" OR  "1/29/2024 AM" : [ClipID,CLipID,ClipID]
            filterModal: false,
            currentClip: {
                id: null,
                workOrderId: "",
                cameraName: "",
                startTime: "",
                endTime: "",
                durationInMinutes:null,
                description: "",
                type: "",
                url: "",
            },
            deleteModal: false,
            filterStartTime: null,
            filterEndTime: null,
            cameras: [

                {name: "Cam 1"},
                {name: "Cam 2"},
                {name: "Cam 3"},
            ],
            filterCams: null,
            isloading: true,
            groupBy: "midnight",
            searchInput: ""
        }
    },

    created(){
        this.getClips()
    },
   
    computed:{
        sortedClipKeysArray(){
            let arr = Array.from(this.sortedClips.keys());
            let timeFormat = this.groupBy == "shift"? "MM/DD/YYYY A": "MM/DD/YYYY"

            return arr.sort((x, y) => {
                const momentX = Moment(x, timeFormat);
                const momentY = Moment(y, timeFormat);
                // Calculate the differences between each date and the current date and time
                const diffX = Math.abs(momentX.diff(Moment(), 'milliseconds'));
                const diffY = Math.abs(momentY.diff(Moment(), 'milliseconds'));
                // Compare the differences between dates
                if (diffX != diffY) {
                    return diffX - diffY;
                } else { //since AM and PM are not registered as different times we must check ourselves 
                    return y.split(' ')[1] === "PM" ? 1: -1; 
                }
            });
        },
    },
    watch:{
        groupBy(){
            this.groupClips();
        }
    },

    methods:{

        getClips() {
            const apiUrl = `/cameras/jobClips/${this.workOrderId}`;
            axios(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                })
                .then(response => {
                    this.allClips = response.data;
                    this.searchFilterClips();
                    this.isloading = false;
                })
                .catch(error => {
                    console.error(error);
                });

        },

        searchFilterClips(){
                this.filteredClips.clear(); 
                for(let clip of this.allClips){
                    if (clip.description.toLowerCase().includes(this.searchInput.toLowerCase()) || clip.cameraName.toLowerCase().includes(this.searchInput.toLowerCase())){
                        this.filteredClips.set(clip.id, clip)
                    }
                }
                this.groupClips();
        },

        groupClips(){   
            let newSortedClips = new Map()
            
            for( const clip of [...this.filteredClips.values()]) {

                let clipMoment = Moment.utc(clip.startTime, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").utcOffset(this.hourOffset)
                let clipShift = clipMoment.format("MM/DD/YYYY");
                if(this.groupBy == "shift"){
                    clipShift = clipMoment.format("MM/DD/YYYY A");
                }
               
                if (newSortedClips.has(clipShift)){
                    newSortedClips.get(clipShift).push(clip.id)
                }else{
                    newSortedClips.set(clipShift, [clip.id] )
                }
            }
            this.sortedClips = new Map(newSortedClips);
        },


        retreiveClips(key){
            
            let shiftSet = []
            for(let i of this.sortedClips.get(key)){
                shiftSet.push(this.filteredClips.get(i))
            }
            return shiftSet;
        },
        deleteClip(clip) {
            
            const apiUrl = `/cameras/jobClips/${clip.workOrderId}/${clip.id}`;
            
            return GlobalFunctions.iwsConfirm({
                title: 'Confirm Clip Deletion',
                body: 'Are you sure you want to delete the current clip?'
            }).then(_answer => {
                if (_answer) {
                    this.isloading = true;
                    return axios(apiUrl, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(response => {
                        this.allClips = response.data;
                        this.searchFilterClips();
                        this.isloading = false;
                        this.hideModal();
                        toast({
                                title: 'Clip deleted successfully',
                                variant: 'success'
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        this.isloading = false;
                        toast({
                                title: error,
                                variant: 'danger'
                        });
                    });
                }
            })
        },

        getDuration(clipData){

            const time = clipData.durationInMinutes
            let hours = Math.floor(time/60)
            let seconds = Math.round((time - Math.floor(time)) *  60)  // retrieve just the decimal form
            let minutes =  Math.floor(time) - (hours * 60) 

            let strHours = hours.toString().padStart(2,'0');
            let strMinutes = minutes.toString().padStart(2,'0');
            let strSeconds = seconds.toString().padStart(2,'0');

            return `${strHours}:${strMinutes}:${strSeconds}`
        },

        getDate(clipData, includeDate = true){
            if (includeDate){
                return applyTimeOffset(clipData.startTime,this.hourOffset,"MM-DD-YYYY [at] HH:mm:ss")
            }
            return applyTimeOffset(clipData.startTime,this.hourOffset,"HH:mm:ss")
        },


        openModal(clipData){
            this.currentClip = clipData;
            this.modalShow= true;
        },

        hideModal(){
            this.modalShow= false;
        }
    }
}

</script>

<style scoped>

.clip-search{
    background-color: #1d2025;
    border: solid 1px #626973;
    color: #ffffff;
}


.clipModal{
    background-color: #2e353d !important;
}

.shift-title{
    background-color: #2e353d;
    border-radius: 5px;
    padding: 10px;
    padding-left: 25px;
    font-family: Arial, Helvetica, sans-serif;
}

.clipSet {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: flex-start; 
    align-items: flex-start; 
    margin: 20px;
}
.breadcrumb{
    font-size: 17px !important;
}
.clipCard{
    width: 178px;
    height: 218px;
    background-color:#1d2939;
    border-radius: 5px;
    border: solid 1px #3b414b;
    margin: 6px;
}

.clipCard:hover{
    background-color: #002A58;
    box-shadow: rgba(0, 0, 0, 0.788) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.5s;
    border: solid 1px #a0a4aa;
    border-radius: 5px;

}
.clipImage{
    width: 176px;
    height: 100px;
    border-radius: 5px 5px 0px 0px ;
}
.clip-time{
    font-size: 13px !important;
    margin-bottom: 2px;
    font-variant-numeric: tabular-nums;
    font-family: Arial, Helvetica, sans-serif;
}
.clip-duration{
    font-size: 13px !important;
    margin-bottom: 2px;
    font-variant-numeric: tabular-nums;
    font-family: Arial, Helvetica, sans-serif;
}
.badge-clip{
    background-color: #166b4a;
    border: 1px solid #12b76a;
    padding-top: 2px;
    padding-bottom: 2px;
    font-family:Arial, Helvetica, sans-serif;
}
.badge-alert{
    background-color: #96241f;
    border: 1px solid #f04438;
    padding-top: 2px;
    padding-bottom: 2px;
    font-family:Arial, Helvetica, sans-serif;
}

.card-title{
    font-size: 13px !important;
    margin-bottom: 3px;
}
.card-body{
	padding: 5px;
}
.delete-button{
    background-color:#46292c ;
    color:white;
    padding-top: 1px;
    padding-bottom: 4px;
    border: 2px solid #91251e;
    padding-left: 10px;
    padding-right: 14px;
}


.modal-mask {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 23, 43, 0.788);
    display: flex;
    align-items: center;
    justify-content: center;
}
  


.modal-wrapper {
    width: 600px;
    background-color: #242a30;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
.date-select{
    padding: 8px;
    border-radius: 5px;
    color:white;
    background-color: #242a30;
    border: solid #626973 1px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: #ffffff;
    font-weight: bold;
}

video{
    border-radius: 5px;
}


</style>

