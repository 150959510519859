<template>
    <div class="video-scrubber-wrapper" style="position: relative">
        <canvas 
            id="scrubber-canvas"
            @mousedown="onTimelineMouseDown"
            @mousemove="onMouseMove"
            @mouseleave="onTimelineMouseLeave"
            @mouseup="onTimelineMouseUp"
            ref="scrubber"
            :height="scrubberCanvasHeight"
            :width="timelineCanvas.width"
        ></canvas>
        
        <div style="padding: 0px 5px 5px 5px">
            <b-row id="scrubber-control-row" no-gutters align-v="center">
                <template v-if="!clippingMode">
                    <iws-button type="outline-light" pill size="sm" class="mr-2" :click="onSkipBackButtonClicked">
                        <template #text>
                            <i class="fas fa-undo mr-1" />
                            30s
                        </template>
                    </iws-button>

                    <iws-button v-if="!paused" :click="onPauseClicked" icon="fas fa-pause" type="outline-light" size="sm" rounded />
                    <iws-button v-else :click="onPlayClicked" icon="fas fa-play" type="outline-light" size="sm" rounded />

                    <iws-button v-if="vodSelected?.label == 'Live' && !liveMode" text="Live" type="outline-primary" pill size="sm" class="ml-2" :click="onLiveButtonClicked" />
                </template>

                <span :class="{ 'shift-slider-time': clippingMode }" id="current-datetime">
                    {{ getReadableDayTime(sliderTime) }}
                </span>

                <span v-if="finishedManualClip">
                    Clip from: <b>{{ getReadableDayTime(manualClip?.start) }}</b> to <b>{{ getReadableDayTime(manualClip?.end) }}</b>
                </span>
                <i v-else-if="clippingMode">
                    Drag mouse on timeline to select section to clip
                </i>

                <!-- <iws-button type="outline-primary" size="sm" class="ml-4" pill :click="onToggleExpandedView">
                    <template #text>
                        <i class="fas fa-bars mr-1" />
                        {{ isExpandedView ? "Collapse View" : "Expanded View" }}
                    </template>
                </iws-button> -->

                <template v-if="clippingMode">
                    <iws-button text="Cancel Clip" type="outline-danger" pill size="sm" class="ml-2" :click="onClippingModeCancelClicked" />

                    <iws-button type="primary" pill size="sm" class="ml-2" :click="onClippingModeSaveClicked" :disabled="!finishedManualClip || (manualClip.start - manualClip.end == 0)">
                        <template #text>
                            <i class="far fa-save mr-1"></i>
                            Save Clip
                        </template>
                    </iws-button>
                </template>
                <iws-button v-else-if="permissions?.edit" type="outline-light" pill size="sm" :click="onClippingModeClicked">
                    <template #text>
                        <i class="fas fa-cut mr-1" />
                        Clip
                    </template>
                </iws-button>
            
                <iws-select
                    key="rangeSelector"
                    class="control-select"
                    :value.sync="rangeSelected"
                    :placeholder="`${rangeOptions[rangeSelected]?.label || 'Select Range'}`"
                    :options="rangeOptions"
                    display-name="label"
                    value-name="index"
                    @change="onRangeSelected"
                    :visibleOptions="7"
                />

                <iws-select v-if="!clippingMode"
                    key="vodSelector"
                    class="control-select"
                    :value.sync="vodSelected"
                    placeholder="Live"
                    :options="vodDropdownItems"
                    display-name="label"
                    @change="onJump"
                    :visibleOptions="7"
                />
            </b-row>
        </div>

        <iws-modal
            title="Save Clip" 

            :showModal="showSaveClipModal"
            max-width="500px"

            @close="onClickCancelSaveClip()"
            @secondary-action="onClickCancelSaveClip()"
            @primary-action="onClickSaveClip()"
        >
            <template #content>
                <div>
                    Select the camera to clip from, then click save to generate the clip. Clipping may take several minutes. You can refresh the Clip Browser to view the clip.
                </div>

                <b-row class="pt-3">
                    <b-col>
                        <div>
                            Clip Start
                        </div>
                        <b>
                            {{ manualClip.start == null ? "" : getReadableDayTime(manualClip.start) }}
                        </b>
                    </b-col>
                    <b-col>
                        <div>
                            Clip End
                        </div>
                        <b>
                            {{ manualClip.end == null ? "" : getReadableDayTime(manualClip.end) }}
                        </b>
                    </b-col>
                    <b-col>
                        <div>
                            Clip Length
                        </div>
                        <b>
                            {{ clipLength.mins }} mins, {{ clipLength.secs }} seconds
                        </b>
                    </b-col>
                </b-row>

                <div style="margin-top: 30px"   >
                    <iws-select
                        ref="clipCameraName"
                        label="Camera"
                        :value.sync="manualClip.camera"
                        placeholder="-- Please select a camera --"
                        :options="cameraList"
                        display-name="displayName"
                        :required="true"
                    />
                </div>
                <div style="margin-top: 30px"   >
                    <iws-select
                        ref="clipStreamConfig"
                        label="Stream"
                        :value.sync="manualClip.streamGlobalId"
                        placeholder="-- Please select a stream --"
                        :options="manualClip?.camera?.streamConfigurations ? manualClip.camera.streamConfigurations : []"
                        display-name="name"
                        value-name="globalId"
                        :required="true"
                    />
                </div>

                <div style="margin-top: 30px">
                    <iws-input 
                        ref="clipCameraDescription"
                        label="Clip Description"
                        placeholder="Type description for clip..."
                        :value.sync="manualClip.description"
                        type="textarea"
                        :rows="3"
                        required
                    />
                </div>
            </template>
        </iws-modal>
    </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, zeroPadding, toast, iwsConfirm } = GlobalFunctions;

import DateFunctions from '../../DateFunctions.js';
const { applyTimeOffset } = DateFunctions;

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css'

import { debounce } from "debounce";
import moment from 'moment';
import eventBus from "../../eventBus";

export default {
    name: 'VideoScrubber',
    components: {
        VueSlider
    },
    props: [
        'user',
        'workOrderId',
        'vodList', // List of Vods (from our database) for this camera
        'alertList',
        'clipList',
        'hourOffset',
        'permissions',
        'cameraList'
    ],

    data() {
        return {
            vodDropdownItems: [{
                label: 'Live',
                startSecond: 0
            }],
            rangeOptions: [
                { label: '1 Hour', value: 3600000, hoursInView: 1, numSectionsInView: 2, numSubsections: 3 },
                { label: '3 Hours', value: 10800000, hoursInView: 3, numSectionsInView: 3, numSubsections: 4 },
                { label: '6 Hours', value: 21600000, hoursInView: 6, numSectionsInView: 6, numSubsections: 4 },
                { label: '12 Hours', value: 43200000, hoursInView: 12, numSectionsInView: 12, numSubsections: 4 },
                { label: '1 Day', value: 86400000, hoursInView: 24, numSectionsInView: 12, numSubsections: 4 },
                { label: '2 Days', value: 172800000, hoursInView: 48, numSectionsInView: 12, numSubsections: 4 },
                { label: '1 Week', value: 604800000, hoursInView: 168, numSectionsInView: 14, numSubsections: 4 }
            ],
            rangeSelected: 2,
            sliderTime: this.getJobLiveTime(true),
            sliderIntervalId: null,
            updateDataInterval: null,
            sliderMinTime: this.getJobLiveTime().subtract(12, 'hours').valueOf(),
            sliderMaxTime: this.getJobLiveTime(true),
            paused: false,
            viewedStreams: [],
            scrubberTimelineHeight: 100,
            offscreenCanvas: new OffscreenCanvas(3650 * 3, 230),
            timelineCanvas: new OffscreenCanvas(3650 * (window.innerWidth < 2000 ? 2 : 3), 230),
            canvasBottom: 90,
            mouseData: null,
            handlingMouseMove: false,
            resizeObserver: null,
            scrubberCanvasHeight: 100,
            canvasPixelDensityFactor: 2,
            vodTimes: [],
            vodCameraConfigs: [],
            vodSelected: null,
            numDiffVodConfigs: 1,
            alerts: [],
            clippingMode: false,
            manualClip: {
                start: null,
                end: null,
                camera: null,
                description: "",
                type: "Manual",
                workOrderId: this.workOrderId
            },
            showSaveClipModal: false,
            finishedManualClip: false,
            showValidation: false,
            abbreviatedMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            previousTimelineStartDate: this.getJobLiveTime(),
            scrubberSettings: [],
            isExpandedView: false,
            vodHoverSegments: [],
            unifiedVods: [],
            selectedCameraForClipping: { streamConfigurations: [] },
        }
    },
    computed: {
        liveMode() {
            return this.sliderTime + 1000 > this.getJobLiveTime(true);
        },
        offsetFromLocal() {
            return moment().valueOf() - this.getJobLiveTime(true);
        },
        scrubberVODHeight() { // Used to control vod height
            return this.isExpandedView ? 60 : 60;
        },
        alertSize() {
            return this.isExpandedView ? 20 : 25;
        },
        vodSegmentsToShow() {
            return this.isExpandedView ? this.vodTimes : this.unifiedVods;
        },
        clipSegmentsToShow() {
            return this.clips?.filter(_clip => _clip.startTime > this.sliderMinTime || _clip.endtime < this.sliderMaxTime) || [];
        },
        clipLength() {
            if (!isFalsy(this.manualClip.start) && !isFalsy(this.manualClip.end)) {
                const diff = this.manualClip.end - this.manualClip.start;

                const secs = Math.floor(Math.abs(diff) / 1000);
                const mins = Math.floor(secs / 60);
                
                return { mins, secs: (secs - (mins*60)) }
            }

            return null;
        }
    },
    methods: {
        getSettingsKey(page) {
            const _name = this.user?.id || this.user?.email;
            return page + _name;
        },
        async setSettings(page, settingsObj) {
            localStorage.setItem(this.getSettingsKey(page), JSON.stringify(settingsObj));
        },
        async getSettings(page) {
            const _item = localStorage.getItem(this.getSettingsKey(page));
            return !isFalsy(_item) ? JSON.parse(_item) : {};
        },
        saveProperty(property, value) {
            this.scrubberSettings[property] = value;
            this.setSettings("scrubber", this.scrubberSettings);
        },

        async onRangeSelected() {
            this.saveProperty('rangeSelected', this.rangeSelected.toString());

            this.applyCurrentTimeRange(this.vodSelected);
            this.updateCanvas(true);
        },
        async onToggleExpandedView() {
            // NOTE: This feature is currently not being included (it does work but we have opted not to include it for now)
            // Leaving the code here to bring back later but fully disabling "for now"
            this.isExpandedView = !this.isExpandedView;
            this.scrubberSettings.expandedView = this.isExpandedView;
            await this.setSettings("scrubber", this.scrubberSettings);
            this.updateCanvas(true);
        },

        getReadableDayTime(time) {
            const date = new Date(time);
            return `${this.abbreviatedMonths[date.getMonth()]}. ${date.getDate()}, ${zeroPadding(date.getHours())}:${zeroPadding(date.getMinutes())}:${zeroPadding(date.getSeconds())}`;
        },
        getReadableTime(time) {
            let d = moment(time);
            if (d.invalid != null) // Only display time if it is valid
                return "";
            
            return `${zeroPadding(d.get('hour'))}:${zeroPadding(d.get('minute'))}`;
        },
        
        getModifiedTime(timestamp, inMilliseconds=false) {
            const _date = moment(applyTimeOffset(timestamp, this.hourOffset))
            return inMilliseconds ? _date.valueOf() : _date;
        },
        getUnixTime(timestamp) {
            return timestamp + this.offsetFromLocal;
        },
        getJobLiveTime(inMilliseconds=false) {
            const _date = moment(applyTimeOffset(Date.now(), this.hourOffset));
            return inMilliseconds ? _date.valueOf() : _date;
        },
        applyCurrentTimeRange(vod) {
            if (this.liveMode || isFalsy(vod) || vod?.label == 'Live') {
                this.sliderMaxTime = this.getJobLiveTime(true);
                this.sliderMinTime = this.sliderMaxTime - this.rangeOptions[this.rangeSelected].value;
            } else {
                this.sliderMinTime = vod.startSecond;
                this.sliderMaxTime = this.sliderMinTime + this.rangeOptions[this.rangeSelected].value;
            }
        },

        convertTimeToDisplayRegionX(time) {
            return ((time - this.sliderMinTime) / (this.sliderMaxTime - this.sliderMinTime)) * this.timelineCanvas.width;
        },
        convertRegionXToTime(x, inMilliseconds=false) {
            const time = (x / this.timelineCanvas.width) * (this.sliderMaxTime - this.sliderMinTime) * this.canvasPixelDensityFactor + this.sliderMinTime;
            return inMilliseconds ? moment(time).valueOf() : time;
        },

        async onLiveButtonClicked() {
            this.sliderTime = this.getJobLiveTime(true);
            this.onJump(this.vodDropdownItems[0]);
            this.updateCanvas(true);
        },
        onSkipBackButtonClicked() {
            this.sliderTime -= 30000;
            eventBus.$emit('scrubber-time-jump', this.getUnixTime(this.sliderTime));

            if (this.sliderTime < this.sliderMinTime) {
                this.sliderMinTime -= 30000
                this.sliderMaxTime -= 30000
            }

            this.updateCanvas();
        },


        onClippingModeClicked() {
            this.clippingMode = true;
            this.finishedManualClip = false;
        },
        onClippingModeCancelClicked() {
            iwsConfirm({
                title: 'Confirm Exit',
                body: 'Are you sure you want to exit clip mode?',
                width: '300px'
            }).then(_answer => _answer && this.onClickConfirmCancelClipping())
        },
        onClickConfirmCancelClipping() {
            this.clippingMode = false;
            this.manualClip.start = null;
            this.manualClip.end = null;
            this.finishedManualClip = false;
            this.onLiveButtonClicked(); //Go back to live after clipping cancelled
            this.updateCanvas();
        },
        onClippingModeSaveClicked() {
            this.showSaveClipModal = true;
        },
        onClickCancelSaveClip() {
            //Clear data
            this.showSaveClipModal = false;
            this.manualClip.camera = null;
            this.manualClip.description = "";
            this.showValidation = false;
        },
        async onClickSaveClip() {
            const clipCameraNameValidation = this.$refs.clipCameraName.validateInput();
            const clipCameraDescriptionValidation = this.$refs.clipCameraDescription.validateInput();
            if (!clipCameraNameValidation || !clipCameraDescriptionValidation)
                return;

            return axios.post(`/cameras/vodClips`, {
                ...this.manualClip,
                camera: this.manualClip.camera.cameraName,
                workOrderId: this.workOrderId,
                start: moment(this.getUnixTime(this.manualClip.start)).format(),
                end: moment(this.getUnixTime(this.manualClip.end)).format()
            }).then(_res => {
                if (!(_res?.data?.status >= 400)) {
                    //Clear data
                    this.clippingMode = false;
                    this.manualClip.start = null;
                    this.manualClip.end = null;
                    this.manualClip.camera = null;
                    this.manualClip.description = "";
                    this.manualClip.streamGlobalId = null
                    this.finishedManualClip = null;
                    this.showSaveClipModal = false;
                    this.onLiveButtonClicked(); //Go back to live after clipping saved
                }
            });
        },
        
        fetchData() {
            const addNewItemsToList = (ogList, newList, key) => {
                const existingItems = {};
                ogList.forEach(_item => existingItems[_item[key]] = true);

                newList.forEach(_item => {
                    if (!existingItems[_item[key]])
                        ogList.push(_item);
                })

                // If any items were added since the OG hashmap, alert the caller to trigger a re-render
                return ogList.length !== Object.keys(existingItems)?.length;
            }

            return Promise.all([
                axios.get(`/cameras/viewing/segments/${this.workOrderId}/dates`),
                axios.get(`/cameras/viewing/alerts/${this.workOrderId}/dates`),
                axios.get(`/cameras/vodClips/${this.workOrderId}`)
            ]).then(_res => {
                if (!isNullOrEmpty(_res[0]?.data) && addNewItemsToList(this.vodList, _res[0].data, 'signature'))
                    this.createVods();

                if (!isNullOrEmpty(_res[1]?.data) && addNewItemsToList(this.alertList, _res[1].data, 'id'))
                    this.createAlerts();

                if (!isNullOrEmpty(_res[2]?.data) && addNewItemsToList(this.clipList, _res[2].data, 'id'))
                    this.createClips();
            })
        },

        startInterval() {
            if (this.sliderIntervalId == null)
                this.sliderIntervalId = setInterval(this.handleTimeChange, 1000);

            if (this.updateDataInterval == null)
                this.updateDataInterval = setInterval(this.fetchData, 5000);
        },
        stopInterval(stopData=true) {
            if (this.sliderIntervalId != null) {
                clearInterval(this.sliderIntervalId);
                this.sliderIntervalId = null;
            }

            if (stopData && this.updateDataInterval != null) {
                clearInterval(this.updateDataInterval);
                this.updateDataInterval = null;
            }
        },

        async onJump(vod) {
            if (vod.label == 'Live') {
                this.sliderTime = this.getJobLiveTime(true);

                // Only re-align min/max timeline if the new sliderTime isn't on screen
                if (this.sliderTime < this.sliderMinTime || this.sliderTime > this.sliderMaxTime)
                    this.applyCurrentTimeRange();
            } else {
                this.sliderTime = vod.startSecond;
                this.applyCurrentTimeRange(vod);
            }

            this.startInterval();

            eventBus.$emit('scrubber-time-jump', this.getUnixTime(this.sliderTime));

            this.updateCanvas(true);
        },

        handleTimeChange() {
            const oldPos = this.sliderTime;
            this.sliderTime += 1000; // now tracked in milliseconds!

            //Update oven player to loop if in clipping mode
            if (this.clippingMode && this.finishedManualClip && this.sliderTime > this.manualClip.end) {
                this.sliderTime = this.manualClip.start;

                eventBus.$emit('scrubber-time-jump', this.getUnixTime(this.sliderTime));
            }

            //Remove skip to next vod functionality if in clipping mode and looping
            if (!this.clippingMode || !this.finishedManualClip) {
                // are we between VODs? (and not Live/close to it)
                if (Math.floor(this.sliderTime / 1000) % 10 == 0 && this.sliderTime + 1000 < this.getJobLiveTime(true)) {
                    const currentVod = this.vodTimes.find(v => v.startTime < this.sliderTime && (v.endTime == null || v.endTime == 0 || v.endTime > this.sliderTime));

                    if (typeof currentVod == 'undefined') {
                        let nextVodStart = Number.MAX_SAFE_INTEGER;
                        nextVodStart = this.vodTimes.reduce((a, b) => b.startTime > this.sliderTime ? Math.min(a, b.startTime) : a, nextVodStart);

                        // if the next VOD is over a minute out skip to it
                        if (nextVodStart - this.sliderTime > 60 * 1000 && nextVodStart < Number.MAX_SAFE_INTEGER)
                            this.sliderTime = nextVodStart;
                    }
                }
            }

            eventBus.$emit('scrubber-time-increment', this.sliderTime);

            // is the passage of time going to drag the cursor offscreen? keep up with it!
            if (oldPos <= this.sliderMaxTime && this.sliderTime > this.sliderMaxTime) {
                this.sliderMaxTime += 1000;
                this.sliderMinTime += 1000;
                this.updateCanvas(true);
            } else {
                //every 10s, if we can see live video on the timeline, do a full redraw to keep up. otherwise just update the canvas as normal
                let redraw = Math.floor(this.sliderTime / 1000) % 10 == 0 && this.getJobLiveTime(true) <= this.sliderMaxTime;
                this.updateCanvas(redraw);
            }
        },
        onPauseClicked() {
            this.paused = true;
            this.stopInterval(false);

            eventBus.$emit('scrubber-pause');
        },
        onPlayClicked() {
            this.paused = false;
            this.startInterval();
            eventBus.$emit('scrubber-play');
        },

        onPageFocus() {
            // when you return to the page, either jump to live (if in live mode) or make sure the players sync up with the slider (if in vod mode)
            // the players should be able to handle this automatically if we send the slider time
            if (!this.liveMode)
                eventBus.$emit('scrubber-time-jump', this.getUnixTime(this.sliderTime));
            this.updateCanvas(true);
        },
        onCameraSelected(cameraEvent) {
            // if previousCamera is not null, then remove the camera from the list
            // it is possible that there are multiple viewers of the same camera, so we only want to remove one instance of the camera from the list
            if (cameraEvent.previousCamera != null) {
                const camIndex = this.viewedStreams.findIndex(item => item == cameraEvent.previousCamera);
                if (camIndex >= 0)
                    this.viewedStreams.splice(camIndex, 1);
            }

            if (cameraEvent.newCamera != null) {
                this.viewedStreams.push(cameraEvent.newCamera);

                //Update the new camera to start at the correct time
                eventBus.$emit('scrubber-time-jump', this.getUnixTime(this.sliderTime));
            }
        },

        onTimelineMouseDown(event) {
            this.mouseData = { x: event.offsetX, y: event.offsetY, mouseMoving: false, mouseDown: true };

            //Set the clip start time and reset clip end
            if (this.clippingMode) {
                this.manualClip.start = this.convertRegionXToTime(event.offsetX, true);
                this.manualClip.end = null;
                this.finishedManualClip = false;
            } else {
                this.drawTimeline();
            }
        },
        async onMouseMove(event) {
            if (event.buttons == 0) {
                this.mouseData = { x: event.offsetX, y: event.offsetY, mouseMoving: true, mouseDown: false };
                await this.updateCanvas();
                return;
            }

            if (this.clippingMode) {
                this.onClippingMouseMove(event);
            } else {
                this.onTimelineMouseMove(event);
            }
        },
        async onClippingMouseMove(event) {
            //Check if should draw selection field
            if (this.mouseData != null && !this.handlingMouseMove && this.mouseData.mouseDown && this.manualClip.start !== null) {
                this.mouseData = { x: event.offsetX, y: event.offsetY, mouseMoving: false, mouseDown: true };
                this.handlingMouseMove = true;
                await this.updateCanvas();
                await this.drawClipSelectionField();
                this.handlingMouseMove = false;
            }
        },
        async onTimelineMouseMove(event) {
            // handle panning
            if (this.mouseData != null && !this.handlingMouseMove) {
                this.handlingMouseMove = true;

                let mouseDistance = this.mouseData.x - event.offsetX;
                if (mouseDistance > 10 || mouseDistance < -10) {
                    var movement = (mouseDistance / this.$refs.scrubber.width) * (this.sliderMaxTime - this.sliderMinTime) * this.canvasPixelDensityFactor;

                    this.sliderMinTime += movement;
                    this.sliderMaxTime += movement;
                    await this.updateCanvas(true);
                    this.mouseData = { x: event.offsetX, y: event.offsetY, mouseMoving: true, mouseDown: true };
                }
                this.handlingMouseMove = false;
            }
        },
        onTimelineMouseUp(event) {
            if (this.mouseData != null && !this.mouseData.mouseMoving && !this.clippingMode) {
                const clickTime = this.convertRegionXToTime(event.offsetX, true);
                let clickedOnAlert = false;

                for (let alert of this.alerts) {
                    let newX = this.convertTimeToDisplayRegionX(clickTime);
                    if (newX > alert.leftX && newX < alert.rightX && (this.mouseData.y+100) > alert.topY && (this.mouseData.y+100) < alert.bottomY) {
                        clickedOnAlert = true;
                        this.sliderTime = alert.timestamp - (1000 * 30); //Go back 30 seconds before timestamp
                        eventBus.$emit('scrubber-time-jump', this.getUnixTime(this.sliderTime));
                        this.updateCanvas();
                    }
                }

                if (!clickedOnAlert) {
                    // Cap the sliderTime at live time
                    this.sliderTime = Math.min(clickTime, this.getJobLiveTime(true));
                    eventBus.$emit('scrubber-time-jump', this.getUnixTime(this.sliderTime));
                    this.updateCanvas();
                }

            } else if (this.clippingMode && this.mouseData != null) { //Check if done with clip selection
                if (this.manualClip.end == null)
                    this.manualClip.end = this.convertRegionXToTime(event.offsetX, true);

                //Check if need to switch clip start and end
                if (this.manualClip.end < this.manualClip.start) {
                    const temp = this.manualClip.end;
                    this.manualClip.end = this.manualClip.start;
                    this.manualClip.start = temp;
                }

                this.finishedManualClip = true;

                //Jump to start of selected clip
                this.sliderTime = this.manualClip.start
                eventBus.$emit('scrubber-time-jump', this.getUnixTime(this.sliderTime));
                this.updateCanvas();
            }

            this.mouseData.mouseDown = false;
        },
        onTimelineMouseLeave(event) {
            this.mouseData = null;
            if (this.manualClip.end == null) //Cancel current clip selection if mouse goes off canvas
                this.manualClip.start = null;
            this.updateCanvas();
        },

        onResize() {
            if (this._isDestroyed || this._isBeingDestroyed)
                return;

            this.timelineCanvas.width = this.$el.offsetWidth * this.canvasPixelDensityFactor;

            // (don't change the timeline canvas width - we don't want to squish the scale, and we need to be able to draw 2 full days or it's possible to pan to an empty timeline)
            this.offscreenCanvas.width = this.$el.offsetWidth * 3 * this.canvasPixelDensityFactor;

            this.updateCanvas(true);
        },

        async drawClipSelectionField() {
            let context = this.$refs.scrubber.getContext("2d");

            //Calculate width of selection
            let width;
            if (this.manualClip.end === null) {
                width = (this.mouseData.x * this.canvasPixelDensityFactor) - this.convertTimeToDisplayRegionX(this.manualClip.start);
            } else {
                width = this.convertTimeToDisplayRegionX(this.manualClip.end) - this.convertTimeToDisplayRegionX(this.manualClip.start);
            }

            //Draw selection
            context.fillStyle = `rgba(250, 168, 16, 0.2)`;
            context.beginPath();
            context.fillRect(this.convertTimeToDisplayRegionX(this.manualClip.start), 0, width, this.canvasBottom);
            context.strokeStyle = "rgba(250, 168, 16, 0.8)";
            context.lineWidth = 2;
            context.strokeRect(this.convertTimeToDisplayRegionX(this.manualClip.start), 0, width, this.canvasBottom);
        },
        async updateCanvas(updateBackground = false) {
            if (updateBackground) {
                await this.drawTimeline();
                await this.drawVODs();
            }

            let w = this.$refs.scrubber.width;
            let h = this.$refs.scrubber.height;
            var context = this.$refs.scrubber.getContext("2d");
            context.clearRect(0, 0, w, h);

            context.drawImage(this.timelineCanvas, 0, 0);
            context.drawImage(this.offscreenCanvas, 0, 0);

            context.strokeStyle = "white";
            context.lineWidth = 2;
            context.beginPath();
            context.moveTo(0, this.scrubberTimelineHeight);
            context.lineTo(w, this.scrubberTimelineHeight);
            context.moveTo(0, this.scrubberTimelineHeight + this.scrubberVODHeight - 1);
            context.lineTo(w, this.scrubberTimelineHeight + this.scrubberVODHeight - 1);
            context.moveTo(0, this.scrubberTimelineHeight + (this.scrubberVODHeight * 2) - 1);
            context.lineTo(w, this.scrubberTimelineHeight + (this.scrubberVODHeight * 2) - 1);

            this.canvasBottom = this.scrubberTimelineHeight + (this.scrubberVODHeight * 2) - 1;

            //Update lines if in expanded mode
            if (this.isExpandedView) {
                for (let i = 1; i < this.numDiffVodConfigs + 1; i++) {
                    context.moveTo(0, this.scrubberTimelineHeight + (this.scrubberVODHeight * (1 + i)) - 1);
                    context.lineTo(w, this.scrubberTimelineHeight + (this.scrubberVODHeight * (1 + i)) - 1);
                    this.canvasBottom = this.scrubberTimelineHeight + (this.scrubberVODHeight * (1 + i)) - 1;
                }
            }

            context.stroke();

            await this.drawScrubber();

            // draw annotations
            context.fillStyle = "white";
            context.lineWidth = 1;
            if (this.isExpandedView) {
                context.font = "bold 26px Open Sans";
                if (this.cameraList.length != 0 && this.vodCameraConfigs.length != 0) {
                    for (let i = 0; i < this.numDiffVodConfigs; i++) {
                        var cameraName = this.cameraList.find(c => c.cameraConfigId == this.vodCameraConfigs[i]).name;
                        context.fillText(cameraName, 6 * this.canvasPixelDensityFactor, this.scrubberTimelineHeight + (this.scrubberVODHeight / 2) + (4 * this.canvasPixelDensityFactor) + (i * this.scrubberVODHeight));
                    }
                    context.fillText("Alerts", 6 * this.canvasPixelDensityFactor, this.scrubberTimelineHeight + (this.scrubberVODHeight / 2) + (4 * this.canvasPixelDensityFactor) + ((this.numDiffVodConfigs) * this.scrubberVODHeight));
                }
            } else {
                context.font = "bold 32px Open Sans";
                context.fillText("Cameras", 6 * this.canvasPixelDensityFactor, this.scrubberTimelineHeight + (this.scrubberVODHeight / 2) + (4 * this.canvasPixelDensityFactor));
                context.fillText("Alerts", 6 * this.canvasPixelDensityFactor, this.scrubberTimelineHeight + (this.scrubberVODHeight / 2) + (4 * this.canvasPixelDensityFactor) + this.scrubberVODHeight);
            }

            //Show clip selection field 
            if (this.clippingMode && this.manualClip.start != null) {
                await this.drawClipSelectionField();
            }

            await this.drawHoverData();
        },
        async drawVODs() {
            //Change height variables depending on number of configs/streams
            var numOfRows = this.isExpandedView ? this.numDiffVodConfigs + 1 : 2;
            this.offscreenCanvas.height = 230 * numOfRows;

            this.$refs.scrubber.height = 100 + (this.scrubberVODHeight * numOfRows);

            let w = this.offscreenCanvas.width;
            let h = this.offscreenCanvas.height;
            var context = this.offscreenCanvas.getContext("2d");
            context.clearRect(0, 0, w, h);
            let vodRect = { x: 0, y: this.scrubberTimelineHeight, height: this.scrubberVODHeight, width: w };

            let currConfig = this.vodTimes[0]?.cameraConfig;
            let currentYOffset = 0;

            for (let vod of this.vodSegmentsToShow) {
                //Change VOD color depending on type
                if (vod.type == null) {
                    context.fillStyle = `rgb(0, 144, 237)`;
                } else if (vod.type == "FieldSynced") {
                    context.fillStyle = `rgb(114, 189, 237)`;
                } else if (vod.type == "FieldPending") {
                    context.fillStyle = `rgb(241,208,0)`;
                } else {
                    continue;
                }

                //Check if need to update Y offset
                if (this.isExpandedView && vod.cameraConfig != currConfig) {
                    currConfig = vod.cameraConfig;
                    currentYOffset++;
                }

                let r = {
                    x: this.convertTimeToDisplayRegionX(vod.startTime),
                    y: vodRect.y + (currentYOffset * vodRect.height),
                    w: (vod.notEnded ? this.convertTimeToDisplayRegionX(this.getJobLiveTime(true)) : this.convertTimeToDisplayRegionX(vod.endTime)) - this.convertTimeToDisplayRegionX(vod.startTime),
                    h: vodRect.height
                };

                //Update information for hover
                vod.leftX = this.convertTimeToDisplayRegionX(vod.startTime);
                vod.rightX = vod.endTime == 0 ? this.convertTimeToDisplayRegionX(this.getJobLiveTime(true)) : this.convertTimeToDisplayRegionX(vod.endTime);
                vod.topY = r.y / 2;
                vod.bottomY = vod.topY + (r.h / 2);

                context.beginPath();
                context.rect(r.x, r.y, r.w, r.h, 20);
                context.fill();
            }

            //Draw alerts
            context.fillStyle = `rgb(255 ,0, 0)`;
            currentYOffset++;
            
            for (let alert of this.alerts) {
                let r = {
                    x: this.convertTimeToDisplayRegionX(alert.timestamp),
                    y: vodRect.y + (currentYOffset * vodRect.height),
                    w: this.convertTimeToDisplayRegionX(alert.timestamp+60000) - this.convertTimeToDisplayRegionX(alert.timestamp),
                    h: vodRect.height
                };

                //Update information for hover
                alert.leftX = this.convertTimeToDisplayRegionX(alert.timestamp);
                alert.rightX = this.convertTimeToDisplayRegionX(alert.timestamp+60000);
                alert.topY = r.y / 2;
                alert.bottomY = alert.topY + (r.h / 2);

                context.beginPath();
                context.rect(r.x, r.y, r.w, r.h, 20);
                context.fill();   
            }
            
            for (let clip of this.clipSegmentsToShow) {
                // Some clips are RedZone alerts, which should be shown in red
                context.fillStyle = clip.type == 'AIRedZone' ? 'rgb(255 ,0, 0)' : '#14ae5c';

                // (for some reason) new clips come in without end times but have duration so we can calculate when it should end
                if (isFalsy(clip.endTime) && !isFalsy(clip.durationInMinutes))
                    clip.endTime = moment(clip.startTime).valueOf() + (clip.durationInMinutes * 60000);

                let r = {
                    x: this.convertTimeToDisplayRegionX(clip.startTime),
                    y: vodRect.y + (currentYOffset * vodRect.height),
                    w: (clip.notEnded ? this.convertTimeToDisplayRegionX(this.getJobLiveTime(true)) : this.convertTimeToDisplayRegionX(clip.endTime)) - this.convertTimeToDisplayRegionX(clip.startTime),
                    h: vodRect.height
                };

                //Update information for hover
                clip.leftX = this.convertTimeToDisplayRegionX(clip.startTime);
                clip.rightX = clip.endTime == 0 ? this.convertTimeToDisplayRegionX(this.getJobLiveTime(true)) : this.convertTimeToDisplayRegionX(clip.endTime);
                clip.topY = r.y / 2;
                clip.bottomY = clip.topY + (r.h / 2);

                context.beginPath();
                context.rect(r.x, r.y, r.w, r.h, 20);
                context.fill();   
            }

        },
        async drawTimeline() {
            if (!(this.rangeSelected in this.rangeOptions))
                 return toast({
                    title: 'Invalid range selected',
                    body: `${this.rangeSelected} is not a valid range option`,
                    variant: 'danger'
                });
                
            let w = this.timelineCanvas.width;
            let h = this.timelineCanvas.height;

            const { hoursInView, numSectionsInView, numSubsections } = this.rangeOptions[this.rangeSelected];

            var context = this.timelineCanvas.getContext("2d");
            context.clearRect(0, 0, w, h);

            let timelineRect = { x: 0, y: 0, height: this.scrubberTimelineHeight, width: w };
            let sectionW = w / numSectionsInView;
            let subsectionW = sectionW / numSubsections;
            let hoursInSection = hoursInView / numSectionsInView;

            const _progressDate = new Date(this.sliderMinTime); // Tracker for displaying date at midnight
            let _lastDate = _progressDate.getDate(); // Whenever the day progresses, we show the date in the timeline
            const _date = new Date(this.sliderMinTime); // The actual min time
            const _rounded = new Date(this.sliderMinTime); // The min time rounded down to the nearest represented hour
            _rounded.setHours(_rounded.getHours() - (_rounded.getHours() % hoursInSection));
            _rounded.setMinutes(0);
            _rounded.setSeconds(0);

            const startTime = _rounded.getHours();

            // The slider starts at the hour for the sliderMinTime but should be shifted to account for how far into the hour sliderMinTime actually is
            const startPosition = ((_date.getTime() - _rounded.getTime()) / (this.sliderMaxTime - this.sliderMinTime)) * this.timelineCanvas.width;
            
            context.strokeStyle = "white"; // lines
            context.lineWidth = 1;
            context.fillStyle = "white"; // text

            // Render slighly more than we need for safety while scrolling
            for (let i = 0; i <= numSectionsInView+2; i++) {
                const hour = (startTime + ((hoursInSection * i) % 24)) % 24;
                const sectionStartX = timelineRect.x + (sectionW * i) + 4 - startPosition;
                const sectionStartTop = (timelineRect.y + 25) * this.canvasPixelDensityFactor;

                //Display day at beginning of day
                if (_progressDate.getDate() != _lastDate) {
                    _lastDate = _progressDate.getDate();
                    context.font = "26px Open Sans";
                    context.fillText(`${this.abbreviatedMonths[_progressDate.getMonth()]} ${_progressDate.getDate()}`, sectionStartX, (timelineRect.y + 10) * this.canvasPixelDensityFactor);
                    context.fillText(`12:00`, sectionStartX, sectionStartTop);
                } else if (hour % 1 == 0) {
                    context.font = "32px Open Sans";
                    
                    //Display correct hour labels
                    context.fillText(`${zeroPadding(hour)}:00`, sectionStartX, sectionStartTop);
                } else {
                    context.font = "32px Open Sans";

                    let cleanedHour = Math.floor(hour); 
                    let mins = (hour % 1) * 60;
                    context.fillText(`${zeroPadding(cleanedHour)}:${zeroPadding(mins)}`, sectionStartX, sectionStartTop);
                }

                context.beginPath();
                context.moveTo(sectionStartX, timelineRect.y + timelineRect.height - (20 * this.canvasPixelDensityFactor));
                context.lineTo(sectionStartX, timelineRect.y + timelineRect.height);
                context.stroke();
                for (let j = 1; j < numSubsections; j++) {
                    context.beginPath();
                    context.moveTo(sectionStartX + (j * subsectionW), timelineRect.y + timelineRect.height - (10 * this.canvasPixelDensityFactor));
                    context.lineTo(sectionStartX + (j * subsectionW), timelineRect.y + timelineRect.height);
                    context.stroke();
                }

                // increment this for the next loop
                if (hoursInSection % 1 == 0) {
                    _progressDate.setHours(_progressDate.getHours() + hoursInSection);
                } else {
                    _progressDate.setMinutes(_progressDate.getMinutes() + (hoursInSection % 1) * 60);
                }
            }
        },
        async drawScrubber() {
            let h = this.$refs.scrubber.height;
            var context = this.$refs.scrubber.getContext("2d");

            var position = this.convertTimeToDisplayRegionX(this.sliderTime);
            let circleWidth = 40 * this.canvasPixelDensityFactor;
            let circleHeight = 20 * this.canvasPixelDensityFactor;
            let stickWidth = 4 * this.canvasPixelDensityFactor;

            context.fillStyle = `#f24727`;
            context.beginPath();
            context.roundRect(position - (circleWidth / 2), 10, circleWidth, circleHeight, 20);
            context.fill();

            context.fillRect(position - (stickWidth / 2), 25, stickWidth, h);

            context.fillStyle = "white"; // text
            context.font = "26px Open Sans";
            context.fillText(`${this.liveMode ? 'Live' : this.getReadableTime(this.sliderTime)}`, position - (circleWidth / 2) + (this.liveMode ? 15 : 5), 40);
        },
        async drawHoverData() {
            if (this.mouseData != null) {
                let h = this.$refs.scrubber.height;
                const context = this.$refs.scrubber.getContext("2d");
                const time = this.convertRegionXToTime(this.mouseData.x, true);
                const hoverX = this.mouseData.x * this.canvasPixelDensityFactor;
                const hoverY = this.mouseData.y;

                const getMaxChars = (ctx, text, maxWidth) => {
                    let str = '';

                    for (const char of text) {
                        var width = ctx.measureText(str + char).width;
                        if (width < maxWidth) {
                            str += char;
                        } else {
                            return `${str}...`;
                        }
                    }

                    return str;
                }

                const drawHoverDetails = (boxHeightPixels, boxWidthPixels, title, subtitle, body) => {
                    const boxHeight = boxHeightPixels * this.canvasPixelDensityFactor;
                    const boxWidth = boxWidthPixels * this.canvasPixelDensityFactor;
                    const offset = 10 * this.canvasPixelDensityFactor;
                    const x = this.mouseData.x * this.canvasPixelDensityFactor;
                    const y = (this.mouseData.y * this.canvasPixelDensityFactor) - (3 * this.canvasPixelDensityFactor);
                    const padding = (5 * this.canvasPixelDensityFactor);

                    context.beginPath();
                    context.fillStyle = `rgb(50, 50, 50)`;
                    context.roundRect(x - (boxWidth / 2), y - boxHeight, boxWidth, boxHeight, 5);
                    context.fill();

                    context.translate(x, (y - offset));
                    context.rotate((45 * Math.PI) / 180); //Rotate 45 degrees
                    context.translate(-x, -(y - offset));
                    context.fillRect(x, (y - offset), 20, 20);
                    context.setTransform(1, 0, 0, 1, 0, 0); //Reset transform

                    context.font = "30px Open Sans";
                    context.fillStyle = "white";
                    context.fillText(title, x - (boxWidth*0.50) + padding, y + 20 - boxHeight + padding);
                    context.font = "22px Open Sans";
                    
                    if (!isFalsy(body)) {
                        context.fillText(getMaxChars(context, subtitle, boxWidth - (padding*2)-5), x - (boxWidth*0.50) + padding, y - (boxHeight/2) + (padding/2));
                        context.fillText(body, x - (boxWidth*0.50) + padding, y - 5 - padding);
                    } else {
                        context.fillText(subtitle, x - (boxWidth*0.50) + padding, y - 5 - padding);
                    }
                }

                // Draw dotted vertical line
                context.strokeStyle = `#197EFB`;
                context.beginPath();
                context.setLineDash([10, 15]);
                context.moveTo(hoverX, 0);
                context.lineTo(hoverX, h);
                context.stroke();
                context.setLineDash([]);

                // Draw top box
                context.fillStyle = `#197EFB`;
                const boxWidth = 45 * this.canvasPixelDensityFactor;
                const leftPosition = hoverX - (boxWidth / 2)
                context.roundRect(leftPosition, 0, boxWidth, 40, 5);
                context.fill();

                // Draw hover timestamp
                context.font = "32px Open Sans";
                context.fillStyle = "white";
                context.fillText(this.getReadableTime(time), leftPosition + (2 * this.canvasPixelDensityFactor), 30);
                
                // Check vod hover
                const vodsForHovering = this.vodSegmentsToShow; //vodTimes; //.filter(x => x.type != null && x.type != "Gap");
                for (let vod of vodsForHovering)
                    if (hoverX > vod.leftX && hoverX < vod.rightX && hoverY > vod.topY && hoverY < vod.bottomY)
                        return drawHoverDetails(50, 110, vod.cameraName, vod.type == 'FieldSynced' ? 'Synced from Field' : 'Syncing from Field', this.getReadableDayTime(vod.startTime));

                // Check alert hover
                for (let alert of this.alerts)
                    if (hoverX >= alert.leftX && hoverX <= alert.rightX && hoverY >= alert.topY && hoverY <= alert.bottomY)
                        return drawHoverDetails(40, 160, alert.type == 'AIRedzone' ? 'AI Redzone Violation' : alert.type, this.getReadableDayTime(alert.timestamp));

                // Check clip hover
                for (let clip of this.clipSegmentsToShow)
                    if (hoverX > clip.leftX && hoverX < clip.rightX && hoverY > clip.topY && hoverY < clip.bottomY)
                       return drawHoverDetails(50, 160, clip.cameraName, clip.description || clip.type, this.getReadableDayTime(clip.startTime));
            }
        },

        mergeVodsOfSameType(segments, addGaps) {
            var currSegmentIndex = 0;
            var mergedVods = [];
            mergedVods.push(segments[0]);

            for (var segment of segments) {
                //Check if the VOD intersects with the most recent VOD
                if (mergedVods[currSegmentIndex].startTime <= segment.endTime && segment.startTime <= mergedVods[currSegmentIndex].endTime) {
                    //Update current segment
                    mergedVods[currSegmentIndex].startTime = Math.min(mergedVods[currSegmentIndex].startTime, segment.startTime);
                    mergedVods[currSegmentIndex].endTime = Math.max(mergedVods[currSegmentIndex].endTime, segment.endTime);
                    mergedVods[currSegmentIndex].notEnded = segment.notEnded;

                } else if (segment.startTime > mergedVods[currSegmentIndex].endTime) {
                    if (addGaps) {
                        mergedVods.push({
                            startTime: mergedVods[currSegmentIndex].endTime,
                            endTime: segment.startTime,
                            type: "Gap"
                        });
                    }
                    //Start new segment
                    mergedVods.push(segment);
                }

                currSegmentIndex = mergedVods.length - 1;
            }

            return JSON.parse(JSON.stringify(mergedVods));
        },
        fillInGaps(originalArray, arrayToAdd) {
            for (var vod of arrayToAdd) {
                //Get new Gaps
                var currGaps = [...originalArray.filter(s => s.type == "Gap")];

                for (var gap of currGaps) {
                    if (gap.startTime <= vod.endTime && vod.startTime <= gap.endTime) {//Check if intersecting
                        var index = originalArray.indexOf(gap);
                        if (vod.startTime >= gap.startTime && vod.endTime <= gap.endTime) { //Case when vod is fully within gap
                            //Create new gap for after vod
                            originalArray.splice(index + 1, 0, {
                                startTime: vod.endTime,
                                endTime: gap.endTime,
                                type: "Gap"
                            });

                            //Add vod between gaps
                            originalArray.splice(index + 1, 0, vod);

                            //Update original gap
                            gap.endTime = vod.startTime;
                            originalArray[index] = gap;

                        } else if (vod.startTime < gap.startTime && vod.endTime > gap.endTime) { //Case when vod covers entire gap
                            //Change gap to be the vod
                            vod.startTime = gap.startTime;
                            vod.endTime = gap.endTime;
                            originalArray[index] = vod;
                        } else if (vod.endTime > gap.startTime && vod.endTime < gap.endTime) { //Case where vod is intersecting beginning of gap
                            //Add in update vod before gap
                            vod.startTime = gap.startTime;
                            originalArray.splice(index, 0, vod);

                            //Update original gap
                            gap.startTime = vod.endTime;
                            originalArray[index + 1] = gap;
                        } else if (vod.startTime > gap.startTime && vod.startTime < gap.endTime) { //Case where vod is intersecting ending of gap
                            //Add in update vod after gap
                            vod.endTime = gap.endTime;
                            originalArray.splice(index + 1, 0, vod);

                            //Update original gap
                            gap.endTime = vod.startTime;
                            originalArray[index] = gap;
                        }
                    }
                }
            }
        },

        createVods() {
            this.vodTimes = this.vodList.map(s => {
                return {
                    startTime: this.getModifiedTime(s.startTime, true),
                    endTime: s.endTime == null ? this.getJobLiveTime(true) : this.getModifiedTime(s.endTime, true),
                    notEnded: s.endTime == null ? true : false,
                    cameraConfig: s.cameraConfigurationId,
                    cameraName: s.cameraName,
                    type: s.type,
                    leftX: null,
                    rightX: null,
                    topY: null,
                    bottomY: null
                }
            });

            this.vodTimes.sort((a, b) => {
                return a.startTime - b.startTime;
            });

            //Make the biggest segments possible based for each type
            var vodListCopy = JSON.parse(JSON.stringify(this.vodTimes)); //Make deep copy of VOD list
            var cloudSegments = [];
            if (vodListCopy.filter(s => s.type == null).length > 0) {
                cloudSegments = this.mergeVodsOfSameType(vodListCopy.filter(s => s.type == null), true);
            }
            var fieldSyncSegments = [];
            if (vodListCopy.filter(s => s.type == "FieldSynced").length > 0) {
                fieldSyncSegments = this.mergeVodsOfSameType(vodListCopy.filter(s => s.type == "FieldSynced"), false);
            }
            var fieldPendingSegments = [];
            if (vodListCopy.filter(s => s.type == "FieldPending").length > 0) {
                fieldPendingSegments = this.mergeVodsOfSameType(vodListCopy.filter(s => s.type == "FieldPending"), false);
            }

            this.unifiedVods = cloudSegments;

            if (fieldSyncSegments.length > 0) {
                this.fillInGaps(this.unifiedVods, fieldSyncSegments);
            }
            if (fieldPendingSegments.length > 0) {
                this.fillInGaps(this.unifiedVods, fieldPendingSegments);
            }

            this.vodDropdownItems = [
                {
                    label: 'Live',
                    startSecond: 0
                }
            ];

            for (var time of this.vodTimes) {
                const dt = moment(time.startTime);
                const dateString = `${dt.get('month') + 1}/${dt.get('date')}/${dt.get('year')}`;
                const existingItem = this.vodDropdownItems.find(i => i.label == dateString);
                if (existingItem == null) {
                    this.vodDropdownItems.push({
                        label: dateString,
                        startSecond: dt.valueOf()
                    });
                } else if (existingItem.startSecond > dt.valueOf()) {
                    existingItem.startSecond = dt.valueOf();
                }
            }

            this.vodTimes.sort((a, b) => {
                return a.cameraConfig - b.cameraConfig;
            });

            //Check how many unique camera configuration ids are in VODs
            this.vodCameraConfigs = [...new Set(this.vodTimes.map(item => item.cameraConfig))];
            this.numDiffVodConfigs = this.vodCameraConfigs.length;

            this.updateCanvas(true);
        },
        createAlerts() {
            this.alerts = this.alertList.map(s => {
                return {
                    timestamp: this.getModifiedTime(s.timestamp, true),
                    cameraName: s.cameraName,
                    description: s.description,
                    type: s.type,
                    leftX: null,
                    rightX: null,
                    topY: 1,
                    bottomY: 1
                }
            });

            this.updateCanvas(true);
        },
        createClips() {
            this.clips = this.clipList.map(clip => {
                return {
                    ...clip,
                    startTime: this.getModifiedTime(clip.startTime, true),
                    endTime: this.getModifiedTime(clip.endTime, true),
                    leftX: null,
                    rightX: null,
                    topY: 1,
                    bottomY: 1
                }
            });

            this.updateCanvas(true);
        }
    },
    watch: {
        vodList: {
            immediate: true,
            deep: true,
            handler() {
                this.createVods();
            }
        },
        alertList: {
            immediate: true,
            deep: true,
            handler() {
                this.createAlerts();
            }
        },
        clipList: {
            immediate: true,
            deep: true,
            handler() {
                this.createClips();
            }
        },
        async workOrderId() {
            //Reset to main mode when workorder changes
            this.clippingMode = false;
            this.manualClip.start = null;
            this.manualClip.end = null;
            this.finishedManualClip = false;

            this.onLiveButtonClicked();
        }
    },
    async mounted() {
        //Get viewer settings
        this.scrubberSettings = await this.getSettings("scrubber");

        var updateSettings = false;
        if (this.scrubberSettings.rangeSelected === undefined) {
            this.scrubberSettings.rangeSelected = this.rangeSelected; //Set range setting to default
            updateSettings = true;
        } else {
            this.rangeSelected = Number(this.scrubberSettings.rangeSelected);
        }

        // Temporarily disabling this feature, leaving code for when/if we want to support it
        // if (this.scrubberSettings.expandedView === undefined) {
        //     this.scrubberSettings.expandedView = this.isExpandedView;
        //     updateSettings = true;
        // } else {
        //     this.isExpandedView = this.scrubberSettings.expandedView;
        // }

        if (updateSettings) {
            await this.setSettings("scrubber", this.scrubberSettings);
        }

        this.sliderTime = this.getJobLiveTime(true);
        this.vodSelected = this.vodDropdownItems[0];
        this.applyCurrentTimeRange();
        this.startInterval();

        document.defaultView.window.addEventListener('focus', this.onPageFocus);
        this.onResize();
        window.addEventListener('resize', () => debounce(this.onResize(), 500), true);
    },
    beforeDestroy() {
        this.stopInterval();

        eventBus.$off('video-player-camera-selected', this.onCameraSelected);

        document.defaultView.window.removeEventListener('focus', this.onPageFocus);
        window.removeEventListener('resize', this.onResize);
    },
    destroyed() {
        this.stopInterval();
    }
};
</script>

<style>
    .control-select {
        margin: 0px 0px 0px 0.5rem !important;
        width: 120px;
    }
    .control-select input {
        height: 31px !important;
        background-color: #101828 !important;
        border-radius: 30px;
        border-color: #007bff !important;
    }
    .control-select input::placeholder {
        color: #007bff !important;
    }
    #scrubber-canvas {
        height: 100%;
        width: 100%;
    }
</style>
<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Open+Sans');
    .video-scrubber-wrapper {
        background-color: rgba(16, 24, 40, .30);
        border-radius: 10px;
    }

    #scrubber-control-row {
        height: 45px;
        background-color: #101828;
        border-radius: 10px;
        padding: 0px 10px;
    }

    #current-datetime {
        margin: 0px auto 0px 10px;
        color: var(--secondary-text-color);
    }
</style>