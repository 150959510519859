import { render, staticRenderFns } from "./ProcessLogPage.vue?vue&type=template&id=365bec40&"
import script from "./ProcessLogPage.vue?vue&type=script&lang=js&"
export * from "./ProcessLogPage.vue?vue&type=script&lang=js&"
import style0 from "./ProcessLogPage.vue?vue&type=style&index=0&id=365bec40&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/9878/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('365bec40')) {
      api.createRecord('365bec40', component.options)
    } else {
      api.reload('365bec40', component.options)
    }
    module.hot.accept("./ProcessLogPage.vue?vue&type=template&id=365bec40&", function () {
      api.rerender('365bec40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/ProcessLogs/ProcessLogPage.vue"
export default component.exports