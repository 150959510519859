import { render, staticRenderFns } from "./ManageSystems.vue?vue&type=template&id=095331b8&"
import script from "./ManageSystems.vue?vue&type=script&lang=js&"
export * from "./ManageSystems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/9519/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('095331b8')) {
      api.createRecord('095331b8', component.options)
    } else {
      api.reload('095331b8', component.options)
    }
    module.hot.accept("./ManageSystems.vue?vue&type=template&id=095331b8&", function () {
      api.rerender('095331b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/ManageSystems.vue"
export default component.exports