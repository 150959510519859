<template>
<div>
    <iws-modal v-if="canManageDashboards"
        :showModal="showManagementModal"
        :title="`Dashboard Management for ${dashboardCustomer?.name}`"
        :secondaryButtonVisible="false"
        :primaryButtonVisible="false"
        @close="showManagementModal = false"
        max-width="80vw"
    >
        <template #content>
            <dashboard-management-component
                :job="job"
                :availableDisplayItems="availableDisplayItems"
                :selectedDashboard="selectedDashboard"
                :initDashboardManagementPage="initDashboardManagementPage"
                :setSelectedDashboard="setSelectedDashboard"
                :reorderDashboards="reorderDashboards"
                :orderedDashboardIds="orderedDashboards.ordered_dashboard_ids"
                :fleetDashboardIds="fleetDashboard.ordered_dashboard_ids"
                @close="showManagementModal = false"
            />
        </template>
    </iws-modal>

    <nav v-if="!_isNullOrEmpty(dashboards) || isDashboardsPage" id="DashboardNavbar" class="navbar navbar-expand-md shadow-sm navbar-light">
        <ul
            class="navbar-nav mr-auto flex-list full-width"
            :class="{ 'limit-width': isDashboardsPage }"
            ref="dashboardContainer"
        >
            <li class="nav-item text-nowrap" v-for='(dashboard, index) in dashboardsForMenu'
                :key='dashboard.id'
                :class="{
                    dashboard_navbar_selected: selectedDashboard.id == dashboard.id,
                    dashboard_navbar_item: selectedDashboard.id != dashboard.id
                }"
                :ref='`dashboard_name_${index}`'
            >
                <a class="nav-link" href="#"
                    v-on:click="setSelectedDashboard(dashboard.id, false, true)"
                    :class="{'font-weight-bold': dashboard.is_customer_default }">
                    {{ dashboard.name }}
                </a>
            </li>

            <li class="nav-item dropdown" ref="moreRef">
                <a  v-if="(!isMoreDropdownEmpty && !isDashboardsPage) || isDashboardsPage"
                    class="nav-link dropdown-toggle"
                    :class="{'selected-dropdown': selectedDashboardInDropdown}"
                    href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                >
                    {{ _isNullOrEmpty(dashboardsForMenu) ? 'Dashboards' : 'More' }}
                </a>

                <div class="dropdown-menu dropdown-menu-left bg-light" id="more-dropdown">
                    <template v-if="canManageDashboards">
                        <li class="nav-item dashboard_navbar_item text-nowrap">
                            <a  class="nav-link text-white" type="button" v-on:click="createNewDashboard()">
                                New Dashboard...
                            </a>
                        </li>

                        <li v-if="dashboardExists" class="nav-item dashboard_navbar_item text-nowrap">
                            <a  class="nav-link text-white" type="button" v-on:click="createNewDashboard(true)">
                                Copy This Dashboard...
                            </a>
                        </li>

                        <li class="nav-item dashboard_navbar_item text-nowrap">
                            <a class="nav-link text-white" type="button" @click="showManagementModal = true">
                               Dashboard Management...
                            </a>
                        </li>

                        <div class="dropdown-divider" v-if="!isMoreDropdownEmpty"></div>
                    </template>

                    <div v-if="!_isNullOrEmpty(dashboardsForDropDown?.default)" class="dropdown-section">
                        <b>
                            Default
                        </b>

                        <li v-for='dashboard in dashboardsForDropDown?.default' :key="dashboard.id"
                            class="nav-item text-nowrap"
                            :class="{
                                'dashboard_navbar_selected': selectedDashboard.id == dashboard.id,
                                'dashboard_navbar_item': selectedDashboard.id != dashboard.id
                            }"
                        >
                            <a class="nav-link text-white" @click="setSelectedDashboard(dashboard.id, false, true)" :key="dashboard.id" type="button">
                                {{ dashboard.name }}
                            </a>
                        </li>
                    </div>

                    <div v-if="!_isNullOrEmpty(dashboardsForDropDown?.nonDefault)" class="dropdown-section">
                        <b>
                            Non Default
                        </b>

                        <li v-for='dashboard in dashboardsForDropDown?.nonDefault' :key="dashboard.id"
                            class="nav-item text-nowrap"
                            :class="{
                                'dashboard_navbar_selected': selectedDashboard.id == dashboard.id,
                                'dashboard_navbar_item': selectedDashboard.id != dashboard.id
                            }"
                        >
                            <a class="nav-link text-white" @click="setSelectedDashboard(dashboard.id, false, true)" :key="dashboard.id" type="button">
                                {{ dashboard.name }}
                            </a>
                        </li>
                    </div>
                </div>
            </li>

            <li class="nav-item dropdown" v-if="!_isNullOrEmpty(dashboardsForDropDown?.shared)" ref="sharedRef">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown">
                    Shared with you
                </a>
                <div class="dropdown-menu bg-light drop-down-width">
                    <li v-for='dashboard in dashboardsForDropDown?.shared' :key="dashboard.id"
                        class="nav-item text-nowrap dashboard_navbar_selected"
                        :class="{
                            'dashboard_navbar_selected': selectedDashboard.id == dashboard.id,
                            'dashboard_navbar_item': selectedDashboard.id != dashboard.id
                        }"
                    >
                        <a class="nav-link text-white" @click="setSelectedDashboard(dashboard.id, false, true)" :key="dashboard.id" type="button">
                            {{ dashboard.name }}
                        </a>
                    </li>
                </div>
            </li>
        </ul>

        <div class="px-2" id="latency-nav" v-if="!job.end && isDashboardsPage">
            <latency-component-new
                :nav="true"
                :utcDifference="utcDifference"
                :latestDataCollection="latestDataCollection"
                :user-roles="userRoles"
            />
        </div>

        <span v-if="canManageDashboards && dashboardExists" id="dashboard-actions" :class="dashboardActions" ref="actionsContainer">
            <span>
                <template v-if="!editMode">
                    <iws-button
                        prepend-icon="fas fa-pencil-alt"
                        text="Edit Layout"
                        type="outline-light"
                        class="primary-text-color"
                        @click="emitEditMode(true)"
                        style="border: none !important; padding-top: 2px; padding-bottom: 2px;"
                        key="editLayout"
                    />

                    <iws-button
                        prepend-icon="fas fa-cog"
                        text="Manage Dashboard"
                        type="outline-light"
                        class="primary-text-color"
                        @click="emitToggleManage(true)"
                        style="border: none !important; padding-top: 2px; padding-bottom: 2px;"
                    />
                </template>
                <template v-else>
                    <iws-select style="width: 150px; display: inline-block"
                        placeholder="Add Component"
                        :options="availableDisplayItems"
                        display-name="friendlyName"
                        @change="emitSelectComponent"
                        :clearOnSelect="true"
                        full-placeholder
                    />

                    <iws-button type="primary" text="Save" style="margin-left: 5px" @click="emitSave" />
                    <iws-button type="danger" text="Discard" @click="emitEditMode(false)" style="margin-left: 5px; margin-right: 10px" />
                </template>
            </span>
        </span>
    </nav>
</div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty } = GlobalFunctions;

import eventBus from '../eventBus.js';

export default {
    props: {
        job: {
            type: [Object],
            required: true
        },
        isAdmin: {
            type: [Boolean, Number],
            required: true
        },
        iwsUser: {
            type: [Boolean, Number],
            required: true
        },
        isCompanyAdmin: {
            type: [Boolean, Number],
            required: true
        },
        userId: {
            type: [String, Number],
            required: true
        },
        customerId: {
            type: [String, Number],
            required: true
        },
        dashboardCustomer: {
            type: Object,
            required: true
        },
        dashboards: {
            type: Array,
            default() {
                return [];
            },
            required: true
        },
        orderedDashboards: {
            type: Object,
            required: true
        },
        fleetDashboard: {
            type: Object,
            required: true
        },
        userRoles: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            isDashboardsPage: window.location.pathname.includes('/dashboards'),
            showManagementModal: false,
            allDashboards: [...this.dashboards],
            initDashboardManagementPage: 1,
            selectedDashboard: {
                id: null,
                name: '',
                layout: [],
                items: [],
                layout_options: {},
                customer_id: null,
                created_by: null,
                is_customer_default: false,
                are_charts_synced: false,
                is_user_shared: false,
                is_role_shared: false,
                shared_users: [],
                shared_roles: [],
                is_writable: false,
                are_trucks_synced: false,
                default_truck_number: null,
            },
            dashboardsForDropDown: {
                default: [],
                nonDefault: [],
                shared: []
            },
            dashboardsForMenu: [],
            selectedDashboardInDropdown: {},
            maxAllowedDashboards: null,
            editMode: false,
            availableDisplayItems: [],
            latestDataCollection: [],
            utcDifference: 0,
        };
    },

    computed: {
        isMoreDropdownEmpty() {
            return isNullOrEmpty(this.dashboardsForDropDown?.default) && isNullOrEmpty(this.dashboardsForDropDown?.nonDefault);
        },
        useFleetDashboards() {
            return this.fleetDashboard.ordered_dashboard_ids.length > 0;
        },
        getOrderedIds() {
            return this.useFleetDashboards ? this.fleetDashboard.ordered_dashboard_ids : this.orderedDashboards.ordered_dashboard_ids;
        },
        dashboardExists() {
            return !isFalsy(this.selectedDashboard?.id);
        },
        canManageDashboards() {
            return (this.iwsUser || this.isCompanyAdmin) && this.isDashboardsPage;
        },
        dashboardActions(){
            if(this.isDashboardsPage && this.editMode){
                return 'dashboard-actions-edit';
            } else if(this.isDashboardsPage){
                return 'dashboard-actions';
            } else {
                return 'dashboard-actions-report';
            }
        }
    },
    methods: {
        _isNullOrEmpty(val) {
            return isNullOrEmpty(val);
        },
        emitSave() {
            this.editMode = false;
            eventBus.$emit('dashboard-save-changes');
        },
        emitEditMode(value) {
            this.editMode = value;
            eventBus.$emit('dashboard-edit-mode-change', value);
        },
        emitSelectComponent(value) {
            eventBus.$emit('dashboard-add-component', value);
        },
        emitToggleManage(value) {
            eventBus.$emit('dashboard-toggle-manage-dashboard', value);
        },
        isFleetDashboard(dashboard) {
            return !!this.fleetDashboard?.ordered_dashboard_ids?.includes(`${dashboard.id}`);
        },
        isSharedDashboard(dashboard) {
            return dashboard.is_shared && !dashboard.is_customer_default && !this.isFleetDashboard(dashboard);
        },
        getFleetDefaultDashboards(dashboards=this.allDashboards) {
            return dashboards.filter(d => d.is_customer_default && this.isFleetDashboard(d));
        },
        getCustomerDefaultDashboards(dashboards=this.allDashboards) {
            return dashboards.filter(d => d.is_customer_default);
        },
        getPrivateAndCustomerDashboards(dashboards=this.allDashboards) {
            return dashboards.filter(d => !d.is_shared || d.is_customer_default || this.isFleetDashboard(d));
        },
        getNavbarDashboards(dashboards=this.allDashboards) {
            // Shared dashboards only show under the shared dropdown
            dashboards = dashboards.filter(_dashboard => !this.isSharedDashboard(_dashboard));
            const sortByPriority = (list, orderedIds) => list?.sort((a, b) => orderedIds.indexOf(`${a.id}`) - orderedIds.indexOf(`${b.id}`)) || [];

            return this.useFleetDashboards
                ? sortByPriority(this.getFleetDefaultDashboards(), this.getOrderedIds)
                : this.getPrivateAndCustomerDashboards(dashboards);
        },
        getMoreMenuDashboards(dashboards=this.allDashboards) {
            const moreDashboards = {
                default: [],
                nonDefault: [],
                shared: []
            };

            dashboards.forEach(dashboard => {
                // Only show dashboards not shown in the navbar
                if (isNullOrEmpty(this.dashboardsForMenu) || isFalsy(this.dashboardsForMenu.find(_dash => _dash.id == dashboard.id))) {
                    // Split the dashboards by defaults or not
                    if (this.isSharedDashboard(dashboard)) {
                        moreDashboards.shared.push(dashboard);
                    } else if (dashboard.is_customer_default) {
                        moreDashboards.default.push(dashboard);
                    } else {
                        moreDashboards.nonDefault.push(dashboard);
                    }
                }
            });

            return moreDashboards;
        },
        findSelectedDashboard(selectedId=this.selectedDashboard.id) {
            const searchList = (list) => {
                if (!isNullOrEmpty(list))
                    for (const dashboard of list)
                        if (dashboard.id == selectedId)
                            return dashboard;
                return null;
            }

            return searchList(this.dashboardsForDropDown.default) || searchList(this.dashboardsForDropDown.nonDefault);
        },

        calculateMaxDashboards() {
            // Use only the menu when on mobile
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            if (vw < 768)
                return 0;

            if (this.$refs.dashboardContainer) {
                const getRefWidth = (refName) => this.$refs[refName]?.clientWidth || 0;

                // The unsplice list of dashboards available to the user
                const dashboardsForMenu = this.getNavbarDashboards();
                const maxWidthAllowed = this.$refs.dashboardContainer.clientWidth - getRefWidth('moreRef') - getRefWidth('sharedRef');

                // Go through each dashboard li, take the actual width needed to display it, sum the widths to determine how many can fit with the max available on screen
                let allocatedWidth = 0;
                for (let i = 0; i < dashboardsForMenu.length; i++) {
                    // Grab the li by index (returns a list with 1 index)
                    const _ref = this.$refs[`dashboard_name_${i}`];

                    if (!isFalsy(_ref) && !isFalsy(_ref[0])) {
                        // If next li exceeds the limit, jump out now returning the last allowed index (without going into negatives)
                        if (allocatedWidth + _ref[0].clientWidth > maxWidthAllowed)
                            return i;
                        // Is allowed, add to allocated and move to the next
                        allocatedWidth += _ref[0].clientWidth;
                    }
                }

                // All lis fit within max allowed
                return null;
            }

            // Allow all at first, once rendered we can grab actual widths to determine exactly how many can be shown
            return null;
        },
        reorderDashboards(dashboards=this.allDashboards, orderedIds) {
            let orderedList = [];
            const sortByPriority = (list) => list?.sort((a, b) => orderedIds.indexOf(`${a.id}`) - orderedIds.indexOf(`${b.id}`)) || [];
            const filterExistingItems = (existingList, list) => list?.filter(d => !existingList.find(_d => _d.id == d.id)) || [];

            if (this.useFleetDashboards) {
                // Start with default fleet dashboards
                orderedList = sortByPriority(this.getFleetDefaultDashboards(dashboards));

                // Next, non fleet customer defaults dashboards
                orderedList = orderedList.concat(
                    sortByPriority(this.getCustomerDefaultDashboards(filterExistingItems(orderedList, dashboards)))
                );
            } else {
                orderedList = sortByPriority(this.getCustomerDefaultDashboards(dashboards));
            }

            // Concatenate the rest of non-default/ordered dashboards
            return orderedList.concat(sortByPriority(filterExistingItems(orderedList, dashboards)));
        },
        updateDashboardsUI() {
            this.dashboardsForMenu = !isFalsy(this.maxAllowedDashboards)
                ? this.getNavbarDashboards().slice(0, this.maxAllowedDashboards)  // If a limit is set, splice the navbar dashboards
                : this.getNavbarDashboards();                                     // Full list must be rendered first to get the actual required width for each individual li

            this.dashboardsForDropDown = this.getMoreMenuDashboards();
            this.selectedDashboardInDropdown = this.findSelectedDashboard();
        },

        renderAllowedDashboards() {
            // Clear max and render all dashboard list items
            this.maxAllowedDashboards = null;
            this.updateDashboardsUI();

            this.$nextTick(() => {
                // Once rendered, take all actual lengths and calculate how many fit and cut of the exta
                this.maxAllowedDashboards = this.calculateMaxDashboards();

                // When maxAllowedDashboards is null, no limit is needed so skip extra rerender
                if (!isFalsy(this.maxAllowedDashboards))
                    this.updateDashboardsUI();
            });
        },
        setSelectedDashboard(id, currentPage, navRequired) {
            if(this.editMode && !confirm('Are you sure you want to leave without saving?')) {
                return;
            }

            if(currentPage) {
                this.initDashboardManagementPage = currentPage;
            }

            localStorage.setItem('LastViewedDashboard' + this.userId, id);
            const path = window.location.pathname;
            if (navRequired) {
                const url = '/dashboards/';
                window.location.href = window.location.origin + url + this.job.jobNumber + '?id=' + id;
            }
            else {
                this.editMode = false;
                this.selectedDashboard.id = id;

                this.getSelectedDashboardDetails();
            }
        },
        getSelectedDashboardDetails() {
            if(!this.selectedDashboard.id) {
                return;
            }

            this.isDashboardLoading = true;

            const url = '/dashboards/get/' + this.selectedDashboard.id;
            const self = this;

            $.get(
                url,
                {},
                function(data) {
                    if(data[0]) {
                        self.selectedDashboard.name = data[0].name;

                        // The original reference to layout must be maintained because
                        // We are passing it with a .sync modifier to grid layout
                        self.selectedDashboard.layout.length = 0;
                        data[0].layout.forEach(el => self.selectedDashboard.layout.push(el));

                        self.selectedDashboard.layout_options = data[0].layout_options;
                        self.selectedDashboard.items = data[0].items;
                        self.selectedDashboard.customer_id = data[0].customer_id;
                        self.selectedDashboard.created_by = data[0].created_by;

                        //backend returns bool as 1 or 0 from database, but .is_customer_default,
                        //.is_user_shared, and .is_role_shared expects true/false
                        self.selectedDashboard.is_customer_default = data[0].is_customer_default === 1? true:false;
                        self.selectedDashboard.are_charts_synced = data[0].are_charts_synced === 1? true:false;
                        self.selectedDashboard.are_tooltips_synced = data[0].are_tooltips_synced === 1? true:false;
                        self.selectedDashboard.chart_tooltip_mode = data[0].chart_tooltip_mode;
                        self.selectedDashboard.is_user_shared = data[0].is_user_shared === 1? true:false;
                        self.selectedDashboard.is_role_shared = data[0].is_role_shared === 1? true:false;
                        self.selectedDashboard.are_trucks_synced = data[0].are_trucks_synced === 1? true : false;
                        self.selectedDashboard.default_truck_number = data[0].default_truck_number;

                        self.selectedDashboard.shared_users = data[0].users;
                        self.selectedDashboard.shared_roles = data[0].roles;

                        if(self.selectedDashboard.created_by != self.userId) {
                            self.selectedDashboard.is_shared = !self.selectedDashboard.is_customer_default;

                            if(self.selectedDashboard.is_user_shared) {
                                const u = self.selectedDashboard.shared_users.find(u => u.id == self.userId);
                                self.selectedDashboard.is_writable = u ? !!u.pivot.is_writable : false;
                            }
                            else if(self.selectedDashboard.is_role_shared) {
                                // Check highest permission on shared dashboard and set them.

                                const roles = self.selectedDashboard.shared_roles.filter(shared_role => {
                                    if(self.userRoles.find(user_role => user_role.id == shared_role.id)) {
                                        return shared_role.pivot.is_writable;
                                    }
                                });

                                self.selectedDashboard.is_writable = !!roles.length;
                            }
                        }
                        self.$root.$emit('updateSelectedDashboard', self.selectedDashboard);
                    }
                    else{
                        console.warn('malformed data:' + data);
                    }
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                if(jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
                else if(jqXHR.status == 404 && localStorage.getItem('LastViewedDashboard' + self.userId)) {
                    // The dashboard ID stored in localStorage is not valid on this deployment
                    // Remove it from localStorage and try again
                    localStorage.removeItem('LastViewedDashboard' + self.userId);
                    self.getSelectedDashboardDetails();
                }
            });
        },
        createNewDashboard(copySelected) { // if copySelected is true, copy current layout
            if(this.editMode && !confirm('Are you sure you want to leave without saving?')) {
                return;
            }


            let newDashboard = {
                id: null,
                name: copySelected ? this.selectedDashboard.name + " (Copy)" : "",
                layout: copySelected ? this.selectedDashboard.layout : [],
                items: copySelected ? this.selectedDashboard.items : [],
                layout_options: {},
                is_customer_default: false,
                is_user_shared: false,
                is_role_shared: false,
                shared_users: [],
                shared_roles: [],
                customer_id: this.customerId,
            };

            this.$root.$emit('createNewDashboard', newDashboard);
        }
    },

    created() {
        this.$root.$on('setAvailableDisplayItems', value => this.availableDisplayItems = value);
        this.$root.$on('updateNavSelectedDashboard', dashboard => this.selectedDashboard = dashboard);
        addEventListener("resize", this.renderAllowedDashboards);
    },
    mounted() {
        if (isNullOrEmpty(this.allDashboards)) {
            this.createNewDashboard();
            return;
        }

        const orderedDashboardIds = this.getOrderedIds;
        if (orderedDashboardIds.length) {
            this.allDashboards = this.reorderDashboards(this.allDashboards, orderedDashboardIds);
        } else {
            // No ordered dashboard ids exist for this customer, just sort customer
            this.allDashboards = this.allDashboards.sort((a, b) => {
                return a.is_customer_default && !b.is_customer_default ? -1 : 1;
            });
        }

        this.allDashboards.forEach(e => {
            if(e.created_by != this.userId) { // self
                e.is_shared = true;
            };
        } );

        const lastDashboard = localStorage.getItem('LastViewedDashboard' + this.userId);

        // Remove the stored dashboard ID from localStorage if it's no longer valid:
        const validDashboard = !!this.allDashboards.filter(dash => dash.id == lastDashboard).length;

        if (!validDashboard)
            localStorage.removeItem('LastViewedDashboard' + this.userId);

        const path = window.location.href;

        const dashboardId = new URL(path).searchParams.get('id');
        const dashboardExists = !!this.allDashboards.filter(dash => dash.id == dashboardId).length;
        if (dashboardId !== null && dashboardExists) {
            this.selectedDashboard.id = dashboardId;
            localStorage.setItem('LastViewedDashboard' + this.userId, this.selectedDashboard.id);
            this.getSelectedDashboardDetails();
        } else if (!this.selectedDashboard.id && path.includes('dashboards')) {
            if (lastDashboard && validDashboard) {
                this.selectedDashboard.id = lastDashboard;
                this.getSelectedDashboardDetails();
            } else if (this.allDashboards[0].id) {
                this.selectedDashboard.id = this.allDashboards[0].id;

                localStorage.setItem('LastViewedDashboard' + this.userId, this.allDashboards[0].id);

                this.getSelectedDashboardDetails();
            }
        }

        this.$root.$emit('setDashboards', this.dashboards);
        this.renderAllowedDashboards();

        eventBus.$on('updateLatestDataCollection', (data) => {
            this.latestDataCollection = data;
        });
        eventBus.$on('updateUtcDifference', (data) => {
            this.utcDifference = data;
        });
    }
};
</script>

<style>
    #dashboard-actions button,
    #dashboard-actions input {
        font-size: 14px;
        height: 30px;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    #dashboard-actions button.btn-primary,
    #dashboard-actions button.btn-danger {
        margin-top: -3px;
    }

    #dashboard-actions .custom-search-select .container {
        width: 295px !important;
    }
</style>
<style scoped>
    #DashboardNavbar.navbar {
        background: #343A40;
        border-bottom: 1px solid #667085;
        padding: 0px !important;
        margin-bottom: 5px;
        padding-top: 5px;
    }
    .nav-link {
        padding: 0.25rem 0.5rem;
    }

    .dashboard-actions {
        width: 285px;
    }

    .dashboard-actions-edit {
        width: 315px;
    }

    .dashboard-actions-report {
        width: 365px;
    }

    .dashboard-actions>span {
        float: right;
    }

    .dashboard-actions-edit>span {
        float: right;
    }

    .dashboard-actions-report>span {
        float: right;
    }

    #latency-nav {
        float: right;
    }




.dropdown-toggle
{
    color:white !important;
}
.dropdown-toggle:hover
{
    color:rgba(255,255,250,.7) !important;
}

#more-dropdown {
    overflow-y: auto;
    /* ViewportHeight - mainNavbarheight - dashboardNavbarHeight - extraBuffer */
    max-height: calc(100vh - 62px - 36px - 5px);
}

.autocomplete-result-list {
    height: 200px;
    overflow: auto;
    padding: 0px;
}
.bar_button{
  padding: 4px;
  font-size: 13px;
}

.info_group  {
  padding: 6px 6px;
  font-weight: bold;
}

.info_group_title{
  font-size: 1.5em;
  display: inline-block;
}

.select_form {
  display: inline;
  vertical-align: top;
  text-align:center;
  cursor: pointer;
  color: #3dcfff;
  font-weight: bold;
}
.drop-down-width {
    width: 250px;
}
.accent-text {
    color: #3dcfff !important;
}
.accent-text:hover {
    background-color: transparent !important;
    color: #3dcfff !important;
    font-weight: bold;
}
.no_text_select{
  user-select: none;
}

.flex-parent {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.max-text-width  {
    max-width: 160px
}

.flex-parent > div {

  margin-top: auto;
  margin-bottom: auto;
}

/* Inserting this collapsed row between two flex items will make
 * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
  height: 0;
}

#item3{
  width: 100%;
}

@media screen and (max-width: 870px) {
  div#item1 {
    order: 1;
  }
  div#item2 {
    order: 3;
  }
  div#item3 {
    order: 2;
  }
}

.vue-grid-item {
  overflow: hidden;
}

.vue-resizable {
  border: 1px solid #555;
  overflow: hidden;
}

.dashboard_navbar {
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
}

.limit-width {
    width: calc(100vw - 430px)
}


.dashboard_new_item {
  cursor: pointer;
  justify-content: center;
  text-align: center;
  height: 100px;
  border: 2px dashed #999;
  width: 90%;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;

}

.dashboard_new_item:hover {
  background-color: #444;
}

.dashboard_navbar_item {
  transition: all 250ms ease-in-out;
}

.dashboard_navbar_item:after {
  display:block;
  content: '';
  color: #fff;
  border-bottom: solid 3px #fff;
  transform: scaleX(0);
  transition: all 250ms ease-in-out;
}

.dashboard_navbar_item:hover:after {
  transform: scaleX(1);
}

.dashboard_navbar_selected{
  cursor: auto;
  color: #fff;
  transform-origin:  0% 50%;
}

.dashboard_navbar_selected:after{
  display:block;
  content: '';
  border-bottom: solid 3px #0275d8;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  transform-origin:  0% 50%;
  color: #fff;
}

.user-remover {
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.user-remover-disabled {
    color: #6c757d!important;
}

.disabled-color {
    background-color: #6c757d!important;
}

.user-remover:hover {
  color: #ff2222;
}

.closer {
  z-index: 1000;
  padding: 0px 0px;
  opacity: 0.8;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #222;
  color: white;
  display: inline-block;
  position: absolute;
  right: 0px;
}
.closer:hover{
  opacity: 1;
  text-decoration: underline;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selected-dropdown {
  font-weight: bold;
  border-bottom: solid 3px #0275d8;
}

.item_title {
  background-color: rgba(65,65,65,0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  z-index: 10;
}

.dropdown button {
  padding: 2px;
}

.customer_dashboard{
  font-weight: bold;
  color: white;
}

.flex-list li {
    padding-right: 0.75em;
    padding-left: 0.75em;
    border-right: 0;
}

#usertable td {
  text-align: center;
  vertical-align: middle;
}

.dropdown-section {
    padding-top: 1em;
}
.dropdown-section>b {
    padding-left: 0.5em;
    font-size: 22px;
    color: rgba(250,250,250);
}
.dropdown-section>li{
    padding-left: 1.5em;
}
</style>
