<template>
    <div class="d-flex flex-column chart-container flex-fill" v-bind:style="{ height: height? height+ 'px': 'auto',  }">
        <!--Header-->
        <div :id="'kpiDiv' + componentId" class="d-flex" v-bind:style="{ height: headerHeight+'px' }">
            <!--Header Text-->
            <div class="mr-auto" style="overflow: hidden; white-space: nowrap">
                <div class="d-flex align-items-center">
                    <div v-if="showNotEnoughDataWarning" class="alert alert-warning m-4 absolute-center" role="alert">
                        Not enough data to generate this KPI summary!
                    </div>
                    <h4 v-else-if='isCombinedPumpHoursAndStagesChart'>
                        Total Stages &amp; Pumping Hours/Day
                    </h4>
                    <h4 v-else-if='selectedType == "kpi-total"'>
                        Total Stages/Day
                    </h4>
                    <h4 v-else-if='selectedType == "kpi-progress"'>
                        Completion Progress
                    </h4>
                    <h4 v-else-if='selectedType == "kpi-lateral"'>
                        Lateral Length/Day
                    </h4>
                    <h4 v-else-if='selectedType == "kpi-pumpingHours"'>
                        Pumping Hours/{{isDayTypeStage ? 'Stage' : 'Day'}}
                    </h4>
                    <h4 v-else-if='selectedType == "kpi-swapTime"'>
                        {{ swapTypeLabel?.upper }} Times
                    </h4>
                    <h4 v-else-if='selectedType == "kpi-metricsPerStage"'>
                        Metrics Per Stage
                    </h4>
                    <h4 v-else>
                        Pump Time/Day
                    </h4>
                    <div v-if="selectedType" v-show="!showNotEnoughDataWarning && !editMode" >
                        <tooltip-component
                            :tooltip_key="getTooltipKeyFromType(selectedType)"
                            :customer_id="job.customer_id"/>
                    </div>
                </div>
            </div>
            <!-- Export button -->
            <span v-tooltip:top="'Export'" v-show="!editMode && !exportOverlayMode">
                <i class="fas fa-download clickable" @click="exportOverlayMode=true;"></i>
            </span>
            <!--Type Selection-->
            <div v-show="editMode" style="z-index:10000;">
                <div v-if="selectedType !== 'kpi-swapTime' && selectedType !== 'kpi-metricsPerStage'" class="mb-1">
                    <label class="input-group-text px-1">
                        <div class="float-left pr-1 ml-1">Time Range:</div>
                        <select id="inputGroupSelect02"
                                class="form-control kpi-select float-right ml-auto"
                                ref="dayType"
                                @change="onTimeRangeChanged"
                                v-model="selectedDayType" >
                            <option v-for="dayType in dayTypes" @change="dayType = $refs['dayType'].value"
                                    :value="dayType"
                                    :key="dayType">
                                {{dayType}}
                            </option>
                        </select>
                    </label>
                </div>

                <div class="mb-1 text-center">
                    <label class="input-group-text px-1 text-center">
                        <div class="float-left pr-1 ml-1" style="text-align:center;">KPI Type:</div>
                        <select id="inputGroupSelect01"
                                class="form-control kpi-select float-right ml-auto"
                                ref="kpiType"
                                @change="onDataChanged"
                                v-model="selectedType">
                            <template v-for="kpiType in kpiTypes">
                                <option v-if="kpiType != 'kpi-swapTime' || isSwapTimeFeature" @change="kpiType = $refs['kpiType'].value"
                                        :value="kpiType"
                                        :key="kpiType">
                                    {{kpiTypeLabels[kpiType]}}
                                </option>
                            </template>
                        </select>
                    </label>
                </div>

                <!-- Data source selection, only for KPI type = kpi-pumpingHours -->
                <!-- Only display the data source dropdown when the feature flag is enabled -->
                <div v-if="selectedType == 'kpi-pumpingHours' && isFeatureFlagged('PUMPTIME_ADX_DATA_SOURCE')"
                    class="mb-1">
                    <label class="input-group-text px-1">
                        <div class="float-left pr-1 ml-1">Data Source:</div>
                        <select id="inputGroupSelect02"
                                class="form-control kpi-select float-right ml-auto"
                                ref="dayType"
                                @change="onDataChanged"
                                v-model="selectedPumpTimeSource" >
                            <option v-for="dataSource in dataSources" :value="dataSource.value" :key="dataSource.value">
                                {{dataSource.label}}
                            </option>
                        </select>
                    </label>
                </div>

                <div v-if="isSwapTimeFeature && selectedType == 'kpi-swapTime'" class="mb-1 text-center">
                    <label class="input-group-text px-1 text-center">
                        <div class="float-left pr-1 ml-1" style="text-align:center;">Swap Type:</div>
                        <select class="form-control kpi-select float-right ml-auto"
                                @change="onDataChanged"
                                v-model="selectedSwapType">
                            <option v-for="(swapType, key) in swapTypes" :value="key" :key="key">
                                {{ swapType }}
                            </option>
                        </select>
                    </label>
                </div>
                <div v-if="selectedType != 'kpi-progress'" class="mb-1 text-center">
                    <label class="input-group-text px-1" :for="'analytics-' + _uid">
                        <div class="float-left pr-1 ml-1">Analytics:</div>
                        <select :id="'analytics-' + _uid"
                                class="form-control kpi-select float-right ml-auto"
                                ref="analyticType"
                                @change="onDataChanged"
                                style="background-color:#fff !important;"
                                @mousedown.prevent="onSelectAnalyticPressed">
                            <option selected> {{ updatedSelectedAnalytics.length }} selected</option>
                        </select>
                    </label>
                    <b-popover :target="'analytics-' + _uid" triggers="focus" placement="bottom" :boundary-padding="0" :delay="{show: 0, hide: 0}" boundary="viewport">
                        <div class="d-flex analytics-dropdown">
                            <checkbox-list
                                label="Select Metrics"
                                height="100"
                                :options="analyticTypes"
                                v-bind:selectedOptions="selectedAnalyticTypes"
                                v-on:update:selectedOptions="onAnalyticsSelected($event)">
                            </checkbox-list>
                        </div>
                    </b-popover>
                </div>
                <div v-if="selectedType == 'kpi-swapTime' && requireWirelineCheck" class="mb-1 text-center">
                    <label class="input-group-text px-1">
                        <div class="float-left pr-1 ml-1" style="text-align:center;">Wireline:</div>
                        <select
                            class="form-control kpi-select float-right ml-auto"
                            v-model="wirelineSwapFilter"
                            @change="onDataChanged"
                        >
                            <option v-for="wireline in dashboardData.wirelineSystems" :value="wireline.number">
                                {{ wireline.name }}
                            </option>
                        </select>
                    </label>
                </div>
                <div v-if="selectedType == 'kpi-swapTime'" class="mb-1 text-center kpi-swap-filter">
                    <label class="input-group-text px-1">
                        <div class="float-left pr-1 ml-1" style="text-align:center; font-size: smaller;">Swap Time<br>Minimum:</div>
                        <select
                            v-if="!customMinSwap"
                            class="form-control kpi-select float-right ml-auto"
                            @change="onDataChanged"
                            v-model="minSwapTimeFilter">
                            <option v-for="filterOption in minSwapFilterOptions" :key="`${filterOption.value}_${filterOption.label}`" :value="filterOption.value">
                                {{ filterOption.label }}
                            </option>
                        </select>
                        <input v-else
                            :value="secondsToMinutes(minSwapTimeFilter)"
                            @input="minSwapTimeFilter = minutesToSeconds($event?.target?.value)"
                            min="0"
                            @change="onDataChanged"
                            class="form-control"
                            placeholder="In Minutes"
                            type="number"
                        >

                        <div style="margin-left: 5px;">
                            <iws-button
                                class="clear-filter"
                                text="X"
                                type="outline-secondary"
                                @click="minSwapTimeFilter = null"
                            />

                            <iws-button v-if="!customMinSwap"
                                class="switch-filter-type"
                                text="Custom"
                                type="outline-secondary"
                                @click="customMinSwap=true; minSwapTimeFilter=null"
                            />
                            <iws-button v-else
                                class="switch-filter-type"
                                text="Preset"
                                type="outline-secondary"
                                @click="customMinSwap=false; minSwapTimeFilter=null"
                            />
                        </div>
                    </label>
                </div>
                <div v-if="selectedType == 'kpi-swapTime'" class="mb-1 text-center kpi-swap-filter">
                    <label class="input-group-text px-1">
                        <div class="float-left pr-1 ml-1" style="text-align:center; font-size: smaller;">Swap Time<br>Maximum:</div>
                        <select v-if="!customMaxSwap"
                                @change="onDataChanged"
                                class="form-control kpi-select float-right ml-auto"
                                v-model="maxSwapTimeFilter"
                        >
                            <option v-for="filterOption in maxSwapFilterOptions" :key="`${filterOption.value}_${filterOption.label}`" :value="filterOption.value">
                                {{ filterOption.label }}
                            </option>
                        </select>
                        <input v-else
                            :value="secondsToMinutes(maxSwapTimeFilter)"
                            @input="maxSwapTimeFilter = minutesToSeconds($event?.target?.value)"
                            min="0"
                            class="form-control"
                            placeholder="In Minutes"
                            @change="onDataChanged"
                            type="number"
                        >

                        <div style="margin-left: 5px;">
                            <iws-button
                                class="clear-filter"
                                text="X"
                                type="outline-secondary"
                                @click="maxSwapTimeFilter = null"
                            />

                            <iws-button v-if="!customMaxSwap"
                                class="switch-filter-type"
                                text="Custom"
                                type="outline-secondary"
                                @click="customMaxSwap=true; maxSwapTimeFilter=null"
                            />
                            <iws-button v-else
                                class="switch-filter-type"
                                text="Preset"
                                type="outline-secondary"
                                @click="customMaxSwap=false; maxSwapTimeFilter=null"
                            />
                        </div>
                    </label>
                </div>
                <template v-if="selectedType == 'kpi-metricsPerStage'">
                    <template v-if="!_isNullOrEmpty(metadataTags)">
                        <div class="mb-1 text-center">
                            <label class="input-group-text px-1">
                                <div class="float-left pr-1 ml-1" style="text-align: center">
                                    Scatter Tag:
                                </div>

                                <select class="form-control kpi-select float-right ml-auto"
                                        @change="onDataChanged"
                                        v-model="scatterTag">
                                    <option v-for="tag in [ {displayName: ' ', tagName: -2}, ...metadataTags ]" :key="`scatter_tag_option_${tag.tagName}`" :value="tag.tagName">
                                        {{ tag.displayName || tag.tagName }}
                                    </option>
                                </select>
                            </label>
                        </div>

                        <div class="mb-1 text-center">
                            <label class="input-group-text px-1">
                                <div class="float-left pr-1 ml-1" style="text-align: center">
                                    Bar Tag:
                                </div>

                                <select class="form-control kpi-select float-right ml-auto"
                                        @change="onDataChanged"
                                        v-model="barTag">
                                    <option v-for="tag in [ {displayName: ' ', tagName: -2}, ...metadataTags ]" :key="`bar_tag_option_${tag.tagName}`" :value="tag.tagName">
                                        {{ tag.displayName || tag.tagName }}
                                    </option>
                                </select>
                            </label>
                        </div>
                    </template>
                    <div v-else>
                        No Customer Tags Found
                    </div>

                    <div class="mb-1 text-center kpi-metrics-filter">
                        <label class="input-group-text px-1">
                            <div class="float-left pr-1 ml-1" style="text-align: left;">
                                Stages Shown:
                            </div>

                            <span v-if="showLimitStageMetrics">
                                <input
                                    v-model="limitStagesMetricsPerStage"
                                    min="0"
                                    class="form-control"
                                    placeholder="Limit the stages shown"
                                    type="number"
                                    @input="onLimitStageMetricsPerStageChanged"
                                >

                                <iws-button
                                    class="clear-filter"
                                    text="X"
                                    type="outline-secondary"
                                    @release="clearLimitMetrics"
                                />
                            </span>
                            <div v-else style="margin: auto;">
                                <iws-button
                                    text="Enable Limit"
                                    type="outline-primary"
                                    @click="onLimitStageMetricsButtonClicked"
                                />
                            </div>
                        </label>
                    </div>

                    <label class="input-group-text px-1" :for="'pump_hours_plotline-' + _uid">
                        <div class="float-left pr-1 ml-1">Average Wells:</div>
                        <input
                            v-model="averageWellDataMetricsPerStage"
                            type="checkbox"
                            :id="'average_well_data-' + _uid"
                            class="form-control float-right"
                            style="background-color:#fff !important;"
                            @change="onAverageWellDataMetricsChecked">
                    </label>
                </template>
                <div v-if="showZeroValueAnalyticField" class="mb-1 text-center" v-show="updatedSelectedAnalytics.length > 0">
                    <label class="input-group-text px-1 text-center">
                        <div class="float-left pr-1 ml-1" style="text-align:center; font-size: smaller;">Treatment of Zero <br> Values in Analytics:</div>
                        <select :id="'zeroStage-' + _uid"
                                class="form-control kpi-select float-right ml-auto"
                                ref="zeroStageType"
                                @change="onDataChanged"
                                v-model="selectedZeroStageType">
                            <option v-for="zeroStageType in zeroStageTypes" @change="zeroStageType = $refs['zeroStageType'].value"
                                    :value="zeroStageType"
                                    :key="zeroStageType">
                                {{zeroStageTypeLabels[zeroStageType]}}
                            </option>
                        </select>
                    </label>
                </div>
                <div v-if='showPumpHourPlotlineOption' class="mb-1 text-center">
                    <label class="input-group-text px-1" :for="'pump_hours_plotline-' + _uid">
                        <div class="float-left pr-1 ml-1">Include Pump Hours Plotline If Available:</div>
                        <input
                            v-model="includePumpHoursPlotline"
                            type="checkbox"
                            :id="'pump_hours_plotline_export-' + _uid"
                            class="form-control float-right"
                            ref="pumpHoursPlotlineExport"
                            style="background-color:#fff !important;"
                            @change="onDataChanged"
                            @click="onSelectPumpHoursPlotline()">
                    </label>
                </div>
                <!-- Stage pumping hours are stacked by well by default, only show the stacked by well toggle for none stage day types -->
                <div class="d-flex align-item-center justify-content-end" v-if="[kpiTypes[0], kpiTypes[3]].includes(selectedType) && !isDayTypeStage">
                    <span class="d-flex align-items-center pr-2">Stack by Well</span><iws-switch :onPress="onStackByWellPressed" :value="isStackedByWell"/>
                </div>
                <div class="mt-2">
                    <div v-if="selectedType != initialSelectedType
                                || selectedDayType != initialSelectedDayType
                                || selectedZeroStageType != initialSelectedZeroStageType
                                || includePumpHoursPlotline != initialPumpHoursPlotlineVal
                                || onSelectedAnalyticsChanged
                                || onStackInitChanged
                                || (!!item.options.selectedSwapType && selectedSwapType != item.options.selectedSwapType)
                                || wirelineSwapFilter != item.options.wirelineSwapFilter
                                || minSwapTimeFilter != item.options.minSwapTimeFilter
                                || maxSwapTimeFilter != item.options.maxSwapTimeFilter
                                || initialSelectedPumpTimeSource != selectedPumpTimeSource
                                || item.options.scatterTag != scatterTag
                                || item.options.barTag != barTag
                                || item.options.averageWellDataMetricsPerStage != averageWellDataMetricsPerStage
                                || item.options.limitStagesMetricsPerStage != limitStagesMetricsPerStage
                            "
                        class="ml-auto float-right">
                        <button v-if="hasDataChanged" @click="revertConfig()"
                                type="button"
                                class="btn btn-secondary">
                            Revert
                        </button>
                        <button v-if="hasDataChanged" @click="saveConfig()"
                                type="button"
                                class="btn btn-success ml-1">
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <!--Export Selection-->
            <div v-show="exportOverlayMode" style="z-index:10000;">
                <div class="mb-1" v-show="selectedType != 'kpi-progress' && selectedType !== 'kpi-swapTime' && selectedType !== 'kpi-metricsPerStage'">
                    <label class="input-group-text px-1">
                        <div class="float-left pr-1 ml-1">Time Range:</div>
                        <select id="exportSelect1"
                                class="form-control kpi-select float-right ml-auto"
                                ref="dayType"
                                @change="onTimeRangeChanged"
                                v-model="selectedDayType" >
                            <option v-for="dayType in dayTypes" @change="dayType = $refs['dayType'].value"
                                    :value="dayType"
                                    :key="dayType">
                                {{dayType}}
                            </option>
                        </select>
                    </label>
                </div>
                <div class="mb-1">
                    <label class="input-group-text px-1">
                        <div class="float-left pr-1 ml-1">Export Type:</div>
                        <select id="exportSelect2"
                                class="form-control kpi-select float-right ml-auto"
                                ref="exportType"
                                @change="onTimeRangeChanged"
                                v-model="selectedExportType"
                        >
                            <option v-for="exportType in exportTypes"
                                @change="selectedExportType = $refs['exportType'].value"
                                :value="exportType"
                                :key="exportType"
                            >
                                {{exportType}}
                            </option>
                        </select>
                    </label>
                </div>
                <div class="mb-1 text-center">
                    <label class="input-group-text px-1 text-center">
                        <div class="float-left pr-1 ml-1" style="text-align:center;">KPI Type:</div>
                        <select id="exportSelect3"
                                class="form-control kpi-select float-right ml-auto"
                                ref="kpiType"
                                v-model="selectedType">
                            <option v-for="kpiType in kpiTypes" v-if="kpiType != 'kpi-swapTime' || isSwapTimeFeature" @change="kpiType = $refs['kpiType'].value"
                                    :value="kpiType"
                                    :key="kpiType">
                                {{kpiTypeLabels[kpiType]}}
                            </option>
                        </select>
                    </label>
                </div>
                <div v-if='showPumpHourPlotlineOption' class="mb-1 text-center">
                    <label class="input-group-text px-1" :for="'pump_hours_plotline-' + _uid">
                        <div class="float-left pr-1 ml-1">Include Pump Hours Plotline If Available:</div>
                        <input
                            v-model="includePumpHoursPlotline"
                            type="checkbox"
                            :id="'pump_hours_plotline-' + _uid"
                            class="form-control float-right ml-auto"
                            ref="pumpHoursPlotline"
                            style="background-color:#fff !important;"
                            @click="onSelectPumpHoursPlotline()">
                    </label>
                </div>
                <div class="d-flex align-item-center justify-content-end" v-if="[kpiTypes[0], kpiTypes[3]].includes(selectedType)">
                    <span class="d-flex align-items-center pr-2">Stack by Well</span><iws-switch :onPress="onStackByWellPressed" :value="isStackedByWell"/>
                </div>
                <div class="mt-2">
                    <div class="ml-auto float-right">
                        <button @click="revertConfig()"
                                type="button"
                                class="btn btn-secondary">
                            Cancel
                        </button>
                        <button
                            :disabled="!isChartExportable()"
                            @click="exportChart()"
                            type="button"
                            class="btn btn-success ml-1">
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showAnalytics" class="d-flex flex-wrap">
            <div
              v-for="(analyticObject, index) in analyticsData"
              :key="index"
              class="px-4 col-wrapper"
              :style="{
                'flex-basis': `calc(100% / ${columnCount})`,
                'max-width': `calc(100% / ${columnCount})`,
              }"
            >
                <div class="mb-2">
                    <div class="d-flex align-items-center">
                        <div :style="'height:10px;width:10px;background-color:' + analyticObject.backgroundColor" class="mr-1"></div>
                        <span>{{ analyticObject.label }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="!showNotEnoughDataWarning" v-bind:style="{backgroundColor: '#575757', position: 'relative'}" class="rounded mt-2 py-2 px-2">
            <template v-if="selectedType == 'kpi-swapTime'">
                {{ swapTypes[selectedSwapType] }}

                <template v-if="requireWirelineCheck && !!selectedWireline">
                    ({{ selectedWireline?.name }})
                </template>
            </template>
            <template v-if="selectedType == 'kpi-metricsPerStage'">
                <div v-if="!_isFalsy(scatterTag) && scatterTag != -1 && scatterTag != -2">
                    Scatter Plot: {{ findTagName(scatterTag) }}
                </div>
                <div v-if="!_isFalsy(barTag) && barTag != -1 && barTag != -2">
                    Bar Chart: {{ findTagName(barTag) }}
                </div>
            </template>
            <template v-else>
                Time Range: {{getTimeRangeDescription}}
            </template>

            <div v-if="selectedType == 'kpi-progress' && averageStagesCompletedPerDay > 0">
                Average stages completed per day: {{ averageStagesCompletedPerDay }}
            </div>


            <span v-if="filterInfoDetails != null || (!!filteredItems && !!filteredItems.length)" style="float: right">
                <div :id="`popover-target-${_uuid}`" class="clickable">
                    {{ filterInfoDetails }}
                </div>

                <b-popover :target="`popover-target-${_uuid}`" triggers="hover" placement="top" :boundary-padding="0" custom-class="filtered-popover" variant="dark">
                    <template #title>Filtered {{ swapTypeLabel.upper }} Times</template>
                    <div v-for="(filteredItem, index) in filteredItems" :class="{ 'pt-2': index > 0 }">
                        <div>
                            <b>{{ swapTypeLabel.upper }} Number: {{ filteredItem.index }}</b>
                        </div>
                        {{ readableSeconds(filteredItem.swapTime) }}
                        <div v-html="formatStartEndTime(filteredItem.meta.from, filteredItem.meta.to)"></div>
                    </div>
                </b-popover>
            </span>
        </div>

        <!--Content-->
        <div v-if="showNotEnoughDataWarning"></div>
        <div v-else-if='selectedType == "kpi-total"' v-bind:style="{ height: this.chartContentHeight+'px' }">
            <bar-chart v-if="!includePumpHoursPlotline"
                ref="kpiTotalChart"
                :style="{ position: 'relative', height: '99%', width: '100%' }"
                :chart-data="kpi.completedStagesPerDay"
                :options="options.completedStagesPerDay">
            </bar-chart>
            <bar-chart v-else
                ref="kpiTotalChart"
                :style="{ position: 'relative', height: '99%', width: '100%' }"
                :chart-data="kpi.pumpHoursAndStagesPerDay"
                :options="options.pumpHoursAndStagesPerDay">
            </bar-chart>
        </div>
        <div v-else-if='selectedType == "kpi-progress"' v-bind:style="{ height: this.chartContentHeight+'px' }" class="d-flex flex-column">
            <div class="d-flex flex-column flex-grow-1" v-bind:style="{ height: this.chartContentHeight< progressHeightTrigger ? this.chartContentHeight*0.7 +'px' : 'auto'  }">
                <simple-line-chart ref="completionProgressChart"
                    :chart-data="kpi.completionProgress"
                    :key="completionProgressChartKey"
                    :style="{ position: 'relative', width: '100%', height: '100%' }"
                    :options="options.completionProgress"
                    :customPlugins="chartPlugins">
                </simple-line-chart>
            </div>
            <div class="d-flex flex-1 flex-column">
                <div class="d-flex flex-1 flex-row">
                    <div class="pl-3 pr-4">
                        <div class="row">
                            <div class="custom-control custom-checkbox mt-1">
                                <input type="checkbox" class="custom-control-input" :id="'full-job' + componentId" v-model="isFullJob" @change="onFullJobchange"/>
                                <label class="custom-control-label  font-size" :for="'full-job' + componentId" >Full Job</label>
                            </div>
                        </div>
                    </div>
                    <div class="pl-4">
                        <div class="row">
                            <label for="progress-per-day-input" class="col-form-label-sm small mr-2">Projected Stages Per Day</label>
                            <div class="d-flex flex-grow-1"></div>
                            <div class="pr-2" >
                                <input class="form-control form-control-sm" style="max-width:75px;" type="number" id="progress-per-day-input" min="0" step="0.5" @input="handleFilterchange" v-model="filters.completionProgress.progressPerDay">
                            </div>
                            <div class="pr-2">
                                <button v-if='job.stagescompletedprojection != null && hasProjectionSetPermissions && projectedValueUpdated' id="save-default-value" ref='save-default-value' class='btn btn-primary btn-sm' @click="saveDefault">Save As Job Default</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if='selectedType == "kpi-lateral"' v-bind:style="{height: this.chartContentHeight+'px' }"
            :key="renderLateralLengthChange">
            <bar-chart
                ref="kpi-lateral"
                :style="{ position: 'relative', height: '99%', width: '100%' }"
                :chart-data="kpi.lateralLengthPerDay"
                :options="options.lateralLengthPerDay">

            </bar-chart>
        </div>
        <div v-else-if='selectedType == "kpi-swapTime" && isSwapTimeFeature' v-bind:style="{height: (this.chartContentHeight||100)+'px' }" :key="renderSwapTimeChange">
            <scatter-line-chart
                ref="kpiSwaptime"
                :style="{ position: 'relative', height: '99%', width: '100%' }"
                :chart-data="kpi.swapTimes"
                :options="options.swapTimes"
            />
        </div>
        <div v-else-if='selectedType == "kpi-metricsPerStage"' v-bind:style="{height: (this.chartContentHeight||100)+'px' }" :key="renderMetricsPerStageChange">
            <bar-chart
                ref="kpiMetricsPerStage"
                :style="{ position: 'relative', height: '99%', width: '100%' }"
                :chart-data="kpi.metricsPerStage"
                :options="options.metricsPerStage"
            />
        </div>
        <div v-else v-bind:style="{ height: this.chartContentHeight+'px' }"
            :key="renderPumpTimeHoursChange">
            <bar-chart
                    :chart-data="kpi.pumpTimes"
                    :options="options.pumpTimes"
                    :style="{ position: 'relative', flex: 1, height: '99%'}"
                    ref="pumpTimeChart">
            </bar-chart>
        </div>
        <div v-if="showNoPumpHourNotification">
            <b-alert show dismissible variant="warning">
                <b>No pump hour data! Job has no clean rate stream to perform data processing.</b>
            </b-alert>
        </div>

        <template v-if="_isIterable(errors)">
            <div v-for="error in errors" class="danger-text-color text-center">
                {{ error?.message || error }}
            </div>
        </template>
    </div>
</template>

<script>
import BarChart from '../barChart.js';
import SimpleLineChart from '../lineChart.js';
import ScatterLineChart from '../scatterLineChart.js';
import GlobalFunctions from '../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, isIterable, toast, validateBoolean } = GlobalFunctions;
import { v4 as uuidv4 } from "uuid";


import DateFunctions from '../DateFunctions.js';
const { dateTimeDisplay, applyTimeOffset, convertHoursToHourAndMinutes } = DateFunctions;
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
import chart from '../chart';
import _ from 'lodash';

const PADDING_SIZE = 32;
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const SECONDARY_DATE_FORMAT = 'MMMM Do, YYYY';
const EVENT_ID_STAGE_START = 60;
const EVENT_ID_STAGE_END = 57;
const ACTIVITY_ID_PUMP_TIME = 6;
const ANALYTICS_COLORS = ['#8EA604', '#aaffee', '#44e3c3', '#F5BB00', '#fa98ef', '#be39e3', '#BF3100'];
const ANALYTICS_COLORS_FOR_PLOTLINE = ['#e22b94', '#bc8238', '#f40d0f', '#c2b59f', '#8c30ef', '#92f210', '#a41a52'];
const DAY_PUMP_HOURS_PLOTLINE_COLOR = '#00008B';
const NIGHT_PUMP_HOURS_PLOTLINE_COLOR = '#228B22';
const DAY_STACK_NAME = 'day';
const NIGHT_STACK_NAME = 'night';
const maxAnalyticsRows = 4;
const NO_VALUE_PLACEHOLDER = '-';
const KPI_TYPES_WITH_ANALYTICS = ['kpi-total', 'kpi-lateral', 'kpi-swapTime', 'kpi-pumpingHours', 'kpi-metricsPerStage'];
const TOP_PADDING_KPI = 10;

const chartStyle = {
    completedStagesPerDay:
    {
        barBackgroundColor: '#1C7CD5',
        barBackgroundColorSecondary: '#000099',
        labelFontColor: '#CCCCCC',
        gridLinesColor: 'rgba(200, 200, 200, 0.2)',
        yAxisLabel: 'Stages Completed'
    },
    completionProgress:
    {
        barBackgroundColor: '#1C7CD5',
        barBackgroundColorSecondary: '#000099',
        labelFontColor: '#CCCCCC',
        gridLinesColor: 'rgba(200, 200, 200, 0.2)',
        yAxisLabel: 'Stage Count'
    },
    lateralLengthPerDay:
    {
        barBackgroundColor: '#1C7CD5',
        barBackgroundColorSecondary: '#000099',
        labelFontColor: '#CCCCCC',
        gridLinesColor: 'rgba(200, 200, 200, 0.2)',
        yAxisLabel: 'Lateral Length (ft)'
    },
    pumpTimes:
    {
        barBackgroundColor: '#1C7CD5',
        barBackgroundColorSecondary: '#000099',
        labelFontColor: '#CCCCCC',
        gridLinesColor: 'rgba(200, 200, 200, 0.2)',
        yAxisLabel: 'Pump Time (Hours)'
    },
    swapTimes:
    {
        barBackgroundColor: '#1C7CD5',
        barBackgroundColorSecondary: '#000099',
        labelFontColor: '#CCCCCC',
        gridLinesColor: 'rgba(200, 200, 200, 0.2)',
        yAxisLabel: 'Swap Duration (Minutes)',
        xAxisLabel: 'Swap Order'
    },
    metricsPerStage:
    {
        barBackgroundColor: '#1C7CD5',
        barBackgroundColorSecondary: '#000099',
        labelFontColor: '#CCCCCC',
        gridLinesColor: 'rgba(200, 200, 200, 0.2)',
        yAxisLabel: 'Total Value',
        xAxisLabel: 'Stage Number'
    }
};

export default {
    components: {
        BarChart,
        SimpleLineChart,
        ScatterLineChart
    },
    props: {
        item: Object,
        jobNumber: String,
        customer: Object,
        editMode: Boolean,
        dashboardData: Object,
        height: Number
    },
    watch: {
        'isStackedByWell': {
            handler: function(newValue, oldValue)
            {
                const chart = this.getChartRef();
                this.setupPumpHoursPerDay(true);
                this.setupCompletedStagesPerDay();
                if (this.isCombinedPumpHoursAndStagesChart) {
                    this.setupCombinedPumpHoursAndStages();
                }
                if(this.selectType !== 'kpi-progress') {
                    // added to make sure analytics persists between kpi type changes
                    this.checkForAnalytics();
                }
                if(chart) {
                    this.setupKPIChart('pumpTimes');
                    this.setupKPIChart('completedStagesPerDay');
                    if (this.isCombinedPumpHoursAndStagesChart) {
                        this.setupKPIChart('pumpHoursAndStagesPerDay');
                    }
                    chart.config.options.scales.yAxes[0].stacked = newValue;
                    chart.config.options.scales.xAxes[0].stacked = newValue;
                    chart.update();
                }
            }
        },
        'includePumpHoursPlotline': {
            handler: function(newValue, oldValue)
            {
                this.onAnalyticsTypeChange();
                this.setupCombinedPumpHoursAndStages();
                const chart = this.getChartRef();
                if(chart && newValue) {
                    chart.config.options.scales.yAxes[0].stacked = this.isStackedByWell;
                    chart.config.options.scales.xAxes[0].stacked = this.isStackedByWell;
                    this.setupKPIChart('pumpHoursAndStagesPerDay');
                    chart.update();
                }
            }
        },
        'selectedAnalyticTypes': {
            handler: function(newValue, oldValue)
            {
                this.onAnalyticsTypeChange(newValue);
            }
        },
        kpi: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                this.getYAxisMax();
            }
        },
        'selectedDayType': {
            handler: function(newValue, oldValue)
            {
                // If the selected KPI type is kpi-pumpingHours and the selected day type is stage then set isStackedByWell to true
                // Stage day type is always stacked by well and doesn't have other options
                if (this.isDayTypeStage && newValue === 'Stage') {
                    this.isStackedByWell = true
                } else if (!this.isDayTypeStage && oldValue === 'Stage') {
                    this.isStackedByWell = false
                }
                this.getYAxisMax();
                if (newValue && this.isCombinedPumpHoursAndStagesChart) {
                    this.getCalculatedPumptimes();
                }
                if(newValue && this.rawCompletedStages?.length > 0) {
                    this.dataLookup = {};
                    this.processResponse(this.rawCompletedStages);
                };
                if (newValue && this.selectedType === 'kpi-lateral') {
                    this.getCalculatedLateralLength();
                }
                if (newValue && this.selectedType === 'kpi-pumpingHours') {
                    this.getCalculatedPumptimes();
                }
                // cumulative stages per day doesn't have analytics
                if(this.selectType !== 'kpi-progress') {
                    // added to make sure analytics persists between day type changes
                    this.checkForAnalytics();
                }
                this.$nextTick(()=>{
                    this.createJobTimeAnnotationLines();
                });
            }
        },
        'selectedType': {
            handler: function(newValue, oldValue)
            {
                this.getYAxisMax();
                // If the selected KPI type is kpi-pumpingHours and the selected day type is stage then set isStackedByWell to true
                // Stage day type is always stacked by well and doesn't have other options
                if (this.isDayTypeStage) {
                    this.isStackedByWell = true
                }
                if(this.selectedType === 'kpi-pumpingHours') {
                    this.getCalculatedPumptimes();
                }
                // cumulative stages per day doesn't have analytics
                if(this.selectType !== 'kpi-progress') {
                    // added to make sure analytics persists between kpi type changes
                    this.checkForAnalytics();
                }
                if (this.selectedType === 'kpi-metricsPerStage' && !!oldValue) {
                    this.fetchMetadataTags()
                    this.fetchMetricsData();
                }
                this.$nextTick(()=>{
                    this.createJobTimeAnnotationLines();
                });
                // If the selected KPI type is saved then update the selectedDayType with the saved KPI day type
                // otherwise reset the selected day type when the KPI type changes, default one is 'Shift start to shift start'
                if (this.item.options.type == newValue && this.item.options.dayType) {
                    this.selectedDayType = this.item.options.dayType;
                } else {
                  this.selectedDayType = this.dayTypes[0];
                }
            }
        },
        'selectedZeroStageType': {
            handler: function(newValue, oldValue)
            {
                this.$nextTick(()=>{
                    this.createJobTimeAnnotationLines();
                });
            }
        },
        scatterTag: {
            handler(newValue, oldValue) {
                // If the tag is set but changes, clear the old tags errors and fetch new data
                if (oldValue !== -1) {
                    this.clearTagError(oldValue);
                    this.fetchMetricsByTag(newValue, 'scatterJSON', true);
                }
            }
        },
        barTag: {
            handler(newValue, oldValue) {
                // If the tag is set but changes, clear the old tags errors and fetch new data
                if (oldValue !== -1) {
                    this.clearTagError(oldValue);
                    this.fetchMetricsByTag(newValue, 'barJSON', true);
                }
            }
        },
        'dashboardData.latestActivityData': {
            handler(newValue, oldValue) {
                if (this.isSwapTimeFeature && this.selectedType == 'kpi-swapTime')
                    if (newValue?.length != oldValue?.length)
                        this.getCalculatedSwapTimes();
            }
        },
        'selectedPumpTimeSource': {
            handler() {
                if (this.selectedType == 'kpi-pumpingHours')
                    this.getCalculatedPumptimes();
            }
        },
    },
    mounted() {
        this.analyticsColors = ANALYTICS_COLORS;
        this.analyticsColorsPlotline = ANALYTICS_COLORS_FOR_PLOTLINE;
        this.selectedType = this.item.options.type ? this.item.options.type : 'kpi-total';
        this.includePumpHoursPlotline = this.item?.options?.includePumpHoursPlotline;
        this.selectedDayType = this.item.options.dayType ? this.item.options.dayType : 'Shift start to shift start';
        this.selectedSwapType = this.item.options.selectedSwapType || 'wlToFrac';
        this.selectedPumpTimeSource = this.item.options.selectedPumpTimeSource ? this.item.options.selectedPumpTimeSource : 'mongoDB';
        this.isStackedByWell = validateBoolean(this.item.options?.isStackedByWell);
        this.wirelineSwapFilter = this.item.options.wirelineSwapFilter || 1;
        this.scatterTag = this.item.options.scatterTag || 'stage_pressure_avgTreatmentZipper1';
        this.barTag = this.item.options.barTag || 'stage_rate_avgTreatmentSlurry';

        this.averageWellDataMetricsPerStage = this.item.options.averageWellDataMetricsPerStage;
        this.limitStagesMetricsPerStage = this.item.options.limitStagesMetricsPerStage;
        this.showLimitStageMetrics = !isFalsy(this.limitStagesMetricsPerStage);

        // If a min or max swap time filter is active but is not when of our presets, switch to custom type
        this.minSwapTimeFilter = this.item.options.minSwapTimeFilter;
        if (!isFalsy(this.minSwapTimeFilter) && !this.minSwapFilterOptions.find(_filter => _filter.value == this.minSwapTimeFilter))
            this.customMinSwap = true;

        this.maxSwapTimeFilter = this.item.options.maxSwapTimeFilter;
        if (!isFalsy(this.minSwapTimeFilter) && !this.maxSwapFilterOptions.find(_filter => _filter.value == this.maxSwapTimeFilter))
            this.customMaxSwap = true;

        this.selectedZeroStageType = this.item.options.zeroStageType ? this.item.options.zeroStageType : 'zeroStage-include';
        this.options.type = this.selectedType;
        this.options.dayType = this.selectedDayType;
        this.initialSelectedType = this.selectedType;
        this.initialSelectedDayType = this.selectedDayType;
        this.initialSelectedZeroStageType = this.selectedZeroStageType;
        this.initialPumpHoursPlotlineVal = this.includePumpHoursPlotline;

        this.initialWirelineSwapFilter = this.wirelineSwapFilter;
        this.initialMinSwapTimeFilter = this.minSwapTimeFilter;
        this.initialMaxSwapTimeFilter = this.maxSwapTimeFilter;

        //hides combined pump hours and total stages chart when there is no feature flag
        if (!this.isFeatureFlagged('PUMP_HOUR_PLOTLINE')) {
            this.includePumpHoursPlotline = false;
        }

        if(([this.kpiTypes[0], this.kpiTypes[3]].includes(this.selectedType) || this.includePumpHoursPlotline) && this.isStackedByWell) {
            this.setupKPIChart('pumpTimes');
            this.setupKPIChart('completedStagesPerDay');
            if (this.isCombinedPumpHoursAndStagesChart && this.isFeatureFlagged('PUMP_HOUR_PLOTLINE')) {
                this.setupKPIChart('pumpHoursAndStagesPerDay');
            }
        }
        this.getKPIData();

        if (this.isSwapTimeFeature && this.selectedType == 'kpi-swapTime') {
            this.getCalculatedSwapTimes();
        } else if (this.selectedType == 'kpi-metricsPerStage') {
            this.fetchMetadataTags().then(this.fetchMetricsData);
        }
    },
    computed: {
        _uuid() {
            return uuidv4();
        },
        showAnalytics() {
            return this.analyticsData.length > 0 && this.kpiTypesWithAnalytics.includes(this.selectedType);
        },
        columnCount() {
            if (this.analyticsData.length <= 3) {
                return this.analyticsData.length;
            } else if (this.analyticsData.length === 4) {
                return 2;
            } else {
                return 3;
            }
        },
        showZeroValueAnalyticField() {
            return !['kpi-swapTime', 'kpi-metricsPerStage', 'kpi-progress'].includes(this.selectedType);
        },
        showNoPumpHourNotification: function() {
            //case where 'include pump hour plotline' on total stages chart is toggled but there is no pump hour data
            return this.isNotEnoughDataMsgPerKPIType("kpi-total", this.kpi.pumpTimes) && this.getChartRef() && this.isFeatureFlagged('PUMP_HOUR_PLOTLINE') && this.isCombinedPumpHoursAndStagesChart;
        },
        showPumpHourPlotlineOption: function() {
            return this.selectedType === "kpi-total" && this.isFeatureFlagged('PUMP_HOUR_PLOTLINE');
        },
        isCombinedPumpHoursAndStagesChart: function() {
            return this.includePumpHoursPlotline && this.selectedType === 'kpi-total';
        },
        getTimeRangeDescription() {
            let type = this.selectedDayType ? this.selectedDayType : this.dayTypes[0];
            // If day type is 'Stage' then return 'Stage' as the time range description and exit
            if (this.dayTypes[3] && type === this.dayTypes[3]) {
                return this.dayTypes[3];
            }
            const shiftStartTime = this.getShiftStartTimes();
            let dayShiftStart = '';
            let timeRangeDescription = '';
            if (shiftStartTime.dayShift != 'Invalid date') {
                let startHour = parseInt(shiftStartTime.dayShift.split(':')[0]);
                let startTime = startHour > 12 ? shiftStartTime.nightShift : shiftStartTime.dayShift;
                startTime = startTime.slice(0, -3); // format time to remove seconds and leading 0
                startTime = startTime.replace(/^0+/, '');
                startTime = startHour === 0 ? '12' + startTime : startTime;
                let shiftTimeDescription = startHour < 12 ? 'AM' : 'PM';
                dayShiftStart = ' (' + startTime + shiftTimeDescription + ' shift change)';
            }

            if (this.dayTypes[0] && type === this.dayTypes[0]) {
                timeRangeDescription = 'Shift Start to Shift Start' + dayShiftStart;
            } else if (this.dayTypes[1] && type === this.dayTypes[1]) {
                timeRangeDescription = 'Midnight to Midnight (12:00AM to 12:00AM)';
            } else if (this.dayTypes[2] && type === this.dayTypes[2]) {
                timeRangeDescription = 'Day Shift and Night Shift' + dayShiftStart;
            } else {
                timeRangeDescription = '';
            }
            return timeRangeDescription;
        },
        showNotEnoughDataWarning: function() {
            //This function calls method isNotEnoughDataMsgPerKPIType for the different cases
            //and is used to display error message when there is not enough data for KPI summary
            switch(this.selectedType){
                case 'kpi-lateral':
                    return this.isNotEnoughDataMsgPerKPIType(this.selectedType, this.kpi.lateralLengthPerDay);
                case 'kpi-pumpingHours':
                    return this.isNotEnoughDataMsgPerKPIType(this.selectedType, this.kpi.pumpTimes);
                case 'kpi-progress':
                    return this.isNotEnoughDataMsgPerKPIType(this.selectedType, this.kpi.completionProgress);
                case 'kpi-total':
                    return this.isNotEnoughDataMsgPerKPIType(this.selectedType, this.kpi.completedStagesPerDay);
                case 'kpi-swapTime':
                    return isNullOrEmpty(this.kpi.swapTimes?.datasets[0]?.data);
                case 'kpi-metricsPerStage':
                    return this.kpi.metricsPerStage?.datasets.map(_dataset => _dataset?.data?.length || 0).reduce((_sum, a) => _sum + a, 0) == 0;
                default:
                    return true;
            }
        },
        updatedSelectedAnalytics: function() {
            //legacy handling: new array to solve bug where # of analytic type is wrong
            //due to old standard deviation option:
            let analyticsWithoutSTD = this.selectedAnalyticTypes.filter(an => an !== 'Standard Deviation');
            return analyticsWithoutSTD;
        },
        chunkTotalStagesAnalytics: function() {
            return _.chunk(this.analyticsData, maxAnalyticsRows);
        },
        dataOptionsKey: function() {
            switch (this.selectedType) {
            case 'completionProgressChart':
                return 'completionProgress';
            case 'kpi-total':
                return 'completedStagesPerDay';
            default:
                return 'completionProgress';
            }
        },
        dayTypes: function() {
            const types = [
                'Shift start to shift start', // Default time option for all KPI types
                'Midnight to midnight'
            ];

            if(this.selectedType !== 'kpi-progress') {
                types.push('Day shift and night shift');
            }

            if(this.selectedType == 'kpi-pumpingHours') {
                types.push('Stage');
            }

            return types;
        },
        chartContentHeight: function() {
            return (this.height-(this.headerHeight+PADDING_SIZE));
        },
        projectedValueUpdated: function() {
            this.ticker;
            return this.filters.completionProgress.progressPerDay != this.job.stagescompletedprojection;
        },
        hasProjectionSetPermissions: function() {
            return (this.dashboardData.isAdmin || this.dashboardData.isCompanyAdmin || this.dashboardData.iwsUser) ? true : false;
        },
        renderLateralLengthChange() {
            return this.kpi.lateralLengthPerDay.datasets.length + this.kpi.lateralLengthPerDay.labels.length;
        },
        renderPumpTimeHoursChange() {
            return this.kpi.pumpTimes.datasets?.length + this.kpi.pumpTimes.labels?.length;
        },
        renderSwapTimeChange() {
            return this.kpi.swapTimes.datasets?.length + this.kpi.swapTimes.labels?.length;
        },
        renderMetricsPerStageChange() {
            return this.kpi.metricsPerStage?.datasets.map(_dataset => _dataset?.data?.length || 0).reduce((_sum, a) => _sum + a, 0) + this.kpi.metricsPerStage.labels?.length;
        },
        // Unless the job start time is actually before shift start, we will miss some stages by the time the job actually starts
        // Start time varies between midnight and shift start depending on setting
        firstStagesMissing() {
            // Shift start/length depends on graph settings
            let startTime, endTime;
            if (this.selectedDayType === 'Midnight to midnight') {
                const shifts = this.getShiftStartTimes();
                startTime = shifts.dayShift;
                endTime = shifts.nightShift;
            } else {
                startTime = '00:00:00';
                endTime = '23:59:59';
            }

            const jobStart = applyTimeOffset(this.job.start, this.job.hourOffset, 'HH:mm:ss');
            const shiftLength = moment(endTime, 'HH:mm:ss') - moment(startTime, 'HH:mm:ss');
            const timeMissed = moment(jobStart, 'HH:mm:ss') - moment(startTime, 'HH:mm:ss');
            const missedStages = Math.round(this.filters.completionProgress.progressPerDay * (timeMissed / shiftLength));

            // If timeMissed is negative, the shift started after the job started and we missed 0 stages
            if (timeMissed < 0)
                return 0;

            // Missed stages cannot exceed predicated progress in a single day
            if (missedStages > this.filters.completionProgress.progressPerDay)
                return this.filters.completionProgress.progressPerDay;

            // Determine how many stages they missed based on how far into day/shift we are so far
            return Math.max(missedStages, 0);
        },
        averageStagesCompletedPerDay(){
            let dataSets = _.cloneDeep(this.kpi.completedStagesPerDay.datasets[0].data)
            if(this.job.end === null){
                while(dataSets[dataSets.length-1] === 0){ // While the last element is a 0,
                    dataSets.pop();
                }
                dataSets = this.modifyArrayWithZeroHandling(dataSets)
            }else{
                dataSets.length > 0 ? dataSets.pop() : null;
            }
            return dataSets.length > 0 ? this.getAverage(dataSets).toFixed(2) : 0;
        },
        averageActualStagesPerDay() {
            const allFullDays = [ ...(this.kpi.completionProgress.datasets[1].data || []) ];
            const defaultProg = this.filters.completionProgress.progressPerDay || 0;

            if (isNullOrEmpty(allFullDays) || allFullDays.length <= 1)
                return defaultProg;

            // Always assume last day is incomplete (we wouldn't be calculating predictions after job completed)
            allFullDays.pop();

            return this.calculateAverageProgress(allFullDays);
        },
        filterInfoDetails() {
            if (!isNullOrEmpty(this.kpi?.swapTimes?.datasets[0]?.data)) {
                // When applying filters, show how many were filtered out vs the full list
                if (!isNullOrEmpty(this.filteredItems)) {
                    let details = `${this.filteredItems.length} / ${this.filteredItems.length+this.kpi.swapTimes.datasets[0].data.length} ${this.swapTypeLabel.lower} events were filtered out (`;

                    if (!isFalsy(this.minSwapTimeFilter))
                        details += `>= ${Math.floor(this.minSwapTimeFilter/60)} mins`;
                    if (!isFalsy(this.minSwapTimeFilter) && !isFalsy(this.maxSwapTimeFilter))
                        details += ` and `;
                    if (!isFalsy(this.maxSwapTimeFilter))
                        details += `<= ${Math.floor(this.maxSwapTimeFilter/60)} mins`;
                    details += ')';

                    return details;
                }
                return `${this.kpi.swapTimes.datasets[0].data.length} total ${this.swapTypeLabel.lower} events`;
            }

            // No data, just show nothing
            return '';
        },
        swapTypeLabel() {
            if (this.selectedSwapType == 'wlToFrac' || this.selectedSwapType == 'fracToWl') {
                this.kpi.swapTimes.datasets[0].label = `Transition Duration (Minutes)`;
                this.options.swapTimes.scales.yAxes[0].scaleLabel.labelString = `Transition Duration (Minutes)`;
                this.options.swapTimes.scales.xAxes[0].scaleLabel.labelString = `Transition Order`;
                return {
                    upper: 'Transition',
                    lower: 'transition'
                };
            }

            this.kpi.swapTimes.datasets[0].label = `Standby Duration (Minutes)`;
            this.options.swapTimes.scales.yAxes[0].scaleLabel.labelString = `Standby Duration (Minutes)`;
            this.options.swapTimes.scales.xAxes[0].scaleLabel.labelString = `Standby Order`;
            return {
                upper: 'Standby',
                lower: 'standby'
            };
        },
        requireWirelineCheck() {
            return this.jobNumber?.startsWith('SF') && this.selectedSwapType == 'wlToWl' && !isNullOrEmpty(this.dashboardData.wirelineSystems) && this.dashboardData.wirelineSystems.length > 1;
        },
        selectedWireline() {
            if (this.requireWirelineCheck && !isNullOrEmpty(this.dashboardData?.wirelineSystems))
                return this.dashboardData?.wirelineSystems?.find(wireline => wireline.number == this.wirelineSwapFilter);
            return null;
        },
        isSwapTimeFeature() {
            return GlobalFunctions.isFeatureFlagged('swapTimeKpi') || GlobalFunctions.isFeatureFlagged('SWAP_TIME_KPI');
        },
        isDayTypeStage() {
            return this.selectedType == 'kpi-pumpingHours' && this.selectedDayType === 'Stage';
        }
    },
    methods: {
        _isFalsy(value) {
            return isFalsy(value);
        },
        _isNullOrEmpty(value) {
            return isNullOrEmpty(value);
        },
        _isIterable(value) {
            return isIterable(value);
        },
        secondsToMinutes(val) {
            if (!isFalsy(val) && val > 0)
                return val / 60;
            return null;
        },
        minutesToSeconds(val) {
            if (!isFalsy(val) && val > 0)
                return val * 60;
            return null;
        },

        getYAxisMax() {
            const chart = this.getChartRef();
            if(chart && (this.selectedType === 'kpi-total' || this.isCombinedPumpHoursAndStagesChart)) {
                const data = chart?.config?.data.datasets.filter(dataset => !dataset.type); //bar chart datasets
                const max = Math.max(...data.reduce((arr, d) => arr.concat(d.data), []));
                const stepSize = parseInt(chart.options.scales.yAxes[0].ticks.stepSize)
                const maxStagesChart = Math.ceil(max / stepSize) * stepSize;

                if (this.maxStagesChart !== maxStagesChart) {
                    this.maxStagesChart = maxStagesChart;
                    chart.options.scales.yAxes[0].ticks.suggestedMax = this.maxStagesChart;
                    chart.update();
                }
            }
        },
        isFeatureFlagged(featureString) {
            return GlobalFunctions.isFeatureFlagged(featureString);
        },
        pumpHoursToXDecimalPoints(res, X) {
            const roundedObj = _.mapValues(res, (nestedObj) => {
                return _.mapValues(nestedObj, (value) => {
                    return parseFloat(GlobalFunctions.roundAccurately(value, X).toFixed(X));
                });
            });
            return roundedObj;
        },
        onAnalyticsSelected: function(selectedAnalytics) {
            this.selectedAnalyticTypes = selectedAnalytics;
            this.onSelectedAnalyticsChanged=true;
            this.onDataChanged();
        },
        onSelectPumpHoursPlotline: function() {
            this.includePumpHoursPlotline = !this.includePumpHoursPlotline;
            this.onDataChanged();
        },
        getChartTypeByDataKey: function(dataKey) {
            switch(dataKey) {
                case 'lateralLengthPerDay':
                    return 'kpi-lateral';
                    break;
                case 'pumpTimes':
                    return 'kpi-pumpingHours';
                    break;
                case 'completionProgress':
                    return 'kpi-progress';
                    break;
                case 'completedStagesPerDay':
                    return 'kpi-total';
                    break;
                default:
                    return null;
                    break;
            }
        },
        getTooltipKeyFromType(selectedType)
        {
            return this.kpiTooltipKeys[selectedType];
        },
        createJobTimeAnnotationLines() {
            if (this.selectedType == 'kpi-swapTime' || this.selectedType == 'kpi-metricsPerStage' ||this.isDayTypeStage)
                return;

            const chart = this.getChartRef();
            if (!chart || (!this.job?.start && !this.job?.end)) {
                return;
            }
            //charts use different time formats for their x axis values
            const formatArray = [DEFAULT_DATE_FORMAT, SECONDARY_DATE_FORMAT, 'YYYY-MM-DD'];
            const dateTicks = chart.scales['date-axis']._ticks;
            if (this.job?.start && dateTicks.length > 0) {
                const newAnnotation = _.cloneDeep(this.defaultAnnotation);
                const startDate = moment.utc(this.job.start, formatArray).add({hours: this.job.hourOffset}).format(('MMM D'));
                const startIndex = dateTicks.findIndex(tick => moment.utc(tick.value, formatArray).format('MMM D') == startDate);
                newAnnotation.value = startIndex;
                newAnnotation.id = 'job-start';
                newAnnotation.label.content = 'Job Start';
                chart.options.annotation.annotations.push(newAnnotation);
            }
            if (this.job?.end && dateTicks.length > 0) {
                const newAnnotation = _.cloneDeep(this.defaultAnnotation);
                const endDate = moment.utc(this.job.end,formatArray).add({hours: this.job.hourOffset}).format(('MMM D'));
                const endIndex = dateTicks.findIndex(tick => moment.utc(tick.value, formatArray).format('MMM D') == endDate);
                newAnnotation.value = endIndex;
                newAnnotation.id = 'job-end';
                newAnnotation.label.content = 'Job End';
                chart.options.annotation.annotations.push(newAnnotation);
            }
            // update to create the annotation elements
            chart.update();
            if (this.job?.start) {
                const element = Object.values(chart.annotation.elements).find(element => element.id == 'job-start');
                //job start annotation is right shifted so it stays within view if placed on chart's first day
                if(element) {
                    const xAdjustValue = (element._model.labelWidth / 2) - 1;
                    element.options.label.xAdjust = -1*xAdjustValue;
                }
            }
            if (this.job?.end) {
                const element = Object.values(chart.annotation.elements).find(element => element.id == 'job-end');
                //job start annotation is left shifted so it stays within view if placed on chart's last day
                if(element) {
                    const xAdjustValue = (element._model.labelWidth / 2) - 1;
                    element.options.label.xAdjust = xAdjustValue;
                }
            }
            //update to adjust annotation positions
            chart.update();
        },
        isNotEnoughDataMsgPerKPIType(selectedKpiType, dataKpiType) {
            /* This function computes if there is no data based on the kpi type.
            If 'datasets' array for that type is empty, or if all 'data' arrays
            within 'datasets' is empty, or there are multiple 'data' arrays but
            they have all falsy values like null's or zeroes, then there is not enough
            data to generate a KPI summary */
            if (dataKpiType && (this.selectedType == selectedKpiType || this.isCombinedPumpHoursAndStagesChart)) {
                //if dataset is empty (length=0) then return true
                if (dataKpiType.datasets?.length == 0) {
                    return true;
                }
                let areDatasetsEmpty = dataKpiType.datasets.every(
                    function(el, index, arr){
                         //if data arrays for each dataset has length zero or all falsy values (null, 0, etc), then return true
                        if (el.data.length === 0 || (el.data.every(item => Boolean(item) === false )) === true) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    });
                if (areDatasetsEmpty === true) {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        isChartExportable() {
            /* Determine if current configuration of chart is exportable.
               To be used for export overlay confirmation button. */
            if(this.showNotEnoughDataWarning){
                return false;
            }
            else if(this.selectedType == 'kpi-pumpingHours'){
                const isDayNight = this.selectedDayType === 'Day shift and night shift';
                if(isDayNight && (!this.pumpTimeData.day || !this.pumpTimeData.night)) {
                    return false;
                }
            }

            return true;
        },
        processExportItems(dataSets, labels, isDayNight) {
            let items = [];
            for (let i = 0; i < labels.length; i++) {
                if (this.isCombinedPumpHoursAndStagesChart) {
                    if(isDayNight){
                        if (this.isStackedByWell) {
                            const rawDatasets_stages = dataSets.filter(dataset => !dataset.type).map(dataset => dataset.data[i]);
                            let rawDayDatasets_pump = dataSets.filter((dataset)=>(dataset.label == DAY_STACK_NAME && dataset.type === 'line'));
                            let rawNightDatasets_pump = dataSets.filter((dataset)=>(dataset.label == NIGHT_STACK_NAME && dataset.type === 'line'));

                            items.push([
                                labels[i],
                                ...rawDatasets_stages,
                                rawDayDatasets_pump[0]?.data[i] ?? NO_VALUE_PLACEHOLDER,
                                rawNightDatasets_pump[0]?.data[i] ?? NO_VALUE_PLACEHOLDER
                            ]);
                        } else {
                            let rawDayDatasets_stages = dataSets.filter((dataset)=>(dataset.label == DAY_STACK_NAME && !dataset?.type));
                            let rawNightDatasets_stages = dataSets.filter((dataset)=>(dataset.label == NIGHT_STACK_NAME && !dataset?.type));
                            let rawDayDatasets_pump = dataSets.filter((dataset)=>(dataset.label == DAY_STACK_NAME && dataset.type === 'line'));
                            let rawNightDatasets_pump = dataSets.filter((dataset)=>(dataset.label == NIGHT_STACK_NAME && dataset.type === 'line'));

                            items.push([
                                labels[i],
                                rawDayDatasets_stages[0]?.data[i] ?? NO_VALUE_PLACEHOLDER,
                                rawNightDatasets_stages[0]?.data[i] ?? NO_VALUE_PLACEHOLDER,
                                rawDayDatasets_pump[0]?.data[i] ?? NO_VALUE_PLACEHOLDER,
                                rawNightDatasets_pump[0]?.data[i] ?? NO_VALUE_PLACEHOLDER
                            ]);
                        }
                    }
                    else {
                        //works ifWellStacked is true or false
                        const rawDatasets_stages = dataSets.filter(dataset => !dataset.type).map(dataset => dataset.data[i]);
                        const rawDatasets_pump = dataSets.filter(dataset => dataset.type === 'line').map(dataset => dataset?.data[i]);
                        items.push([labels[i], ...rawDatasets_stages ?? NO_VALUE_PLACEHOLDER, ...rawDatasets_pump ?? NO_VALUE_PLACEHOLDER]);
                    }
                } else {
                    if(isDayNight){
                        items.push([labels[i], dataSets[0].data[i] ?? NO_VALUE_PLACEHOLDER, dataSets[1].data[i] ?? NO_VALUE_PLACEHOLDER]);
                    }
                    else {
                        items.push([labels[i], dataSets[0].data[i] ?? NO_VALUE_PLACEHOLDER]);
                    }
                }
            }
            return items;
        },
        processExportItemsForProgress(dataSets, labels) {
            let items = [];

            const minLength = Math.min(labels.length, dataSets[1].data.length, dataSets[0].data.length);
            for (let i = 0; i < minLength; i++) {
                items.push([labels[i], dataSets[1].data[i], dataSets[0].data[i]]);
            }

            return items;
        },
        processStackedExportItems(dataSets, labels) {
            let row;
            let items = [];
            for (let i = 0; i < labels.length; i++) {
                row = [labels[i]]; // Start each row with date label
                for (var k = 0; k < dataSets.length; k++) {
                    row.push(dataSets[k].data[i]);
                }
                items.push(row);
            }
            return items;
        },
        processStackedExportFields(dataSets) {
            let label, colon;
            let fields = this.isDayTypeStage ? ['Stage'] : ['Date'];
            for (let i = 0; i < dataSets.length; i++) {
                label = dataSets[i].label;
                // "Remove ': ' after label
                colon = label.indexOf(':');
                if(colon != -1){
                    label = label.substring(0, colon);
                }
                fields.push(label);
            }
            return fields;
        },
        exportChart() {
            let isDayNight = this.selectedDayType === 'Day shift and night shift';
            let dataSets, labels, fields, items, fileName, fieldsStacked;

            if(this.selectedType == 'kpi-pumpingHours'){
                dataSets = this.kpi.pumpTimes.datasets;
                labels = this.kpi.pumpTimes.exportLabels;

                if(this.isStackedByWell) {
                    fields = this.processStackedExportFields(dataSets);
                    items = this.processStackedExportItems(dataSets, labels);
                }
                else {
                    fields = isDayNight ? ['Date', 'Day', 'Night'] : this.isDayTypeStage ? ['Stage', 'Hours'] : ['Date', 'Hours'];
                    items = this.processExportItems(dataSets, labels, isDayNight);
                }
                fileName = this.getExportFilename(isDayNight ? this.selectedType + '-dayNight' : this.selectedType);
            }
            else if(this.selectedType == 'kpi-total'){
                if (this.includePumpHoursPlotline && !this.showNoPumpHourNotification) {
                    labels = this.kpi.pumpHoursAndStagesPerDay.defaultFormatLabels;
                    dataSets = this.kpi.pumpHoursAndStagesPerDay.datasets;
                    if(this.isStackedByWell){
                        let dataSets_stages = dataSets.filter(dataset => !dataset.type);
                        fieldsStacked = this.processStackedExportFields(dataSets_stages);
                        let pumpHourPlotlineFields = isDayNight ? ['Pump Time (Day)', 'Pump Time (Night)'] : ['Pump Time'];
                        fields = [...fieldsStacked, ...pumpHourPlotlineFields];
                        items = this.processExportItems(dataSets, labels, isDayNight);
                    } else {
                        fields = isDayNight ? ['Date', 'Stages (Day)', 'Stages (Night)', 'Pump Time (Day)', 'Pump Time (Night)'] : ['Date', 'Stages', 'Pump Time'];
                        items = this.processExportItems(dataSets, labels, isDayNight);
                    }
                    fileName = this.getExportFilename(this.selectedType + '-including-pump-hours');
                } else {
                    labels = this.kpi.completedStagesPerDay.defaultFormatLabels;
                    dataSets = this.kpi.completedStagesPerDay.datasets;
                    if(this.isStackedByWell){ // Stages need to be added as fields in case of stacked by well
                        fields = this.processStackedExportFields(dataSets);
                        items = this.processStackedExportItems(dataSets, labels);
                    }
                    else {
                        fields = isDayNight ? ['Date', 'Day', 'Night'] : ['Date', 'Stages'];
                        items = this.processExportItems(dataSets, labels, isDayNight);
                    }
                    fileName = this.getExportFilename(isDayNight ? this.selectedType + '-dayNight' : this.selectedType);
                }
            }
            else if(this.selectedType == 'kpi-progress'){
                dataSets = this.kpi.completionProgress.datasets;
                // while the chart headers is in secondary date format, we want the export
                // labels in default date format
                labels = this.initialProgressDefaultFormatLabels;
                fields = ['Date', 'Actual Stages Complete', 'Projected Stages Complete'];

                items = this.processExportItemsForProgress(dataSets, labels);
                fileName = this.getExportFilename(this.selectedType);
            }
            else if(this.selectedType == 'kpi-lateral'){
                dataSets = this.kpi.lateralLengthPerDay.datasets;
                labels = this.kpi.lateralLengthPerDay.defaultFormatLabels;
                fields = isDayNight? ['Date', 'Day Length', 'Night Length'] : ['Date', 'Length'];
                items = this.processExportItems(dataSets, labels, isDayNight);
                fileName = this.getExportFilename(this.selectedType);
            } else if (this.selectedType == 'kpi-swapTime') {
                dataSets = _.cloneDeep(this.kpi.swapTimes.datasets);
                labels = _.cloneDeep(this.kpi.swapTimes.labels);
                fields = ['Index', 'Swap Description (include stage and Activity)', 'Start', 'End', 'From (Well)', 'From (Stage)', 'From (Activity)', 'To (Well)', 'To (Stage)', 'to (Activity)', `${this.swapTypeLabel.upper} Duration (Minutes)`, 'Duration (Seconds)'];

                // Scatter Lines have a different structure, restructure to fit the export function
                items = this.processExportItems(dataSets, labels).map(_list => {
                    // Should be a list of exactly two
                    if (isNullOrEmpty(_list) || _list.length !== 2)
                        return null;

                    const minutes = Math.floor(_list[1].y/60);
                    const seconds = +((_list[1].y - (minutes * 60)).toFixed(2));

                    return [
                        _list[0],
                        `${_list[1]?.meta?.from?.name} ${_list[1]?.meta?.from?.stageNumber} ${_list[1]?.meta?.from?.activity} to ${_list[1]?.meta?.to?.name} ${_list[1]?.meta?.to?.stageNumber} ${_list[1]?.meta?.to?.activity}`,
                        applyTimeOffset(_list[1]?.meta?.from?.endTime, this.job.hourOffset),
                        applyTimeOffset(_list[1]?.meta?.to?.startTime, this.job.hourOffset),
                        _list[1]?.meta?.from?.name,
                        _list[1]?.meta?.from?.stageNumber,
                        _list[1]?.meta?.from?.activity,
                        _list[1]?.meta?.to?.name,
                        _list[1]?.meta?.to?.stageNumber,
                        _list[1]?.meta?.to?.activity,
                        `${minutes}.${seconds}`,
                        _list[1].y
                    ];
                }).filter(_list => !isNullOrEmpty(_list));

                let preName = this.selectedType+'-'+this.selectedSwapType;

                if (!isFalsy(this.minSwapTimeFilter))
                    preName += `MoreThan${Math.floor(this.minSwapTimeFilter/60)}Mins`;
                if (!isFalsy(this.maxSwapTimeFilter))
                    preName += `LessThan${Math.floor(this.maxSwapTimeFilter/60)}Mins`;

                fileName = this.getExportFilename(preName);
            } else if (this.selectedType == 'kpi-metricsPerStage') {
                dataSets = this.kpi.metricsPerStage.datasets;
                labels = this.kpi.metricsPerStage.labels;

                const scatterTagHasData = !isNullOrEmpty(this.scatterJSON);
                const barTagHasData = !isNullOrEmpty(this.barJSON);

                if (this.averageWellDataMetricsPerStage) {
                    if (!scatterTagHasData)
                    dataSets.unshift({ data: [] });

                    items = [];
                    fields = [ 'Stage', this.findTagName(this.scatterTag), this.findTagName(this.barTag) ];

                    const maxStage = Math.max(dataSets[0]?.data?.length || 0, dataSets[1]?.data?.length || 0) || 0;

                    for (let stage = 0; stage < maxStage; stage++)
                        items.push([
                            dataSets[0]?.data[stage]?.x || dataSets[1]?.data[stage]?.x,
                            scatterTagHasData
                                ? dataSets[0]?.data[stage]?.y || ''
                                : '',
                            barTagHasData
                                ? dataSets[1]?.data[stage]?.y || ''
                                : ''
                        ]);
                } else {
                    // Search the built datasets for the highest well number
                    const wellCount = Math.max(...dataSets.map(_dataSet => _dataSet?.data || [])?.flat()?.map(_event => _event.wellNumber || 0));

                    // When tags both are set, split the datasets in half to know how when the bar tags datasets start
                    // When only one tag is set, no need for offsets
                    const offset = scatterTagHasData && barTagHasData ? Math.floor(dataSets.length/2) : 0;

                    items = [];
                    fields = [ 'Well', 'Stage', this.findTagName(this.scatterTag), this.findTagName(this.barTag) ];

                    // Go through each well data set adding the data for each tag at once
                    for (let i = 0; i <= wellCount; i++) {
                        const maxStageForWell = Math.max(dataSets[i]?.data?.length || 0, dataSets[i+offset]?.data?.length || 0) || 0;

                        for (let stage = 0; stage < maxStageForWell; stage++) {
                            items.push([
                                i+1,
                                dataSets[i]?.data[stage]?.x || dataSets[i+offset]?.data[stage]?.x || '',
                                scatterTagHasData
                                    ? dataSets[i]?.data[stage]?.y || ''
                                    : '',
                                barTagHasData
                                    ? dataSets[i+offset]?.data[stage]?.y || ''
                                    : ''
                            ]);
                        }
                    }
                }

                fileName = this.getExportFilename(this.selectedType);
            }

            if (isFalsy(fileName)) {
                toast({
                    title: 'Export failed',
                    body: `Invalid file name: ${filename}`,
                    variant: 'danger'
                });
                return;
            }

            if(this.selectedExportType == 'csv'){
                this.exportCSV(fields, items, fileName);
            }
            else if(this.selectedExportType == 'json'){
                this.exportJSON(fields, items, fileName);
            }
        },
        exportCSV(fields, items, fileName) {
            let csv = [];

            // Compose header/field name row, add it to csv data
            csv.push([...fields]);

            // Add data rows after header row
            csv.push(...items);

            // Compile export data and format:
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([csv.join('\r\n')]));
            link.setAttribute('download', fileName + '.csv');

            document.body.appendChild(link);
            link.click();
        },
        exportJSON(fields, items, fileName) {
            let exportObject = {};

            for (let i = 0; i < items.length; i++) {
                exportObject[i] = {};
                for (let f = 0; f < fields.length; f++) {
                    exportObject[i][fields[f]] = items[i][f];
                }
            }

            let data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObject));

            // Format
            const link = document.createElement('a');
            link.href = 'data:' + data;
            link.setAttribute('download', fileName + '.json');

            document.body.appendChild(link);
            link.click();
        },
        // Export filename to format as follows: <customer>_<WO number>_<pad name>_KPI-output type-filter details_<date of export>
        // Example: Aethon_FS12518-BARBO SGT PEPPER 1H 2HB_KPI-FracToWirelineWellSwapTime_FilteredLessThan45 mins_23-Jun-2023.csv
        getExportFilename(pre) {
            return `${this.customer.name}_${this.job.jobNumber}_${this.job.location}_${pre}_${moment().format('DD-MMMM-YYYY HH-mm-ss')}`;
        },
        onTimeRangeChanged() {
            if(![this.kpiTypes[0], this.kpiTypes[3]].includes(this.selectedType)) {
                this.isStackedByWell = false;
            }
            this.onDataChanged();
        },
        onDataChanged() {
            this.hasDataChanged = true;
        },
        onStackByWellPressed() {
            this.onStackInitChanged = true;
            this.isStackedByWell = !this.isStackedByWell;
            this.onDataChanged();
        },
        onSelectAnalyticPressed() {
            this.$refs.analyticType.focus();
        },
        getChartRef() {
            let key = null;
            switch (this.selectedType) {
            case 'kpi-total':
                key = 'kpiTotalChart';
                break;
            case 'kpi-progress':
                key = 'completionProgressChart';
                break;
            case 'kpi-lateral':
                key = 'kpi-lateral';
                break;
            case 'kpi-pumpingHours':
                key = 'pumpTimeChart';
                break;
            case 'kpi-swapTime':
                key = 'kpiSwaptime';
                break;
            case 'kpi-metricsPerStage':
                key = 'kpiMetricsPerStage';
                break;
            default:
                key = 'pumpTimeChart';
                break;
            }

            try {
                return this.$refs[key].$data._chart;
            } catch (error) {
                return null; //no ref found;
            }
        },
        leftTrim(arrayData) { //remove leading 0s from array
            if(!arrayData) {return [];}
            return arrayData.filter((last => v => last = last || v)(false));
        },
        checkForAnalytics(useSelectedAnalytics = true) {
            this.$nextTick(()=>{
                const savedAnalytics = this.item.options.analyticsType ? JSON.parse(this.item.options.analyticsType) : [];
                if(!useSelectedAnalytics && savedAnalytics.length > 0) {
                    this.selectedAnalyticTypes =  savedAnalytics;
                } else {
                    this.onAnalyticsTypeChange(this.selectedAnalyticTypes);
                }
                this.createJobTimeAnnotationLines();
            });
        },
        onAnalyticsTypeChange(calculationTypes) {
            // none of this is relevant to swap times and it was clearly neccessary data. just avoid running
            if (this.selectedType == 'kpi-swapTime' || this.selectedType == 'kpi-metricsPerStage')
                return;

            const chart = this.getChartRef();
            let datasets = null;
            if (this.selectedType === 'kpi-total' || this.selectedType === 'kpi-lateral' || this.selectedType === 'kpi-pumpingHours') {
                datasets = this.analyticDatasets; //datasets without appended zeroes
            } else {
                datasets = chart?.data?.datasets;
            }

            if(!datasets || !chart?.options?.annotation?.annotations) { return; }


            const isDayNight = this.selectedDayType === 'Day shift and night shift';
            //remove previous analytics annotations so they can be recreated
            chart.options.annotation.annotations = chart.options.annotation.annotations.filter(anno => anno.scaleID === 'date-axis');
            if (calculationTypes?.length > 0) {
                calculationTypes?.forEach(calculationType => {
                    const yAxis = chart?.options?.scales?.yAxes[0];
                    const yAxisPumpPlotline = this.isCombinedPumpHoursAndStagesChart? chart?.options?.scales?.yAxes[1] : null;
                    let rawDataPoints = datasets[0].data;
                    let dayShiftDataPoints = null;
                    let nightShiftDataPoints = null;
                    if(yAxis) {
                        if(this.isStackedByWell || isDayNight) {
                            const rawDatasets = datasets.map((dataset)=>dataset.data);
                            rawDataPoints = _.zipWith(...rawDatasets, function(...datasetValues) {
                                return _.sum(datasetValues);
                            });
                        }

                        if(isDayNight) {
                            if(this.isStackedByWell) {
                                const rawDayDatasets = datasets.filter((dataset)=>(dataset.stack == DAY_STACK_NAME)).map((dataset)=>dataset.data);
                                const rawNightDatasets = datasets.filter((dataset)=>(dataset.stack == NIGHT_STACK_NAME)).map((dataset)=>dataset.data);
                                dayShiftDataPoints = _.zipWith(...rawDayDatasets, function(...datasetValues) {
                                    return _.sum(datasetValues);
                                });

                                nightShiftDataPoints = _.zipWith(...rawNightDatasets, function(...datasetValues) {
                                    return _.sum(datasetValues);
                                });
                            } else {
                                dayShiftDataPoints = datasets[0]?.data ?? 0;
                                nightShiftDataPoints = datasets[1]?.data ?? 0;
                            }
                        }

                        let activeDayDataPoints_stages = null;
                        let activeDayDataPoints_pump = null;
                        if (this.isCombinedPumpHoursAndStagesChart) {
                            const rawDatasets_stages = datasets.filter(dataset => !dataset.type).map((dataset)=>dataset.data);
                            let rawDataPoints_stages = _.zipWith(...rawDatasets_stages, function(...datasetValues) {
                                return _.sum(datasetValues);
                            });

                            const rawDatasets_pump = datasets.filter(dataset => dataset.type === 'line').map((dataset)=>dataset.data);
                            let rawDataPoints_pump = _.zipWith(...rawDatasets_pump, function(...datasetValues) {
                                return _.sum(datasetValues);
                            });

                            activeDayDataPoints_stages = rawDataPoints_stages;
                            activeDayDataPoints_pump = rawDataPoints_pump;
                        }

                        let rawDayDatasets_stages = datasets.filter((dataset)=>((dataset.label == DAY_STACK_NAME || dataset.stack == DAY_STACK_NAME) && !dataset.type)).map((dataset)=>dataset.data);
                        let rawNightDatasets_stages = datasets.filter((dataset)=>((dataset.label == NIGHT_STACK_NAME || dataset.stack == NIGHT_STACK_NAME) && !dataset.type)).map((dataset)=>dataset.data);
                        let rawDayDatasets_pump = datasets.filter((dataset)=>(dataset.label == DAY_STACK_NAME && dataset.type === 'line')).map((dataset)=>dataset.data);
                        let rawNightDatasets_pump = datasets.filter((dataset)=>(dataset.label == NIGHT_STACK_NAME && dataset.type === 'line')).map((dataset)=>dataset.data);
                        let dayShiftDataPoints_stages = _.zipWith(...rawDayDatasets_stages, function(...datasetValues) {
                            return _.sum(datasetValues);
                        });
                        let nightShiftDataPoints_stages = _.zipWith(...rawNightDatasets_stages, function(...datasetValues) {
                            return _.sum(datasetValues);
                        });
                        let dayShiftDataPoints_pump = _.zipWith(...rawDayDatasets_pump, function(...datasetValues) {
                            return _.sum(datasetValues);
                        });
                        let nightShiftDataPoints_pump = _.zipWith(...rawNightDatasets_pump, function(...datasetValues) {
                            return _.sum(datasetValues);
                        });

                        const scaleID = yAxis.id;
                        const scaleIDPumpPlotline = yAxisPumpPlotline? yAxisPumpPlotline.id : scaleID;
                        const activeDayDataPoints = rawDataPoints;


                        if(calculationType == this.analyticTypes[0]) { //average
                            if (this.isCombinedPumpHoursAndStagesChart) {
                                //average for stages on combined stages and pump hours chart
                                const average_stages = this.getAverage(this.modifyArrayWithZeroHandling(activeDayDataPoints_stages));
                                this.drawHorizontalLine(`${calculationType} (stages)`, average_stages, scaleID, this.analyticsColors[0]);
                                //for pump hours plotline
                                const average_pump = this.getAverage(this.modifyArrayWithZeroHandling(activeDayDataPoints_pump));
                                this.drawHorizontalLine(`${calculationType} (pump)`, average_pump, scaleIDPumpPlotline, this.analyticsColorsPlotline[0]);
                            } else {
                                const average = this.getAverage(this.modifyArrayWithZeroHandling(activeDayDataPoints));
                                this.drawHorizontalLine(calculationType, average, scaleID, this.analyticsColors[0]);
                            }
                            if(isDayNight) {
                                if (this.isCombinedPumpHoursAndStagesChart) {
                                    const dayAverage_stages = this.getAverage(this.modifyArrayWithZeroHandling(dayShiftDataPoints_stages));
                                    const nightAverage_stages = this.getAverage(this.modifyArrayWithZeroHandling(nightShiftDataPoints_stages));
                                    const dayAverage_pump = this.getAverage(this.modifyArrayWithZeroHandling(dayShiftDataPoints_pump));
                                    const nightAverage_pump = this.getAverage(this.modifyArrayWithZeroHandling(nightShiftDataPoints_pump));
                                    this.drawHorizontalLine('Day average (stages)', dayAverage_stages, scaleID, this.analyticsColors[1]);
                                    this.drawHorizontalLine('Night average (stages)', nightAverage_stages, scaleID, this.analyticsColors[2]);
                                    this.drawHorizontalLine('Day average (pump)', dayAverage_pump, scaleIDPumpPlotline, this.analyticsColorsPlotline[1]);
                                    this.drawHorizontalLine('Night average (pump)', nightAverage_pump, scaleIDPumpPlotline, this.analyticsColorsPlotline[2]);
                                } else {
                                    dayShiftDataPoints = dayShiftDataPoints;
                                    nightShiftDataPoints = nightShiftDataPoints;
                                    const dayAverage = this.getAverage(this.modifyArrayWithZeroHandling(dayShiftDataPoints));
                                    const nightAverage = this.getAverage(this.modifyArrayWithZeroHandling(nightShiftDataPoints));
                                    this.drawHorizontalLine('Day average', dayAverage, scaleID, this.analyticsColors[1]);
                                    this.drawHorizontalLine('Night average', nightAverage, scaleID, this.analyticsColors[2]);
                                }
                            }
                        } else if (calculationType == this.analyticTypes[1]) { //median
                             if (this.isCombinedPumpHoursAndStagesChart) {
                                const median_stages = this.getMedian(this.modifyArrayWithZeroHandling(activeDayDataPoints_stages));
                                this.drawHorizontalLine(`${calculationType} (stages)`, median_stages, scaleID, this.analyticsColors[3]);
                                const median_pump = this.getMedian(this.modifyArrayWithZeroHandling(activeDayDataPoints_pump));
                                this.drawHorizontalLine(`${calculationType} (pump)`, median_pump, scaleIDPumpPlotline, this.analyticsColorsPlotline[3]);
                            } else {
                                const median = this.getMedian(this.modifyArrayWithZeroHandling(activeDayDataPoints));
                                this.drawHorizontalLine(calculationType, median, scaleID, this.analyticsColors[3]);
                            }
                            if(isDayNight) {
                                if (this.isCombinedPumpHoursAndStagesChart) {
                                    dayShiftDataPoints_stages = dayShiftDataPoints_stages;
                                    nightShiftDataPoints_stages = nightShiftDataPoints_stages;
                                    dayShiftDataPoints_pump = dayShiftDataPoints_pump;
                                    nightShiftDataPoints_pump = nightShiftDataPoints_pump;
                                    const dayMedian_stages = this.getMedian(this.modifyArrayWithZeroHandling(dayShiftDataPoints_stages));
                                    const nightMedian_stages = this.getMedian(this.modifyArrayWithZeroHandling(nightShiftDataPoints_stages));
                                    const dayMedian_pump = this.getMedian(this.modifyArrayWithZeroHandling(dayShiftDataPoints_pump));
                                    const nightMedian_pump = this.getMedian(this.modifyArrayWithZeroHandling(nightShiftDataPoints_pump));
                                    this.drawHorizontalLine('Day median (stages)', dayMedian_stages, scaleID, this.analyticsColors[4]);
                                    this.drawHorizontalLine('Night median (stages)', nightMedian_stages, scaleID, this.analyticsColors[5]);
                                    this.drawHorizontalLine('Day median (pump)', dayMedian_pump, scaleIDPumpPlotline, this.analyticsColorsPlotline[4]);
                                    this.drawHorizontalLine('Night median (pump)', nightMedian_pump, scaleIDPumpPlotline, this.analyticsColorsPlotline[5]);
                                } else {
                                    dayShiftDataPoints = dayShiftDataPoints;
                                    nightShiftDataPoints = nightShiftDataPoints;
                                    const dayMedian = this.getMedian(this.modifyArrayWithZeroHandling(dayShiftDataPoints));
                                    const nightMedian = this.getMedian(this.modifyArrayWithZeroHandling(nightShiftDataPoints));
                                    this.drawHorizontalLine('Day median', dayMedian, scaleID, this.analyticsColors[4]);
                                    this.drawHorizontalLine('Night median', nightMedian, scaleID, this.analyticsColors[5]);
                                }
                            }
                        }
                    }
                });
            } else { //wipe annotations
                chart.update();
            }
        },
        modifyArrayWithZeroHandling(usedDataSet) {
            //modify dataset based on zero stage setting
            switch (this.selectedZeroStageType)
            {
            case 'zeroStage-include':
                //do nothing
                return usedDataSet;
                break;
            case 'zeroStage-nonLeading':
                for(let i = 0; i < usedDataSet.length; i++) {
                    //cull out 0 values from beginning of array only
                    if (usedDataSet[i] != 0) {
                        break;
                    }
                    usedDataSet[i] = null;
                }
                return usedDataSet.filter(d => d != null);
                break;
            case 'zeroStage-none':
                return usedDataSet.filter(val => val != 0);
                break;
            }
        },
        getAverage(dataArray) {
            return _.mean(dataArray);
        },
        getMedian(rawArray) {
            if (rawArray.length === 0) {
                return;
            }
            const dataArray = _.cloneDeep(rawArray);
            if(dataArray.length ===0) {
                return null;
            };

            dataArray.sort(function(a,b) {
                return a-b;
            });

            const half = Math.floor(dataArray.length / 2);

            if (dataArray.length % 2) {
                return dataArray[half];
            }

            return (dataArray[half - 1] + dataArray[half]) / 2.0;
        },
        drawHorizontalLine(calculationType, value, scaleId, color, contentConverter = (value) => value.toFixed(2)) {
            if (isFalsy(value) || typeof value != 'number' || isFalsy(scaleId) || this.selectedType === 'kpi-progress') {
                console.warn('invalid data set for annotations');
                console.warn({ calculationType, value, scaleId, color });
                return;
            }

            const chart = this.getChartRef();

            if (chart == null)
                return;

            if (!('annotations' in chart.options?.annotation) || chart.options.annotation?.annotations == null)
                chart.options.annotation = { annotations: [] };

            chart.options.annotation.annotations.push({
                type: 'line',
                mode: 'horizontal',
                scaleID: scaleId,
                value: value,
                borderWidth: 2,
                borderColor: color,
                borderDash: [5,5],
                legendInfo: {
                    type: calculationType,
                    content: calculationType + ' = ' + contentConverter(value),
                    backgroundColor: color
                }
            });

            if (!isNullOrEmpty(chart?.options?.annotation?.annotations)) {
                this.analyticsData = chart.options.annotation.annotations
                    .filter(an => an.legendInfo)
                    .map(annotation => {
                        return {
                            type: annotation.legendInfo.type,
                            label: annotation.legendInfo.content,
                            backgroundColor: annotation.legendInfo.backgroundColor
                        };
                    });
            } else {
                this.analyticsData = [];
            }

            chart.update();
        },
        onFullJobchange() {
            if(this.isFullJob) {
                this.calculatePredictedCompletionRate();
            } else {
                this.kpi.completionProgress.datasets =   this.kpi.completionProgress.datasets.slice(0, 2);
                const untilTodayDataLength = this.kpi.completionProgress.datasets[0].data.length;
                this.kpi.completionProgress.labels = this.kpi.completionProgress.labels.slice(0, untilTodayDataLength);
            }
            this.$refs?.completionProgressChart?.$data?._chart?.update();
        },
        calculatePredictedCompletionRate(init) {
            if (!this.isFullJob)
                return;

            if (init) {
                this.initialProgressLabels = this.kpi.completionProgress.labels;
                this.initialProgressDefaultFormatLabels = this.kpi.completionProgress.defaultFormatLabels;
            }

            const progressPerDay = parseInt(this.filters.completionProgress.progressPerDay);
            const projectedArrayLength = this.kpi.completionProgress.datasets[0].data.length;
            const momentLastDate = moment(this.initialProgressLabels[(this.initialProgressLabels.length-1)], SECONDARY_DATE_FORMAT);
            const lastProjectedValue = this.kpi.completionProgress.datasets[0].data[this.kpi.completionProgress.datasets[0].data.length-1];
            const dataSet = _.cloneDeep(this.kpi.completionProgress.datasets[1].data);
            const lastActualValue  = dataSet[dataSet.length-1];
            const lastKnownValues = _.cloneDeep(dataSet);

            // Always assume last day is incomplete (we wouldn't be calculating predictions after job completed)
            lastKnownValues.pop();

            const futureLabels = [];

            let futureProjectedArray = [];
            let futureActualValuesArray = [];
            let futureActualValue = lastActualValue;
            let newProjectedValue = lastProjectedValue;

            if (newProjectedValue !== null && progressPerDay > 0)
                while (newProjectedValue <= this.totalStages) { //calculation - future projected values
                    futureProjectedArray.push(newProjectedValue);
                    newProjectedValue = newProjectedValue + progressPerDay;
                }

            if (!isNullOrEmpty(futureProjectedArray) && futureProjectedArray[futureProjectedArray.length-1] < this.totalStages) {
                futureProjectedArray.push(this.totalStages);
            }

            // Future actual values should account for the stages we haven't had time to complete yet today
            let addOffset = 0;
            while (futureActualValue <= this.totalStages && this.averageActualStagesPerDay > 0 ) { //calculation - future predicted values
                // On the last day, cap the line to the total stagess
                const adjustedValue = futureActualValue+addOffset;
                futureActualValuesArray.push(adjustedValue < this.totalStages ? adjustedValue : this.totalStages);

                // Calculate offset only after the first index to connect the lines
                if (addOffset === 0 && dataSet.length > 1)
                    addOffset = Math.max(this.averageActualStagesPerDay - (dataSet[dataSet.length-1] - dataSet[dataSet.length-2]), 0);

                futureActualValue = futureActualValue + this.averageActualStagesPerDay;
                lastKnownValues.push(futureActualValue);
            }

            if (!isNullOrEmpty(futureActualValuesArray) && futureActualValuesArray[futureActualValuesArray.length-1] < this.totalStages) {
                futureActualValuesArray.push(this.totalStages);
            }

            let newDaysToAdd = 0;

            if (futureActualValuesArray.length > futureProjectedArray.length) {
                newDaysToAdd = futureActualValuesArray.length;
            } else {
                newDaysToAdd = futureProjectedArray.length;
            }

            for (let i = 0; i < newDaysToAdd; i++) {
                futureLabels.push(momentLastDate.add(1, 'day').format(SECONDARY_DATE_FORMAT).toString());
            }

            this.futureProgressLabels = futureLabels;
            this.kpi.completionProgress.labels = [...this.initialProgressLabels, ...futureLabels];
            const currentFilledValues = new Array((projectedArrayLength||1)-1).fill(null);
            futureProjectedArray = [...currentFilledValues, ...futureProjectedArray];
            futureActualValuesArray =  [...currentFilledValues, ...futureActualValuesArray];

            const futureProjectedDataset = {
                backgroundColor: '#F0AD4E',
                borderColor: '#F0AD4E',
                borderDash: [3,5],
                data: futureProjectedArray,
                label: 'Predicted Stages Completed',
                fill: false
            };

            const futureActualDataset = {
                backgroundColor: '#1C7CD5',
                borderColor: '#1C7CD5',
                data: futureActualValuesArray,
                borderDash: [3,5],
                fill: false,
                label: 'Predicted Completion Rate',
                labelColor: '#CCCCCC'
            };

            this.kpi.completionProgress.datasets[2] = futureProjectedDataset;
            this.kpi.completionProgress.datasets[3] = futureActualDataset;
            this.$refs?.completionProgressChart?.$data?._chart?.update();
        },
        calculateAverageProgress(knownValuesArray) {
            // Need at least two values to calculate difference
            if (isNullOrEmpty(knownValuesArray) || knownValuesArray.length < 2)
                return 0;

            const numberDifference = [];
            for (let i = 1; i < knownValuesArray.length; i++) {
                const rateOfChange = knownValuesArray[i] - knownValuesArray[i - 1];
                // Ignore NaN and non working days
                if (!!rateOfChange && rateOfChange > 0)
                    numberDifference.push(rateOfChange);
            }

            if (isNullOrEmpty(numberDifference))
                return 0;

            const sumOfDifference = numberDifference.reduce((acc, current) => acc+current, 0);
            return Math.round(sumOfDifference/numberDifference.length);
        },
        saveDefault() {
            const self = this;
            //Should save this information to the jobs table
            $.post(
                '/jobkpisettings/' + this.job.id + '/setprojectedstages',
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    stagescompletedprojection: this.filters.completionProgress.progressPerDay
                },
                function (data) {
                    if(data.error)
                    {
                        console.warn(data.error);
                    }
                    self.job.stagescompletedprojection = self.filters.completionProgress.progressPerDay;
                    //Forces computed value to re-process
                    self.ticker ^= true;
                },
                'json'
            ).done(() => { });
        },
        revertConfig: function() {
            this.exportOverlayMode = false;
            this.selectedType = this.initialSelectedType;
            this.selectedDayType = this.initialSelectedDayType;
            this.selectedZeroStageType = this.initialSelectedZeroStageType;
            if (this.showPumpHourPlotlineOption) {
                this.includePumpHoursPlotline = this.initialPumpHoursPlotlineVal;
            }

            this.wirelineSwapFilter = this.initialWirelineSwapFilter;
            this.minSwapTimeFilter = this.initialMinSwapTimeFilter;
            this.maxSwapTimeFilter = this.initialMaxSwapTimeFilter;
            this.hasDataChanged = false;
            toast({
                title: 'Your changes have been reverted.',
                variant: 'success'
            });
        },
        saveConfig: function() {
            if (!this.hasDataChanged) return;

            this.options.type = this.selectedType;
            this.options.isStackedByWell = this.isStackedByWell;
            this.options.dayType= this.selectedDayType;
            this.options.analyticsType = this.selectedAnalyticTypes;
            this.options.includePumpHoursPlotline = this.includePumpHoursPlotline;
            this.item.options = {
                'isStackedByWell': this.isStackedByWell,
                'analyticsType': JSON.stringify(this.selectedAnalyticTypes),
                'type': this.selectedType,
                'dayType': this.selectedDayType,
                'zeroStageType': this.selectedZeroStageType,
                'includePumpHoursPlotline': this.includePumpHoursPlotline
            };

            if (this.selectedType == 'kpi-swapTime') {
                this.item.options.selectedSwapType = this.selectedSwapType;
                this.item.options.wirelineSwapFilter = this.wirelineSwapFilter;
                this.item.options.minSwapTimeFilter = this.minSwapTimeFilter;
                this.item.options.maxSwapTimeFilter = this.maxSwapTimeFilter;

                this.initialWirelineSwapFilter = this.wirelineSwapFilter;
                this.initialMinSwapTimeFilter = this.minSwapTimeFilter;
                this.initialMaxSwapTimeFilter = this.maxSwapTimeFilter;
            } else if (this.selectedType == 'kpi-pumpingHours') {
                this.item.options.selectedPumpTimeSource = this.selectedPumpTimeSource;
            } else if (this.selectedType == 'kpi-metricsPerStage') {
                this.item.options.scatterTag = this.scatterTag;
                this.item.options.barTag = this.barTag;
                this.item.options.averageWellDataMetricsPerStage = this.averageWellDataMetricsPerStage;
                this.item.options.limitStagesMetricsPerStage = this.limitStagesMetricsPerStage;
            }

            this.initialSelectedType = this.selectedType;
            this.initialSelectedDayType = this.selectedDayType;
            this.initialSelectedZeroStageType = this.selectedZeroStageType;
            this.initialPumpHoursPlotlineVal = this.includePumpHoursPlotline;
            this.initialSelectedPumpTimeSource = this.selectedPumpTimeSource;
            this.onStackInitChanged = false;
            this.onSelectedAnalyticsChanged = false;
            this.hasDataChanged = false;
            toast({
                title: 'Your changes have been saved.',
                variant: 'success'
            });
        },
        getKPIData: function() {
            const url = '/kpi-summary-info/'+this.jobNumber;
            const self = this;

            $.get(
                url,
                {},
                async function(data) {
                    self.job = data.job;
                    self.totalStages = data.totalStages;
                    self.contractors = data.contractors;
                    self.eventActivities = data.eventActivities;
                    self.eventActivityEventReasons = data.eventActivityEventReasons;
                    self.eventReasons = data.eventReasons;

                    self.filters.completionProgress.progressPerDay = data.job.jobkpisettings ? data.job.jobkpisettings.stagesCompletedProjection : 9.5;
                    self.job.stagescompletedprojection = self.filters.completionProgress.progressPerDay;

                    await self.getJobCompletedStagesInfo().then((response) => self.processResponse(response));
                    self.getCalculatedLateralLength();
                    self.getCalculatedPumptimes(false);

                    setTimeout(self.getKPIData, 1000 * 60 * 60);
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                if(jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
            });
        },
        async getCalculatedLateralLength() {
            //package async call into a function so new data can be acquired during watcher handler on selectedDayType
            await this.getLateralLengthData().then((response) => this.processCalculatedKPIResponse('lateralLengthPerDay',response));
            const kpiDays = []
            const defaultFormatLabels = []
            const { hours, minutes } = this.getShiftStartTimeInHourAndMinutes();
            const isDayNight = this.dayTypes[2] && this.selectedDayType === this.dayTypes[2];
            this.kpi.lateralLengthPerDay.labels.forEach((ts) => {
                kpiDays.push(moment(ts).format(SECONDARY_DATE_FORMAT));
                if(isDayNight){
                    defaultFormatLabels.push(
                        moment(ts)
                        .hour(hours)
                        .minute(minutes)
                        .format(DEFAULT_DATE_FORMAT)
                    );
                } else {
                    defaultFormatLabels.push(ts);
                }
            });
            this.kpi.lateralLengthPerDay.labels = kpiDays;
            this.kpi.lateralLengthPerDay.defaultFormatLabels = defaultFormatLabels;
            this.checkForAnalytics(false);
        },
        async getCalculatedPumptimes(useSelectedAnalytics = true) {
            //package async call into a function so new data can be acquired during watcher handler on selectedDayType
            await this.getPumpTimeData().then((response) => this.processCalculatedKPIResponse('pumpTimes',response));
            if (this.isCombinedPumpHoursAndStagesChart) {
                this.setupCombinedPumpHoursAndStages(useSelectedAnalytics);
            } else {
                this.setupPumpHoursPerDay(useSelectedAnalytics);
            }
            this.$nextTick(()=>{
                this.createJobTimeAnnotationLines();
                this.checkForAnalytics(false);
            });
        },
        async getCalculatedSwapTimes() {
            const activitiesResponse = await $.get('/activities-summary/'+this.jobNumber, {
                startTime: new Date(this.job.start).getTime(),
                endTime: new Date().getTime(),
                getUppperLowerBound: true
            });

            this.kpi.swapTimes.labels = [];
            this.kpi.swapTimes.datasets[0].data = [];
            this.filteredItems = [];

            if (isNullOrEmpty(activitiesResponse?.activities))
                return;

            // Need to check analytics data is set before drawing data
            await this.checkForAnalytics(false);

            let wlToFrac = false, fracToWl = false, wlToWl = false, fracToFrac = false;
            let activities = [ ...activitiesResponse.activities ].sort((x, y) => new Date(x.startTime).getTime() - new Date(y.startTime).getTime());
            const wells = this.dashboardData.wells.reduce((accumulator, obj) => ({ ...accumulator, [obj.id]: obj}), {});

            // Each scenario will require specific handling, convert to simple bools
            if (this.selectedSwapType == 'wlToFrac') {
                wlToFrac = true;
            } else if (this.selectedSwapType == 'fracToWl') {
                fracToWl = true;
            } else if (this.selectedSwapType == 'wlToWl') {
                wlToWl = true;
            } else if (this.selectedSwapType == 'fracToFrac') {
                fracToFrac = true;
            }

            const isMatching = wlToWl || fracToFrac;

            // CF jobs can allow partial, but not full overlaps in fracToFrac. Should show the overlap as a swap of 0 as opposed to a negative swap time
            const allowPartialOverlap = fracToFrac && this.jobNumber.startsWith('CF')
            const isSimultaneousFrac = this.jobNumber?.startsWith('SF')

            let i = 0;
            let currentActivity
            let lastFinishedEvent;

            // On top of sorting, we do a bit of pre-processing of the events list to make our lives easier down the road
            // When the swap type is matching, we only care about that activity type, filter out the opposite to reduce computation time
            if (fracToFrac) {
                activities = activities.filter(activity => activity.activity == 'frac');
            } else if (wlToWl) {
                if (this.requireWirelineCheck) {
                    activities = activities.filter(activity => activity.activity == 'wireline' && activity?.data?.service_id == this.wirelineSwapFilter);
                } else {
                    activities = activities.filter(activity => activity.activity == 'wireline');
                }
            }

            if (isNullOrEmpty(activities))
                return toast({
                    title: 'Failed filtering of activities: '+this.selectedSwapType,
                    variant: 'danger'
                });

            // run through once, adding properties that are needed later
            activities.forEach((activity, i) => {
                // Old events could exist from before we stored wellId on the activity. If not set, try to add wellId for later
                if (!('wellId' in activity && wells[activity.wellId]) && activity.wellNumber in this.dashboardData.wells)
                    activity.wellId = this.dashboardData.wells[activity.wellNumber].id;

                activity.name = wells[activity.wellId]?.name || wells[activity.wellId]?.nameLong || '';
                activity.index = i;

                activity.isSimalFrac = false;

                if (isSimultaneousFrac && activity.activity == 'frac') {
                    for (let j=i+1; j < activities.length; j++) {
                        if (activities[j].activity == 'frac' && activities[j].startTime < activity.endTime) {
                            // Look ahead several events for simul fraccing
                            // If both events are frac and the future is running alongside the current it is considered simal and will require special handling later
                            activity.isSimalFrac = true;
                        } else if (activities[j].wellId == activity.wellId) {
                            // Once we find an activity for the same well that is not simul frac, stop the search
                            break;
                        }
                    }
                }
            });


            const addSwapTime = (activityA, activityB) => {
                let swapTime = (new Date(activityB.startTime).getTime() - new Date(activityA.endTime).getTime()) / 1000;

                if (allowPartialOverlap && swapTime < 0) {
                    // Should show swap time as a positive number and swap the timestamps to show start and end of overlap section
                    swapTime = Math.abs(swapTime);

                    const _temp = activityA.endTime;
                    activityA.endTime = activityB.startTime;
                    activityB.startTime = _temp;
                }

                // Ignore negative times as that's a bug
                if (swapTime >= 0) {
                    // Label is the swap index. The index does not change when applying filters
                    // Ensure label is stored as a string so other methods (like export) can perform actions on it
                    const index = `${this.kpi.swapTimes.labels.length+this.filteredItems.length+1}`;

                    const meta = {
                        from: {
                            activity: activityA.activity,
                            name: activityA.name,
                            endTime: activityA.endTime,
                            startTime: activityA.startTime,
                            stageNumber: activityA.stageNumber
                        },
                        to: {
                            activity: activityB.activity,
                            name: activityB.name,
                            endTime: activityB.endTime,
                            startTime: activityB.startTime,
                            stageNumber: activityB.stageNumber
                        },
                    };

                    if ((isFalsy(this.minSwapTimeFilter) || swapTime >= this.minSwapTimeFilter) && (isFalsy(this.maxSwapTimeFilter) || swapTime <= this.maxSwapTimeFilter)) {
                        this.kpi.swapTimes.labels.push(index);
                        this.kpi.swapTimes.datasets[0].data.push({
                            x: index,
                            y: swapTime,
                            meta: meta
                        });
                    } else {
                        this.filteredItems.push({
                            index: index,
                            swapTime: swapTime,
                            meta: meta
                        });
                    }
                }
            }

            // Depending on the widgets settings, we only care about specific event type pairings
            const isValidEventSet = (activityA, activityB) => {
                // Both properties must be set to calculate swap time
                if (isFalsy(activityB.startTime) || isFalsy(activityA.endTime))
                    return false;

                if (allowPartialOverlap) {
                    // CF fraccing can overlap but only partially
                    if (moment.utc(activityB.startTime) > moment.utc(activityA.startTime) && moment.utc(activityB.endTime) < moment.utc(activityA.endTime))
                        return false;
                } else {
                    if (moment.utc(activityB.startTime) < moment.utc(activityA.endTime))
                        return false;
                }

                // In simul fraccing, we pair with the last simul frac to start in the set
                if (isSimultaneousFrac && activityB.isSimalFrac)
                    return false;

                // (Following logic applies to fracToWl in reverse activity order)
                // 1. needs to be wireline -> frac set (order specific)
                // 2. The frac needs to be waiting for wireline to finish perforating. i.e. wireline needs to be the last activity to end
                if (wlToFrac)
                    return activityA.activity == 'wireline' && activityB.activity == 'frac' && activityA.endTime >= lastFinishedEvent.endTime;
                if (fracToWl)
                    return activityA.activity == 'frac' && activityB.activity == 'wireline' && activityA.endTime >= lastFinishedEvent.endTime;

                return activityA.activity == activityB.activity;
            }
            const findNextMatchingEvent = (matchType=currentActivity?.activity) => {
                // Find the next similiarly typed event
                while (i in activities) {
                    if (isFalsy(lastFinishedEvent?.endTime) || activities[i].endTime > lastFinishedEvent.endTime)
                        lastFinishedEvent = { ...activities[i] };

                    // look for an event that matches this event type
                    if (activities[i].activity == matchType)
                        break;

                    i++;
                }

                if (i in activities)
                    currentActivity = activities[i++];
            }


            // Start from the first event relevent to the setting
            findNextMatchingEvent(wlToFrac || wlToWl ? 'wireline' : 'frac');

            // If no relevent starting event could be found, abort
            if (!(i in activities) || isFalsy(currentActivity))
                return;

            while (i in activities) {
                // Go one by one, looking at the next event in the list for all valid sets
                const pairedActivity = activities[i++];

                if (isFalsy(pairedActivity))
                    break;

                const isPair = isValidEventSet(currentActivity, pairedActivity);

                // We need to track the end of the last seen event
                // when swapping wireline -> frac, wireline needs to be the last event to finish (except the paired frac activity)
                if (currentActivity.endTime > lastFinishedEvent.endTime)
                    lastFinishedEvent = { ...currentActivity };
                if (pairedActivity.endTime > lastFinishedEvent.endTime && (!pairedActivity.isSimalFrac || isMatching))
                    lastFinishedEvent = { ...pairedActivity };

                if (isSimultaneousFrac && pairedActivity.isSimalFrac) {
                    // Don't actually need to do anything, just avoid the remaining block from replacing currentActivity.
                    // Once we reach the last simal frac in the pairing, this condition will not match and allow current to move one
                    continue;
                }

                // If the event pairing is what we care about, add the swap time to our map
                if (isPair) {
                    addSwapTime(currentActivity, pairedActivity);

                    if (isSimultaneousFrac && isMatching) {
                        if (lastFinishedEvent.endTime > currentActivity.endTime) {
                            // If the simal frac pair was made, jump to the last event to end
                            currentActivity = { ...lastFinishedEvent };
                            i = lastFinishedEvent.index+1;
                            continue;
                        } else {
                            findNextMatchingEvent();
                        }
                    }
                }

                // If the swap was successfully paired or the currentActivity is no longer the most recent to finish, adjust our starting point
                if (isPair || pairedActivity.endTime > currentActivity.endTime) {
                    if (currentActivity.activity === pairedActivity.activity) {
                        // The swap source needs to be the most recent event to end
                        currentActivity = { ...pairedActivity };
                    } else {
                        findNextMatchingEvent();
                    }
                }

                // Once we reach live events, just break
                if (isFalsy(currentActivity.endTime) || isFalsy(pairedActivity.endTime))
                    break;
            }

            const swapTimes = this.kpi.swapTimes?.datasets[0]?.data?.map(_item => _item.y).filter(_num => !isFalsy(_num)) || [];

            const range = Math.max(...swapTimes) - Math.min(...swapTimes);
            const smartStepSize = Math.floor(Math.round(range / 5) / 600) * 600;
            if (!isFalsy(this.options?.swapTimes?.scales?.yAxes[0]?.ticks?.stepSize) && !isFalsy(smartStepSize))
                this.options.swapTimes.scales.yAxes[0].ticks.stepSize = Math.max(smartStepSize, 300) || 300;

            // Let the graph render before adding the annotations
            this.$nextTick(() => {
                if (!isNullOrEmpty(this.kpi?.swapTimes?.labels) && this.kpi.swapTimes.labels.length > 1) {
                    const chart = this.getChartRef();
                    const yAxisId = chart?.options?.scales?.yAxes[0]?.id;

                    if (!isNullOrEmpty(chart?.options?.annotation?.annotations))
                        chart.options.annotation.annotations = [];

                    if (this.selectedAnalyticTypes.includes('Average'))
                        this.drawHorizontalLine('Average', Math.floor(this.getAverage(swapTimes)), yAxisId, this.analyticsColors[1], this.readableSeconds);
                    if (this.selectedAnalyticTypes.includes('Median'))
                        this.drawHorizontalLine('Median', Math.floor(this.getMedian(swapTimes)), yAxisId, this.analyticsColors[2], this.readableSeconds);
                }
            });
        },
        fetchMetricsByTag(tag, ref, convertData=false) {
            // when refetching tag info, clear errors for this tag (will get re added if error persists)
            this.clearTagError(tag);

            // If the tag is not in a valid state, avoid fetching
            // -1: denotes initial state (not yet selected)
            // -2: denotes the not selected option in settings
            if (isFalsy(tag) || tag < 0) {
                this[ref] = [];

                if (convertData)
                    this.convertMetricsData();
            } else {
                return $.get(`/job-kpi-metrics-by-tag/${this.jobNumber}/${tag}`).then(_res => {
                    // If the list is empty or all values are null, show error instead of rendering graph
                    if (!isNullOrEmpty(_res) && !isFalsy(_res.find(_value => !isFalsy(_value.value) && !isNaN(_value.value)))) {
                        _res.sort((a, b) => a.stageNumber - b.stageNumber);
                        this[ref] = _res;
                    } else {
                        this[ref] = [];

                        this.errors.push({
                            tag,
                            message: `No data found for tag: ${this.findTagName(tag)}`
                        });
                    }

                    // convertData is optional, default to false
                    // From mounted, we wait till both tags are fetched to convert
                    // If called on on change of a single tag, convert once the tag is fetched
                    if (convertData)
                        this.convertMetricsData();

                    return _res;
                });
            }
        },
        fetchMetricsData() {
            return Promise.all([
                this.fetchMetricsByTag(this.scatterTag, 'scatterJSON'),
                this.fetchMetricsByTag(this.barTag, 'barJSON')
            ]).then(_res => {
                this.convertMetricsData();
            });
        },
        fetchMetadataTags() {
            if (isFalsy(this.customer?.id))
                return toast({
                    title: 'Failed to read customer id',
                    body: 'Id: ' + this.customer?.id,
                    variant: 'danger'
                });

            return $.get(`/job-tags-metrics-by-tag/${this.customer.id}`, { _token: GlobalFunctions.getCSRFToken() }).then(result => {
                if (result.error) {
                    return toast({
                        title: 'Failed to fetch tokens',
                        body: result.message,
                        variant: 'danger'
                    });
                } else if (isNullOrEmpty(result?.frac) && isNullOrEmpty(result?.wireline)) {
                    return toast({
                        title: 'No Tags',
                        body: 'No Tags found for customer: ' + this.customer?.name,
                        variant: 'danger'
                    });
                } else {
                    let data = !isNullOrEmpty(result?.frac) ? [ ...result.frac ] : [];
                    const tagsMap = data.reduce((accumulator, tag) => ({ ...accumulator, [tag.tagName]: true }), {});

                    // Wireline tags could already exist in frac, don't add duplicates
                    if (!isNullOrEmpty(result?.wireline))
                        result.wireline.forEach(tag => {
                            if (!tagsMap[tag.tagName]) {
                                data.push(tag);
                                tagsMap[tag.tagName] = true;
                            }
                        })

                    if (!isNullOrEmpty(data)) {
                        // Some tags aren't compatible with this KPI and shouldn't be available
                        data = data.filter(_tag => !['stage_wellhead_openingTime_wellbore', 'stage_wellhead_closingTime_wellbore'].includes(_tag.tagName))
                        data.sort((a, b) => (a.tagName > b.tagName) ? 1 : ((b.tagName > a.tagName) ? -1 : 0));
                        this.metadataTags = data;
                    } else {
                        return toast({
                            title: 'No Tags',
                            body: 'No Tags found for customer: ' + this.customer.name,
                            variant: 'danger'
                        });
                    }
                }
            }).catch(result => {
                return toast({
                    title: 'Failed to fetch tokens',
                    body: result.message,
                    variant: 'danger'
                });
            });
        },
        clearTagError(tag) {
            this.errors = this.errors.filter(_error => _error.tag != tag);
        },
        findTagName(tag) {
            if (isFalsy(tag) || tag < 0)
                return '';
            const matchedTag = this.metadataTags?.find(_tag => _tag.tagName == tag);
            return matchedTag?.displayName || matchedTag?.tagName || tag;
        },
        friendlyMetric(tag, value) {
            if (isFalsy(value) || isNaN(value))
                return value;

            const tagName = tag?.toLowerCase();
            if (tagName?.includes('rate'))
                return +value.toFixed(1);
            if (tagName?.includes('pressure') || tagName?.includes('wireline') || tagName?.includes('vol'))
                return Math.floor(Math.round(value));
            return +value.toFixed(2);
        },
        onAverageWellDataMetricsChecked() {
            this.convertMetricsData();
            this.onDataChanged();
        },
        onLimitStageMetricsButtonClicked() {
            this.showLimitStageMetrics = true;
            this.onDataChanged();
        },
        onLimitStageMetricsPerStageChanged() {
            this.convertMetricsData();
            this.onDataChanged();
        },
        convertMetricsData() {
            this.kpi.metricsPerStage.labels = [];

            // The length of datasets is dyanmic when not showing as average of all wells
            if (!isNullOrEmpty(this.kpi?.metricsPerStage?.datasets))
                this.kpi.metricsPerStage.datasets = [];
            if (!isNullOrEmpty(this.options.metricsPerStage.scales.yAxes))
                this.options.metricsPerStage.scales.yAxes = [];

            const scatterName = this.findTagName(this.scatterTag);
            const barName = this.findTagName(this.barTag);

            const stagesList = new Set([ ...this.scatterJSON.map(_data => _data.stageNumber), ...this.barJSON.map(_data => _data.stageNumber)]);
            const wellsList = new Set([ ...this.scatterJSON.map(_data => _data.wellNumber), ...this.barJSON.map(_data => _data.wellNumber)]);

            const stageCount = Math.max(...stagesList)+1
            const lowestStage = isFalsy(this.limitStagesMetricsPerStage) || this.limitStagesMetricsPerStage < 1 ? 1 : Math.max(stageCount - this.limitStagesMetricsPerStage, 1);
            const wellCount = Math.max(...wellsList)+1;

            const prepareDataSets = (tagName, list, ogDataset, ogOptions, graphType) => {
                if (isNullOrEmpty(list))
                    return [];

                let min = null;
                let max = null;

                let datasets = [];

                if (this.averageWellDataMetricsPerStage) {
                    const colours = ['#F0AD4E', '#1C7CD5'];
                    const groupedByStage = {};

                    // Group all values by matching stage numbers
                    list.forEach(_data => {
                        if (_data.stageNumber < lowestStage)
                            return;

                        // If new stage Number, default to []
                        if (!(`${_data.stageNumber}` in groupedByStage))
                            groupedByStage[_data.stageNumber] = [];

                        groupedByStage[_data.stageNumber].push(_data.value);
                    });

                    // Average all groupings then add the average mapped by the stage number
                    Object.keys(groupedByStage).forEach(_stageListKey => {
                        let x = _stageListKey;
                        let y = this.friendlyMetric(tagName, this.getAverage(groupedByStage[_stageListKey]));

                        // Track min and max of each graph to avoid overlap between chart types
                        if (min === null || y < min)
                            min = y;
                        if (max === null || y > max)
                            max = y;

                        ogDataset.data.push({ x, y });
                    })

                    // Only one dataset but return within a list to easily merge with the other graph type
                    datasets.push({
                        ...ogDataset,
                        backgroundColor: colours[graphType],
                        borderColor: colours[graphType],
                        yAxisID: `right-axis-${graphType}`
                    });
                } else {
                    // Create a local dataset per well
                    for (let i = 0; i < wellCount; i++)
                        datasets.push({
                            ...ogDataset,
                            data: [],
                            label: `${ogDataset.label} (${this.dashboardData?.wells[i]?.name})`,
                            backgroundColor: this.dashboardData?.wells[i]?.color,
                            borderColor: this.dashboardData?.wells[i]?.color,
                            yAxisID: `right-axis-${graphType}`
                        })

                    list.forEach(_data => {
                        if (_data.stageNumber < lowestStage)
                            return;

                        // Track min and max of each graph to avoid overlap between chart types
                        if (min === null || _data.value < min)
                            min = _data.value;
                        if (max === null || _data.value > max)
                            max = _data.value;

                        // Seperate the data between each well
                        datasets[_data.wellNumber].data.push({
                            x: _data.stageNumber,
                            y: this.friendlyMetric(tagName, _data.value),
                            wellNumber: _data.wellNumber
                        });
                    });
                }

                // To avoid the two graphs from overlapping, we set the suggested min
                // to 50% of the lowest value so the bar graph should only use the top 2/3 of the space
                if (!isFalsy(ogOptions?.ticks?.suggestedMin) && !isFalsy(min) && min >= 0)
                    ogOptions.ticks.suggestedMin = min * 0.75;

                // To avoid the two graphs from overlapping, we set the suggested max
                // to 50% of the highest value so the bar graph should only use the bottom 2/3 of the space
                if (graphType && !isFalsy(ogOptions?.ticks?.suggestedMax) && !isFalsy(max) && max >= 0)
                    ogOptions.ticks.suggestedMax = max * 1.25;

                const range = (ogOptions.ticks.suggestedMax || max) - (ogOptions.ticks.suggestedMin || min);
                const graphSuggested = graphType === 0 ? 500 : 10;
                const smartStepSize = Math.ceil(Math.round(range / 5) / 10) * 10;
                if (!isFalsy(ogOptions?.ticks?.stepSize) && !isFalsy(range) && range > 0)
                    ogOptions.ticks.stepSize = Math.max(smartStepSize, graphSuggested);

                return {
                    datasets: datasets,
                    options: ogOptions
                };
            }
            const addDataset = (tagName, list, ogDataset, ogOptions, graphType) => {
                // Only show datsets with data
                if (!isNullOrEmpty(list)) {
                    let { datasets, options } = prepareDataSets(tagName, list, ogDataset, ogOptions, graphType);

                    if (!isFalsy(options))
                        this.options.metricsPerStage.scales.yAxes.push(options);

                    if (!isNullOrEmpty(datasets))
                        datasets.forEach(dataset => this.kpi.metricsPerStage.datasets.push(dataset));
                }
            };

            // When set, limitStagesMetricsPerStage controls how many (starting from the back) stage should be shown on the graph
            for (let i = lowestStage; i < stageCount; i++)
                this.kpi.metricsPerStage.labels.push(i);

            addDataset(
                this.scatterTag,
                this.scatterJSON,
                {
                    type: 'line',
                    label: scatterName,
                    fill: false,
                    data: [],
                    backgroundColor: '#F0AD4E',
                    borderColor: '#F0AD4E'
                },
                {
                    id: 'right-axis-0',
                    scaleLabel: {
                        display: true,
                        fontColor: chartStyle.metricsPerStage.labelFontColor,
                        fontSize: 14,
                        labelString: scatterName
                    },
                    ticks: {
                        beginAtZero: false,
                        suggestedMin: 10000,
                        stepSize: 500,
                        maxRotation: 0,
                        fontSize: 18,
                        fontColor: chartStyle.metricsPerStage.labelFontColor
                    },
                    gridLines: {
                        color: chartStyle.metricsPerStage.gridLinesColor
                    }
                },
                0
            )

            addDataset(
                this.barTag,
                this.barJSON,
                {
                    type: 'bar',
                    label: barName,
                    fill: true,
                    data: [],
                    backgroundColor: '#1C7CD5',
                    borderColor: '#1C7CD5'
                },
                {
                    id: 'right-axis-1',
                    position: 'right',
                    scaleLabel: {
                        display: true,
                        fontColor: chartStyle.metricsPerStage.labelFontColor,
                        fontSize: 14,
                        labelString: barName
                    },
                    ticks: {
                        beginAtZero: false,
                        suggestedMin: 0,
                        suggestedMax: 100,
                        stepSize: 10,
                        maxRotation: 0,
                        fontSize: 18,
                        fontColor: chartStyle.metricsPerStage.labelFontColor
                    },
                    gridLines: {
                        display: false
                    }
                },
                1
            );

            this.checkForAnalytics(false);

            // Calling update on the chart ensures we avoid any race condition render issues
            // Only need this if there are no analytics as this gets called after adding the analytics anyway
            if (!this.selectedAnalyticTypes.includes('Average') && !this.selectedAnalyticTypes.includes('Median'))
                this.$refs?.kpiMetricsPerStage?.$data?._chart?.update();

            // Let the graph render before adding the annotations
            this.$nextTick(() => {
                const chart = this.getChartRef();
                const scatterData = this.kpi.metricsPerStage.datasets.filter(_dataSet => _dataSet.yAxisID == 'right-axis-0')?.map(_dataSet => _dataSet?.data || [])?.flat().map(_obj => +_obj.y);
                const barData = this.kpi.metricsPerStage.datasets.filter(_dataSet => _dataSet.yAxisID == 'right-axis-1')?.map(_dataSet => _dataSet?.data || [])?.flat().map(_obj => +_obj.y);

                if (!isNullOrEmpty(chart?.options?.annotation?.annotations))
                    chart.options.annotation.annotations = [];

                // Draw analytics per graph type
                if (this.selectedAnalyticTypes.includes('Average')) {
                    if (!isNullOrEmpty(scatterData))
                        this.drawHorizontalLine(`${scatterName} Average`, this.friendlyMetric(this.scatterTag, this.getAverage(scatterData)), 'right-axis-0', this.analyticsColors[1], (value) => value);
                    if (!isNullOrEmpty(barData))
                        this.drawHorizontalLine(`${barName} Average`, this.friendlyMetric(this.barTag, this.getAverage(barData)), 'right-axis-1', this.analyticsColors[3], (value) => value);
                }

                if (this.selectedAnalyticTypes.includes('Median')) {
                    if (!isNullOrEmpty(scatterData))
                        this.drawHorizontalLine(`${scatterName} Median`, this.friendlyMetric(this.scatterTag, this.getMedian(scatterData)), 'right-axis-0', this.analyticsColors[2], (value) => value);
                    if (!isNullOrEmpty(barData))
                        this.drawHorizontalLine(`${barName} Median`, this.friendlyMetric(this.barTag, this.getMedian(barData)), 'right-axis-1', this.analyticsColors[4], (value) => value);
                }
            });
        },
        clearLimitMetrics() {
            this.limitStagesMetricsPerStage = null;
            this.showLimitStageMetrics = false;
            this.convertMetricsData();
        },
        async getLateralLengthData() {
            const res = await $.ajax({
                method: 'GET',
                url: `/job-kpi-lateral-length/${this.jobNumber}`,
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    timeRangeType: this.selectedDayType
                },
                dataType: 'json'
            });
            return res;
        },
        async getPumpTimeData() {
            const res = await $.ajax({
                method: 'GET',
                url: `/job-kpi-pump-times/${this.jobNumber}`,
                data: {
                    '_token': GlobalFunctions.getCSRFToken(),
                    timeRangeType: this.selectedDayType,
                    dataSource: this.selectedPumpTimeSource
                },
                dataType: 'json'
            });
            let roundedResponse = this.pumpHoursToXDecimalPoints(res, 2);
            return roundedResponse;
        },
        processCalculatedKPIResponse(kpiTypeKey, responseData) {
            if (responseData?.error) {
                console.log(responseData?.message);
                return;
            }

            const isDayNight = this.selectedDayType === 'Day shift and night shift';
            const defaultDataSetObj = {
                label: chartStyle[kpiTypeKey].yAxisLabel,
                labelColor: '#CCCCCC',
                fill: false,
                backgroundColor: chartStyle[kpiTypeKey].barBackgroundColor,
                borderColor: chartStyle[kpiTypeKey].barBackgroundColor,
                data: []
            };

            if(isDayNight) {
                this.kpi[kpiTypeKey].datasets = [
                    { ...defaultDataSetObj,label: 'day'},
                    { ...defaultDataSetObj,label: 'night',
                        backgroundColor: chartStyle[kpiTypeKey].barBackgroundColorSecondary }
                ];
            } else {
                this.kpi[kpiTypeKey] = {
                    datasets: [
                        {...defaultDataSetObj}
                    ]
                };
            }
            //if timeRange is dayNight, then split the lateral length data into seperate collections based on shift times
            if (isDayNight) {
                //shiftData start times only required for day-night calculations currently
                const shiftStartTimes = this.getShiftStartTimes();
                const days = kpiTypeKey === 'pumpTimes' ? {} : [];
                const nights = kpiTypeKey === 'pumpTimes' ? {} : [];
                const labels = new Set(); //collection of only unique values for the date axis labels

                Object.entries(responseData).forEach(record => {//record[0] => dateTime, record[1] => lateral length value
                    //add unique dates to the set of date labels
                    const date = moment(record[0]).format('YYYY-MM-DD');
                    labels.add(date);
                    //compare time of the record to which shift threshold it occurs on
                    if (moment(record[0]).format('HH:mm:ss') >= shiftStartTimes.nightShift
                        || moment(record[0]).format('HH:mm:ss') < shiftStartTimes.dayShift) {
                        kpiTypeKey === 'pumpTimes' ?  nights[date] = record[1] : nights.push(record[1]);
                    } else {
                        kpiTypeKey === 'pumpTimes' ?  days[date] = record[1] : days.push(record[1]);
                    }
                });
                //turn date labels into an array without duplicates dates, use $set to force chart re-render
                this.$set(this.kpi[kpiTypeKey], 'labels', Array.from(labels));
                //populate datasets with filtered data (rounded to 2 decimals but kept as a  Number type)
                if(kpiTypeKey === 'pumpTimes') {
                    this.pumpTimeData =  {};
                    this.pumpTimeData.day = days;
                    this.pumpTimeData.night = nights;
                    this.kpi[kpiTypeKey].datasets[0].data = Object.values(days).map(value=>value.duration);
                    this.kpi[kpiTypeKey].datasets[1].data = Object.values(nights).map(value=>value.duration);
                } else {
                    this.kpi[kpiTypeKey].datasets[0].data = days.map(value=>value);
                    this.kpi[kpiTypeKey].datasets[1].data = nights.map(value=>value);
                }
            } else {
                //Add values based on dates already calculated on the backend, use $set to ensure re-render
                //use unary (+) to enforce Number type of data
                this.$set(this.kpi[kpiTypeKey], 'labels', Object.keys(responseData));
                if(kpiTypeKey === 'pumpTimes') {
                    this.pumpTimeData = responseData;
                    this.kpi[kpiTypeKey].datasets[0].data = Object.values(responseData).map(value=>value.duration);
                } else {
                    this.kpi[kpiTypeKey].datasets[0].data = Object.values(responseData).map(value=>value);
                }
            }

            if(this.getChartTypeByDataKey(kpiTypeKey) === this.selectedType) {
                this.analyticDatasets = _.cloneDeep(this.kpi[kpiTypeKey].datasets);
            }

            this.appendZeroEntriesToEnd(this.kpi[kpiTypeKey]);
        },
        appendZeroEntriesToEnd(chartData, minDisplayDates = 10, timeFormat = 'YYYY-MM-DD HH:mm:ss') {
            if((chartData?.labels?.length < minDisplayDates) && chartData?.datasets) {
                const datasets = chartData.datasets;
                const labelsToAdd = minDisplayDates - chartData.labels.length;
                const lastDate = moment(chartData.labels[chartData.labels.length-1]);

                _.times(labelsToAdd, ()=> {
                    const missedLabel = lastDate.add({ days: 1 }).format(timeFormat);
                    chartData.labels.push(missedLabel);
                });

                datasets.forEach(dataset => {
                    const dataToAdd = minDisplayDates - dataset.data.length;
                    _.times(dataToAdd, ()=> {
                        dataset.data = [...dataset.data, 0];
                    });
                });
            }
        },
        async getJobCompletedStagesInfo() {
            const res = await $.ajax({
                method: 'GET',
                url: `/job-kpi-completed-activities/${this.jobNumber}`,
                dataType: 'json'
            });

            return res;
        },
        handleMouseMove(evt)
        {
            const element = document.elementFromPoint(evt.clientX,evt.clientY);
            const hoveringMarker = element.classList.contains('dot-clickable');
            if(hoveringMarker)
            {
                this.hoverX = element.offsetParent.offsetLeft + this.$refs?.completionProgressChart?.$data?._chart?.chartArea.left;
            }
            else
            {
                this.hoverX = -100;
            }

            this.$refs?.completionProgressChart?.$data?._chart?.update();
        },
        createLookupByDate(data)
        {
            const allShiftData = data; //data comes in as moment.js dates

            for(let i = 0; i < allShiftData.length; i++)
            {
                const timestamp = moment(allShiftData[i].endTime).startOf('day').valueOf().toString();

                if(this.dataLookup.hasOwnProperty(timestamp))
                {
                    this.dataLookup[timestamp].push(allShiftData[i]);
                }
                else
                {
                    this.dataLookup[timestamp] = [allShiftData[i]];
                }
            }

            //order the keys by date
            this.dataLookup = Object.keys(this.dataLookup).sort().reduce(
                (obj, key) => {
                    obj[key] = this.dataLookup[key];
                    return obj;
                },
                {}
            );

            if(Object.keys(this.dataLookup).length > 0) {
                //get all days in range between dates
                //can't use moment-range due to issues with IOS

                const dateKeys = Object.keys(this.dataLookup);
                const from = moment(parseInt(dateKeys[0])).utc();
                const to = moment(parseInt(dateKeys[dateKeys.length - 1])).utc();
                const diff = to.diff(from, 'days');
                const allDays = [];

                for (let i = 0; i < diff; i++)
                {
                    allDays.push(moment(parseInt(dateKeys[0])).utc().add(i, 'days'));
                }

                const dateRange = allDays.map((day) => day.valueOf().toString());
                let newDatesAdded = false;
                dateRange.forEach(dateKey => {
                    if(!this.dataLookup[dateKey]) {
                        newDatesAdded = true;
                        this.dataLookup[dateKey] = [];
                    }
                });

                if(newDatesAdded) {
                    this.dataLookup = Object.keys(this.dataLookup).sort().reduce(
                        (obj, key) => {
                            obj[key] = this.dataLookup[key];
                            return obj;
                        },
                        {}
                    );
                }

                this.unshiftedDataLookup = this.dataLookup;
            }
        },
        shiftDatesByStartTime(shiftStartTime)
        {
            const keys = Object.keys(this.dataLookup);

            const entriesToPrepend = [];
            for(let i = 0; i < keys.length; i++)
            {
                const entriesToMove = this.dataLookup[keys[i]].filter(a => {
                    return a.endTime.hours() < shiftStartTime;
                });

                const cleanedList = this.dataLookup[keys[i]].filter(a => {
                    return a.endTime.hours() >= shiftStartTime;
                });

                this.dataLookup[keys[i]] = cleanedList;
                if(entriesToMove.length > 0)
                {
                    // handles case where we need to push days to an earlier date, push to
                    // data lookup after the loop to avoid editing arrays in place during loop
                    if(i == 0)
                    {
                        entriesToPrepend.push(entriesToMove);
                    }
                    else
                    {
                        this.dataLookup[keys[i-1]] = this.dataLookup[keys[i-1]].concat(entriesToMove);
                    }
                }
            }

            // prepend the earlier date
            if(entriesToPrepend.length > 0)
            {
                const firstDate = moment.utc(parseInt(keys[0]));
                const dayBefore = firstDate.subtract({days: 1});

                if(dayBefore.valueOf().toString() in this.dataLookup)
                {
                    this.dataLookup[dayBefore.valueOf().toString()] = [ ...this.dataLookup[dayBefore.valueOf().toString()], ...entriesToPrepend];
                }
                else
                {
                    this.dataLookup[dayBefore.valueOf().toString()] = entriesToPrepend;
                }
            }

            //TODO :
            //On some occasions due to timestamp collisions instead of getting a single stage object you may receive multiple
            //Because of this I have added handling for that case to flatten the array
            //but it points to fundamental issues in how lookup is being constructed
            //this should be fixed so that all of the index's are unique so that there is no doublepacking creating sub arrays
            for (const timestamp in this.dataLookup) {
                if (Object.prototype.hasOwnProperty.call(this.dataLookup, timestamp) && Array.isArray(this.dataLookup[timestamp])) {
                    this.dataLookup[timestamp] = this.dataLookup[timestamp].flat();
                }
            }
        },
        getShiftStartTimes() {
            const { hours, minutes } = this.getShiftStartTimeInHourAndMinutes()
            const shiftStartDateTime = new Date();
            shiftStartDateTime.setHours(hours,minutes,0);
            const shiftTimes = { //get only the shift times (not dates) as a string
                dayShift: moment(shiftStartDateTime).format('HH:mm:ss'),
                nightShift: moment(shiftStartDateTime).add(12,'hours').format('HH:mm:ss')
            };
            return shiftTimes;
        },
        getShiftStartTimeInHourAndMinutes() {
            return convertHoursToHourAndMinutes(this.job.shiftStart);
        },
        setupKPIChart(kpiType) {
            if (this.isDayTypeStage) {
                this.options[kpiType].scales.xAxes[0].ticks = {
                    stepSize: 1,
                    min: this.kpi[kpiType].labels[0],
                    max: this.kpi[kpiType].labels[this.kpi[kpiType].labels.length - 1]
                };
            }
            if(this.isStackedByWell) {
                this.options[kpiType].scales.xAxes[0].stacked = true;
                this.options[kpiType].scales.yAxes[0].stacked = true;
                this.options[kpiType].scales.xAxes[0].ticks = {
                    fontColor: chartStyle.pumpTimes.labelFontColor
                };
                this.options[kpiType].plugins.datalabels.display = function(ctx) {
                    if (!ctx.dataset.stack)
                        return false;

                    // grouped stack bar chart ( coded for only 2 groups / example. day and night )
                    let numberOfDataSets, headGroupEndIndex, tailGroupEndIdex, datasetIndex, barDatasets, lineDatasets, isCombinedPumpHoursAndStagesChart;
                    barDatasets = ctx.chart.config.data.datasets.filter(dataset => dataset.type === 'bar' || !dataset.type);
                    lineDatasets = ctx.chart.config.data.datasets.filter(dataset => dataset.type === 'line');

                    isCombinedPumpHoursAndStagesChart = barDatasets?.length > 0 && lineDatasets?.length > 0;
                    if (isCombinedPumpHoursAndStagesChart) {
                        //need to subtract the number of line chart datasets in combined chart
                        //to avoid inproper indexing of datalabels
                        let numberOfLineChartDatasets = lineDatasets.length;
                        numberOfDataSets = ctx.chart.config.data.datasets.length - numberOfLineChartDatasets;
                        datasetIndex = ctx.datasetIndex - numberOfLineChartDatasets;
                    } else {
                        numberOfDataSets = ctx.chart.config.data.datasets.length;
                        datasetIndex = ctx.datasetIndex;
                    }

                    headGroupEndIndex = (numberOfDataSets/2) - 1;
                    tailGroupEndIdex = numberOfDataSets - 1;

                    return datasetIndex === headGroupEndIndex || datasetIndex === tailGroupEndIdex;
                };

                this.options[kpiType].plugins.datalabels.formatter = (value, ctx) => {
                    const stack = ctx.dataset.stack ?? null;
                    const total = ctx.chart.$totalizer.totals[ctx.dataIndex + stack];
                    if (typeof total == 'number') {
                        return total > 0 ? +total.toFixed(2) : 0;
                    } else {
                        return '';
                    }
                };
            } else {
                this.options[kpiType].scales.xAxes[0].stacked = false;
                this.options[kpiType].scales.yAxes[0].stacked = false;
                if (!this.isCombinedPumpHoursAndStagesChart) {
                    this.options[kpiType].plugins.datalabels.display = true;
                    this.options[kpiType].plugins.datalabels.formatter = (value, ctx) => {
                        if (!value) {
                            return '';
                        }
                    }
                }
            }
        },
        setupPumpHoursPerDay(useSelectedAnalytics) {
            const isDayNight = this.selectedDayType === 'Day shift and night shift';
            let labels
            if(isDayNight && (!this.pumpTimeData.day || !this.pumpTimeData.night)) {
                return;
            }
            this.kpi.pumpTimes = {
                datasets: [{
                    label: chartStyle.pumpTimes.yAxisLabel,
                    backgroundColor: chartStyle.pumpTimes.backgroundColor
                }]
            };
            // still using this incase we decide to switch back to using wellId instead of wellNumber
            const wellsById = _.keyBy(this.dashboardData.wells, 'index');
            const numberOfWells = this.dashboardData.wells.length;
            // If selectedType is kpi-pumpingHours and selectedDayType is Stage we sort the labels by stage number
            if (this.isDayTypeStage) {
                const keys = Object.keys(this.pumpTimeData);
                // Step 1: Filter numeric keys
                const numericKeys = keys.filter(key => !isNaN(key));
                // Step 2: Sort numeric keys in ascending order
                labels = numericKeys.sort((a, b) => Number(a) - Number(b)).map(key => Number(key));
            } else if (isDayNight) {
                labels = [...new Set([...Object.keys(this.pumpTimeData.day), ...Object.keys(this.pumpTimeData.night)])].sort()
            } else {
                labels = Object.keys(this.pumpTimeData).sort()
            }
            this.kpi.pumpTimes.labels = labels;
            this.kpi.pumpTimes.datasets = [{ label: chartStyle.pumpTimes.yAxisLabel, backgroundColor: chartStyle.pumpTimes.barBackgroundColor, data: [] }];

            if(isDayNight) {
                if(this.isStackedByWell) {
                    this.kpi.pumpTimes.datasets = [];
                    for (let dayIndex = 0; dayIndex < 2; dayIndex++) {
                        for (const [index, wellInfo] of Object.entries(wellsById)) {
                            this.kpi.pumpTimes.datasets.push({ label: wellInfo.name + (dayIndex? ' night' : ' day') + ' Pump Hours(s)', backgroundColor: wellInfo.color, data: [] });
                        }
                    }
                } else {
                    this.kpi.pumpTimes.datasets = [
                        { label: 'day', backgroundColor: chartStyle.pumpTimes.barBackgroundColor, data: [] },
                        { label: 'night', backgroundColor: chartStyle.pumpTimes.barBackgroundColorSecondary, data: [] }
                    ];
                }
            } else {
                if(this.isStackedByWell) {
                    this.kpi.pumpTimes.datasets = [];
                    for (const [index, wellInfo] of Object.entries(wellsById)) {
                        this.kpi.pumpTimes.datasets.push({ label: wellInfo.name + ' Pump Hour(s)', backgroundColor: wellInfo.color, data: [] });
                    }
                }
            }

            for(const key of labels) {
                if(isDayNight) {
                    if(this.isStackedByWell) {
                        for (const [index, wellInfo] of Object.entries(wellsById)) {
                            const datasetIndex = Number(index);
                            const hasKey = this.pumpTimeData.day.hasOwnProperty(key);
                            const pumpTime = hasKey ? this.pumpTimeData.day[key][wellInfo.index] ?? 0 : 0;
                            this.kpi.pumpTimes.datasets[datasetIndex].data.push(pumpTime === 0 || Number.isNaN(+pumpTime) ? 0 : pumpTime);
                            this.kpi.pumpTimes.datasets[datasetIndex].stack = DAY_STACK_NAME;
                        }
                        for (const [index, wellInfo] of Object.entries(wellsById)) {
                            const datasetIndex = Number(index) + numberOfWells;
                            const hasKey = this.pumpTimeData.night.hasOwnProperty(key);
                            const pumpTime = hasKey ? this.pumpTimeData.night[key][wellInfo.index] ?? 0 : 0;
                            this.kpi.pumpTimes.datasets[datasetIndex].data.push(pumpTime === 0 || Number.isNaN(+pumpTime) ? 0 : pumpTime);
                            this.kpi.pumpTimes.datasets[datasetIndex].stack = NIGHT_STACK_NAME;
                        }
                    } else {
                        this.kpi.pumpTimes.datasets[0].data.push(this.pumpTimeData.day[key]?.duration ?? 0);
                        this.kpi.pumpTimes.datasets[1].data.push(this.pumpTimeData.night[key]?.duration ?? 0);
                    }
                } else {
                    if(this.isStackedByWell) {
                        for (const [index, wellInfo] of Object.entries(wellsById)) {
                            const datasetLength = this.kpi.pumpTimes.datasets.length - 1;
                            const datasetIndex = Number(index > datasetLength ? datasetLength : index);
                            const pumpTime = this.pumpTimeData[key][wellInfo.index] ?? 0;
                            this.kpi.pumpTimes.datasets[datasetIndex].data.push(Number.isNaN(pumpTime) ? 0 : pumpTime);
                        }
                    } else {
                        this.kpi.pumpTimes.datasets[0].data.push(this.pumpTimeData[key]?.duration ?? 0);
                    }
                }
            }
            // if selectedType is kpi-pumpingHours and selectedDayType is stage then no need to format the labels
            // as they are already in the correct format, assign labels and exit the function.
            if (this.isDayTypeStage) {
                this.kpi.pumpTimes.labels = labels;
                this.kpi.pumpTimes.exportLabels = labels;
                return;
            }

            let endDate = moment.utc().add({ hours: this.job.hourOffset }).startOf('day');
            if (this.job.end){
                endDate = moment.utc(this.job.end).add({ hours: this.job.hourOffset }).startOf('day')
            }
            function getDates(startDate, stopDate) {
                var dateArray = [];
                var currentDate = moment(startDate);
                var stopDate = moment(stopDate);
                while (currentDate <= stopDate) {
                    dateArray.push( moment(currentDate) )
                    currentDate = moment(currentDate).add({days: 1});
                }
                //append dates to beginning so UI always has at least 10 results
                while (dateArray.length < 10)
                {
                    dateArray.push(moment(dateArray[dateArray.length-1]).add({days: 1}));
                }

                return dateArray;
            }
            if(!this.job?.start) { return; }
            let startDate = moment.utc(this.job.start).add({ hours: this.job.hourOffset }).startOf('day');
            let dateRange = getDates(startDate, endDate); //returns days between job start and end or job start and present day

            let kpiDataDays = this.kpi.pumpTimes.labels.map(n => {
                return moment(n).utc()
                    .format('YYYY-MM-DD')
                    .toString();
            });

            //adds zeroes to datasets and labels to fill in spaces
            for(let i = 0; i < dateRange.length; i++)
            {
                dateRange = dateRange.reverse();
                if (moment(dateRange[i]).isBefore(moment(kpiDataDays[0]), 'days')) {
                    kpiDataDays.splice(0, 0, dateRange[i]);
                    for (let index = 0; index < this.kpi.pumpTimes.datasets.length; index++) {
                        this.kpi.pumpTimes.datasets[index].data.splice(0, 0, 0);
                    }
                }
                dateRange = dateRange.reverse();
                if (moment(dateRange[i]).isAfter(moment(kpiDataDays[kpiDataDays.length-1]), 'days')) {
                    kpiDataDays.push(dateRange[i]);
                    for (let index = 0; index < this.kpi.pumpTimes.datasets.length; index++) {
                        this.kpi.pumpTimes.datasets[index].data.splice(kpiDataDays.length-1, 0, 0);
                    }
                }
            }
            this.kpi.pumpTimes.labels = kpiDataDays.map(t => {
                return moment(t)
                    .format(SECONDARY_DATE_FORMAT)
                    .toString();
            });

            this.kpi.pumpTimes.exportLabels = kpiDataDays.map(t => {
                const {hours, minutes} = this.getShiftStartTimeInHourAndMinutes();
                const timestamp = moment(t);
                if (isDayNight || (this.dayTypes[0] && this.selectedDayType === this.dayTypes[0])){
                    timestamp
                        .hour(hours)
                        .minute(minutes);

                }
                return timestamp.format(DEFAULT_DATE_FORMAT);
            })

            this.checkForAnalytics(useSelectedAnalytics);
        },
        setupCombinedPumpHoursAndStages(useSelectedAnalytics = true) {
            if (this.kpi.pumpHoursAndStagesPerDay) {
                this.setupCompletedStagesPerDayHandler(this.kpi.pumpHoursAndStagesPerDay, this.isCombinedPumpHoursAndStagesChart);
                this.checkForAnalytics(useSelectedAnalytics);
            }
        },
        setupCompletedStagesPerDay() {
            if (this.kpi.completedStagesPerDay) {
                this.setupCompletedStagesPerDayHandler(this.kpi.completedStagesPerDay, false);
            }
        },
        setupCompletedStagesPerDayHandler(dataKpiType, isCombinedPumpHoursAndStagesChart) {
            const isDayNight = this.selectedDayType === 'Day shift and night shift';
            const wellsById = _.keyBy(this.dashboardData.wells, 'index');
            const numberOfWells = this.dashboardData.wells.length;

            dataKpiType.labels = Object.keys(this.dataLookup).sort();
            dataKpiType.datasets = [{ label: chartStyle.completedStagesPerDay.yAxisLabel, backgroundColor: chartStyle.completedStagesPerDay.barBackgroundColor, data: [] }];

            if(isDayNight) {
                if(this.isStackedByWell) {
                    dataKpiType.datasets = [];
                    const numberOfGroups = 2;
                    for (let dayIndex = 0; dayIndex < numberOfGroups; dayIndex++) {
                        for (const [index, wellInfo] of Object.entries(wellsById)) {
                            if (isCombinedPumpHoursAndStagesChart) {
                                dataKpiType.datasets.push({ label: wellInfo.name + ' Stages Completed' + (dayIndex? ' (Night)' : ' (Day)'), backgroundColor: wellInfo.color, data: [] });
                            } else {
                                dataKpiType.datasets.push({ label: wellInfo.name + (dayIndex? ' night' : ' day') + ' Stages Completed', backgroundColor: wellInfo.color, data: [] });
                            }
                        }
                    }
                } else {
                    dataKpiType.datasets = [
                        { label: 'day', backgroundColor: chartStyle.completedStagesPerDay.barBackgroundColor, data: [] },
                        { label: 'night', backgroundColor: chartStyle.completedStagesPerDay.barBackgroundColorSecondary, data: [] }
                    ];
                }
            } else if(this.isStackedByWell) {
                dataKpiType.datasets = [];
                for (const [index, wellInfo] of Object.entries(wellsById)) {
                    dataKpiType.datasets.push({ label: wellInfo.name + ' Stages Completed', backgroundColor: wellInfo.color, data: [] });
                }
            }

            for (const key of Object.keys(this.dataLookup).sort()) {
                if (isDayNight) {
                    //group
                    if(this.isStackedByWell) {
                        this.mapStackedData(this.dayNightDataLookup[key].day, wellsById, 0, DAY_STACK_NAME, dataKpiType);
                        this.mapStackedData(this.dayNightDataLookup[key].night, wellsById, numberOfWells, NIGHT_STACK_NAME, dataKpiType);
                    } else {
                        dataKpiType.datasets[0].data.push(this.dayNightDataLookup[key].day.length);
                        dataKpiType.datasets[1].data.push(this.dayNightDataLookup[key].night.length);
                    }
                } else {
                    if (this.isStackedByWell) {
                        this.mapStackedData(this.dataLookup[key], wellsById, 0, null, dataKpiType);
                    } else {
                        dataKpiType.datasets[0].data.push(this.dataLookup[key].length);
                    }
                }
            }

            let endDate = moment.utc().add({ hours: this.job.hourOffset }).startOf('day');
            if (this.job.end){
                endDate = moment.utc(this.job.end).add({ hours: this.job.hourOffset }).startOf('day')
            }
            function getDates(startDate, stopDate) {
                var dateArray = [];
                var currentDate = moment(startDate);
                var stopDate = moment(stopDate);
                while (currentDate <= stopDate) {
                    dateArray.push( moment(currentDate) )
                    currentDate = moment(currentDate).add({days: 1});
                }
                return dateArray;
            }
            if(!this.job?.start) { return; }
            let startDate = moment.utc(this.job.start).add({ hours: this.job.hourOffset }).startOf('day');
            let dateRange = getDates(startDate, endDate); //returns days between job start and end or job start and present day

            let kpiDataDays = dataKpiType.labels.map(n => {
                return moment(parseInt(n)).utc()
                    .format('YYYY-MM-DD')
                    .toString();
            });

            //adds zeroes to datasets and labels to make sure kpi chart starts at job start and ends at job end or present day
            for(let i = 0; i < dateRange.length; i++)
            {
                dateRange = dateRange.reverse();
                if (moment(dateRange[i]).isBefore(moment(kpiDataDays[0]), 'days')) {
                    kpiDataDays.splice(0, 0, dateRange[i]);
                    for (let index = 0; index < dataKpiType.datasets.length; index++) {
                        dataKpiType.datasets[index].data.splice(0, 0, 0);
                    }
                }
                dateRange = dateRange.reverse();
                if (moment(dateRange[i]).isAfter(moment(kpiDataDays[kpiDataDays.length-1]), 'days')) {
                    kpiDataDays.push(dateRange[i]);
                    for (let index = 0; index < dataKpiType.datasets.length; index++) {
                        dataKpiType.datasets[index].data.splice(kpiDataDays.length-1, 0, 0);
                    }
                }
            }

            const selectedDayType = this.selectedDayType ?? this.dayTypes[0];
            dataKpiType.labels = kpiDataDays.map(t => {
                const isDayShiftSelected = this.dayTypes[0] && selectedDayType === this.dayTypes[0];
                const isDayNightShiftSelected = this.dayTypes[2] && selectedDayType === this.dayTypes[2];
                const timestamp = moment.utc(t)
                if (isDayShiftSelected || isDayNightShiftSelected) {
                    const {hours, minutes} = this.getShiftStartTimeInHourAndMinutes()
                    timestamp
                        .hour(hours)
                        .minute(minutes)
                }
                return timestamp
                    .valueOf()
                    .toString();
            });
            //pumptime plotline dataset
            if (isCombinedPumpHoursAndStagesChart && !this.showNoPumpHourNotification) {
                if (isDayNight) {
                    if (this.pumpTimeData.day && this.pumpTimeData.night) {
                        const labels = [...new Set([...Object.keys(this.pumpTimeData.day), ...Object.keys(this.pumpTimeData.night)])].sort();
                        let dayNightDatasets = [
                            {
                                label: 'day',
                                data: [],
                                tension: 0,
                                lineTension: 0,
                                type: 'line',
                                borderColor: DAY_PUMP_HOURS_PLOTLINE_COLOR,
                                pointStyle: 'rectRot',
                                pointBorderColor: 'black',
                                pointBackgroundColor: DAY_PUMP_HOURS_PLOTLINE_COLOR,
                                pointRadius: 6,
                                pointLabel: null,
                                labels: {
                                    usePointStyle: true, // Use point style for legend symbol
                                },
                                fill: false,
                                yAxisID: 'right-axis',
                            },
                            {
                                label: 'night',
                                data: [],
                                tension: 0,
                                lineTension: 0,
                                type: 'line',
                                borderColor: NIGHT_PUMP_HOURS_PLOTLINE_COLOR,
                                pointStyle: 'rectRot',
                                pointBorderColor: 'black',
                                pointBackgroundColor: NIGHT_PUMP_HOURS_PLOTLINE_COLOR,
                                pointRadius: 6,
                                pointLabel: null,
                                labels: {
                                    usePointStyle: true, // Use point style for legend symbol
                                },
                                fill: false,
                                yAxisID: 'right-axis',
                            }
                        ];

                        for(const key of labels) {
                            dayNightDatasets[0].data.push(this.pumpTimeData.day[key]?.duration ?? 0);
                            dayNightDatasets[1].data.push(this.pumpTimeData.night[key]?.duration ?? 0);
                        }
                        dataKpiType.datasets.unshift(...dayNightDatasets);
                    }
                } else {
                    const labels = Object.keys(this.pumpTimeData).sort();
                    dataKpiType.datasets.unshift({
                        label: chartStyle.pumpTimes.yAxisLabel,
                        data: [],
                        tension: 0,
                        lineTension: 0,
                        type: 'line',
                        borderColor: '#00008B',
                        pointStyle: 'rectRot',
                        pointBorderColor: 'black',
                        pointBackgroundColor: '#00008B',
                        pointRadius: 6,
                        pointLabel: null,
                        fill: false,
                        yAxisID: 'right-axis',
                    });
                    for(const key of labels) {
                        dataKpiType.datasets[0].data.push(this.pumpTimeData[key]?.duration ?? 0);
                    }
                }
            }
            this.analyticDatasets = _.cloneDeep(dataKpiType.datasets);

            let completionProgressLabels =  _.cloneDeep(dataKpiType?.labels);
            let completionProgressData = _.cloneDeep(dataKpiType.datasets[0]?.data);
            this.completionProgressData = completionProgressData;

            const remainingToGenerate = 10 - dataKpiType.labels.length;

            if (dataKpiType.labels.length && dataKpiType.datasets.length) {
                for(let i = 0; i < remainingToGenerate; i++) {
                    const lastLabel = parseInt(dataKpiType.labels[dataKpiType.labels.length - 1]);
                    const appendedLabel = (lastLabel + parseInt(8.64e+7)).toString();
                    dataKpiType.labels.push(appendedLabel);
                    for (let index = 0; index < dataKpiType.datasets.length; index++) {
                        dataKpiType.datasets[index].data.push(0);
                    }
                }
            }

            // Preserve timestamps in default format for export operations
            dataKpiType.defaultFormatLabels = dataKpiType.labels.map(t => {
                return moment.utc(parseInt(t))
                    .format(DEFAULT_DATE_FORMAT)
                    .toString();
            });

            dataKpiType.labels = dataKpiType.labels.map(t => {
                return moment(parseInt(t)).utc()
                    .format(SECONDARY_DATE_FORMAT)
                    .toString();
            });

            const completionProgressDefaultFormatLabels = completionProgressLabels.map(t => {
                return moment.utc(parseInt(t))
                    .format(DEFAULT_DATE_FORMAT)
                    .toString();
            })

            completionProgressLabels = completionProgressLabels.map(t => {
                return moment(parseInt(t)).utc()
                    .format(SECONDARY_DATE_FORMAT)
                    .toString();
            });
            if (!isCombinedPumpHoursAndStagesChart) {
                if(completionProgressData) {
                    this.kpi.completionProgress = {
                        labels: completionProgressLabels, // for now share
                        defaultFormatLabels: completionProgressDefaultFormatLabels,
                        datasets: [
                            {
                                label: 'Projected Stages Completed',
                                labelColor: '#CCCCCC',
                                fill: false,
                                // below line creates the projected data array by adding 0's until we hit the point in the array that we have completed stages
                                // completed stages are calculated as 9.5 * days since first stage completed.
                                data: completionProgressData.map((v, i) => {
                                    const multiplier = this.filters.completionProgress.progressPerDay;
                                    const projectedValue = ((i+1)*multiplier) - this.firstStagesMissing;

                                    // In case it happens, filter out negative values
                                    if (projectedValue > 0) {
                                        // Cap the line to the total stages
                                        return projectedValue < this.totalStages ? projectedValue : this.totalStages;
                                    }
                                    return 0;
                                }),
                                backgroundColor: '#F0AD4E',
                                borderColor: '#F0AD4E'
                            },
                            {
                                label: 'Actual Stages Completed',
                                labelColor: '#CCCCCC',
                                fill: false,
                                backgroundColor: chartStyle.completionProgress.barBackgroundColor,
                                borderColor: chartStyle.completionProgress.barBackgroundColor,
                                data: completionProgressData.reduce((acc, current, index) => [...acc, current + (acc[index-1] || 0)], []) // creates a new array with a running cumulative sum
                            }
                        ]
                    };
                }
                this.calculatePredictedCompletionRate(true);
            }
        },
        mapStackedData(dataLookUpDay, wellsInfo, indexStart = 0, groupName = null, dataKpiType) {
            const dataByWellNumber =   _.groupBy(dataLookUpDay, 'wellNumber');
            for (const [index] of Object.entries(wellsInfo)) {
                const datasetIndex = Number(index) + indexStart;
                dataKpiType.datasets[datasetIndex].data.push(dataByWellNumber[index]? dataByWellNumber[index].length : 0);

                if(groupName) {
                    dataKpiType.datasets[datasetIndex].stack = groupName;
                }
            }
        },
        setupDayAndNightData( lookup ) {
            const dayStartHour = this.job.shiftStart;
            const dayNightDataLookup = {};

            Object.entries(lookup).forEach(date => {
                const key = date[0];
                const values = date[1];

                const dataObj = {
                    day: [],
                    night: []
                };

                values.forEach(stage => {
                    if(dayStartHour > 12) { //12 = 12 noon
                        if((stage.endTime.hours() >= dayStartHour && stage.endTime.hours() < 24) || (stage.endTime.hours() >= 0 && stage.endTime.hours() < (dayStartHour + 12))) {
                            dataObj.day.push(stage);
                        } else {
                            dataObj.night.push(stage);
                        }
                    } else {
                        if(stage.endTime.hours() >= dayStartHour && stage.endTime.hours() < (dayStartHour + 12)) {
                            dataObj.day.push(stage);
                        } else {
                            dataObj.night.push(stage);
                        }
                    }
                });

                dayNightDataLookup[key] = dataObj;
            });
            return dayNightDataLookup;
        },
        setupPumpTime()
        {
            if(this.pumpTimes <= 0)
            {return;}

            const pumpTimeData = Object.entries(this.pumpTimes).map(entry => (parseInt(entry[1]) / 1000 / 60 / 60).toFixed(2)).reverse(); // convert pump time milliseconds to hours
            const pumpTimeLabels = Object.entries(this.pumpTimes).map(entry => String(entry[0])).reverse();
            const toAdd = Math.max(10 - pumpTimeData.length, 0);

            for(let i = 0; i < toAdd; i++)
            {
                pumpTimeLabels.splice(0, 0, parseInt(pumpTimeLabels[0])-8.64e+7); // 8.64e+7 is 24 hours in milliseconds, this adds a new empty label entry 24 hours before the first one
                pumpTimeData.splice(0, 0, 0);
            }

            this.kpi.pumpTimes = {
                labels: pumpTimeLabels,
                datasets: [
                    {
                        label: 'Pump Time Per Day',
                        labelColor: '#CCCCCC',
                        fill: false,

                        data: pumpTimeData,
                        backgroundColor: chartStyle.pumpTimes.barBackgroundColor,
                        borderColor: chartStyle.pumpTimes.barBackgroundColor
                    }
                ]
            };

            this.$nextTick(() => {
                if(this.$refs.pumpTimeChartTimeline)
                {this.$refs.pumpTimeChartTimeline.correctTimelineWidth(this.$refs.pumpTimeChart.$data._chart);}
            });
        },
        processResponse(data)
        {
            this.rawCompletedStages = data;
            this.dataLookup = {};
            // adding job offset here
            const dataTimeWithJobOffset = data.map((activity)=>{
                return {
                    ...activity,
                    startTime: moment.utc(activity.startTime, 'YYYY-MM-DD HH:mm:ss').add({ hours: this.job.hourOffset}),
                    endTime: moment.utc(activity.endTime, 'YYYY-MM-DD HH:mm:ss').add({ hours: this.job.hourOffset}),
                    readableLocalEndTime: moment.utc(activity.endTime, 'YYYY-MM-DD HH:mm:ss').add({ hours: this.job.hourOffset}).format(DEFAULT_DATE_FORMAT)
                };
            });

            this.createLookupByDate(dataTimeWithJobOffset);

            if(Object.keys(this.dataLookup).length > 0)
            {
                if (this.selectedDayType !== 'Midnight to midnight')
                    this.shiftDatesByStartTime(this.job.shiftStart);
                this.dayNightDataLookup = this.setupDayAndNightData(this.dataLookup);
                this.numberOfDays = Object.keys(this.dataLookup).length;
                this.setupCompletedStagesPerDay();
            }
        },
        readableSeconds(value) {
            const mins = Math.floor(value/60);

            if (mins > 0) {
                const seconds = Math.floor(value - (mins*60));

                if (seconds > 0)
                    return `${mins} minutes, ${seconds} seconds`;
                return `${mins} minutes`;
            }
            return `${value} seconds`;
        },
        formatStartEndTime(from, to) {
            return `
                Start: ${dateTimeDisplay(applyTimeOffset(from.endTime, this.job.hourOffset))}
                <br/>
                End: ${dateTimeDisplay(applyTimeOffset(to.startTime, this.job.hourOffset))}
            `;
        },

        handleFilterchange() {
            this.filters.completionProgress.progressPerDay = Math.max(0, this.filters.completionProgress.progressPerDay);
            this.setupCompletedStagesPerDay();
        }
    },
    data() {
        return {
            errors: [],
            kpiTypesWithAnalytics: KPI_TYPES_WITH_ANALYTICS,
            maxStagesChart: null,
            componentId: this.item.i,
            includePumpHoursPlotline: false,
            exportOverlayMode: false,
            selectedExportType: 'csv',
            selectedTimezone: 'local',
            exportTypes: ['csv', 'json'],
            exportTimezone: ['local', 'utc'],
            isStackedByWell: false,
            onStackInitChanged: false,
            onSelectedAnalyticsChanged: false,
            analyticsData: [],
            analyticsColors: [],
            analyticsColorsPlotline: [],
            selectedAnalyticTypes: [],
            pumpTimeData: [],
            analyticTypes: [
                'Average',
                'Median'
            ],
            rawCompletedStages: [],
            dayNightDataLookup: {},
            kpiTypes: [
                'kpi-total',
                'kpi-progress',
                'kpi-lateral',
                'kpi-pumpingHours',
                'kpi-swapTime',
                'kpi-metricsPerStage'
            ],
            kpiTypeLabels: {
                'kpi-total': 'Total Stages Per Day',
                'kpi-progress': 'Cumulative Complete Stages',
                'kpi-lateral': 'Lateral Length Completed',
                'kpi-pumpingHours': 'Pump Time Summary',
                'kpi-swapTime': 'Standby/Transition Times',
                'kpi-metricsPerStage': 'Metrics Per Stage'
            },
            kpiTooltipKeys: {
                'kpi-total': "kpi-total-stages",
                'kpi-progress': "kpi-completion-progress",
                'kpi-lateral': "kpi-lateral-length-completed",
                'kpi-pumpingHours': "kpi-pumping-hours-per-day",
                'kpi-swapTime': 'kpi-swapTime',
                'kpi-metricsPerStage': 'kpi-metricsPerStage'
            },
            zeroStageTypes: [
                'zeroStage-include',
                'zeroStage-nonLeading',
                'zeroStage-none'
            ],
            zeroStageTypeLabels: {
                'zeroStage-include': 'Include days with 0 values',
                'zeroStage-nonLeading': 'No leading days with 0 values',
                'zeroStage-none': 'No days with 0 values'
            },
            swapTypes: {
                'wlToFrac': 'Wireline to Frac',
                'wlToWl': 'Wireline to Wireline',
                'fracToWl': 'Frac to Wireline',
                'fracToFrac': 'Frac to Frac'
            },
            ticker: true,
            analyticDatasets: null,
            initialSelectedDayType: null,
            totalStages: 0,
            completionProgressData: [],
            initialProgressLabels: [],
            initialProgressDefaultFormatLabels: [],
            futureProgressLabels: [],
            isFullJob: true,
            headerHeight: 40,
            progressHeightTrigger: 500,
            selectedType: null,
            selectedDayType: null,
            selectedZeroStageType: null,
            selectedSwapType: 'fracToWl',
            wirelineSwapFilter: 1,
            minSwapTimeFilter: null,
            minSwapFilterOptions: [
                { value: null, label: 'No Limit' },
                { value: 60, label: '1 Minute' },
                { value: 120, label: '2 Minutes' },
                { value: 300, label: '5 Minutes' },
                { value: 600, label: '10 Minutes' }
            ],
            maxSwapTimeFilter: null,
            maxSwapFilterOptions: [
                { value: null, label: 'No Limit' },
                { value: 1800, label: '30 Minutes' },
                { value: 2700, label: '45 Minutes' },
                { value: 3600, label: '1 Hour' },
                { value: 5400, label: '1.5 Hours' },
                { value: 7200, label: '2 Hours' }
            ],
            customMinSwap: false,
            customMaxSwap: false,
            metadataTags: null,
            scatterTag: -1,
            barTag: -1,
            averageWellDataMetricsPerStage: false,
            limitStagesMetricsPerStage: null,
            showLimitStageMetrics: false,
            completionProgressChartKey: new Date().getTime() + this._uid,
            initialSelectedType: null,
            initialSelectedZeroStageType: null,
            initialPumpHoursPlotlineVal: null,
            isSaveDefault: false,
            filteredItems: [],
            job: {},
            contractors: [],
            eventActivities: [],
            eventActivityEventReasons: [],
            eventReasons: [],
            pumpTimes: {},
            dataLookup: {},
            unshiftedDataLookup: {},
            hoverX: 0,
            defaultAnnotation: {
                type: 'line',
                drawTime: 'beforeDatasetsDraw',
                mode: 'vertical',
                scaleID: 'date-axis',
                value: null,
                borderColor: 'red',
                borderWidth: 2,
                borderDash: [5,8],
                label: {
                    content: '',
                    enabled: true,
                    position: 'top',
                    backgroundColor: 'red'
                }
            },
            chartPlugins: [{
                callbackScope: this, // not a chartjs plugin property, just using this to pass scope
                id: 'verticalLine',
                beforeDatasetDraw: function(chart) {
                    const ctx = chart.ctx;
                    const x = this.callbackScope.hoverX;
                    const topY = chart.chartArea.top;
                    const bottomY = chart.chartArea.bottom;

                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = '#FFFFFF';
                    ctx.stroke();
                    ctx.restore();
                }
            }],
            kpi: {
                completedStagesPerDay: {
                    labels: [],
                    datasets: [
                        {
                            label: chartStyle.completedStagesPerDay.yAxisLabel,
                            data: [],
                            backgroundColor: chartStyle.completedStagesPerDay.barBackgroundColor,
                        }
                    ]
                },
                pumpHoursAndStagesPerDay: {
                    labels: [],
                    datasets: [
                        {
                            label: chartStyle.completedStagesPerDay.yAxisLabel,
                            data: [],
                            backgroundColor: chartStyle.completedStagesPerDay.barBackgroundColor,
                        }
                    ]
                },
                completionProgress: {
                    labels: [],
                    defaultFormatLabels: [],
                    datasets: [{
                        label: 'Projected Stages Completed',
                        fill: false,
                        data: [],
                        backgroundColor: '#F0AD4E',
                        borderColor: '#F0AD4E'
                    },
                    {
                        label: 'Actual Stages Completed',
                        fill: false,
                        data: [],
                        backgroundColor: chartStyle.completionProgress.barBackgroundColor,
                        borderColor: chartStyle.completionProgress.barBackgroundColor
                    }]
                },
                lateralLengthPerDay: {
                    labels: [],
                    datasets: [{
                        label: chartStyle.lateralLengthPerDay.yAxisLabel,
                        data: [],
                        backgroundColor: chartStyle.lateralLengthPerDay.barBackgroundColor
                    }]
                },
                pumpTimes: {
                    labels: [],
                    datasets: [{
                        label: chartStyle.pumpTimes.yAxisLabel,
                        data: [],
                        backgroundColor: chartStyle.pumpTimes.barBackgroundColor,
                        borderColor: chartStyle.pumpTimes.barBackgroundColor
                    }]
                },
                swapTimes: {
                    labels: [],
                    datasets: [{
                        label: chartStyle.swapTimes.yAxisLabel,
                        data: [],
                        backgroundColor: chartStyle.lateralLengthPerDay.barBackgroundColor
                    }]
                },
                metricsPerStage: {
                    labels: [],
                    datasets: [{
                        type: 'line',
                        label: 'Metrics per Stage Scatter',
                        fill: false,
                        data: [],
                        backgroundColor: '#F0AD4E',
                        borderColor: '#F0AD4E'
                    },
                    {
                        type: 'bar',
                        label: 'Metrics per Stage Bar',
                        fill: true,
                        data: [],
                        backgroundColor: '#1C7CD5',
                        borderColor: '#1C7CD5',
                        yAxisID: 'right-axis'
                    }]
                },
            },
            options:
            {
                completedStagesPerDay: {
                    layout: { padding: { top: TOP_PADDING_KPI }},
                    annotation: {
                        annotations: []
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            color: chartStyle.completedStagesPerDay.labelFontColor,
                            font: {
                                size: 16
                            },
                            formatter: null,
                            anchor: 'end',
                            align: 'top'
                        },
                        zoom: {
                            pan: {
                                enabled: false,
                                mode: 'x',
                                rangeMin: {
                                    x: null
                                },
                                rangeMax: {
                                    x: null
                                }
                            },
                            zoom: {
                                enabled: false,
                                drag: false
                            }
                        }
                    },
                    legend: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            id: 'date-axis',
                            scaleLabel: {
                                display: true
                            },
                            gridLines: {
                                color: 'transparent'
                            },
                            ticks: {
                                fontColor: chartStyle.completedStagesPerDay.labelFontColor,
                                callback: function(value) {
                                    return moment.utc(value, SECONDARY_DATE_FORMAT).format('MMM D');
                                }
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.completedStagesPerDay.labelFontColor,
                                fontSize: 14,
                                labelString: chartStyle.completedStagesPerDay.yAxisLabel
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 5,
                                maxRotation: 0,
                                fontColor: chartStyle.completedStagesPerDay.labelFontColor
                            },
                            gridLines: {
                                color: chartStyle.completedStagesPerDay.gridLinesColor
                            }
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                pumpHoursAndStagesPerDay: {
                    cubicInterpolationMode: 'linear', // Set interpolation mode to linear
                    layout: { padding: { top: TOP_PADDING_KPI }},
                    annotation: {
                        annotations: []
                    },
                    plugins: {
                        paddingBelowLegends: true,
                        datalabels: {
                            display: function(context) {
                                return context.dataset.type !== 'line'; // dont want datalabels on pump hours plotline
                            },
                            color: chartStyle.completedStagesPerDay.labelFontColor,
                            font: {
                                size: 16
                            },
                            formatter: null,
                            anchor: 'end',
                            align: 'top'
                        },
                        zoom: {
                            pan: {
                                enabled: false,
                                mode: 'x',
                                rangeMin: {
                                    x: null
                                },
                                rangeMax: {
                                    x: null
                                }
                            },
                            zoom: {
                                enabled: false,
                                drag: false
                            }
                        }
                    },
                    legend: {
                        onClick: (event, legendItem) => {
                            // Disable hiding datasets on legend click
                            event.stopPropagation();
                        },
                        display: true,
                        labels: {
                            fontColor: '#FFFFFF',
                            usePointStyle: true,
                            generateLabels: function(chart) {
                                let originalLabels = Chart.defaults.global.legend.labels.generateLabels(chart);
                                let modifiedLabels = _.cloneDeep(originalLabels);
                                for (let i = 0; i < originalLabels.length; i++) {
                                    if (chart.data.datasets[i].type === 'line') {
                                        if (chart.data.datasets[i].label === 'day' || chart.data.datasets[i].label === 'night') {
                                            modifiedLabels[i].text = originalLabels[i].text === "day"? 'Pump Time (Day)' : 'Pump Time (Night)'; // Add a custom label for line datasets
                                        }
                                    }
                                    else {
                                        if (chart.data.datasets[i].label === 'day' || chart.data.datasets[i].label === 'night') {
                                            modifiedLabels[i].text = originalLabels[i].text === "day"? 'Completed Stages (Day)' : 'Completed Stages (Night)'
                                        }
                                    }
                                }
                                return modifiedLabels;
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            id: 'date-axis',
                            scaleLabel: {
                                display: true
                            },
                            gridLines: {
                                color: 'transparent'
                            },
                            ticks: {
                                fontColor: chartStyle.completedStagesPerDay.labelFontColor,
                                callback: function(value) {
                                    return moment.utc(value, SECONDARY_DATE_FORMAT).format('MMM D');
                                }
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.completedStagesPerDay.labelFontColor,
                                fontSize: 14,
                                labelString: chartStyle.completedStagesPerDay.yAxisLabel
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 5,
                                maxRotation: 0,
                                fontColor: chartStyle.completedStagesPerDay.labelFontColor
                            },
                            gridLines: {
                                color: chartStyle.completedStagesPerDay.gridLinesColor
                            }
                        },
                        {
                            id: 'right-axis',
                            position: 'right',
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.completedStagesPerDay.labelFontColor,
                                fontSize: 14,
                                labelString: chartStyle.pumpTimes.yAxisLabel
                            },
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: this.selectedDayType === 'Day shift and night shift'? 24 : 12,
                                stepSize: 4,
                                maxRotation: 0,
                                fontColor: chartStyle.completedStagesPerDay.labelFontColor
                            },
                            gridLines: {
                                display: false
                            }
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                lateralLengthPerDay: {
                    layout: { padding: { top: TOP_PADDING_KPI }},
                    annotation: {
                        annotations: []
                    },
                    plugins: {
                        datalabels: {
                            display: 'auto',
                            color: chartStyle.lateralLengthPerDay.labelFontColor,
                            font: {
                                size: 16
                            },
                            anchor: 'end',
                            align: 'top'
                        },
                        zoom: {
                            pan: {
                                enabled: false,
                                mode: 'x',
                                rangeMin: {
                                    x: null
                                },
                                rangeMax: {
                                    x: null
                                }
                            },
                            zoom: {
                                enabled: false,
                                drag: false
                            }
                        }
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            id: 'date-axis',
                            scaleLabel: {
                                display: true
                            },
                            gridLines: {
                                color: 'transparent'
                            },
                            ticks: {
                                fontColor: chartStyle.lateralLengthPerDay.labelFontColor,
                                callback: function(value) {
                                    return moment.utc(value, SECONDARY_DATE_FORMAT).format('MMM D');
                                }
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.lateralLengthPerDay.labelFontColor,
                                fontSize: 14,
                                labelString: chartStyle.lateralLengthPerDay.yAxisLabel
                            },
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 15,
                                stepSize: 5,
                                maxRotation: 0,
                                fontColor: chartStyle.lateralLengthPerDay.labelFontColor
                            },
                            gridLines: {
                                color: chartStyle.lateralLengthPerDay.gridLinesColor
                            }
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                completionProgress: {
                    progressPerDay: 9.5,
                    layout: { padding: { top: TOP_PADDING_KPI }},
                    annotation: {
                        annotations: []
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                            color: chartStyle.completionProgress.labelFontColor,
                            font: {
                                size: 16
                            },
                            anchor: 'end',
                            align: 'top'
                        },
                        zoom: {
                            pan: {
                                enabled: false,
                                mode: 'x',
                                rangeMin: {
                                    x: null
                                },
                                rangeMax: {
                                    x: null
                                }
                            },
                            zoom: {
                                enabled: false,
                                drag: false
                            }
                        }
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                            filter: function(label) {
                                if(label.text === 'Predicted Stages Completed') {
                                    return false;
                                } else {
                                    return true;
                                }
                            },
                            fontColor: '#CCC'
                        }

                    },
                    scales: {
                        xAxes: [{
                            id: 'date-axis',
                            scaleLabel: {
                                display: true
                            },
                            gridLines: {
                                color: 'transparent'
                            },
                            ticks: {
                                fontColor: chartStyle.completionProgress.labelFontColor,
                                callback: function(value) {
                                    return moment.utc(value, SECONDARY_DATE_FORMAT).format('MMM D');
                                }
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.completionProgress.labelFontColor,
                                fontSize: 14,
                                labelString: chartStyle.completionProgress.yAxisLabel
                            },
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 15,
                                stepSize: 10,
                                maxRotation: 0,
                                fontColor: chartStyle.completionProgress.labelFontColor
                            },
                            gridLines: {
                                color: chartStyle.completionProgress.gridLinesColor
                            }
                        }]
                    },
                    elements: {
                        line: {
                            fill: false
                        }
                    },
                    responsive: true,
                    onResize: () =>
                    {
                        this.$nextTick(() => {
                            if(this.$refs.completionProgressChartTimeline)
                            {this.$refs.completionProgressChartTimeline.correctTimelineWidth(this.$refs?.completionProgressChart?.$data?._chart);}
                        });
                    },
                    maintainAspectRatio: false
                },
                pumpTimes: {
                    layout: { padding: { top: TOP_PADDING_KPI }},
                    annotation: {
                        annotations: []
                    },
                    plugins: {
                        datalabels: {
                            display: 'auto',
                            color: chartStyle.pumpTimes.labelFontColor,
                            font: {
                                size: 16
                            },
                            formatter: function(value, context) {
                                if (typeof value == 'number') {
                                    return value != 0? value.toFixed(2) : '0';
                                } else {
                                    return '';
                                }
                            },
                            anchor: 'end',
                            align: 'top'
                        },
                        zoom: {
                            pan: {
                                enabled: false,
                                mode: 'x',
                                rangeMin: {
                                    x: null
                                },
                                rangeMax: {
                                    x: null
                                }
                            },
                            zoom: {
                                enabled: false,
                                drag: false
                            }
                        }
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            id: 'date-axis',
                            scaleLabel: {
                                display: true
                            },
                            gridLines: {
                                color: 'transparent'
                            },
                            ticks: {
                                fontColor: chartStyle.pumpTimes.labelFontColor,
                                callback: function(value) {
                                    // staged labels are numbers, not time, and should be handled differently
                                    return typeof value == 'number' ? value : moment.utc(value,SECONDARY_DATE_FORMAT).format('MMM D');
                                }
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.pumpTimes.labelFontColor,
                                fontSize: 14,
                                labelString: chartStyle.pumpTimes.yAxisLabel
                            },
                            ticks: {
                                beginAtZero: true,
                                //ternary appears to work on reverse logic here, unsure why?
                                //true:false results had to be flipped to achieve the desired result, normally would be 12 : 24
                                suggestedMax: this.selectedDayType === 'Day shift and night shift'? 24 : 12,
                                stepSize: 4,
                                maxRotation: 0,
                                fontColor: chartStyle.pumpTimes.labelFontColor
                            },
                            gridLines: {
                                color: chartStyle.pumpTimes.gridLinesColor
                            }
                        }]
                    },
                    responsive: true,
                    onResize: () =>
                    {
                        this.$nextTick(() => {
                            if(this.$refs.pumpTimeChartTimeline)
                            {this.$refs.pumpTimeChartTimeline.correctTimelineWidth(this.$refs.pumpTimeChart.$data._chart);}
                        });
                    },
                    maintainAspectRatio: false
                },
                swapTimes: {
                    layout: { padding: { top: TOP_PADDING_KPI }},
                    annotation: {
                        annotations: []
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            color: chartStyle.completedStagesPerDay.labelFontColor,
                            font: {
                                size: 16
                            },
                            formatter: null,
                            anchor: 'end',
                            align: 'top'
                        },
                        zoom: {
                            pan: {
                                enabled: false,
                                mode: 'x',
                                rangeMin: {
                                    x: null
                                },
                                rangeMax: {
                                    x: null
                                }
                            },
                            zoom: {
                                enabled: false,
                                drag: false
                            }
                        }
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            id: 'date-axis',
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.swapTimes.labelFontColor,
                                fontSize: 14,
                                labelString: chartStyle.swapTimes.xAxisLabel
                            },
                            gridLines: {
                                color: 'transparent'
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.swapTimes.labelFontColor,
                                fontSize: 14,
                                labelString: chartStyle.swapTimes.yAxisLabel
                            },
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 5,
                                stepSize: 15,
                                maxRotation: 0,
                                fontColor: chartStyle.swapTimes.labelFontColor,
                                callback: (value) => {
                                    return `${Math.floor(value/60)}`;
                                }
                            },
                            gridLines: {
                                color: chartStyle.swapTimes.gridLinesColor
                            }
                        }]
                    },
                    tooltips: {
                        enabled: false,
                        mode: 'nearest',
                        intersect: true,
                        position: 'cursor',
                        animationDuration: 0,
                        backgroundColor: '#000',
                        callbacks: {
                            label: (tooltipItem, data) => {
                                const { from, to } = data?.datasets[tooltipItem.datasetIndex]?.data[tooltipItem.index]?.meta;

                                return `
                                    ${this.swapTypeLabel.upper} ${tooltipItem.label}
                                    <br/>

                                    ${from.name}, ${from.stageNumber} to ${to.name}, ${to.stageNumber}
                                    <br/>

                                    Duration: ${this.readableSeconds(tooltipItem.value)}
                                    <br/>

                                    ${this.formatStartEndTime(from, to)}
                                `;
                            }
                        },
                        custom: function(tooltipModel) {
                            // Tooltip Element
                            let tooltipEl = document.getElementById('chartjs-tooltip-summary-bar');

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip-summary-bar';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign);
                            } else {
                                tooltipEl.classList.add('no-transform');
                            }

                            function getBody(bodyItem) {
                                return bodyItem.lines;
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                const titleLines = tooltipModel.title || [];
                                const bodyLines = tooltipModel.body.map(getBody);

                                let innerHtml = '<thead>';

                                titleLines.forEach(function(title) {
                                    const style = 'width : 10px; height: 10px; border-width : 1px;';
                                    innerHtml += '<tr><th><div class="d-flex pr-3"><div class="mx-2 mt-1" style="' + style + '"></div>' + title + '</div></th></tr>';
                                });
                                innerHtml += '</thead><tbody>';


                                bodyLines.forEach(function(body, i) {
                                    const colors = tooltipModel.labelColors[i];
                                    if(body && body.length>0) {
                                        const style = `background: ${typeof colors.backgroundColor === 'string'? colors.backgroundColor : 'transparent'}; width : 9px; height: 9px; border-width : 1px; border-color: ${typeof colors.backgroundColor === 'string'? '#FFFFFF' : 'transparent'}; border-style: solid`;
                                        innerHtml += '<tr><td><div class="d-flex pr-3"> <div class="mx-2 mt-1" style="' + style + '"></div>' + body + ' </div></td></tr>';
                                    }
                                });
                                innerHtml += '</tbody>';

                                const tableRoot = tooltipEl.querySelector('table');
                                tableRoot.innerHTML = innerHtml;
                            }

                            // `this` will be the overall tooltip
                            const position = this._chart.canvas.getBoundingClientRect();

                            //mouse position
                            let offset = tooltipModel.caretX;

                            //when the tooltip tries to render at the right edge
                            //of the screen, give it more space to the left
                            const averageTooltipWidth = 150;
                            if (tooltipModel.caretX > this._chart.width - averageTooltipWidth)
                            {offset = this._chart.width - averageTooltipWidth;}

                            // Display, position, and set styles for font
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.position = 'fixed';
                            tooltipEl.style.left = position.left + offset + 'px';
                            tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = 2 + 'px ' + 0 + 'px';
                            tooltipEl.style.pointerEvents = 'none';
                        }
                    },
                    elements: {
                        point: {
                            radius: 5,
                            hoverRadius: 8
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                metricsPerStage: {
                    cubicInterpolationMode: 'linear',
                    layout: { padding: { top: TOP_PADDING_KPI }},
                    annotation: {
                        annotations: []
                    },
                    plugins: {
                        paddingBelowLegends: true,
                        datalabels: {
                            display: false
                        },
                        zoom: {
                            pan: {
                                enabled: false,
                                mode: 'x',
                                rangeMin: {
                                    x: null
                                },
                                rangeMax: {
                                    x: null
                                }
                            },
                            zoom: {
                                enabled: false,
                                drag: false
                            }
                        }
                    },
                    legend: {
                        onClick: (event, legendItem) => {
                            // Disable hiding datasets on legend click
                            event.stopPropagation();
                        },
                        display: true,
                        labels: {
                            fontColor: '#FFFFFF',
                            usePointStyle: true
                        }
                    },
                    scales: {
                        xAxes: [{
                            id: 'stage-axis',
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.metricsPerStage.labelFontColor,
                                fontSize: 14,
                                labelString: 'Stage Number'
                            },
                            gridLines: {
                                color: 'transparent'
                            },
                            ticks: {
                                fontSize: 15,
                                fontColor: chartStyle.completionProgress.labelFontColor
                            }
                        }],
                        yAxes: [{
                            id: 'right-axis-0',
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.metricsPerStage.labelFontColor,
                                fontSize: 14,
                                labelString: 'Total Scatter Value'
                            },
                            ticks: {
                                beginAtZero: false,
                                suggestedMin: 10000,
                                stepSize: 500,
                                maxRotation: 0,
                                fontSize: 18,
                                fontColor: chartStyle.metricsPerStage.labelFontColor
                            },
                            gridLines: {
                                color: chartStyle.metricsPerStage.gridLinesColor
                            }
                        },
                        {
                            id: 'right-axis-1',
                            position: 'right',
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.metricsPerStage.labelFontColor,
                                fontSize: 14,
                                labelString: 'Total Bar Value'
                            },
                            ticks: {
                                beginAtZero: false,
                                suggestedMin: 0,
                                suggestedMax: 100,
                                stepSize: 10,
                                maxRotation: 0,
                                fontSize: 18,
                                fontColor: chartStyle.metricsPerStage.labelFontColor
                            },
                            gridLines: {
                                display: false
                            }
                        }]
                    },
                    tooltips: {
                        enabled: false,
                        mode: 'nearest',
                        intersect: true,
                        position: 'cursor',
                        animationDuration: 0,
                        backgroundColor: '#000',
                        callbacks: {
                            label: (tooltipItem, data) => {
                                return `
                                    ${data?.datasets[tooltipItem.datasetIndex].label}
                                    <br/>

                                    ${
                                        'wellNumber' in data?.datasets[tooltipItem.datasetIndex]?.data[tooltipItem.index]
                                        ? `
                                            Well Number: ${data?.datasets[tooltipItem.datasetIndex]?.data[tooltipItem.index]?.wellNumber+1}
                                            <br/>`
                                        : ''
                                    }
                                    Stage Number: ${data?.datasets[tooltipItem?.datasetIndex]?.data[tooltipItem?.index]?.x || tooltipItem.label}
                                    <br/>

                                    Value: ${tooltipItem.value}
                                `;
                            }
                        },
                        custom: function(tooltipModel) {
                            // Tooltip Element
                            let tooltipEl = document.getElementById('chartjs-tooltip-summary-bar');

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip-summary-bar';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign);
                            } else {
                                tooltipEl.classList.add('no-transform');
                            }

                            function getBody(bodyItem) {
                                return bodyItem.lines;
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                const bodyLines = tooltipModel.body.map(getBody);

                                let innerHtml = '<tbody>';
                                bodyLines.forEach(function(body, i) {
                                    const colors = tooltipModel.labelColors[i];
                                    if(body && body.length>0) {
                                        const style = `background: ${typeof colors.backgroundColor === 'string'? colors.backgroundColor : 'transparent'}; width : 9px; height: 9px; border-width : 1px; border-color: ${typeof colors.backgroundColor === 'string'? '#FFFFFF' : 'transparent'}; border-style: solid`;
                                        innerHtml += '<tr><td><div class="d-flex pr-3"> <div class="mx-2 mt-1" style="' + style + '"></div>' + body + ' </div></td></tr>';
                                    }
                                });
                                innerHtml += '</tbody>';

                                const tableRoot = tooltipEl.querySelector('table');
                                tableRoot.innerHTML = innerHtml;
                            }

                            // `this` will be the overall tooltip
                            const position = this._chart.canvas.getBoundingClientRect();

                            //mouse position
                            let offset = tooltipModel.caretX;

                            //when the tooltip tries to render at the right edge
                            //of the screen, give it more space to the left
                            const averageTooltipWidth = 150;
                            if (tooltipModel.caretX > this._chart.width - averageTooltipWidth)
                            {offset = this._chart.width - averageTooltipWidth;}

                            // Display, position, and set styles for font
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.position = 'fixed';
                            tooltipEl.style.left = position.left + offset + 'px';
                            tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = 2 + 'px ' + 0 + 'px';
                            tooltipEl.style.pointerEvents = 'none';
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                type: null
            },
            filters:
            {
                completionProgress: {
                    selectedActivityTypes: [2], // 2 is NPT activity
                    progressPerDay: 9.5,
                    activityDuration: 100
                }
            },
            // Pumping hours data
            dataSources: [
                { value: 'mongoDB', label: 'Mongo DB' },
                { value: 'ADXDB', label: 'ADX DB' },
            ],
            selectedPumpTimeSource: 'mongoDB',
            initialSelectedPumpTimeSource: null,
            barJSON: [],
            scatterJSON: [],
            hasDataChanged: false
        };
    }
};
</script>

<style>
.filtered-popover .popover-body {
    overflow-y: auto;
    max-height: 50vh;
}
</style>
<style scoped>
.kpi-select {
    width:250px;
}

.analytics-dropdown {
    width:260px
}
.check-box-list-container {
    width: 100% !important;
}
.bs-popover-bottom {
    margin: 0px!important;
}

.chart-container {
    background-color: #404040;
    border-style: solid;
    border-width: thin;
    border-radius: 4px;
    border-color: #CCCCCC;
    padding: 16px;
    margin: 10px;
}

.font-size {
    font-size: .875rem;
}

.options-dropdown {
    background-color: #373A3C;
    padding: 0px;
    border-style: solid;
    border-radius: 3.5px;
    border-color: #95989A;
    border-width: thin;
    color: #CCCCCC;

}
.legend-info {
    position: absolute;
    top: 15px;
    right: 40px;
    max-height: 200px;
}
.absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.clickable {
    cursor: pointer;
    border: 0px;
    color: #999999;
}

.clickable:hover {
    color: #FFFFFF;
}

.kpi-swap-filter select,
.kpi-swap-filter input {
    width: 140px
}
.kpi-swap-filter input {
    margin-left: 10px;
}
.kpi-swap-filter .clear-filter {
    width: 40px;
 }
 .kpi-swap-filter .switch-filter-type {
    width: 65px;
    padding: .375rem 0px;
}

.kpi-metrics-filter input {
    width: 204px;
    margin-left: 17px;
    display: inline-block;
}

.kpi-metrics-filter .clear-filter {
    position: relative;
    top: -3px;

    width: 40px;
    display: inline-block;
 }
</style>
