<!--
    * Component Description
        IWS custom stylization of the bootstrap component "alert"
        https://getbootstrap.com/docs/4.0/components/alerts/
        See props for an explanation of all supported functions

    * Slots
        #icon   <- Target badge icon
        #text   <- Target badge body

    * Example Usage
        <iws-alert type="info">
            <template #body>
                <InsertContentHere>
            </template>
        </iws-alert>
-->
<template>
    <div :class="`alert alert-${type} ${!icon ? 'hide-icon' : ''}`" role="alert">
        <div v-if="!!icon">
            <slot name="icon">
                <i :class="icon"></i>
            </slot>
        </div>

        <div>
            <slot name="body">

            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // Alert type / styling
        // Examples: primary, secondary, success, danger, warning, info, light, dark
        type: {
            type: String,
            default: 'primary'
        },
        
        // Icon appearing in the left most section of the alert
        // Defaults to the info circle, pass :icon="null" to show no icon
        icon: {
            type: String,
            default: 'fas fa-info-circle'
        }
    }
}
</script>

<style scoped>
    .alert.alert-info {
        border-color: var(--primary-color);
        color: var(--primary-color);
        background-color: #F5FAFF;
    }
    .alert.alert-warning {
        border-color: #7D5E00;
        color: #7D5E00;
        background-color: #FFF3CD;
    }
    .alert.alert-danger {
        border-color: #EA868F;
        color: #EA868F;
        background-color: #842029;
    }


    .alert>div:first-child {
        display: inline-block;
        position: absolute;
        top: 0.75rem;
    }
    .alert>div:last-child {
        display: inline-block;
        width: calc(100% - 30px);
        padding-left: 25px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
    }

    .alert>div:last-child .title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
    }
    .alert>div:last-child .sub_title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
    }

    .alert.hide-icon>div {
        position: relative !important;
        top: 0 !important;

        display: block !important;
        width: calc(100%) !important;
        padding-left: 0px !important;
    }
</style>