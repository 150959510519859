<template>
<iws-modal
    primary-button-text="Save"
    
    :showModal="showBaseModal"
    max-width="1200px"
    max-height="95vh"

    @close="cancelAction"
    @secondary-action="modalDetails.secondaryAction"
    :primaryButtonClick="modalDetails.primaryAction"

    :primary-button-disabled="!hasPermission || !modalDetails.primaryEnabled"

    :close-on-clickaway="false"
    id="TimeSeriesChart"
>
    <template #title>
        {{ modalDetails.title }} {{ !_isFalsy(selectedConfig?.templateName) ? `(${selectedConfig?.templateName})` : '' }}
    </template>

    <template v-if="!!data" #content>
        <div v-if="!_isNullOrEmpty(measurementUnits)" class="row" id="measurementUnitsModal">
            <div v-for="unit in measurementUnits" class="col-xs-12 col-sm-6 col-lg-4">
                <div 
                    class="measurement-unit-container clickable" 
                    :class="{ 'selected-measurement': !_isFalsy(selectedMeasurement?.measurement_unit_id) && selectedMeasurement?.measurement_unit_id == unit.measurement_unit_id }"
                    @click="selectedMeasurement = unit" 
                >
                    <div class="measurement-title">
                        {{ unit?.measurement_unit_name }}
                    </div>

                    <div class="measurement-body">
                        <b>Abbreviation</b>: {{ unit?.abbreviation || 'N/A' }}
                    </div>
                    <div class="measurement-body">
                        <b>Decimal Places</b>: {{ unit?.decimal_places || 'N/A' }}
                    </div>
                    <div class="measurement-body">
                        <b>Color</b>: {{ unit?.color || 'N/A' }}
                    </div>
                    <div class="measurement-body">
                        <b>defaultaxis</b>: {{ unit?.defaultaxis?.label || 'N/A' }}
                    </div>
                </div>
            </div>
        </div>
        <iws-form v-else-if="!_isFalsy(selectedAxis)" ref="custom_form">
            <template #body>
                <template v-if="!_isFalsy(selectedAxis)">
                    <iws-input
                        :value.sync="selectedAxis.label"
                        label="Name"
                        required
                        :disabled="!hasPermission"
                    />

                    <iws-input
                        :value.sync="selectedAxis.min"
                        label="Minimum"
                        type="number" 
                        :max="selectedAxis.max"
                        required
                        form-spacing
                        :disabled="!hasPermission"
                    />

                    <iws-input
                        :value.sync="selectedAxis.max"
                        label="Maximum"
                        type="number"
                        :min="selectedAxis.min"
                        required
                        form-spacing
                        :disabled="!hasPermission"
                    />

                    <iws-input
                        :value.sync="selectedAxis.ticks"
                        label="# of Tick Intervals"
                        type="number"
                        :min="2"
                        required
                        form-spacing
                        :disabled="!hasPermission"
                    />
                    <div>
                        (Default to 10 tick intervals, minimum: 2)
                    </div>

                    <iws-select
                        label="Position"
                        :value.sync="selectedAxis.position"
                        :options="[
                            { label: 'Left', value: 'left' },
                            { label: 'Right', value: 'right' }
                        ]"
                        display-name="label"
                        value-name="value"
                        required
                        form-spacing
                        :disabled="!hasPermission"
                    />

                    <iws-checkbox
                        label="Primary Axis"
                        :value.sync="selectedAxis.isPrimaryAxis"
                        form-spacing
                        :disabled="!hasPermission"
                        enable-click-label
                    />

                    <iws-checkbox
                        label="Align To Primary Axis"
                        :value.sync="selectedAxis.alignToPrimaryAxis"
                        :disabled="selectedAxis.isPrimaryAxis || !hasPermission"
                        form-spacing
                        enable-click-label
                    />

                    <iws-checkbox
                        label="Hide Axis"
                        :value.sync="selectedAxis.hideYAxis"
                        form-spacing
                        :disabled="!hasPermission"
                        enable-click-label
                    />

                    <iws-checkbox
                        label="Logarithmic Scale"
                        :value.sync="selectedAxis.logarithmic"
                        form-spacing
                        :disabled="!hasPermission"
                        enable-click-label
                    />

                    <iws-colour-picker
                        label="Axis Color"
                        :value.sync="selectedAxis.color"
                        form-spacing
                        required
                        :disabled="!hasPermission"
                    />

                    <iws-checkbox
                        label="Display Gridlines"
                        :value.sync="selectedAxis.displayGridlines"
                        :disabled="!hasPermission"
                        form-spacing
                        enable-click-label
                        @change="selectedAxis.customGridlinesColor = selectedAxis.displayGridlines ? selectedAxis.customGridlinesColor || '#FFFFFF' : null"
                        class="display-inline-block"
                    />
                    <iws-colour-picker v-if="selectedAxis.displayGridlines" 
                        :value.sync="selectedAxis.customGridlinesColor"
                        required
                        :disabled="!hasPermission"
                        class="display-inline-block ml-2"
                        style="top: 5px"
                    />
                </template>
            </template>
        </iws-form>
        <iws-form v-else-if="!_isFalsy(selectedChartItem)" ref="custom_form">
            <template #body>
                <template v-if="!_isFalsy(selectedChartItem)">
                    <iws-select 
                        ref="sourceTagName"
                        label="Source Tag Name"
                        :value="selectedChartItem.tagName"
                        :options="filteredTags"
                        display-name="name"
                        value-name="name"
                        :disabled="!hasPermission"
                        required
                        @change="onTagChanged"
                    />

                    <div v-if="getWell(selectedChartItem)" class="row justify-content-center align-items-center">
                        <div class="col">
                            <div v-if="isWellheadTag(selectedChartItem.tagName) && !_isFalsy(getWellheadPrefixForTag(selectedChartItem.tagName))">
                                <iws-input
                                    :value="getWellheadPrefixForTag(selectedChartItem.tagName)"
                                    label="Prefix"
                                    form-spacing
                                    disabled
                                />
                            </div>
                            <iws-input v-else
                                :value.sync="selectedChartItem.prefixName"
                                label="Prefix"
                                placeholder="Provide a prefix name"
                                form-spacing
                                :disabled="!hasPermission"
                            />
                        </div>

                        <div class="col-3">
                            <iws-input
                                :value="getWellheadSuffixTitleForTag(selectedChartItem.tagName)"
                                label="Suffix Title"
                                form-spacing
                                disabled
                            />
                        </div>

                        <div class="col">
                            <iws-input
                                :value.sync="selectedChartItem.suffixName"
                                label="Suffix"
                                placeholder="Provide a suffix name"
                                form-spacing
                                :disabled="!hasPermission"
                            />
                        </div>
                    </div>
                    <iws-input v-else
                        :value.sync="selectedChartItem.friendlyName"
                        label="Friendly Name"
                        placeholder="Provide a friendly name"
                        form-spacing
                        required
                        :disabled="!hasPermission"
                    />

                    <iws-input
                        :value.sync="selectedChartItem.unit"
                        label="Unit"
                        form-spacing
                        required
                        :disabled="!hasPermission"
                    />

                    <iws-input
                        :value.sync="selectedChartItem.timeoutThreshold"
                        label="Tag Timeout Threshold (in seconds)"
                        hint="Enter 0 to disable timeout"
                        type="number" 
                        :min="0" 
                        form-spacing
                        required
                        :disabled="!hasPermission"
                    />

                    <iws-select
                        ref="chartAxisSelect"
                        label="Chart Axis"
                        :value.sync="selectedChartItem.chartYAxis_key"
                        :options="selectedConfig.data.chartYAxes"
                        display-name="label"
                        value-name="key"
                        form-spacing
                        required
                        :disabled="!hasPermission"
                        class="display-inline-block"
                        style="width: calc(100% - 115px);"
                    />

                    <span id="axis-action-container">
                        <iws-button 
                            type="outline-primary"
                            icon="fas fa-plus"
                            size="small"
                            :disabled="!hasPermission"
                            rounded
                            @click="$event => { $event.preventDefault(); addAxis() }"
                            class="mr-1"
                        />

                        <iws-button 
                            :icon="`fas ${!hasPermission ? 'fa-eye' : 'fa-pencil-alt'}`"
                            type="text-primary"
                            @click="$event => { $event.preventDefault(); editAxis(findFullSelectedAxis()) }"
                            :disabled="_isFalsy(selectedChartItem?.chartYAxis_key)"
                        />

                        <iws-button 
                            icon="fas fa-trash-alt"
                            type="text-danger"
                            @click="$event => { $event.preventDefault(); deleteYAxis() }"
                            :disabled="_isFalsy(selectedChartItem?.chartYAxis_key) || !hasPermission"
                        />
                    </span>

                    <iws-input
                        :value.sync="selectedChartItem.decimalPrecision"
                        label="Decimal Precision"
                        type="number" 
                        :min="0" 
                        :max="9"
                        form-spacing
                        required
                        :disabled="!hasPermission"
                    />

                    <iws-input
                        :value.sync="selectedChartItem.lineWidth"
                        label="Line Width"
                        type="number" 
                        :min="0" 
                        :max="5"
                        form-spacing
                        required
                        :disabled="!hasPermission"
                    />

                    <iws-colour-picker 
                        label="Line Color"
                        :value.sync="selectedChartItem.color"
                        form-spacing
                        required
                        :disabled="!hasPermission"
                        @change="selectedChartItem.customLineColor = true"
                    />

                    <iws-checkbox
                        label="Custom background color"
                        :value.sync="chartItemBgColorEnabled"
                        :disabled="!hasPermission"
                        form-spacing
                        enable-click-label
                        @change="selectedChartItem.bgColor = chartItemBgColorEnabled ? selectedChartItem.bgColor || '#FFFFFF' : null"
                        class="display-inline-block"
                    />
                    <iws-colour-picker v-if="chartItemBgColorEnabled" 
                        :value.sync="selectedChartItem.bgColor"
                        required
                        :disabled="!hasPermission"
                        class="display-inline-block ml-2"
                        style="top: 5px"
                    />

                    <iws-checkbox
                        label="Show Data Points"
                        :value.sync="selectedChartItem.showDataPoints"
                        form-spacing
                        :disabled="!hasPermission"
                        enable-click-label
                    />

                    <iws-checkbox
                        label="Selected By Default"
                        :value.sync="selectedChartItem.selectedByDefault"
                        form-spacing
                        :disabled="!hasPermission"
                        enable-click-label
                    />

                    <iws-checkbox
                        label="Static Tag Data Source"
                        :value.sync="selectedChartItem.staticSource"
                        form-spacing
                        :disabled="!hasPermission"
                        enable-click-label
                    />
                </template>
            </template>
        </iws-form>
        <iws-form v-else-if="!_isFalsy(selectedSection)" ref="custom_form">
            <template #body>
                <template v-if="!_isFalsy(selectedSection)">
                    <iws-input :value.sync="selectedSection.name" label="Name" required :disabled="!hasPermission" />

                    <div class="mt-4">
                        <b class="container-label">Data Sources</b>

                        <span class="float-buttons-right">
                            <iws-button type="outline-light" pre-icon="fas fa-plus" icon="fas fa-plus" :click="addChartItem" :disabled="!hasPermission" />
                        </span>
                    </div>

                    <div v-if="!_isNullOrEmpty(selectedSection?.orderedChartItems)" id="sections-container" class="ordered-chart-items-container">
                        <div
                            v-for="(chartItem, index) in selectedSection.orderedChartItems"
                            class="chartItem-row"
                            :class="{ 'border-top': !!index }"
                            :key="`${chartItem.friendlyName}_${index}`"
                        >
                            <span class="box" :style="{ 'background-color': getChartLineColour(chartItem) }"></span>

                            <!--tags that begin with 'wellhead_ get special treatment where parts / all of their displayed
                                name are auto generated with well information and pre-defined descriptors-->
                            <span class="list-label chartItem-label">
                                <template v-if="isWellheadTag(chartItem.tagName)">    
                                    {{ getWellheadTagNameForChartItem(chartItem) }}
                                </template>
                                <template v-else>
                                    {{ chartItem.friendlyName }} ({{ chartItem.tagName }})
                                </template>
                            </span>

                            <span class="float-buttons-right">
                                <iws-button type="text-primary" :icon="`fas ${!hasPermission ? 'fa-eye' : 'fa-pencil-alt'}`" @click="editChartItem(chartItem, index)" />

                                <iws-button type="text-danger" icon="fas fa-trash-alt" :click="$event => { $event.preventDefault(); deleteChartItem(chartItem, index) }" :disabled="!hasPermission" />
                            </span>
                        </div>
                    </div>
                    <div v-else id="no-items">
                        No Data Sources
                    </div>
                </template>
            </template>
        </iws-form>

        <!-- Use vshow here instead of vif so we can avoid full rerenders breaking accordian and other components -->
        <div v-show="_isFalsy(selectedAxis) && _isFalsy(selectedChartItem) && _isFalsy(selectedSection)">
            <div id="step-control">
                <span>
                    <iws-button v-if="currentStep > 0" 
                        type="outline-light"
                        icon="fas fa-chevron-left"
                        @click="jumpToStep(currentStep-1)"
                        :disabled="currentStep <= 0"
                    />
                    <iws-button v-else
                        type="outline-light"
                        pre-icon="fas fa-plus"
                        text="New Template"
                        icon="fas fa-plus"
                        :click="handleAddConfig"
                        :disabled="!hasPermission"
                    />
                </span>

                <span>
                    <div class="btn-group margin-auto" role="group" aria-label="Template Steps">
                        <iws-button
                            v-for="(step, index) in steps"
                            :key="`step_button_${index}`"
                            :type="currentStep == index ? 'primary' : 'outline-light'"
                            :class="{ 'seen-step': index <= maxSeenStep }"
                            @click="jumpToStep(index)"
                        >
                            <template #text>
                                <span class="circle">
                                    <template v-if="currentStep === index">
                                        &#x2022;
                                    </template>
                                    <template v-else-if="index <= maxSeenStep">
                                        &check;
                                    </template>
                                    <template v-else>
                                        {{ index+1 }}
                                    </template>
                                </span>
                                {{ step.title }}
                            </template>
                        </iws-button>
                    </div>
                </span>

                <span>
                    <iws-button
                        type="primary"
                        icon="fas fa-chevron-right"
                        @click="jumpToStep(currentStep+1)"
                        :disabled="!configSelected || currentStep >= steps.length-1"
                        class="float-right"
                    />    
                </span>
            </div>

            <div class="row" id="step-container">
                <div class="col">
                    <div class="box-container position-relative">
                        <template v-if="_isFalsy(selectedConfig) || currentStep === 0">
                            <div>
                                <h3 class="display-inline-block no-margin">
                                    Select Template
                                </h3>

                                <span class="float-right" style="max-width: 250px;">
                                    <iws-search :value.sync="tabSearch" placeholder="Search Templates" @input="setTemplateDisplayArray" />
                                </span>
                            </div>

                            <hr>

                            <iws-tabs v-if="!_isNullOrEmpty(templateOptions)"
                                :tabs="templateOptions.map(_template => { return { title: `${_template.label} (${_template.options.length})` } })"
                                :value.sync="selectedConfigType"
                                ref="templatesTab"
                            >
                                <template v-for="(tab, index) in templateOptions" v-slot:[`tab_${index}`]>
                                    <div class="row">
                                        <div v-for="template in tab.options" class="col-xs-12 col-sm-6 col-lg-4 template-card">
                                            <div @click="tab.key !== 'global_templates' ? onSelectedTemplateChange(template.id) : null" class="clickable" :class="{ 'active-card': selectedConfig?.id == template.id }">
                                                <b>
                                                    <iws-checkbox v-if="tab.key !== 'global_templates'"
                                                        :value="selectedConfig?.id == template.id"
                                                        :disabled="selectedConfig?.id == template.id"
                                                        style="position: absolute !important; top: 10px;"
                                                    />
                                                
                                                    <span class="display-inline-block" style="width: calc(100% - 35px); margin-left: 35px;">
                                                        <span class="text-ellipsis-2">
                                                            {{ template.templateName }}
                                                        </span>
                                                    </span>
                                                </b>

                                                <div v-if="tab.key == 'all_templates'" style="position: relative; top: -15px">
                                                    Owner: {{ template.username }}
                                                </div>
                                                
                                                <iws-button type="outline-light" text="Duplicate" :click="() => copyConfig(template)" />

                                                <span v-if="tab.key !== 'global_templates'" class="float-buttons-right">
                                                    <iws-button
                                                        type="outline-danger"
                                                        icon="fas fa-trash-alt"
                                                        :disabled="!_isFalsy(template.disableMessage)"
                                                        :click="() => deleteConfig(template)"
                                                        @disabled-click="_toast(template.disableMessage)"
                                                    />

                                                    <iws-button type="outline-light" icon="fas fa-history" :click="() => openHistoryByTemplate(template)" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </iws-tabs>
                            <h3 v-else id="no-items">
                                No templates to select from,<br>click 'New Template' below to begin creating your first template.
                            </h3>
                        </template>
                        <iws-form v-else-if="currentStep === 1" ref="custom_form">
                            <template #body>
                                <iws-input
                                    :value.sync="selectedConfig.templateName"
                                    label="Template Name"
                                    placeholder="Provide a template name"
                                    required
                                    :disabled="!hasPermission"
                                />
                                    
                                <iws-checkbox v-if="selectedConfig?.owned_by === userId && !selectedConfig?.isDefault"
                                    label="Share this template"
                                    :value.sync="selectedConfig.shared"
                                    form-spacing
                                    :disabled="!hasPermission"
                                    @change="templateOptions = buildTemplateDisplayArray()"
                                    enable-click-label
                                />

                                <iws-checkbox v-if="!selectedConfig?.isDefault && customerDefaultToggleText && selectedConfig?.header_style !== 'default'"
                                    :label="customerDefaultToggleText"
                                    :value.sync="selectedConfig.isDefault"
                                    @change="onCustomerDefaultToggled"
                                    form-spacing
                                    :disabled="!hasPermission"
                                    enable-click-label
                                />

                                <iws-select
                                    label="Header Style"
                                    :value.sync="selectedConfig.header_style"
                                    :options="[ 
                                        { label: 'Frac Status', value: 'frac' },
                                        { label: 'Wireline Status', value: 'wireline' },
                                        { label: 'Default Status', value: 'default' }
                                    ]"
                                    display-name="label"
                                    value-name="value"
                                    form-spacing
                                    required
                                    :disabled="!hasPermission || chartType == 'wireline' || selectedConfig.isDefault == 1"
                                />

                                <iws-select
                                    label="Control Panel Location"
                                    :value.sync="selectedConfig.controlsPosition"
                                    :options="[ 
                                        { label: 'Left', value: 'left' },
                                        { label: 'Right', value: 'right' },
                                        { label: 'Top', value: 'top' },
                                        { label: 'Bottom', value: 'bottom' }
                                    ]"
                                    display-name="label"
                                    value-name="value"
                                    form-spacing
                                    required
                                    :disabled="!hasPermission"
                                />

                                <iws-input
                                    :value.sync="selectedConfig.controlsSize"
                                    label="Control Panel Size (10-50%)"
                                    type="number" 
                                    :min="10" 
                                    :max="50" 
                                    form-spacing
                                    required
                                    :disabled="!hasPermission || selectedConfig.hideChart == 1"
                                />

                                <iws-select
                                    label="Axis Type"
                                    :value.sync="xAxisType"
                                    :options="[ 
                                        { label: 'Date', value: 'Date' },
                                        { label: customAxisLabel(), value: customAxisLabel() }
                                    ]"
                                    display-name="label"
                                    value-name="value"
                                    form-spacing
                                    :disabled="!hasPermission"
                                />

                                <iws-input
                                    :value="msToHours(selectedConfig.rightSideMsOffset, 0.5) /* ms to hours or default to half an hour */"
                                    @change="selectedConfig.rightSideMsOffset = hoursToMs($event.target.value)"
                                    label="End Time Offset (hours)"
                                    type="number" 
                                    :min="0" 
                                    required
                                    :disabled="!hasPermission || xAxisType !== 'Date'"
                                    form-spacing
                                />

                                <iws-input
                                    :value.sync="selectedConfig.resolutionZoomHours"
                                    label="Default Resolution Zoom (hours)"
                                    type="number" 
                                    :min="0" 
                                    required
                                    :disabled="!hasPermission || xAxisType !== 'Date'"
                                    form-spacing
                                />

                                <iws-select
                                    label="Default Resolution Action"
                                    :value.sync="selectedConfig.defaultZoomAction"
                                    :options="[ 
                                        { label: 'Drag to Zoom', value: 'dragToZoom' },
                                        { label: 'Drag to Pan, Scroll to Zoom', value: 'scrollToZoom' }
                                    ]"
                                    display-name="label"
                                    value-name="value"
                                    form-spacing
                                    required
                                    :disabled="!hasPermission"
                                />
                            </template>
                        </iws-form>
                        <iws-form v-else-if="currentStep === 2" ref="custom_form">
                            <template #body>
                                <iws-select
                                    label="Stage Label Style"
                                    :value.sync="selectedConfig.stageLabelType"
                                    :options="[ 
                                        { label: 'Label', value: 'label' },
                                        { label: 'Hover', value: 'hover' }
                                    ]"
                                    display-name="label"
                                    value-name="value"
                                    required
                                    :disabled="!hasPermission"
                                    style="width: 150px;"
                                />

                                <iws-checkbox
                                    label="Pin to Latest Data"
                                    :value.sync="selectedConfig.pinRightSideMsOffset"
                                    form-spacing
                                    :disabled="!hasPermission"
                                    enable-click-label
                                />

                                <iws-checkbox
                                    label="Vertical Chart"
                                    :value.sync="selectedConfig.isVertical"
                                    form-spacing
                                    :disabled="!hasPermission"
                                    enable-click-label
                                />

                                <iws-checkbox
                                    label="Hide Date Axis"
                                    :value.sync="selectedConfig.isDateAxisHidden"
                                    form-spacing
                                    :disabled="!hasPermission"
                                    enable-click-label
                                />

                                <iws-checkbox
                                    :label="`${xAxisType} Axis Gridline Color`"
                                    :value.sync="selectedConfig.showVerticalGridlines"
                                    :disabled="!hasPermission"
                                    form-spacing
                                    enable-click-label
                                    @change="selectedConfig.verticalGridlinesColor = selectedConfig.showVerticalGridlines ? selectedConfig.verticalGridlinesColor || '#FFFFFF' : null"
                                    class="display-inline-block"
                                />
                                <iws-colour-picker v-if="selectedConfig.showVerticalGridlines" 
                                    :value.sync="selectedConfig.verticalGridlinesColor"
                                    required
                                    :disabled="!hasPermission"
                                    class="display-inline-block ml-2"
                                    style="top: 5px"
                                />
                                
                                <iws-checkbox
                                    label="Show NPT Lines"
                                    :value.sync="selectedConfig.showNPTLines"
                                    form-spacing
                                    :disabled="!hasPermission"
                                    enable-click-label
                                />

                                <iws-checkbox
                                    label="Show Legend"
                                    :value.sync="selectedConfig.showLegend"
                                    form-spacing
                                    :disabled="!hasPermission"
                                    enable-click-label
                                />

                                <iws-checkbox
                                    label="Hide Chart"
                                    :value.sync="selectedConfig.hideChart"
                                    form-spacing
                                    :disabled="!hasPermission"
                                    enable-click-label
                                />

                                <iws-checkbox
                                    label="Show Stage Lines"
                                    :value.sync="selectedConfig.showStageLines"
                                    form-spacing
                                    :disabled="!hasPermission"
                                    enable-click-label
                                />

                                <iws-checkbox
                                    label="Hide Comments Timeline"
                                    :value.sync="selectedConfig.hideCommentsTimeline"
                                    form-spacing
                                    :disabled="!hasPermission"
                                    enable-click-label
                                />
                            </template>
                        </iws-form>
                        <template v-else-if="currentStep === 3">
                            <div>
                                <b class="container-label">Sections</b>

                                <iws-button type="outline-light" :click="addSection" :disabled="!hasPermission" icon="fas fa-plus" class="float-buttons-right" />
                            </div>

                            <div v-if="!_isNullOrEmpty(selectedConfig?.data?.orderedSections)" id="sections-container" class="sections-list-container">
                                <div
                                    v-for="(section, index) in selectedConfig.data.orderedSections"
                                    :key="`${section.name}_${index}`"
                                    :class="{ 'border-top': !!index }"
                                >
                                    <span class="list-label">
                                        {{ section.name || 'N/A' }}
                                    </span>

                                    <span class="float-buttons-right">
                                        <iws-button type="text-primary" :icon="`fas ${!hasPermission ? 'fa-eye' : 'fa-pencil-alt'}`" @click="editSection(section, index)" />

                                        <iws-button type="text-danger" icon="fas fa-trash-alt" :click="() => deleteSection(index)" :disabled="!hasPermission" />
                                    </span>
                                </div>
                            </div>
                            <div v-else id="no-items">
                                No Sections
                            </div>
                        </template>
                        <template v-else>
                            Invalid step, please navigate back above.
                        </template>
                    </div>
                </div>

                <div v-show="currentStep > 0" class="col-4">
                    <div class="box-container position-relative">
                        <iws-accordian ref="quickSummaryAccordion" :cards="accordionStepAccess" :value="currentStep" :allow-multi-tabs="true">
                            <template #card-body_0>
                                <template v-if="configSelected">
                                    {{ selectedConfig.templateName }}
                                </template>
                                <div v-else>
                                    Please select a config to move on
                                </div>
                            </template>

                            <template #card-body_1>
                                <div>
                                    <b>Header Style</b> <span>{{ selectedConfig.header_style || 'N/A' | camelToEnglish }}</span>
                                </div>
                                <div>
                                    <b>Is Shared</b> <span>{{ selectedConfig.shared ? 'Yes' : 'No' }}</span>
                                </div>
                                <div v-if="selectedConfig.isDefault">
                                    <b>Is Default</b> <span>Yes</span>
                                </div>
                                <div>
                                    <b>Control Panel Location</b> <span>{{ selectedConfig.controlsPosition || 'N/A' | camelToEnglish }}</span>
                                </div>
                                <div>
                                    <b>Control Panel Size</b> <span>{{ !_isFalsy(selectedConfig.controlsSize) ? `${selectedConfig.controlsSize}%` : 'N/A' }}</span>
                                </div>
                                <div>
                                    <b>Axis Type</b> <span>{{ xAxisType || 'N/A' }}</span>
                                </div>
                                <div>
                                    <b>End Time Offset</b> <span>{{ !_isFalsy(selectedConfig.rightSideMsOffset) ? `${msToHours(selectedConfig.rightSideMsOffset, 0.5)} hours` : 'N/A' }}</span>
                                </div>
                                <div>
                                    <b>Resolution Zoom</b> <span>{{ !_isFalsy(selectedConfig.resolutionZoomHours) ? `${selectedConfig.resolutionZoomHours} hours` : 'N/A' }}</span>
                                </div>
                                <div>
                                    <b>Resolution Action</b> <span>{{ selectedConfig.defaultZoomAction || 'N/A' | camelToEnglish }}</span>
                                </div>
                            </template>

                            <template #card-body_2>
                                <div>
                                    <b>Label Type</b> <span>{{ selectedConfig.stageLabelType || 'N/A' | camelToEnglish }}</span>
                                </div>
                                <div>
                                    <b>Pin to Latest</b> <span>{{ !!selectedConfig.pinRightSideMsOffset ? 'On' : 'Off' }}</span>
                                </div>
                                <div>
                                    <b>Vertical Chart</b> <span>{{ !!selectedConfig.isVertical ? 'On' : 'Off' }}</span>
                                </div>
                                <div>
                                    <b>Hide Date Axis</b> <span>{{ !!selectedConfig.isDateAxisHidden ? 'On' : 'Off' }}</span>
                                </div>
                                <div>
                                    <b>Date Axis Gridlines</b> <span>{{ !!selectedConfig.showVerticalGridlines ? 'On' : 'Off' }}</span>
                                </div>
                                <div>
                                    <b>Show NPT Lines</b> <span>{{ !!selectedConfig.showNPTLines ? 'On' : 'Off' }}</span>
                                </div>
                                <div>
                                    <b>Show Legend</b> <span>{{ !!selectedConfig.showLegend ? 'On' : 'Off' }}</span>
                                </div>
                                <div>
                                    <b>Hide Chart</b> <span>{{ !!selectedConfig.hideChart ? 'On' : 'Off' }}</span>
                                </div>
                                <div>
                                    <b>Show Stage Lines</b> <span>{{ !!selectedConfig.showStageLines ? 'On' : 'Off' }}</span>
                                </div>
                                <div>
                                    <b>Hide Comments Timeline</b> <span>{{ !!selectedConfig.hideCommentsTimeline ? 'On' : 'Off' }}</span>
                                </div>
                            </template>

                            <template #card-body_3>
                                <span v-if="!_isNullOrEmpty(selectedConfig?.data?.orderedSections)">
                                    <div v-for="section in selectedConfig.data.orderedSections">
                                        {{ section.name }}

                                        <ol v-if="!_isNullOrEmpty(section?.orderedChartItems)" class="pl-4">
                                            <li v-for="chartItem in section.orderedChartItems">
                                                {{ chartItem.tagName }}
                                            </li>
                                        </ol>
                                    </div>
                                </span>
                                <span v-else>
                                    0 sections
                                </span>
                            </template>
                        </iws-accordian>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else #content>
        <div id="loading">
            <div class="spinner-border light-text-color" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </template>

    <template #footer>
        <div class="full-width">
            <iws-button
                text="Use Template"
                type="outline-light"
                :disabled="selectedConfig == null || selectedConfig.id == data?.chartConfiguration?.id"
                :click="() => onUseTemplateSelected(selectedConfig)"
            />

            <span class="float-buttons-right">
                <iws-button
                    text="Cancel"
                    type="outline-light"
                    @click="modalDetails.secondaryAction"
                />

                <iws-button
                    text="Save"
                    type="primary"
                    :disabled="!hasPermission || !modalDetails.primaryEnabled"
                    @click="modalDetails.primaryAction"
                    class="ml-2"
                />
            </span>
        </div>
    </template>
</iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { flushToasts, toast, isFalsy, isNullOrEmpty, iwsConfirm, getCSRFToken } = GlobalFunctions;

import { v4 as uuidv4 } from "uuid";

export default {
    props: {
        userId: String,
        iwsUser: Boolean,
        isAdmin: Boolean,
        isCompanyAdmin: Boolean,
        jobNumber: String,
        chartType: String,
        jobHourOffset: Number,
        wells: Array,
        dashboardData: Object,
        dashboardItem: Object,
        defaultChartColumnCount: String | Number
    },

    data() {
        return {
            showBaseModal: false,
            resolve: null,
            data: null,

            currentStep: 0,
            maxSeenStep: 0,
            steps: [ 
                { title: 'Template' },
                { title: 'General Setup' },
                { title: 'Features' },
                { title: 'Sections' }
            ],

            companyConfigs: [],
            versionHistory: null,

            xAxisType: 'Date',
            tabSearch: null,
            templateOptions: [],
            selectedConfigType: null,
            selectedConfig: {},
            originalConfig: {},
            selectedSection: null,
            selectedSectionIndex: null,
            selectedChartItem: null,
            selectedChartItemIndex: null,
            selectedAxis: null,
            chartItemBgColorEnabled: false,

            measurementUnits: [],
            selectedMeasurement: null,
            resolveMeasurement: null,

            modifiedSections: [],
            defaultGridItemObj: {	//default column count / 3 because the 'assumed' column size is 3, but need a multiple of that to resize chart item widths
                'x': 0,
                'y': 0,
                'w': this.defaultChartColumnCount / 3,
                'h': 2,
                'i': 0,
                'tagName': '',
                'labelTextOptions': {},
                'dataTextOptions': {}
            },
        };
    },

    computed: {
        accordionStepAccess() {
            // The general details section on the right side only shows accordian cards for 'unlocked' steps
            return [ ...this.steps ].splice(0, this.maxSeenStep+1);
        },

        configSelected() {
            return !isFalsy(this.selectedConfig) && !isNullOrEmpty(Object.keys(this.selectedConfig))
        },

        configChanged() {
            if (!this.configSelected)
                return false;

            if (!isFalsy(this.originalConfig))
                return !this.compareObjects(this.selectedConfig, this.originalConfig);
            return true;
        },
        selectedSectionHasChanged() {
            if (isFalsy(this.selectedSection))
                return false;

            const originalObj = this.originalConfig?.data?.orderedSections?.find(_section => _section.key === this.selectedSection.key);

            if (!isFalsy(originalObj))
                return !this.compareObjects(this.selectedSection, originalObj);
            return true;
        },
        selectedChartItemHasChanged() {
            if (isFalsy(this.selectedChartItem))
                return false;

            const originalObj = this.selectedSection?.orderedChartItems?.find(_section => _section.key === this.selectedChartItem.key);

            if (!isFalsy(originalObj))
                return !this.compareObjects(this.selectedChartItem, originalObj);
            return true;
        },
        selectedAxisItemHasChanged() {
            if (isFalsy(this.selectedAxis))
                return false;
            return !this.compareObjects(this.selectedAxis, this.findFullSelectedAxis());
        },

        // Template configuration requires large, imbedded forms for each level
        // Each form overrides the modal structure, title and actions
        modalDetails() {
            if (!isNullOrEmpty(this.measurementUnits))
                return {
                    title: 'Select Measurement Unit',
                    secondaryAction: () => this.resolveMeasurement(null),
                    primaryAction: () => this.resolveMeasurement(this.selectedMeasurement),
                    primaryEnabled: () => !isFalsy(this.selectedMeasurement)
                };

            if (!isFalsy(this.selectedAxis))
                return {
                    title: 'Edit Chart Axis',
                    secondaryAction: this.clearAxis,
                    primaryAction: this.confirmAxis,
                    primaryEnabled: this.selectedAxisItemHasChanged
                };

            if (!isFalsy(this.selectedChartItem))
                return {
                    title: 'Edit Chart Item',
                    secondaryAction: this.clearChartItem,
                    primaryAction: this.confirmChartItem,
                    primaryEnabled: this.selectedChartItemHasChanged
                };

            if (!isFalsy(this.selectedSection))
                return {
                    title: 'Edit Section',
                    secondaryAction: this.clearSection,
                    primaryAction: this.confirmSection,
                    primaryEnabled: this.selectedSectionHasChanged
                };

            return {
                title: 'Chart Configuration',
                secondaryAction: this.cancelAction,
                primaryAction: this.confirmAction,
                primaryEnabled: this.configChanged
            };
        },

        hasPermission() {
            // Admin can edit all
            if (this.isAdmin)
                return true;

             if (!isFalsy(this.selectedConfig?.customer_id))
                 // If not default check if company admin to edit
                 // If not company admin check if user created to edit
                return this.isCompanyAdmin || this.iwsUser || this.selectedConfig?.user_id === this.userId

            return false;
        },
        customerDefaultToggleText() {
            if (this.selectedConfig?.header_style === 'frac')
                return 'Customer Frac Default';
            if (this.selectedConfig?.header_style === 'wireline')
                return 'Customer Wireline Default';
            return null;
        },

        filteredTags() {
            // Create a hashmap of the config wide used tags mapping the tagname -> chartItem.key
            const _usedTags = {};
            this.selectedConfig?.data?.orderedSections.forEach(_section => {
                _section?.orderedChartItems?.forEach(_chartItem => {
                    _usedTags[_chartItem.tagName] = _chartItem.key;
                });
            });
            this.selectedSection?.orderedChartItems.forEach(_chartItem => {
                if (!(_chartItem.tagName in _usedTags))
                    _usedTags[_chartItem.tagName] = _chartItem.chartYAxis_key;
            });

            // Only show tags not being used (unless it's this chartItem)
            return this.dashboardData?.tags.filter(_tag => 
                !(_tag.name in _usedTags) || _usedTags[_tag.name] == this.selectedChartItem.key || _usedTags[_tag.name] == this.selectedChartItem.chartYAxis_key
            );
        }
    },
    
    methods: {
        _isFalsy(val) {
            return isFalsy(val)
        },
        _isNullOrEmpty(val) {
            return isNullOrEmpty(val)
        },
        _toast(title, body, variant='danger') {
            return toast({
                title: title,
                body: body,
                variant: variant
            });
        },
        _set(item, key, value) {
            Vue.set(item, key, value);
        },
        // Takes two objects (or already stringified objects) and compares if they match (returns true when they do)
        compareObjects(objectA, objectB) {
            // Both are empty, they match enough
            if (isFalsy(objectA) && isFalsy(objectB))
                return true;
            // One is falsy, abort before stringifies
            if (isFalsy(objectA) ^ isFalsy(objectB))
                return false;

            // Support either 'object' being pre stringified for optimization purposes
            const stringifyA = typeof objectA == 'object' ? JSON.stringify(objectA) : objectA;
            const stringifyB = typeof objectB == 'object' ? JSON.stringify(objectB) : objectB;

            return stringifyA === stringifyB;
        },
        msToHours(val, defaultval=0) {
            if (!isFalsy(val))
                return val / 1000 / 60 / 60;
            return defaultval;
        },
        hoursToMs(val, defaultval=0) {
            if (!isFalsy(val))
                return val * 1000 * 60 * 60;
            return defaultval;
        },

        async jumpToStep(stepIndex) {
            flushToasts();

            if (!this.configSelected)
                return toast({
                    title: 'Please select config to move forward',
                    variant: 'warning'
                });
            if (stepIndex < 0 || stepIndex >= this.steps.length)
                return toast({
                    title: 'Invalid step',
                    variant: 'warning'
                });

            let passedValidtion = true;

            // If a a form is visible, must pass validiton to move forward
            if (!isFalsy(this.$refs?.custom_form?.validate))
                passedValidtion = await this.$refs?.custom_form?.validate();

            if (!passedValidtion)
                return toast({
                    title: 'Please fix all fields',
                    variant: 'danger'
                });
                
            if (!this.selectedConfig?.isNew || stepIndex <= this.maxSeenStep+1) {
                // Show the last steps quick details
                if (!this.$refs.quickSummaryAccordion.isActive(this.currentStep))
                    this.$refs.quickSummaryAccordion.openByIndex(this.currentStep, 'show');
                this.currentStep = stepIndex;

                if (this.currentStep > this.maxSeenStep)
                    this.maxSeenStep = this.currentStep;
            } else {
                toast({
                    title: 'Please complete this step to move forward',
                    variant: 'warning'
                });
            }
        },

        async cancelAction() {
            if (this.configChanged) {
                const _answer = await iwsConfirm({
                    title: 'Are you sure?',
                    body: `Closing now without saving will discard all your changes to the current config.`,
                    width: '420px'
                })

                // must confirm discarding changes
                if (_answer !== true)
                    return;
            }
            this.resolve(null)
        },
        confirmAction() {
            const payload = _.cloneDeep(this.selectedConfig);

            if (this.xAxisType == 'Date' && payload.chartXAxis)
                delete payload.chartXAxis;

            //if y axes were added or deleted then update yaxes in the config data
            if (!isNullOrEmpty(payload?.chartYAxes)) {
                //get a copy of the current y axes without duplicating the chart item data
                payload.chartYAxes.forEach(axis => {
                    delete axis?.chartItems;
                });
            }

            const outputChartConfigData = {};
            Object.assign(outputChartConfigData, payload.data);
            outputChartConfigData.controlsSize = payload.controlsSize;

            if (outputChartConfigData?.sectionIndexes && outputChartConfigData.orderedSections?.length !== outputChartConfigData?.sectionIndexes?.length) {
                const sectionsIndexes = [];

                outputChartConfigData.sectionIndexes.forEach(section => {
                    if (!isNullOrEmpty(outputChartConfigData?.orderedSections) && section.index in outputChartConfigData?.orderedSections)
                        sectionsIndexes.push(section);
                });

                outputChartConfigData.sectionIndexes = sectionsIndexes;
            }

            return $.post('/user/config/chartconfig/edit', {
                _token: $('meta[name="csrf-token"]').attr('content'),
                id: payload.id,
                version_id: payload.version_id || payload.id,
                jobNumber: this.jobNumber,
                
                ...payload,
                shareWithCompany: payload.shared,
                data: JSON.stringify(outputChartConfigData),
                modifiedSections: JSON.stringify(this.modifiedSections || [])
            })
            .then(_res => {
                if (_res.error)
                    return toast({
                        title: 'Failed to update chart configuration',
                        body: _res.body,
                        variant: 'danger'
                    });

                location.reload();
            }).fail(_error => {
                if (_error.error)
                    return toast({
                        title: 'Failed to update chart configuration',
                        body: _error.message,
                        variant: 'danger'
                    });
            });
        },
        open(data) {
            this.loadCustomerConfigTemplates().then(_res => {
                this.data = _.cloneDeep(data);

                // Reflect the current state of the config in the list (some options like the truck select are cosmetic only and should only come from the parent instance)
                const index = this.companyConfigs.findIndex(_config => _config.id == data?.chartConfiguration?.id);
                if (index >= 0)
                    this.companyConfigs[index] = data?.chartConfiguration;

                this.onSelectedTemplateChange(data?.chartConfiguration?.id)
                this.setTemplateDisplayArray();

                this.pushSelectedTemplateToFront();

                if (!isFalsy(data?.chartItemIndex)) {
                    this.editSection(this.selectedConfig.data.orderedSections[data.sectionIndex], data.sectionIndex);
                    this.editChartItem(this.selectedConfig.data.orderedSections[data.sectionIndex].orderedChartItems[data.chartItemIndex], data.chartItemIndex)

                    this.currentStep = this.steps.length-1;
                    this.maxSeenStep = this.steps.length-1;
                }
            });

            return new Promise( (resolve, reject) => {
                this.resolve = (value) => {
                    this.showBaseModal = false;
                    this.data = null;
                    this.currentStep = 0;
                    this.maxSeenStep = 0;
                    this.companyConfigs = null;

                    this.selectedSection = null;
                    this.selectedChartItem = null;
                    this.selectedAxis = null;
                    resolve(value);
                };

                this.showBaseModal = true;
            });
        },

        pushSelectedTemplateToFront() {
            // Bump the preselected template config to the front of every list
            // We intentionally only do this on open with the inital config to avoid reshifting every time one is selected
            this.templateOptions.forEach(_tab => {
                const _index = _tab.options.findIndex(_template => _template?.id == this.selectedConfig?.id);
                
                if (!isFalsy(_index) && _index > 0) {
                    const _template = _tab.options[_index];

                    _tab.options.splice(_index, 1);
                    _tab.options.unshift(_template);
                }
            });
        },
        buildTemplateDisplayArray() {
            const tabSearch = !isFalsy(this.tabSearch) ? this.tabSearch.toLowerCase() : null;
            return [
                {
                    label: 'Global Templates',
                    key: 'global_templates',
                    options: this.iwsUser 
                        ? this.companyConfigs
                            .filter(c => isFalsy(c.customer_id))
                            .map(c => { return { ...c, disableMessage: 'Cannot remove global templates' } }) 
                        : []
                }, {
                    label: `Frac Default`,
                    key: 'default_frac_templates',
                    options: this.companyConfigs
                                .filter(c => c.isDefault && c.customer_id != null && c.header_style === 'frac')
                                .map(c => { return { ...c, disableMessage: 'Cannot remove default templates' } }) 
                }, {
                    label: `Wireline Default`,
                    key: 'default_wireline_templates',
                    options: this.companyConfigs
                                .filter(c => c.isDefault && c.customer_id != null && c.header_style === 'wireline')
                                .map(c => { return { ...c, disableMessage: 'Cannot remove default templates' } }) 
                }, {
                    label: `${this.dashboardData?.customer || ''} Templates`,
                    key: 'customer_templates',
                    options: this.companyConfigs
                                .filter(c => (c.owned_by != this.userId || this.iwsUser ) && c.shared && !c.isDefault && c.customer_id != null)
                                .map((c, arr) => { return { ...c, disableMessage: arr?.length <= 1 ? 'Cannot remove template, must have at least one company template.' : null } }) 
                }, {
                    label: 'Shared Templates',
                    key: 'shared_templates',
                    options: this.companyConfigs
                                .filter(c => c.owned_by === this.userId && c.shared)
                                .map(c => { return { ...c, disableMessage: this.canConfigDelete(c) } }) 
                }, {
                    label: 'My Templates',
                    key: 'user_templates',
                    options: this.companyConfigs
                                .filter(c => c.owned_by == this.userId && !c.shared && isFalsy(c.deleted_at))
                                .map(c => { return { ...c, disableMessage: this.canConfigDelete(c) } }) 
                },  {
                    label: 'All Templates',
                    key: 'all_templates',
                    options: this.iwsUser
                        ? this.companyConfigs
                                .filter(c => isFalsy(c.deleted_at))
                                .map(c => { return { ...c, disableMessage: this.canConfigDelete(c) } }) 
                        : []
                }
            ].map(_option => {
                return {
                    ..._option,
                    options: !isFalsy(tabSearch)
                        ? _option.options.filter(_template => _template?.templateName?.toLowerCase().includes(tabSearch))
                        : _option.options
                };
            }).filter(_option => !isNullOrEmpty(_option.options))
            .map(_option => {
                _option.options.sort((a, b) => (a.templateName > b.templateName) - (a.templateName < b.templateName))
                return _option;
            });
        },
        setTemplateDisplayArray() {
            const _currentTab = !isFalsy(this.selectedConfigType) ? this.templateOptions[this.selectedConfigType]?.key : null;

            this.templateOptions = this.buildTemplateDisplayArray();

            // Attempt to open the default tab
            if (!isNullOrEmpty(this.templateOptions)) {
                const currentSelectedIndex = !isFalsy(this.selectedConfig?.id)
                    ? this.templateOptions.findIndex(_type => _type.options.find(_template => _template.id == this.selectedConfig?.id))
                    : -1;
                const _matchedCurrentTab = this.templateOptions.findIndex(_type => _type.key == _currentTab);
                
                // Always open default if we just arrived and aren't seeing a tab yet
                // Open the most applicable default if the current tab is not available anymore due to filter
                if (isFalsy(this.selectedConfig?.id)) {
                    this.findDefaultTemplate();
                    this.openCurrentTemplateTab();
                } else if (!isFalsy(this.$refs.templatesTab)) {
                    if (_matchedCurrentTab >= 0) {
                        this.$refs.templatesTab.openByIndex(_matchedCurrentTab);
                    } else if (currentSelectedIndex >= 0) {
                        this.$refs.templatesTab.openByIndex(currentSelectedIndex);
                    } else {
                        this.$refs.templatesTab.openByIndex(0);
                    }
                } else {
                    this.openCurrentTemplateTab();
                }
            }
        },

        pushTemplate(template) {
            this.companyConfigs.push(template);
            this.setTemplateDisplayArray();
            this.$forceUpdate();
        },
        removeTemplate(template) {
            this.companyConfigs = this.companyConfigs.filter(_template => _template.id !== template.id);
            this.setTemplateDisplayArray();
            this.$forceUpdate();
        },

        loadCustomerConfigTemplates() {
            //Should fetch all of the config templates for the company
            return $.get(`/customer/config/chartconfigs/${this.chartType}/${this.jobNumber}`).then(_templates => {
                if (!isNullOrEmpty(_templates)) {
                    this.companyConfigs = _templates;
                } else {
                    return toast({
                        title: 'No config data found',
                        variant: 'danger'
                    });
                }
            });
        },

        findDefaultTemplate() {
            for (let _templateOptions of this.templateOptions) {
                const _temp = _templateOptions.options.find(_option => _option.isDefault === 1);

                if (!isFalsy(_temp)) {
                    this.onSelectedTemplateChange(_temp.id);
                    return;
                }
            }

            // No defaults found, just open the very first option
            this.onSelectedTemplateChange(this.templateOptions[0]?.options[0]?.id);
        },
        openCurrentTemplateTab() {
            this.selectedConfigType = this.templateOptions.findIndex(_type => _type.options.find(_template => _template.id == this.selectedConfig?.id));

            // If the tabs are rendered, make sure we jump to the valid tab
            if (!isFalsy(this.$refs?.templatesTab))
                this.$refs.templatesTab.openByIndex(this.selectedConfigType);
        },

        switchToConfig(config) {
            return $.post('/user/config/selected/edit', {
                _token: $('meta[name="csrf-token"]').attr('content'),
                jobNumber: this.jobNumber,
                chartConfig_id: config.id,
                chartType: config.type,
                dashboardId: this.dashboardData?.selectedDashboard?.id ?? null,
                dashboardItemIndex: this.dashboardItem ? this.dashboardItem.i : null
            },
            result => {
                if (result.error) {
                    return toast({
                        title: 'Failed to assign template',
                        body: result.message,
                        variant: 'danger'
                    });
                } else {
                    location.reload();
                }
            },
            'json');
        },
        onUseTemplateSelected(config) {
            return iwsConfirm({
                title: 'Are you sure?',
                body: `Do you want the chart configuration to be set to '${config.templateName}'?`,
                width: '420px'
            }).then(_answer => {
                if (_answer)
                    this.switchToConfig(config);
            })
        },

        copyConfig(config) {
            return iwsConfirm({
                title: 'Are you sure?',
                body: `Are you sure you want to duplicate '${config.templateName}'? You can edit the new configuration once done.`,
                width: '420px'
            }).then(_answer => {
                if (_answer) {
                    //Should create a new chart config on the server that is a copy of the currently selected config
                    return $.post('/user/config/chartconfig/newfromexisting', {
                        _token: GlobalFunctions.getCSRFToken(),
                        id: config.id,
                        jobNumber: this.jobNumber,
                        isAdmin: this.isAdmin,
                        user_id: !this.isAdmin && !this.isCompanyAdmin && !this.iwsUser
                    }).then((result) => {
                        if (result.error) {
                            return toast({
                                title: 'Failed to duplicate template',
                                body: result.message,
                                variant: 'danger'
                            });
                        } else {
                            this.pushTemplate(result.newConfig);
                            this.onSelectedTemplateChange(result.newConfig.id);
                            this.openCurrentTemplateTab();
                        }
                    }).catch(result => {
                        return toast({
                            title: 'Failed to duplicate template',
                            body: result.message,
                            variant: 'danger'
                        });
                    });
                }
            });
        },
        canConfigDelete(config) {
            if (config?.isDefault) //deleting a default chart config
                return 'Cannot remove default templates';

            if (isFalsy(config?.customer_id))
                return 'Cannot remove global templates';

            return null;
        },
        deleteConfig(config) {
            return iwsConfirm({
                title: 'Are you sure?',
                body: `Are you sure you want to delete '${config.templateName}'? This cannot be undone!`,
                width: '420px',
                confirmColour: 'danger'
            }).then(_answer => {
                if (_answer === true) {
                    return $.ajax({
                        url: `/user/config/chartconfig/${config.id}/delete`,
                        method: 'POST',
                        data: {
                            _token: GlobalFunctions.getCSRFToken(),
                            jobNumber: this.jobNumber
                        },
                        dataType: 'json'
                    }).done(data => {
                        if (data.error)
                            return toast({
                                title: 'Failed to delete config',
                                body: data.message,
                                variant: 'danger'
                            });

                        if (this.selectedConfig?.id == config.id)
                            this.selectedConfig = null;

                        //Remove the config from the list locally
                        this.removeTemplate(config);
                    });
                }
            });
        },
        addConfig(type) {
            // Should create a new chart config on the server
            // Then repopulate everything
            return $.post('/user/config/chartconfig/new', {
                _token: getCSRFToken(),
                type: type,
                jobNumber: this.jobNumber,
                isAdmin: this.isAdmin
            }).then(result => {
                if (result.error)
                    return toast({
                        title: 'Failed to create config',
                        body: result.message,
                        variant: 'danger'
                    });

                result.newConfig.isNew = true;

                //Should add the new configuration to the configuration list
                this.pushTemplate(result.newConfig);

                //Then should switch to the new configuration
                this.onSelectedTemplateChange(result.newConfig.id);

                this.pushSelectedTemplateToFront();
                this.openCurrentTemplateTab();
                
                this.jumpToStep(1);
            }).catch(result => {
                return toast({
                    title: 'Failed to create config',
                    body: result.message,
                    variant: 'danger'
                });
            });
        },
        handleAddConfig() {
            const { key } = this.templateOptions[this.selectedConfigType] || {}

            if (key == 'default_frac_templates') {
                return this.addConfig('frac');
            } else if (key == 'default_wireline_templates') {
                return this.addConfig('wireline');
            } else {
                return iwsConfirm({
                    title: 'Select template type',
                    rejectText: 'Frac',
                    rejectColour: 'light',
                    confirmText: 'Wireline',
                    confirmColour: 'outline-light',
                    hideClose: true
                }).then(_answer => {
                    if (_answer === true) {
                        return this.addConfig('wireline');
                    } else if (_answer === false) {
                        return this.addConfig('frac');
                    }
                });
            }

        },
        async onSelectedTemplateChange(config_id) {
            const _config = _.cloneDeep(this.companyConfigs.find(config => config.id == config_id) || {});
            _config.data = typeof _config.data == 'string' ? JSON.parse(_config.data) : _config.data;

            // Chart items do not have any unique properties so apply a uuid key for referencing
            _config?.data?.orderedSections.forEach(_section => {
                _section?.orderedChartItems.forEach(_chartItem => {
                    if (isFalsy(_chartItem.key)) {
                        _chartItem.key = uuidv4();
                        this.$set(_chartItem, 'axisUpdate', false);
                    }
                });
            });

            // Some properties have default values
            _config.header_style = _config.header_style || 'default';
            _config.controlsSize = _config.controlsSize || _config?.data?.controlsSize || 30;

            this.selectedConfig = _.cloneDeep(_config);
            this.originalConfig = _.cloneDeep(_config)

            // When a template is selection/changed, progress in steps is reset
            this.currentStep = 0;
            this.maxSeenStep = 0;
        },

        openHistoryByTemplate(template) {
            this.showBaseModal = false;

            return $.get('/user/config/history/' + template.id).then(res => {
                if (res.error)
                    return toast({
                        title: 'Failed to fetch history',
                        body: res.message,
                        variant: 'danger'
                    });

                this.$parent.$refs.TimeSeriesHistoryModal.open(res, template, this.hasPermission).then(result => {
                    this.showBaseModal = true;

                    if (!isFalsy(result) && typeof result == 'object' && 'newConfig' in result) {
                        this.loadCustomerConfigTemplates();
                        this.companyConfigs = this.companyConfigs.filter(config => config.version_id != result.newConfig.version_id);
                        this.pushTemplate(result.newConfig);

                        this.onSelectedTemplateChange(result.newConfig.id);
                    }
                });
            }).catch(result => {
                return toast({
                    title: 'Failed to fetch history',
                    body: result.message,
                    variant: 'danger'
                });
            });
        },


        customAxisLabel() {
            //need to check both here so that updates to the selected axis re-compute this variable output
            if (!isFalsy(this.selectedConfig?.chartXAxis))
                return `Custom: ${this.selectedConfig.chartXAxis?.label ?? 'No Label'}`;
            return 'Custom: No Axis Created';
        },

        async onCustomerDefaultToggled($event) {
            if ($event) {
                return iwsConfirm({
                    title: 'Are you sure?',
                    body: `Setting '${this.selectedConfig.templateName}' as the ${this.selectedConfig.header_style} default template will replace the current default`,
                    width: '420px'
                }).then(_confirm => {
                    // If they did not accept the consequences, revert the change
                    if (!_confirm) {
                        this.selectedConfig.isDefault = false;
                    } else {
                        this.setTemplateDisplayArray();
                    }
                });
            } else {
                this.setTemplateDisplayArray();
            }
        },

        waitForStepsToChange(originalStepLength) {
            // Perform polling the accordion child component waiting for the local data to update with the new steps size
            return new Promise(resolve => {
                let attempts = 0;
                const _interal = setInterval(() => {
                    // No way it should take more than a few seconds to update, jump out of polling after several attempts to avoid crashing the tab
                    if (attempts++ > 30 || this.$refs.quickSummaryAccordion.cards.length !== originalStepLength) {
                        clearInterval(_interal);
                        resolve(true);
                    }
                }, 100)
            })
        },

        addSection() {
            //check whether template is a user template or company template
            let user_id = null;
            if (this.selectedConfig?.user_id && this.selectedConfig.user_id == this.user_id)
                user_id = true;

            this.editSection({
                key: `newSection-${ Date.now()}`,
                name: '',
                customer_id: this.selectedConfig.customer_id,
                user_id: user_id,
                columnCount: 3,
                orderedChartItems: [],
                chartItemLayout: []
            }, -1);
        },
        editSection(section, index) {
            this.selectedSection = _.cloneDeep(section);
            this.selectedSectionIndex = index;
        },
        confirmSection() {
            // Nothing to save
            if (isFalsy(this.selectedSection))
                return;

            if (!this.$refs?.custom_form?.validate())
                return toast({
                    title: 'Please fix all fields',
                    variant: 'danger'
                });
                
            // Save the current selected section to the dataset then clear it to return to normal state
            const index = this.selectedConfig.data.orderedSections.findIndex(_section => _section.key === this.selectedSection.key);

            if (!isFalsy(this.selectedSectionIndex)) {
                // Confirm all children components when a higher up is saved
                this.confirmChartItem();

                if (this.selectedSectionIndex < 0) {
                    this.selectedConfig.data.orderedSections.push(_.cloneDeep(this.selectedSection));
                } else {
                    this.$set(this.selectedConfig.data.orderedSections, this.selectedSectionIndex, _.cloneDeep(this.selectedSection));
                }

                this.modifiedSections.push(_.cloneDeep(this.selectedSection));

                this.selectedSection = null;
                this.selectedSectionIndex = null;

                toast({
                    title: 'Changes saved',
                    variant: 'success'
                });
            } else {
                return toast({
                    title: 'Cannot find section in dataset',
                    variant: 'danger'
                });
            }
        },
        async clearSection(confirmDiscard=true) {
            // When hitting cancel, if there are unsaved changes, we will ask to user to confirm discarding them
            // When saving or canceling, we apply the same action to all children nodes
            if (confirmDiscard && (this.selectedSectionHasChanged || this.selectedChartItemHasChanged)) {
                const _res = await iwsConfirm({
                    title: 'Are you sure?',
                    body: `Your unsaved changes to '${this.selectedSection?.name}' and any of its Data Sources will be lost if you cancel now`,
                    confirmColour: 'danger',
                    width: '420px'
                });

                // If false or null, abort before discarding
                if (_res !== true)
                    return;
            }

            this.selectedSection = null;
            this.selectedChartItem = null;
        },
        deleteSection(index) {
            if (isNullOrEmpty(this.selectedConfig?.data?.orderedSections) || !(index in this.selectedConfig.data.orderedSections))
                return toast({
                    title: 'Cannot find section in dataset',
                    variant: 'danger'
                });

            return iwsConfirm({
                title: 'Are you sure?',
                body: `Deleting '${this.selectedConfig.data.orderedSections[index]?.name}' will result in losing all ${this.selectedConfig.data.orderedSections[index]?.orderedChartItems?.length} Data Source(s) in it. Do you want to proceed?`,
                confirmColour: 'danger',
                width: '420px'
            }).then(_answer => {
                if (_answer === true)
                    this.selectedConfig.data.orderedSections.splice(index, 1);
            });
        },
        isSectionValid() {
            return !isFalsy(this.selectedSection?.name)
        },

        getChartLineColour(chartItem) {
            if (isFalsy(chartItem))
                return null;
            return chartItem.color;
        },
        getTagAbbreviation(tagName) {
            // adds VP or HP to the well name if the chart item is a valve position or handle position
            const valvePositionTag = '_valvePosition_';
            const handlePositionTag = '_handlePosition_';
            let isValvePosition = tagName.includes(valvePositionTag);
            let isHandlePosition = tagName.includes(handlePositionTag);
            let type = isValvePosition ? ' VP' : isHandlePosition ? ' HP' : '';
            return type;
        },
        getTagDataByTagName(tagName) {
            return this.dashboardData?.tags?.find(tag => tag.name === tagName);
        },
        isWellheadTag(tagName) {
            return !!this.getTagDataByTagName(tagName)?.isWellheadTag;
        },
        getWellheadPrefixForTag(tagName) {
            return this.getTagDataByTagName(tagName)?.wellheadTagName?.prefix ?? '';
        },
        getWellheadSuffixTitleForTag(tagName) {
            return this.getTagDataByTagName(tagName)?.wellheadTagName?.suffixTitle ?? '';
        },
        getWellheadTagNameForChartItem(chartItem) {
            const tag = this.getTagDataByTagName(chartItem.tagName);
            let prefixName = chartItem?.prefixName;
            let suffixTitleName = '';

            if (tag?.isWellheadTag) {
                // wellhead tag names should have data for special prefixes, but if not 
                // fallback to the friendly name prefix input by the chart config modal
                prefixName = tag?.wellheadTagName?.prefix || prefixName;
                suffixTitleName = tag?.wellheadTagName?.suffixTitle;
            }

            return `${prefixName || ''} ${suffixTitleName || ''} ${chartItem?.suffixName || ''} (${tag?.name || ''})`;
        },
        getWell(chartItem) {
            const tagName = chartItem?.tagName || chartItem?.name;
            if (tagName?.includes('wellhead_')) {
                const wellIndex = tagName.match(/\d+/)[0] - 1;
                return this.wells.find(well => well.index === wellIndex);
            }

            return null
        },

        openMeasurementOptions(measurementUnits) {
            this.measurementUnits = measurementUnits;

            return new Promise( (resolve) => {
                this.resolveMeasurement = (value) => {
                    this.measurementUnits = null;
                    this.selectedMeasurement = null;
                    resolve(value);
                };
            });
        },
        async onTagChanged(newTagName) {
            const tag = this.dashboardData?.tags.find(_tag => _tag.name == newTagName);
            
            // when change tag, reset custom line color
            this.selectedChartItem.customLineColor = false;

            if (!isFalsy(tag?.name)) {
                try {
                    // Specific key data points should be pulled over from the tag measurement tables
                    const _measurementUnits = await $.get(`/measurement/${tag.standard_tag_name_id}`);

                    if (!isNullOrEmpty(_measurementUnits)) {
                        const measurement = _measurementUnits?.length == 1
                            ? _measurementUnits[0]
                            : await this.openMeasurementOptions(_measurementUnits);

                        if (!isFalsy(measurement)) {
                            if (!isFalsy(measurement?.abbreviation))
                                Vue.set(tag, 'unit', measurement.abbreviation);
                            if (!isFalsy(measurement?.decimal_places))
                                Vue.set(tag, 'decimalPrecision', measurement.decimal_places);
                            if (!isFalsy(measurement?.color))
                                Vue.set(tag, 'wellColor', measurement.color);
                            if (!isFalsy(measurement?.defaultaxis))
                                Vue.set(tag, 'defaultAxis', measurement.defaultaxis);
                        }
                    }
                } catch(e) {
                    console.log(e);
                }

                Vue.set(this.selectedChartItem, 'tagName', tag.name);
                Vue.set(this.selectedChartItem, 'friendlyName', tag.prioritizedTagName);

                let suffixPositionName = null;

                if (tag?.valveName) {
                    Vue.set(this.selectedChartItem, 'wellShortName', `${tag.valveName} ${this.getTagAbbreviation(tag.name)}`);
                     // If a tag has a valve name it is using, show the well shortname after it
                    suffixPositionName = tag.wellShortName;
                } else {
                    Vue.set(this.selectedChartItem, 'wellShortName', tag.wellShortName);
                    suffixPositionName = tag?.dynamicTagName;
                }

                Vue.set(this.selectedChartItem, 'suffixPositionName', suffixPositionName);
                Vue.set(this.selectedChartItem, 'wellColor', tag.wellColor);

                this.selectedChartItem.color = tag.wellColor ? tag.wellColor : this.getRandomColor();
        
                if (tag.unit) {
                    Vue.set(this.selectedChartItem, 'unit', tag.unit);
                } else{
                    Vue.set(this.selectedChartItem, 'unit', null);
                }

                if (tag.color) {
                    Vue.set(this.selectedChartItem, 'color', tag.wellColor ? tag.wellColor : tag.color);
                }
                
                if (tag.decimalPlaces != null) {
                    Vue.set(this.selectedChartItem, 'decimalPrecision', tag.decimalPlaces);                 
                } else {
                    Vue.set(this.selectedChartItem, 'decimalPrecision', this.defaultDecimalPrecision);  
                }

                if (tag.defaultAxis) {
                    //set default axis here
                    let axis = {
                        "customGridlinesColor": "#FFFFFFFF",
                        "displayGridlines": false,
                        "hideYAxis": !!tag.defaultAxis.hideAxis,
                        "isPrimaryAxis": false,
                        "key": tag.defaultAxis.id,
                        "label": tag.defaultAxis.label,
                        "logarithmic": tag.scaleType == "logarithmic",
                        "max": tag.defaultAxis.max,
                        "min": tag.defaultAxis.min,
                        "position": tag.defaultAxis.position,
                        "ticks": null,
                        "useCustomGridlinesColor": true
                    };

                    //add default axis to list of available axis' if not existing
                    let existingTempAxis = this.selectedConfig?.data?.chartYAxes.find(configAxis => configAxis.key == tag.defaultAxis.key || configAxis.key == tag.defaultAxis.id);
                    
                    if (!isFalsy(existingTempAxis)) {
                        if (!isFalsy(existingTempAxis?.chartItems))
                            existingTempAxis.chartItems.push(tag);
                    } else if (!isNullOrEmpty(this.selectedConfig?.data?.chartYAxes)) {
                        this.selectedConfig.data.chartYAxes.push(axis);
                    } else {
                        this.selectedConfig.data.chartYAxes = [ axis ];
                    }

                    let existingConfigAxis = this.selectedConfig?.yaxes?.find(configAxis => configAxis.key == axis.key);
                    if (!isFalsy(existingConfigAxis?.chartItems)) {
                        existingConfigAxis.chartItems.push(tag);
                    } else if (!isNullOrEmpty(this.selectedConfig?.yaxes)) {
                        this.selectedConfig.yaxes.push(axis);
                    } else {
                        this.selectedConfig.yaxes = [ axis ];
                    }

                    //set key to axis that now exists in the available axis'
                    Vue.set(this.selectedChartItem, 'chartYAxis_key', axis.key);   
                    Vue.set(this.selectedChartItem, 'chartyaxis', existingTempAxis ? existingTempAxis : axis);
                } else {   
                    //clear axis if none found
                    //clear item out of axis' items list
                    if (this.selectedChartItem.chartYAxis_key) {
                        const oldExistingAxis = this.selectedConfig?.data?.chartYAxes?.find(configAxis => configAxis.key == this.selectedChartItem.chartYAxis_key);
                        const existingIndex = oldExistingAxis?.chartItems?.findIndex(item => item.key == this.selectedChartItem.chartYAxis_key);
                        if (existingIndex) 
                            oldExistingAxis.chartItems.splice(existingIndex, 1);
                    }
                    
                    Vue.set(this.selectedChartItem,'chartYAxis_key', null);   
                    Vue.set(this.selectedChartItem, 'chartyaxis', null);
                }
            }
        },

        getRandomColor() {
            return '#'+Math.floor(Math.random()*16777215).toString(16);
        },
        addChartItem() {
            if (isFalsy(this.selectedSection?.name))
                return toast({
                    title: 'Section name cannot be empty!',
                    variant: 'danger'
                });

            this.editChartItem({
                tagName: '',
                friendlyName: '',
                lineWidth: 1,
                bgColor: null,
                color: this.getRandomColor(),
                chartyaxis: '',
                decimalPrecision: this.defaultDecimalPrecision
            }, -1);
        },
        editChartItem(chartItem, index) {
            if (!this.isSectionValid())
                return toast({
                    title: 'Cannot find section in dataset',
                    variant: 'warning'
                });

            // Deep clone the selected item to discard unsaved changes
            const _clone = _.cloneDeep(chartItem);

            // Ensure that chart item has a color on edit, especially if it is a wellbore tag
            if (isFalsy(_clone.bgColor) && this.isWellheadTag(_clone.tagName))
                _clone.bgColor = this.getWell(_clone)?.bgColor;

            if (isFalsy(_clone.timeoutThreshold))
                _clone.timeoutThreshold = 0;
            
            // If already has a colour set, show picker (if not do not use custom background colour until manually enabled)
            this.chartItemBgColorEnabled = !isFalsy(_clone.bgColor);

            this.$set(_clone, 'staticSource', !isFalsy(_clone.staticSource))

            // Should switch to target chart item edit view
            this.selectedChartItem = _clone;
            this.selectedChartItemIndex = index;
        },
        deleteChartItem(chartItem, index) {
            if (isFalsy(chartItem) || isNullOrEmpty(this.selectedSection?.orderedChartItems) || !(index in this.selectedSection.orderedChartItems))
                return toast({
                    title: 'Cannot find section in dataset',
                    variant: 'warning'
                });

            return iwsConfirm({
                title: 'Are you sure?',
                body: `Do you really want to remove '${chartItem?.friendlyName || chartItem?.tagName}'?`,
                confirmColour: 'danger',
                width: '420px'
            }).then(_answer => {
                if (_answer === true) {
                    this.selectedSection.orderedChartItems.splice(index, 1);

                    if (!isNullOrEmpty(this.selectedConfig?.data?.chartYAxes))
                        this.selectedConfig.data.chartYAxes.forEach(axis => {
                            if(axis?.chartItems) {
                                const id = axis.chartItems.findIndex(element => { return element.tagName == chartItem.tagName });
                                if (id != -1)
                                    axis.chartItems.splice(id, 1);
                            }
                        });

                    if (!isNullOrEmpty(this.selectedSection.chartItemLayout))
                        this.removeChartItemFromLayout(index);
                }
            });
        },
        confirmChartItem() {
            // Nothing to save
            if (isFalsy(this.selectedChartItem) || isFalsy(this.selectedSection?.orderedChartItems))
                return;

            if (!this.$refs?.custom_form?.validate())
                return toast({
                    title: 'Please fix all fields',
                    variant: 'danger'
                });

            // Save the current selected chartItem to the dataset then clear it to return to normal state
            if (!isFalsy(this.selectedChartItemIndex)) {
                this.selectedChartItem.key = this.selectedChartItem.key || uuidv4();

                if (this.selectedChartItemIndex < 0) {
                    this.selectedSection.orderedChartItems.push(_.cloneDeep(this.selectedChartItem));
                } else {
                    this.$set(this.selectedSection.orderedChartItems, this.selectedChartItemIndex, _.cloneDeep(this.selectedChartItem));
                }

                this.addChartItemToLayout(this.selectedChartItem);

                this.selectedChartItem = null;
                this.selectedChartItemIndex = null;

                toast({
                    title: 'Changes saved',
                    variant: 'success'
                });
            } else {
                return toast({
                    title: 'Cannot find chart item in dataset',
                    variant: 'danger'
                });
            }
        },
        async clearChartItem(confirmDiscard=true) {
            // When hitting cancel, if there are unsaved changes, we will ask to user to confirm discarding them
            if (confirmDiscard && this.selectedChartItemHasChanged) {
                const _res = await iwsConfirm({
                    title: 'Are you sure?',
                    body: `You have unsaved changes to '${this.selectedChartItem?.friendlyName || this.selectedChartItem?.tagName}' that will be lost if you cancel now`,
                    confirmColour: 'danger',
                    width: '420px'
                });

                // If false or null, abort before discarding
                if (_res !== true)
                    return;
            }

            this.selectedChartItem = null;
        },

        findFullSelectedAxis(chartYAxis_key=this.selectedChartItem?.chartYAxis_key) {
            if (isNullOrEmpty(this.selectedConfig?.data?.chartYAxes))
                return null;
            return this.selectedConfig?.data?.chartYAxes?.find(axis => axis.key === chartYAxis_key);
        },
        addAxis() {
            this.selectedConfig.data.chartYAxes.push({
                'label': 'New Axis',
                'min': 0,
                'max': 100,
                'ticks': 10,
                'position': 'left',
                'displayGridlines': false,
                'useCustomGridlinesColor': true,
                'customGridlinesColor': '#FFFFFFFF',
                'logarithmic': false,
                'hideYAxis': false,
                'color': '#FFFFFF',
                'isPrimaryAxis': false,
                'alignToPrimaryAxis': false,
                'key': `newYAxis-${ Date.now() }`
            });

            this.editAxis(this.selectedConfig.data.chartYAxes[this.selectedConfig.data.chartYAxes.length-1]);
        },
        editAxis(axis) {
            if (!axis?.useCustomGridlinesColor)
                this.$set(axis, 'useCustomGridlinesColor', true);
            if (!axis?.customGridlinesColor)
                this.$set(axis, 'customGridlinesColor', axis.customGridlinesColor || axis?.color || '#FFFFFF');

            this.selectedAxis = _.cloneDeep(axis);
        },
        deleteYAxis() {
            if (isFalsy(this.selectedChartItem?.chartYAxis_key))
                return toast({
                    title: 'Must select y axis to delete it',
                    variant: 'danger'
                });

            const setAxis = this.selectedConfig.data.chartYAxes.find(_axis => _axis.key == this.selectedChartItem.chartYAxis_key);
            const affectedChartItemsCount = this.selectedSection?.orderedChartItems?.filter(chartItem => {
                return chartItem.chartYAxis_key === this.selectedChartItem.chartYAxis_key;
            })?.length || 0;

            return iwsConfirm({
                title: 'Are you sure?',
                body: `Deleting '${setAxis?.label}' will remove all ${affectedChartItemsCount} data source(s) that use it.`,
                confirmColour: 'danger',
                width: '420px'
            }).then(_answer => {
                if (_answer === true) {
                    this.selectedSection.orderedChartItems = this.selectedSection?.orderedChartItems?.filter(chartItem => {
                        return chartItem.chartYAxis_key !== this.selectedChartItem.chartYAxis_key;
                    });

                    // Also delete chartItems that use this axis from selected config
                    this.selectedConfig.data.chartYAxes = this.selectedConfig?.data?.chartYAxes.filter(axis => axis.key !== this.selectedChartItem.chartYAxis_key) || [];

                    this.$refs.chartAxisSelect?.clearOption(false);
                }
            });
        },
        confirmAxis() {
            // Nothing to save
            if (isFalsy(this.selectedAxis) || isNullOrEmpty(this.selectedConfig?.data?.chartYAxes))
                return;

            if (!this.$refs?.custom_form?.validate())
                return toast({
                    title: 'Please fix all fields',
                    variant: 'danger'
                });

            const index = this.selectedConfig?.data?.chartYAxes?.findIndex(_section => _section.key === this.selectedAxis.key);

            // Save the current selected chartItem to the dataset then clear it to return to normal state
            if (!isFalsy(index) && index >= 0) {
                this.$set(this.selectedConfig.data.chartYAxes, index, _.cloneDeep(this.selectedAxis));
                this.selectedAxis = null;
                toast({
                    title: 'Changes saved',
                    variant: 'success'
                });

                this.$nextTick(() => {
                    this.$set(this.selectedChartItem, 'axisUpdate', true);
                });
            } else {
                return toast({
                    title: 'Cannot find chart item in dataset',
                    variant: 'danger'
                });
            }
        },
        async clearAxis(confirmDiscard=true) {
            // When hitting cancel, if there are unsaved changes, we will ask to user to confirm discarding them
            if (confirmDiscard && this.selectedAxisItemHasChanged) {
                const _res = await iwsConfirm({
                    title: 'Are you sure?',
                    body: `You have unsaved changes to '${this.selectedAxis?.label}' that will be lost if you cancel now`,
                    confirmColour: 'danger',
                    width: '420px'
                });

                // If false or null, abort before discarding
                if (_res !== true)
                    return;
            }

            this.selectedAxis = null;
        },

        removeChartItemFromLayout(index) {
            if (isNullOrEmpty(this.selectedSection?.chartItemLayout) || !(index in this.selectedSection?.chartItemLayout))
                return toast({
                    title: 'Cannot find chartItem in dataset',
                    variant: 'danger'
                });

            this.selectedSection.chartItemLayout.splice(index, 1);
            for (let i = index; i < this.selectedSection.chartItemLayout.length; i++)
                this.selectedSection.chartItemLayout[i].i = i;
        },
        addChartItemToLayout(newChartItem) {
            if (!isNullOrEmpty(this.selectedSection?.chartItemLayout)) {
                //get the current last chart item layout in the grid (highest row and column value)
                const lastRowValue = Math.max(...this.selectedSection.chartItemLayout.map(layout => layout.y));
                const lastRowLayouts = this.selectedSection.chartItemLayout.filter(layout => layout.y === lastRowValue);
                const lastColumnInLastRow = Math.max(...lastRowLayouts.map(layout => layout.x));
                const lastGridItem = this.selectedSection.chartItemLayout.find(layout => layout.y === lastRowValue && layout.x === lastColumnInLastRow);
                const lastCols = lastGridItem.x + lastGridItem.w - 1 + lastGridItem.w > this.defaultChartColumnCount - 1;

                this.selectedSection.chartItemLayout.push({
                    i: this.selectedSection.chartItemLayout.length,
                    t: newChartItem.tagName,
                    h: lastGridItem.h,
                    w: lastGridItem.w,
                    // Determine how many columns the last grid item occupies in its row, and if there is space for the next item
                    x: lastCols ? 0 : lastGridItem.x + lastGridItem.w,
                    // Assigns the new grid item to either the current row if space exists, or to a new row
                    y: lastCols ? lastGridItem.y + 2 : lastGridItem.y,
                    labelTextOptions: {},
                    dataTextOptions: {}
                });
            } else if ('chartItemLayout' in this.selectedSection && this.selectedSection.chartItemLayout !== null) { //No previous chart item layout data, so use the default grid item object for first values
                const gridItemObj = Object.assign({},this.defaultGridItemObj);
                gridItemObj.tagName = newChartItem.tagName;
                this.selectedSection.chartItemLayout.push(gridItemObj);
            }
        }
    }
};
</script>

<style>
    #TimeSeriesChart .content {
        min-height: calc(100vh - 222px);
    }

    .text-ellipsis-2 {
        display: inline !important;
        -webkit-line-clamp: 2 !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        display: -webkit-box !important;
        -webkit-box-orient: vertical !important;
        word-wrap: break-word !important;
    }

    .config-history-popover {
        border: none !important;
    }
    #history-popover {
        max-height: 60vh;
        overflow-y: auto;
    }
    #history-popover table {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        border: 1px solid #7B8A98;
    }
    
    #TimeSeriesChart .btn-outline-light.seen-step {
        background-color: rgba(0, 77, 191, 0.2);
    }
    #TimeSeriesChart .btn-outline-light.seen-step:hover {
        background-color: rgba(0, 77, 191, 0.05);
        color: white !important;
    }

    #TimeSeriesChart .nav.nav-pills {
        position: sticky;
        top: -10px;
        z-index: 1;
    }

    #step-container #SearchInput.active input[data-v-00928927]:not(.is-invalid),
    #step-container #SearchInput.active .input-group-text[data-v-00928927] {
        border-color: white !important;
    }

    #step-container #accordion .card {
        border: none !important;
        background-color: #242A30 !important;
    }

    #step-container #accordion .card-header {
        background-color: none !important;
        border-bottom: none !important;
        padding: 5px 10px !important;
        
    }
    #step-container #accordion i {
        top: 8px !important;
    }
    #step-container #accordion h5 {
        font-size: 14px !important;
    }
    #step-container #accordion .card-body {
        padding: 10px;
    }
    #step-container #accordion .card-body>div {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
    }
    #step-container #accordion .card-body>div>span {
        float: right;
        overflow: hidden;

        display: inline-block;
        position: relative;
        background-color: #2E353D;
        z-index: 9;
        padding-left: 5px;
        padding-right: 5px;
    }
    #step-container #accordion .card-body>div:after {
        content: "  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .";
    }


    .template-card>div.clickable b label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: calc(100% - 30px);
        position: relative;
        top: 8px;
    }
    .active-card {
        border-color: var(--primary-color) !important;
    }
</style>
<style scoped>
    .btn-group {
        border-radius: 15px;
        margin: auto;
        display: table;
    }
    
    .btn-group .btn-primary {
        border: 1px solid white;
    }
    .btn-group .btn:focus {
        box-shadow: none !important;
    }
    .btn-group .btn:first-of-type {
        border-radius: 15px 0px 0px 15px;
    }
    .btn-group .btn:last-of-type {
        border-radius: 0px 15px 15px 0px;
    }

    .template-card {
        padding: 0px 10px 15px 10px;
    }
    .template-card>div.clickable {
        padding: 10px;

        border: 1px solid #7B8A98;
        border-radius: 4px;
    }
    .template-card>div.clickable:hover {
        background-color: rgba(255, 255, 255, 0.05);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .template-card>div.clickable b {
        font-size: 16px;
        color: white;

        display: block;
        padding-bottom: 25px;
        height: 70px;
    }

    .circle {
        position: relative;
        top: -2px;

        display: inline-block;
        border: 1px solid white;
        border-radius: 50%;

        height: 20px !important;
        width: 20px !important;
        padding-top: 2px;
        margin-right: 5px;

        font-size: 10px;
    }
    .btn-outline-light:not(.seen-step):hover .circle {
        border-color: #212529;
    }

    #step-container {
        padding: 1.5em 0px 0px 0px;
    }

    #step-container>div {
        padding: 0px 20px;
        height: 100%;
    }
    #step-container .box-container {
        border: 1px solid #7B8A98;
        border-radius: 4px;

        padding: 10px;
    }
    .box-container.position-relative {
        overflow-y: auto;
        height: calc(100vh - 280px);
    }
    .sections-list-container {
        overflow-y: auto;
        height: calc(100vh - 340px);
    }
    .ordered-chart-items-container {
        overflow-y: auto;
        height: calc(100vh - 395px);
    }

    hr {
        background-color: #7B8A98;
    }

    .fake-button {
        color: white;
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        border: 0px solid transparent;
        text-shadow: 0px 0px 0px transparent;
    }

    .fake-button:hover {
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        border: 0px solid transparent;
        text-shadow: 0px 0px 0px transparent;
    }

    .fake-button:active {
        outline: none;
        border: none;
    }

    .fake-button:focus {
        outline: 0;
    }

    #step-control>span {
        display: inline-block;
        margin: auto;
        width: calc(100% - 320px);
    }
    #step-control>span:first-of-type {
        float: left;
        width: 160px;
    }
    #step-control>span:last-of-type {
        float: right;
        width: 160px;
    }

    .border-top {
        border-top: 1px solid #7B8A98;
    }
    .container-label {
        font-size: 20px;
        margin-top: 5px;
        display: inline-block;
    }
    .float-buttons-right {
        display: inline-block;
        float: right;
    }
    .float-buttons-right>.btn:not(:last-child) {
        margin-right: 5px;
    }

    #sections-container {
        margin-top: 20px;
    }
    #sections-container>div {
        padding: 7.5px 0px
    }

    #edit-section-input {
        display: inline-block;
        width: calc(100% - 175px);
        max-width: 350px;
    }

    .datasource-container {
        margin-top: 20px
    }
    
    .chartItem-row {
        height: 45px !important;
        padding: 10px 0px !important;
    }
    .chartItem-row>span,
    .selected-chartItem-row>div>span {
        display: inline-block;
    }
    .box {
        width: 25px;
        height: 25px;
    }
    .list-label {
        font-size: 16px;
    }
    .chartItem-label {
        position: relative;
        top: -8px;
        
        width: calc(100% - 100px);
        padding: 0px 5px;
    }
    .selected-chartItem-row .chartItem-label {
        width: calc(100% - 145px);
    }

    #loading {
        width: 100%;
        text-align: center;
        padding: 20px;
    }
    #loading>div {
        height: 60px;
        width: 60px;
    }
    #no-items {
        font-size: 22px;
        text-align: center;

        margin: 30px;
        color: var(--danger-color);
    }

    #axis-action-container {
        position: relative;
        top: -2px;
        padding-left: 10px;
    }

    #measurementUnitsModal>div {
        padding: 0px 10px 15px 10px;
    }
    .measurement-unit-container {
        border: 1px solid #7B8A98;
        border-radius: 4px;
        padding: 10px;
    }
    .measurement-unit-container.clickable:hover {
        background-color: rgba(255, 255, 255, 0.05);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .selected-measurement {
        border-color: var(--primary-color) !important;
    }
    .measurement-title {
        font-size: 25px;
        padding-bottom: 10px
    }
    .measurement-body {
        font-size: 16px;
    }
</style>