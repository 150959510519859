import { render, staticRenderFns } from "./SingleWellDisplayComponent.vue?vue&type=template&id=ffb3dc48&scoped=true&"
import script from "./SingleWellDisplayComponent.vue?vue&type=script&lang=js&"
export * from "./SingleWellDisplayComponent.vue?vue&type=script&lang=js&"
import style0 from "./SingleWellDisplayComponent.vue?vue&type=style&index=0&id=ffb3dc48&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffb3dc48",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/9519/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ffb3dc48')) {
      api.createRecord('ffb3dc48', component.options)
    } else {
      api.reload('ffb3dc48', component.options)
    }
    module.hot.accept("./SingleWellDisplayComponent.vue?vue&type=template&id=ffb3dc48&scoped=true&", function () {
      api.rerender('ffb3dc48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/SingleWellDisplayComponent.vue"
export default component.exports