<template>
<div>
    <div class="row">
        <div class="col-md-6">
                <label>First Name</label>
                <div class="field-output">
                    {{ user.first_name }}
                </div>
            </div>
            <div class="col-md-6">
                <label>Last Name</label>
                <div class="field-output">
                    {{ user.last_name }}
                </div>
            </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-6">
                <label>Email</label>
                <div class="field-output">
                    {{ user.email }}
                </div>
            </div>
            <div class="col-md-6">
                <label>Username</label>
                <div class="field-output">
                    {{ user.username }}
                </div>
            </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-6">
                <label>Phone</label>
                <div class="field-output">
                    {{ user.phone_number ? user.phone_number:'&nbsp' }}
                </div>
            </div>
            <div class="col-md-6">
                <label>Tooltip Font Size</label>
                <iws-select :value.sync="tooltipFontSize" :options="tooltipOptions" display-name="label" value-name="value" @change="tooltipChanged" style="padding:5px;" >

                </iws-select>
                <br/>
                <ul>
                    <li>Medium:<span style="font-size: 12px;"> Sample Text</span> </li>
                    <li>Large:<span style="font-size: 16px;"> Sample Text</span></li>
                    <li>Extra Large:<span style="font-size: 20px;"> Sample Text</span></li>
                </ul>
            </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-6 d-flex">
            <button class="btn btn-primary" @click="()=> this.$bvModal.show('password-change-modal')">Change Password</button>
        </div>
        <b-modal
            id="password-change-modal"
            size="xl"
            hide-header-close
            header-class="border-bottom-0 justify-content-center pb-0"
            footer-class="border-top-0 pt-0"
            content-class="modal-bg"
            :ok-disabled="true"
            hide-footer
            >
            <change-password-component :passwordStrengthRequired="passwordStrengthRequired" :requestedByUser="true" :user="user"/>
        </b-modal>
    </div>
</div>
</template>

<script>
export default {
    props: ['user', 'passwordStrengthRequired'],

    data() {
        return {
            tooltipFontSize: 'medium',
            tooltipOptions : [
                {value: 'medium', label: 'Medium'},
                {value: 'large', label: 'Large'},
                {value: 'x-large', label: 'Extra Large'}
            ]
        }
    },

    methods: {
        tooltipChanged() {
            localStorage.setItem('tooltipFontSize' + this.user.id, this.tooltipFontSize);
        },
    },
    mounted() {
        let tooltipFontSize = localStorage.getItem('tooltipFontSize' + this.user.id);
        if (tooltipFontSize) {
            this.tooltipFontSize = tooltipFontSize;
        }else{
            localStorage.setItem('tooltipFontSize' + this.user.id, 'medium');
        }
    },
}
</script>

<style scoped>
.field-output{
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #676E78;
    background: #343A40;
}
</style>
