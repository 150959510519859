var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "d-flex flex-column align-items-center" }, [
        _vm.requestedByUser
          ? _c("div", { staticClass: "font-large" }, [
              _vm._v("Change password"),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.requestedByUser && !_vm.passwordResetEmail
          ? _c("div", { staticClass: "font-large mt-4 mb-3" }, [
              _vm._v(
                "Please change your password to meet the new requirements"
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.passwordResetEmail
          ? _c("div", { staticClass: "font-large mt-4 mb-3" }, [
              _vm._v("Reset Password"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isSuccess
          ? _c("div", { staticClass: "text-success" }, [
              _vm._v(
                "\n                Password successfully changed\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isSuccess && !_vm.requestedByUser && !_vm.passwordResetEmail
          ? _c("div", { staticClass: "text-success" }, [
              _vm._v(
                "\n                Redirecting to home page\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.errorOcurred
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n                Failed to update password\n            "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "form-group px-5" }, [
        _vm.requestedByUser || _vm.passwordResetEmail
          ? _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "form-label col-3" }, [
                _vm._v("Email"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group has-validation col-9" }, [
                _c("input", {
                  staticClass: "form-control",
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "text",
                    "aria-describedby": "inputGroupPrepend",
                    readonly: "",
                  },
                  domProps: { value: _vm.userData.email },
                }),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.passwordResetEmail
          ? _c("div", { staticClass: "row pt-4 align-items-end" }, [
              _c("label", { staticClass: "form-label col-3" }, [
                _vm._v("Current password"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group has-validation col-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentPassword,
                      expression: "currentPassword",
                    },
                  ],
                  class:
                    "form-control " + _vm.errors.currentPassword
                      ? "is-invalid"
                      : "",
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "password",
                    "aria-describedby": "inputGroupPrepend",
                    required: "",
                  },
                  domProps: { value: _vm.currentPassword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.currentPassword = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.errors.currentPassword
                          ? _vm.errors.currentPassword[0]
                          : ""
                      ) +
                      "\n                    "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row pt-4" }, [
          _c("label", { staticClass: "form-label col-3" }, [
            _vm._v("Password"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group has-validation col-9" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newPassword,
                  expression: "newPassword",
                },
              ],
              class: "form-control " + +_vm.errors.password ? "is-invalid" : "",
              staticStyle: { width: "100%" },
              attrs: {
                type: "password",
                "aria-describedby": "inputGroupPrepend",
                required: "",
              },
              domProps: { value: _vm.newPassword },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.newPassword = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "invalid-feedback" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.errors.password ? _vm.errors.password[0] : "") +
                  "\n                    "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pt-4" }, [
          _c("label", { staticClass: "form-label col-3" }, [
            _vm._v("Confirm password"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group has-validation col-9" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.confirmNewPassword,
                  expression: "confirmNewPassword",
                },
              ],
              class:
                "form-control " + +_vm.errors.confirmPassword
                  ? "is-invalid"
                  : "",
              staticStyle: { width: "100%" },
              attrs: {
                type: "password",
                "aria-describedby": "inputGroupPrepend",
                required: "",
              },
              domProps: { value: _vm.confirmNewPassword },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.confirmNewPassword = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "invalid-feedback" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(
                    _vm.errors.confirmPassword
                      ? _vm.errors.confirmPassword[0]
                      : ""
                  ) +
                  "\n                    "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.passwordStrengthRequired
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-3" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-9" },
                [
                  _c("password-strength-component", {
                    attrs: {
                      password: _vm.newPassword,
                      firstName: _vm.userData.first_name,
                      lastName: _vm.userData.last_name,
                      email: _vm.userData.email ?? _vm.email,
                      currentPassword: _vm.currentPassword ?? "",
                      passwordResetEmail: _vm.passwordResetEmail,
                    },
                    on: {
                      requirementsMet: (value) =>
                        (_vm.passRequirementsMet = value),
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", [
      !_vm.passwordResetEmail
        ? _c(
            "div",
            { staticClass: "d-flex w-100 flex-row-reverse" },
            [
              _c("iws-button", {
                attrs: {
                  type: "success",
                  disabled:
                    _vm.passwordStrengthRequired && !_vm.passRequirementsMet,
                  text: "Save",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onSavePasswordClicked.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex w-100 flex-row-reverse" },
            [
              _c("iws-button", {
                attrs: {
                  type: "success",
                  disabled:
                    _vm.passwordStrengthRequired && !_vm.passRequirementsMet,
                  text: "Reset Password",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onSavePasswordClicked.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }